// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

function ActionsCell({ onDelete }) {
  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" px={2}>
      <SoftBox color="darkgrey" lineHeight={0}>
        <Icon
          sx={{
            fontWeight: "bold",
          }}
          fontSize="default"
          onClick={onDelete}
        >
          <DeleteForeverIcon />
        </Icon>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the RefundsCell
ActionsCell.propTypes = {
  onDelete: PropTypes.func,
};

export default ActionsCell;
