import PropTypes from "prop-types";
import SoftBox from "../SoftBox";

const IconAvatar = ({ size = "md", children, sx }) => {
  // make a switch statement for width & height, calculated by size
  const sizes = {
    xs: "0.5rem",
    sm: "1rem",
    md: "2rem",
    lg: "3rem",
    xl: "4rem",
    xxl: "5rem",
  };

  return (
    <SoftBox
      sx={{
        ...sx,
        width: sizes[size],
        height: sizes[size],
        borderRadius: "50%",
        backgroundColor: "grey.200",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "secondary",
      }}
    >
      {children}
    </SoftBox>
  );
};

IconAvatar.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default IconAvatar;
