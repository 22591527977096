import PropTypes from "prop-types";
import SliderCard from "src/components/SliderCard";
import Fab from "@mui/material/Fab";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

const StageSliderCard = ({
  backgroundColor = "primary.background",
  onAddStage,
  onClick = () => {},
  children,
}) => {
  if (onAddStage) {
    return (
      <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
        <SliderCard
          width="200px"
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "140px",
            pl: 1.5,
            cursor: "default",
            boxShadow: "none",
          }}
        >
          <Fab
            sx={{ boxShadow: "none" }}
            variant="extended"
            color={backgroundColor}
            onClick={() => onAddStage(null)}
            aria-label="add stage"
          >
            <span style={{ paddingRight: "0.4rem" }}>+</span>
            Add Stage
          </Fab>
        </SliderCard>
      </WithPermissions>
    );
  }

  return (
    <SliderCard
      onClick={onClick}
      width="280px"
      sx={{
        backgroundColor: backgroundColor,
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "140px",
        boxShadow: "none",
      }}
    >
      {children}
    </SliderCard>
  );
};

StageSliderCard.propTypes = {
  backgroundColor: PropTypes.string,
  onAddStage: PropTypes.func,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default StageSliderCard;
