import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

// Custom styles for SoftProgress
import SoftProgressRoot from "./SoftProgressRoot";

const SoftProgress = forwardRef(
  ({ value = 0, valueBuffer = 0, color, label = false, ...rest }, ref) => (
    <>
      {label && (
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {value}%
        </SoftTypography>
      )}
      <SoftProgressRoot
        {...rest}
        ref={ref}
        variant="buffer"
        value={value}
        valueBuffer={valueBuffer}
        ownerState={{
          color,
          value,
          valueBuffer,
        }}
      />
    </>
  )
);

// Typechecking props for the SoftProgress
SoftProgress.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  valueBuffer: PropTypes.number,
  label: PropTypes.bool,
};

export default SoftProgress;
