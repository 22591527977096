import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
// import SoftBadge from 'src/components/SoftBadge';
import PlaneIcon from "src/assets/images/plane-icon.png";
import PropTypes from "prop-types";

function AircraftCard({ aircraft, children, image }) {
  return (
    <SoftBox bgColor="grey-100" padding={1} borderRadius="md">
      <SoftBox
        borderRadius="md"
        component="img"
        width="100%"
        src={image ?? aircraft?.photo ?? PlaneIcon}
      />
      <SoftTypography variant="h4" color="primary" textAlign="center">
        N{aircraft?.tailNumber || ""}
      </SoftTypography>
      <SoftTypography variant="h6" textAlign="center">
        {aircraft?.displayName ?? ""}
      </SoftTypography>
      <SoftTypography
        variant="body2"
        textAlign="center"
        style={{ fontSize: 12, width: "100%" }}
      >
        {(aircraft?.aircraft_description?.model ?? aircraft?.details?.model) ||
          ""}
      </SoftTypography>
      {/* <SoftBox> // TODO: Add aircraft category and add badges back in
        {aircraft?.airworthiness?.category
          .filter((cat) => cat)
          .map((cat) => (
            <SoftBadge
              key={cat}
              variant="contained"
              color="success"
              size="xs"
              badgeContent={cat}
              container
              sx={{
                marginRight: 1,
              }}
            />
          ))}
      </SoftBox> */}
      <SoftBox mt={1}>{children}</SoftBox>
    </SoftBox>
  );
}

AircraftCard.propTypes = {
  aircraft: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default AircraftCard;
