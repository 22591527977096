import { ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SoftBox from "../SoftBox";
import { Icon } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

interface IPrintPreviewActionButtonProps {
  children: ReactNode;
}

const PrintPreviewActionButton = ({
  children,
  ...rest
}: IPrintPreviewActionButtonProps) => {
  const printableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  return (
    <div>
      <Icon onClick={handlePrint} {...rest}>
        <PrintIcon />
      </Icon>
      <SoftBox style={{ display: "none" }}>
        <div ref={printableRef}>{children}</div>
      </SoftBox>
    </div>
  );
};

export default PrintPreviewActionButton;
