import { useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { getFunctions, httpsCallable } from "firebase/functions";
import { createStripeCustomer, getUserWithId } from "src/features/user/utils";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import toastr from "toastr";
import { useClubs } from "src/features/club/ClubProvider";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Please provide the name as shown on the card."),
    zip: yup.string().required("Your cards zip code is required."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "visible",
  boxShadow: 24,
  p: 4,
};

function AddNewCard({ handleClose, stripe, elements, userId }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { globalUsersSnapReload } = useClubs();

  const onSubmit = async (data) => {
    const user = (await getUserWithId(userId))?.data();

    if (!stripe || !elements || !user) {
      return;
    }
    setIsSubmitting(true);

    if (!user.stripeId) {
      const stripeCustomer = await createStripeCustomer(user);
      user.stripeId = stripeCustomer.data.id;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement("card"),
      billing_details: {
        name: data.name,
        address: {
          line1: data.addressLine1,
          line2: data.addressLine1,
          city: data.city,
          state: data.state,
          country: "US",
          postal_code: data.zip,
        },
      },
    });

    if (result.error) {
      console.error(result.error.message);
      toastr.error(result.error.message);
      setIsSubmitting(false);
      return;
    }

    const functions = getFunctions();
    const addNewCardToCustomer = httpsCallable(
      functions,
      "addNewCardToCustomer"
    );
    addNewCardToCustomer({
      customerId: user.stripeId,
      paymentMethodId: result.paymentMethod.id,
    })
      .then(() => {
        if (result.error) {
          console.error(result.error.message);
          toastr.error(result.error.message);
          setIsSubmitting(false);
        } else {
          globalUsersSnapReload();
          toastr.success("Card added successfully");
          handleClose({ success: true });
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.error(error.message);
        setIsSubmitting(false);
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
      },
    },
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#168895",
      },
    },
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add New Card
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1} mb={4}>
              <Grid item xs={12} sm={12}>
                <SoftBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Card Details
                  </SoftTypography>
                </SoftBox>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </Grid>
            </Grid>
            <SoftBox>
              <SoftTypography variant="h6" fontWeight="bold">
                Billing Address
              </SoftTypography>
              <SoftBox mt={1.625}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Cardholder&rsquo;s Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="Cardholder's Name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors?.name?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.name.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Address Line 1
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="Address Line 1"
                        {...register("addressLine1", {
                          required: true,
                        })}
                      />
                      {errors?.addressLine1?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.addressLine1.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Address Line 2
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="Address Line 2"
                        {...register("addressLine2", {
                          required: true,
                        })}
                      />
                      {errors?.addressLine2?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.addressLine2.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          City
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="City"
                        {...register("city", {
                          required: true,
                        })}
                      />
                      {errors?.city?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.city.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          State
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="State"
                        {...register("state", {
                          required: true,
                        })}
                      />
                      {errors?.state?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.state.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SoftBox mb={2} lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Zip
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        placeholder="Zip"
                        {...register("zip", {
                          required: true,
                        })}
                      />
                      {errors?.zip?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.zip.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Add Card
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default function InjectedAddNewCard({ handleClose, ...rest }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <AddNewCard
          handleClose={handleClose}
          stripe={stripe}
          elements={elements}
          {...rest}
        />
      )}
    </ElementsConsumer>
  );
}

AddNewCard.propTypes = {
  handleClose: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  user: PropTypes.object.isRequired,
};

InjectedAddNewCard.propTypes = {
  handleClose: PropTypes.func,
};
