import { createContext, useContext, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserEnrollmentsCollection,
  getUserEnrolledCoursesCollection,
  getUserEnrollmentsCollectionGroup,
  getUserLessonGradingsCollection,
  getUserTaskGradingsCollection,
} from "src/features/user/collections";
import {
  getClubCoursesCollection,
  getCourseStagesCollection,
  getCourseLessonsCollection,
  getLessonTasksCollection,
  getLessonDocumentsCollection,
} from "src/features/lms/collections";

import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getDocs,
  getDoc,
  doc,
  where,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteField,
  and,
  deleteDoc,
} from "firebase/firestore";
import { entityCrudUtils } from "../firebase/firestore/entityCrudUtils";
import { getClubBookingsCollection } from "../club/collections";
import { differenceInSeconds } from "date-fns";

const LmsContext = createContext(null);
LmsContext.displayName = "LmsContext";

function LmsProvider({ children }) {
  const { user, userId } = useUser();
  const { updateData } = entityCrudUtils();
  const { selectedClubId, selectedLocationId } = useClubs();
  const [allStages, setAllStages] = useState(null); // all stages in selected club
  const [allLessons, setAllLessons] = useState(null); // all lessons in selected club
  const [allTasks, setAllTasks] = useState(null); // all tasks in selected club
  const [isStudent, setIsStudent] = useState(false);

  const userEnrollmentsRef = getUserEnrollmentsCollection(user?.uid);
  const { data: enrollments, isDataLoaded: enrollmentsLoaded } =
    useRealtimeCollectionData(userEnrollmentsRef, false);

  const { data: allCourses, isDataLoaded: clubCoursesLoaded } =
    useRealtimeCollectionData(getClubCoursesCollection(selectedClubId), false); // all courses in selected club

  const getAllTasks = async () => {
    const lessonTasks = await Promise.all(
      allLessons.map(async (lesson) => {
        const taskDocs = await getDocs(
          query(
            getLessonTasksCollection(
              selectedClubId,
              lesson.courseId,
              lesson.stageId,
              lesson.id
            ),
            where("deleted", "==", false),
            orderBy("index", "asc")
          )
        );
        return taskDocs.docs.map((taskDoc) => ({
          id: taskDoc.id,
          ...taskDoc.data(),
        }));
      })
    );
    setAllTasks(lessonTasks.flat());
  };

  const getAllLessons = async () => {
    const stageLessons = await Promise.all(
      allStages.map(async (stage) => {
        const lessonDocs = await getDocs(
          query(
            getCourseLessonsCollection(
              selectedClubId,
              stage.courseId,
              stage.id
            ),
            where("deleted", "==", false),
            orderBy("index", "asc")
          )
        );
        return lessonDocs.docs.map((lessonDoc) => ({
          id: lessonDoc.id,
          ...lessonDoc.data(),
        }));
      })
    );
    setAllLessons(stageLessons.flat());
  };

  const getAllStages = async () => {
    const courseStages = await Promise.all(
      allCourses.map(async (course) => {
        const stageDocs = await getDocs(
          query(
            getCourseStagesCollection(selectedClubId, course.id),
            where("deleted", "==", false),
            orderBy("index", "asc")
          )
        );
        return stageDocs.docs.map((stageDoc) => ({
          id: stageDoc.id,
          ...stageDoc.data(),
        }));
      })
    );
    setAllStages(courseStages.flat());
  };

  useEffect(() => {
    if (allCourses && clubCoursesLoaded) {
      getAllStages();
    }
  }, [allCourses]);

  useEffect(() => {
    if (allStages) {
      getAllLessons();
    }
  }, [allStages]);

  useEffect(() => {
    if (allLessons) {
      getAllTasks();
    }
  }, [allLessons]);

  useEffect(() => {
    if (enrollments) {
      enrollments.forEach((document) => {
        if (document.status === "active") {
          setIsStudent(true);
        }
      });
    }
  }, [enrollments, enrollmentsLoaded]);

  // Takes in an enrolledCourse and lessonGrades to get all current course & progress information
  const formatEnrolledCourse = async (
    enrolledCourse,
    lessonGrades,
    taskGradeDocs
  ) => {
    // get course and associated stage documents
    const course = allCourses?.find(
      (course) => course.id === enrolledCourse.course.value
    );
    const stages =
      allStages?.filter(
        (stage) => stage.courseId === enrolledCourse.course?.value
      ) || [];

    // initialize the progress tracking variables
    const progress = {
      stages: {
        total: stages?.length,
        completed: 0,
        byIndex: [],
      },
      lessons: {
        total: 0,
        completed: 0,
        requiredTotal: 0,
        requiredCompleted: 0,
      },
      tasks: {
        total: 0,
        completed: 0,
      },
    };
    let courseCurrentStageIndex = 0;
    let courseCurrentLesson;
    let status = enrolledCourse.status;

    const stagesWithLessons = await Promise.all(
      stages.map(async (stage) => {
        // get lessons and lessonGrades from firestore
        const lessons =
          allLessons?.filter((lesson) => lesson.stageId === stage.id) || [];

        // Discern which lessons are required, completed, and both
        // Required lessons have the isOptional tag set to false
        const requiredLessons = lessons.filter((lesson) => !lesson.isOptional);
        const lessonIdsWithGrades = [];
        const completedLessonIds = lessonGrades.docs
          ?.filter((lessonGrade) => {
            // Only track the most recent grade for each lesson, as previous ones aren't deleted
            if (lessonIdsWithGrades.includes(lessonGrade.data().lessonId)) {
              return false;
            }
            lessonIdsWithGrades.push(lessonGrade.data().lessonId);
            // completed lessons have a lessonGrade "S". We only need the lesson Id from the lesson Grade
            return (
              lessonGrade.data().submitted === true &&
              lessonGrade.data().lessonGrade === "S" &&
              lessonGrade.data().stageId === stage.id
            );
          })
          .map((lessonGrade) => lessonGrade.data().lessonId);
        // completed required lessons exist in requiredLessons and have an associated completedLessonId
        const requiredLessonsCompleted = requiredLessons.filter((lesson) =>
          completedLessonIds.includes(lesson.id)
        );

        const stageProgress = Math.floor(
          (requiredLessonsCompleted.length / requiredLessons.length) * 100
        );
        // If all the lessons are completed, the stage is completed
        if (stageProgress === 100) {
          progress.stages.completed += 1;
          if (stage?.index + 1 !== stages.length) {
            courseCurrentStageIndex += 1;
          }
        }
        // Set the details of the stage progress
        progress.stages.byIndex.push({
          progress: stageProgress,
          lessonsCompleted: completedLessonIds.length,
        });
        // update the lesson totals with the information from this stage
        progress.lessons.total += lessons?.length;
        progress.lessons.completed += completedLessonIds.length;
        progress.lessons.requiredTotal += requiredLessons.length;
        progress.lessons.requiredCompleted += requiredLessonsCompleted.length;
        // return formatted stage data
        return {
          ...stage,
          editable: stage.index <= progress.stages.completed,
          lessons: await Promise.all(
            lessons.map(async (lesson) => {
              // find lessonGrade and get task data
              const lessonGrade = lessonGrades.docs?.find(
                (l) => l.data().lessonId === lesson.id
              );
              const tasks =
                allTasks?.filter((task) => task.lessonId === lesson.id) || [];
              const documents = await getDocs(
                getLessonDocumentsCollection(
                  selectedClubId,
                  enrolledCourse.course?.value,
                  stage.id,
                  lesson.id
                )
              );
              // update the progress object
              if (
                lessonGrade?.data()?.submitted &&
                lessonGrade.data().lessonGrade === "S" &&
                !courseCurrentLesson
              ) {
                courseCurrentLesson = lesson.id;
              }
              progress.tasks.total += tasks?.length;
              // return formatted lesson data

              const taskGrades = taskGradeDocs.docs?.map((taskGrade) => ({
                id: taskGrade.id,
                ...taskGrade.data(),
              }));
              // Convert old taskGrades data format to new taskGrades data format
              if (lessonGrade?.data()?.taskGrades) {
                for (const taskId in lessonGrade.data().taskGrades) {
                  // check if there's a taskGrading object in fb with tasks from this lesson's taskGrades
                  const taskGrade = taskGrades.find(
                    (taskGradeDoc) => taskGradeDoc.taskId === taskId
                  );
                  // if no, create one for this task
                  if (!taskGrade) {
                    const newTaskGrade = await addDoc(
                      getUserTaskGradingsCollection(
                        enrolledCourse.member.value,
                        enrolledCourse.enrollmentId
                      ),
                      {
                        taskId,
                        grade: lessonGrade.data().taskGrades[taskId],
                        deleted: false,
                        enrollmentId: enrolledCourse.enrollmentId,
                        lessonId: lesson.id,
                        studentId: enrolledCourse.member.value,
                        createdAt: new Date(),
                      }
                    );
                    taskGrades.push(newTaskGrade);
                  } else {
                    // if there already is one, there was a problem updating the lessonGrading doc to remove task grades previously
                    console.error(
                      "LessonGrades did not update correctly when moving taskGrades over to the new data format."
                    );
                  }
                }
                // update lessonGrading to remove taskGrades
                await updateDoc(
                  doc(
                    getUserLessonGradingsCollection(
                      enrolledCourse.member.value,
                      enrolledCourse.enrollmentId
                    ),
                    lessonGrade.id
                  ),
                  {
                    taskGrades: deleteField(),
                  }
                );
              }

              const types = [];
              if (lesson.flightTime || lesson.simTime) {
                types.push("flight");
              }
              if (lesson.groundTime) {
                types.push("ground");
              }

              return {
                ...lesson,
                types,
                studentId: enrolledCourse.member?.value,
                instructorId: enrolledCourse.instructor.value,
                enrollmentId: enrolledCourse.enrollmentId,
                enrolledCourseId: enrolledCourse.id,
                id: lesson.id,
                documents: documents.docs.map((document) => ({
                  id: document.id,
                  ...document.data(),
                })),
                grade: { ...lessonGrade?.data(), id: lessonGrade?.id },
                tasks: tasks.map((task) => {
                  const taskGrade = taskGrades.find(
                    (tGrade) => tGrade.taskId === task.id
                  );
                  if (taskGrade && parseInt(taskGrade.grade) < 5) {
                    progress.tasks.completed += 1;
                  }
                  return {
                    ...task,
                    types,
                    grade: taskGrade,
                    enrollmentId: enrolledCourse.enrollmentId,
                    enrolledCourseId: enrolledCourse.id,
                  };
                }),
              };
            })
          ),
        };
      })
    );
    if (
      (progress.tasks.completed || progress.lessons.completed) &&
      status === "NOT_STARTED"
    ) {
      updateData(
        {
          entity: getUserEnrolledCoursesCollection(
            enrolledCourse.member.value,
            enrolledCourse.enrollmentId
          ),
          pathSegmentsArr: [enrolledCourse.id],
        },
        { status: "IN_PROGRESS" }
      );
      status = "IN_PROGRESS";
    }
    const lessonCompletionPercentage = Math.round(
      (progress.lessons.requiredCompleted / progress.lessons.requiredTotal) *
        100
    );
    const timeCompletionPercentage = Math.round(
      (differenceInSeconds(new Date(), enrolledCourse.startDate?.toDate()) /
        differenceInSeconds(
          enrolledCourse.completionDate?.toDate(),
          enrolledCourse.startDate?.toDate()
        )) *
        100
    );
    const pace = lessonCompletionPercentage - timeCompletionPercentage + 50;
    return {
      ...enrolledCourse,
      ...course,
      id: enrolledCourse.id,
      currentStageIndex: courseCurrentStageIndex,
      currentLesson: courseCurrentLesson,
      stages: stagesWithLessons,
      progress: {
        ...progress,
        pace,
      },
      status,
    };
  };

  // Takes in an enrollment and adds the info for its enrolledCourses with lessonGrade information
  const getFullEnrollmentData = async (
    enrollment,
    instructorId,
    courseConstraints = []
  ) => {
    const constraints = [...courseConstraints];
    if (instructorId) {
      constraints.unshift(where("instructor.value", "==", instructorId));
    }

    const enrolledCoursesData = await getDocs(
      query(
        getUserEnrolledCoursesCollection(
          enrollment.data().member.value,
          enrollment.id
        ),
        and(where("deleted", "==", false), ...constraints)
      )
    );

    const lessonGrades = await getDocs(
      query(
        getUserLessonGradingsCollection(
          enrollment.data().member.value,
          enrollment.id
        ),
        orderBy("completedAt", "desc")
      )
    );

    const taskGrades = await getDocs(
      query(
        getUserTaskGradingsCollection(
          enrollment.data().member.value,
          enrollment.id
        ),
        orderBy("createdAt", "desc")
      )
    );

    const enrolledCourses = await Promise.all(
      enrolledCoursesData.docs.map(async (enrolledCourseDoc) => {
        const enrolledCourseData = {
          id: enrolledCourseDoc.id,
          ...enrolledCourseDoc.data(),
          member: enrollment.data().member,
          graduationDate: enrollment.data().graduationDate,
          startDate: enrollment.data().startDate,
        };
        const enrolledCourse = await formatEnrolledCourse(
          enrolledCourseData,
          lessonGrades,
          taskGrades
        );
        return enrolledCourse;
      })
    );

    return {
      ...enrollment.data(),
      id: enrollment.id,
      enrolledCourses,
    };
  };

  // Takes in optional locationId & instructorId to return full enrollment data for a club
  const getClubEnrollmentsData = async (
    locationId,
    instructorId,
    courseConstraints = []
  ) => {
    const constraints = [where("clubId", "==", selectedClubId)];
    if (locationId) {
      constraints.push(where("locationId", "==", locationId));
    }
    const enrollmentsData = await getDocs(
      query(
        getUserEnrollmentsCollectionGroup(),
        ...constraints,
        where("deleted", "==", false)
      )
    );

    const returnData = Promise.all(
      enrollmentsData.docs.map(
        async (enrollmentDoc) =>
          await getFullEnrollmentData(
            enrollmentDoc,
            instructorId,
            courseConstraints
          )
      )
    );
    return returnData;
  };

  const getStudentEnrollmentsData = async (
    studentId,
    courseConstraints = []
  ) => {
    const enrollmentsData = await getDocs(
      query(
        getUserEnrollmentsCollection(studentId),
        where("clubId", "==", selectedClubId),
        where("deleted", "==", false)
      )
    );

    const returnData = Promise.all(
      enrollmentsData.docs.map(
        async (enrollmentDoc) =>
          await getFullEnrollmentData(enrollmentDoc, null, courseConstraints)
      )
    );

    return returnData;
  };

  const getUserEnrollmentsData = async (userId, courseConstraints = []) => {
    const studentEnrollments = await getStudentEnrollmentsData(
      userId,
      courseConstraints
    );
    const instructorEnrollments = await getClubEnrollmentsData(
      null,
      userId,
      courseConstraints
    );
    return [...studentEnrollments, ...instructorEnrollments];
  };

  // Used in the lesson and course view pages to obtain the info for a single enrolledCourse
  const getSingleEnrolledCourse = async (
    studentId,
    enrollmentId,
    enrolledCourseId
  ) => {
    const enrollmentDoc = await getDoc(
      doc(getUserEnrollmentsCollection(studentId), enrollmentId)
    );

    const enrolledCourseDoc = await getDoc(
      doc(
        getUserEnrolledCoursesCollection(studentId, enrollmentId),
        enrolledCourseId
      )
    );
    const lessonGrades = await getDocs(
      query(
        getUserLessonGradingsCollection(studentId, enrollmentId),
        where("courseId", "==", enrolledCourseDoc.data().course?.value),
        orderBy("completedAt", "desc")
      )
    );

    const taskGrades = await getDocs(
      query(
        getUserTaskGradingsCollection(studentId, enrollmentId),
        orderBy("createdAt", "desc")
      )
    );

    const enrolledCourse = await formatEnrolledCourse(
      {
        ...enrolledCourseDoc.data(),
        id: enrolledCourseDoc.id,
        member: enrollmentDoc.data().member,
        startDate: enrollmentDoc.data().startDate,
        graduationDate: enrollmentDoc.data().graduationDate,
      },
      lessonGrades,
      taskGrades
    );
    return {
      ...enrolledCourse,
      member: enrollmentDoc.data().member,
    };
  };

  const getLessonBookings = async (lessonId, memberId) => {
    const bookingData = await getDocs(
      query(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        where("extendedProps.pilot.value", "==", memberId),
        where("extendedProps.lesson.value", "==", lessonId)
      )
    );
    const bookings = bookingData.docs.map((booking) => ({
      id: booking.id,
      ...booking.data(),
    }));
    return bookings;
  };

  const checkIsStudent = async (userId) => {
    const courseDocs = await getDocs(
      query(
        getUserEnrollmentsCollection(userId),
        where("status", "==", "active"),
        where("deleted", "==", false)
      )
    );
    if (courseDocs.docs.length > 0) return true;

    return false;
  };

  const checkIsApprovedInstructor = (lesson) => {
    const permissions = lesson?.gradingPermissions;
    const isAssignedInstructor = userId === lesson.instructorId;
    if (userId === lesson.studentId) {
      return false;
    }
    if (permissions?.approvedInstructors?.length) {
      return !!permissions.approvedInstructors.find((instructor) => {
        return instructor.value === userId;
      });
    }
    switch (true) {
      case !permissions:
      case permissions?.allowAssignedInstructor &&
        permissions?.allowUnassignedInstructors:
      case isAssignedInstructor && permissions?.allowAssignedInstructor:
      case !isAssignedInstructor && permissions?.allowUnassignedInstructors:
        return true;
      default:
        return false;
    }
  };

  const getTasksFromEnrolledCourse = (enrolledCourse, customFilter) => {
    return enrolledCourse.stages.flatMap((stage) =>
      stage.lessons.flatMap((lesson) =>
        customFilter ? lesson.tasks.filter(customFilter) : lesson.tasks
      )
    );
  };

  const getTasksFromFullEnrollmentsData = (enrollments, customFilter) => {
    return enrollments.flatMap((enrollment) =>
      enrollment.enrolledCourses.flatMap((enrolledCourse) =>
        getTasksFromEnrolledCourse(enrolledCourse, customFilter)
      )
    );
  };

  const completeStage = async (studentId, enrollmentId, stageData) => {
    const newLessonGrades = Promise.all(
      stageData.lessons.map(async (lesson) => {
        if (!lesson.grade.id && !lesson.isOptional) {
          const lessonGradeData = {
            lessonId: lesson.id,
            courseId: lesson.courseId,
            stageId: lesson.stageId,
            savedAt: new Date(),
            completedAt: new Date(),
            autoGenerated: true,
            submitted: true,
            deleted: false,
            lessonGrade: "S"
          }
          // create grade with value S and autoGenerated true
          const newLessonGrade = await addDoc(
            getUserLessonGradingsCollection(studentId, enrollmentId),
            lessonGradeData
          );
          if (newLessonGrade) {
            return {
              ...lessonGradeData,
              id: newLessonGrade.id
            }
          }
        }
      })
    )
    return newLessonGrades
  }

  const undoCompleteStage = async (studentId, enrollmentId, stageData) => {
    const newLessonGrades = Promise.all(
      stageData.lessons.map(async (lesson) => {
        if (lesson.grade?.autoGenerated) {
          return await deleteDoc(
            doc(
              getUserLessonGradingsCollection(
                studentId,
                enrollmentId
              ),
              lesson.grade.id
            )
          );
        }
      })
    );
    return newLessonGrades;
  };

  const value = {
    allCourses,
    allStages,
    allLessons,
    allTasks,
    clubCoursesLoaded,
    enrollments,
    isStudent,
    checkIsStudent,
    getClubEnrollmentsData, // gets enrollment data for the current club, optionally filtered by location and/or instructor
    getSingleEnrolledCourse, // gets enrollment data for a single enrolledCourse
    getStudentEnrollmentsData, // gets enrollments where user is student
    getUserEnrollmentsData, // gets all enrollments where user is either student or instructor
    getLessonBookings,
    checkIsApprovedInstructor,
    getTasksFromEnrolledCourse,
    getTasksFromFullEnrollmentsData,
    completeStage, // creates auto-grades for all lessons within a stage and updates progress object
    undoCompleteStage
  };

  return <LmsContext.Provider value={value}>{children}</LmsContext.Provider>;
}

function useLms() {
  const context = useContext(LmsContext);

  if (!context) {
    throw new Error("useLms should be used inside the LmsContextProvider.");
  }

  return context;
}

LmsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LmsProvider, useLms };
