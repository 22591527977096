import TableBody from "@mui/material/TableBody";

import { Droppable } from "react-beautiful-dnd";

const DroppableBody = (id) =>
  function (props) {
    return (
      <Droppable key={id} droppableId={id} direction="vertical">
        {(provided) => (
          <TableBody
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    );
  };

export default DroppableBody;