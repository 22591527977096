import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";

interface ISoftSelectLabelProps {
  children: any;
  labelText: string;
  borderless?: boolean;
  hidden?: boolean;
}

function SoftSelectLabel({
  children,
  labelText,
  borderless,
  hidden,
}: ISoftSelectLabelProps) {
  const containerSX = borderless
    ? {
        width: "220px",
        position: "relative",
        pr: 2,
      }
    : {};

  const labelSX: any = borderless
    ? {
        position: "absolute",
        top: "-6px",
        mb: 1,
      }
    : {
        my: 1,
      };

  if (hidden) {
    labelSX.display = "none";
  }

  return (
    <SoftBox flex={1} sx={containerSX}>
      <SoftBox sx={labelSX} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          aria-label={labelText.split(" ").join("-")}
        >
          {labelText}
        </SoftTypography>
      </SoftBox>
      {children}
    </SoftBox>
  );
}

export default SoftSelectLabel;
