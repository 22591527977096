import { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import * as toastr from "toastr";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import AircraftCard from "../aircrafts/AircraftCard";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import { getSimulatorsCollection } from "src/features/aircraft/collections";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

const schema = yup
  .object({
    tailNumber: yup.string().required("A simulator ID is required."),
    displayName: yup.string().required("A display name is required."),
    hourlyRate: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Hourly rate is required."),
    hobbs: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Starting hobbs is required."),
  })
  .required();

function NewSimulatorModal({ onCompleted, simulator }) {
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);
  const { selectedClubId, clubLocations, clubMemberships } = useClubs();
  const { addData, updateData } = entityCrudUtils();
  const [imgData, setImgData] = useState(null);
  const [picture, setPicture] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...simulator,
    },
  });

  const locations = watch("locations");
  const selectedMemberships = watch("restrictions.memberships");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSubmit = async (data) => {
    try {
      setFirebaseInProgress(true);
      const storage = getStorage();

      data.billFrom = "hobbs";

      const docToUpload = {
        ...simulator,
        ...data,
        index: simulator?.index || 0,
        deleted: false,
        simulator: true,
        disabled: false,
        rates: {
          standard: data.hourlyRate,
        },
      };
      if (data.locations && typeof data.locations === "string") {
        docToUpload.locations = [data.locations];
      }

      if (data.memberships && typeof data.memberships === "string") {
        docToUpload.memberships = [data.memberships];
      }

      if (picture) {
        const fileName = uuidv4() + picture.name;
        const storageRef = ref(storage, fileName);

        const fi = await uploadBytes(storageRef, picture);
        docToUpload.photo = await getDownloadURL(ref(storage, fi.ref.name));
      }

      if (!simulator?.id) {
        await addData(getSimulatorsCollection(selectedClubId), docToUpload);
      } else {
        await updateData(
          {
            entity: getSimulatorsCollection(selectedClubId),
            pathSegmentsArr: [simulator.id],
          },
          docToUpload
        );
      }

      toastr.success("Simulator added!", "Success");

      onCompleted(true);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setFirebaseInProgress(false);
    }
  };

  const isLocationSelected = (location) => {
    if (typeof locations === "string") {
      return location === locations;
    }
    if (typeof locations === "object") {
      return (
        typeof locations?.find((element) => element === location) === "string"
      );
    }

    return false;
  };

  const isMembershipSelected = (membership) => {
    if (typeof selectedMemberships === "string") {
      return membership === selectedMemberships;
    }
    if (typeof selectedMemberships === "object") {
      return (
        typeof selectedMemberships?.find(
          (element) => element === membership
        ) === "string"
      );
    }

    return false;
  };

  if (firebaseInProgress) {
    return (
      <Grid
        container
        columns={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <CircularProgress />
          <SoftTypography mt={2}>Saving</SoftTypography>
        </Grid>
      </Grid>
    );
  }

  return (
    <SoftBox sx={style}>
      <SoftTypography textAlign="center" textTransform="capitalize">
        {simulator?.id ? "Edit" : "Add"} simulator
      </SoftTypography>
      <SoftBox>
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container columns={{ xs: 12 }} spacing={{ xs: 2 }}>
            <Grid item xs={6}>
              <AircraftCard aircraft={simulator} image={imgData}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Upload photo
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    placeholder="0"
                    type="file"
                    onChange={onChangePicture}
                  />
                </SoftBox>
              </AircraftCard>
            </Grid>

            <Grid item xs={6}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    ID *
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. SIM01"
                  {...register("tailNumber", {
                    required: true,
                  })}
                />
                {errors?.displayName?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.displayName.message}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Display Name *
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Plane Name"
                  {...register("displayName", {
                    required: true,
                  })}
                />
                {errors?.displayName?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.displayName.message}
                  </SoftTypography>
                )}
              </SoftBox>
              <Grid container spacing={{ xs: 2 }}>
                <Grid item xs={6}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Hourly Rate *
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="hourlyRate"
                      render={({
                        field: { onChange, value, ref: inputRef },
                      }) => (
                        <SoftCurrencyInput
                          currencySymbol="$"
                          placeholder="0.00"
                          outputFormat="number"
                          onBlur={onChange}
                          inputRef={inputRef}
                          value={value}
                        />
                      )}
                    />
                    {errors?.hourlyRate?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.hourlyRate.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
                <Grid item xs={6}>
                  <SoftBox lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Starting Hobbs *
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      placeholder="0"
                      {...register("hobbs", {
                        required: true,
                      })}
                    />
                    {errors?.hobbs?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.hobbs.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  multiline
                  rows={5}
                  placeholder="optional description"
                  {...register("description", {})}
                />
                {errors?.description?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.description.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
            <Grid item xs={6}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Select Locations
                  </SoftTypography>
                </SoftBox>
                {clubLocations.map((loc) => (
                  <SoftBox display="flex" alignItems="center" key={loc.id}>
                    <Checkbox
                      value={loc.id}
                      checked={isLocationSelected(loc.id)}
                      {...register("locations", {})}
                    />

                    <SoftTypography component="a" href="#" variant="button">
                      {loc.name}
                    </SoftTypography>
                  </SoftBox>
                ))}
                {errors?.locations?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.locations.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
            {clubMemberships.length > 0 && (
              <Grid item xs={6}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Required Membership Levels
                    </SoftTypography>
                  </SoftBox>
                  {clubMemberships.map((membership) => (
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      key={membership.id}
                    >
                      <Checkbox
                        value={membership.id}
                        checked={isMembershipSelected(membership.id)}
                        {...register("restrictions.memberships", {})}
                      />

                      <SoftTypography component="a" href="#" variant="button">
                        {membership.data().label}
                      </SoftTypography>
                    </SoftBox>
                  ))}
                  {errors?.memberships?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.memberships.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            )}
          </Grid>
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="info" fullWidth type="submit">
              Save
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

NewSimulatorModal.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  simulator: PropTypes.object,
};

export default NewSimulatorModal;
