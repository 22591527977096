// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "src/assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { grey } = colors;

const linearProgress = {
  styleOverrides: {
    root: {
      height: pxToRem(6),
      borderRadius: borderRadius.md,
    },

    colorPrimary: {
      backgroundColor: grey["400"],
    },

    colorSecondary: {
      backgroundColor: grey["400"],
    },

    bar: {
      height: pxToRem(6),
      borderRadius: borderRadius.sm,
      position: "absolute",
      transform: `translate(0, 0) !important`,
      transition: "width 0.6s ease !important",
    },
  },
};

export default linearProgress;
