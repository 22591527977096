import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";

function About({ register, formErrors, formControl, formWatch, resetField }) {
  const [businessStructureOptions, setBusinessStructureOptions] = useState([]);
  const businessType = formWatch("business.type")?.value;

  useEffect(() => {
    resetField("businessStructure", { defaultValue: "" });
    switch (businessType) {
      case "company":
        setBusinessStructureOptions([
          { value: "sole_proprietorship", label: "Sole Proprietorship" },
          { value: "single_member_llc", label: "Single Member LLC" },
          { value: "multi_member_llc", label: "Multi Member LLC" },
          { value: "private_partnership", label: "Private Partnership" },
          { value: "private_corporation", label: "Private Corporation" },
          {
            value: "unincorporated_association",
            label: "Unincorporated Association",
          },
          { value: "public_partnership", label: "Public Partnership" },
          { value: "public_corporation", label: "Public Corporation" },
        ]);
        break;

      case "non_profit":
        setBusinessStructureOptions([
          {
            value: "incorporated_non_profit",
            label: "Incorporated Non Profit",
          },
          {
            value: "unincorporated_non_profit",
            label: "Unincorporated Non Profit",
          },
        ]);
        break;

      case "government_entity":
        setBusinessStructureOptions([
          { value: "governmental_unit", label: "Governmental Unit" },
          {
            value: "government_instrumentality",
            label: "Government Instrumentality",
          },
          {
            value: "tax_exempt_government_instrumentality",
            label: "Tax Exempt Government Instrumentality",
          },
        ]);
        break;

      default:
        setBusinessStructureOptions(null);
        break;
    }
  }, [businessType]);

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Let&apos;s start with the basic company information
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          All companies will need to be verified before they can be paid. Please
          provide accurate information for the company below. Failure to do so
          may result in a delay in the verification process.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Applicant Type
                </SoftTypography>
              </SoftBox>
              <Controller
                control={formControl}
                name="business.type"
                render={({ field: { onChange, value, ref } }) => (
                  <SoftSelect
                    placeholder="Business Type"
                    onChange={onChange}
                    inputRef={ref}
                    value={value}
                    options={[
                      { value: "company", label: "Company" },
                      { value: "individual", label: "Individual" },
                      { value: "non_profit", label: "Non Profit" },
                      {
                        value: "government_entity",
                        label: "Government Entity",
                      },
                    ]}
                  />
                )}
              />
              {formErrors?.business?.type?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {formErrors.business.type.message}
                </SoftTypography>
              )}
            </SoftBox>
            <SoftBox mb={2}>
              {businessStructureOptions && (
                <>
                  <Controller
                    name="business.structure"
                    control={formControl}
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={businessStructureOptions}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        placeholder="Business Structure"
                      />
                    )}
                  />
                  {formErrors?.business?.structure?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.business.structure.message}
                    </SoftTypography>
                  )}
                </>
              )}
            </SoftBox>
            {businessType === "company" && (
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Company Tax ID
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("company.taxId", {
                    required: true,
                  })}
                />
                {formErrors?.company?.taxId?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {formErrors.company.taxId.message}
                  </SoftTypography>
                )}
              </SoftBox>
            )}
            {businessType !== "individual" && (
              <>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Business URL
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("company.url", {
                      required: true,
                    })}
                    placeholder="https://www.example.com"
                  />
                  {formErrors?.company?.url?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.company.url.message}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Business Phone
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("company.phone", {
                      required: true,
                    })}
                  />
                  {formErrors?.company?.phone?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.company.phone.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <Grid container rowSpacing={0} columnSpacing={3}>
                {businessType !== "individual" && (
                  <>
                    {businessType === "company" && (
                      <Grid item xs={12}>
                        <SoftBox mb={2}>
                          <SoftBox mb={2} lineHeight={1.25}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Company Name
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              {...register("company.name", {
                                required: true,
                              })}
                            />
                            {formErrors?.company?.name?.message && (
                              <SoftTypography
                                marginTop={1}
                                fontSize={12}
                                color="error"
                              >
                                {formErrors.company.name.message}
                              </SoftTypography>
                            )}
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <SoftBox mb={2}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Address Line 1
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("company.address.line1", {
                              required: true,
                            })}
                          />
                          {formErrors?.company?.address?.line1?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {formErrors.company.address.line1.message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftBox mb={2}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Address Line 2
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput {...register("company.address.line2")} />
                          {formErrors?.company?.address?.line2?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {formErrors.company.address.line2.message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={2}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              City
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("company.address.city", {
                              required: true,
                            })}
                          />
                          {formErrors?.company?.address?.city?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {formErrors.company.address.city.message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftBox mb={2}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              State
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("company.address.state", {
                              required: true,
                            })}
                          />
                          {formErrors?.company?.address?.state?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {formErrors.company.address.state.message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftBox mb={2}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Zip
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("company.address.zip", {
                              required: true,
                            })}
                          />
                          {formErrors?.company?.address?.zip?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {formErrors.company.address.zip.message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </>
                )}
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

About.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  formControl: PropTypes.any,
  formWatch: PropTypes.func,
  resetField: PropTypes.func,
};

export default About;
