import { useEffect, useMemo, useState } from "react";
import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubsCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddMembershipModal } from "src/modals/AddMembershipModal";
import { doc, updateDoc } from "firebase/firestore";
import Switch from "@mui/material/Switch";
import SoftSelect from "src/components/SoftSelect";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const timeTypeOptions = [
  { label: "Days", value: "days" },
  { label: "Hours", value: "hours" },
];

function TimeOffApproval() {
  const { selectedClub, selectedClubId, staffUsers } = useClubs();
  const { preferences } = selectedClub;
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [openAddMembershipModal, setOpenAddMembershipModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [nonApproverStaff, setNonApproverStaff] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [error, setError] = useState(undefined);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  useEffect(() => {
    if (!approvers || !staffUsers) return;
    const updatedNonApprovers = staffUsers.filter(
      (staffUser) =>
        !approvers.find((approver) => approver?.uid === staffUser?.uid)
    );
    setNonApproverStaff(updatedNonApprovers);
  }, [approvers, staffUsers]);

  useEffect(() => {
    if (!staffUsers) return;

    const approverUsers =
      preferences?.timeOffApproval?.approvers
        ?.map((approverId) =>
          staffUsers.find(
            (userInLocation) => userInLocation?.uid === approverId.toString()
          )
        )
        .filter((v) => !!v) ?? [];

    setApprovers(approverUsers);
  }, [staffUsers, preferences?.timeOffApproval?.approvers]);

  const updatePreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        preferences,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const toggleApprovalRequired = (event) => {
    updatePreference("timeOffApproval", {
      ...preferences.timeOffApproval,
      enabled: event.target.checked,
      timeBeforeApproval: preferences?.timeOffApproval?.timeBeforeApproval ?? 0,
      timeType:
        preferences?.timeOffApproval?.timeType ?? timeTypeOptions[0].value,
    });
  };

  const deleteApprover = (user) => {
    updatePreference("timeOffApproval", {
      ...preferences.timeOffApproval,
      approvers: preferences?.timeOffApproval?.approvers.filter(
        (x) => x.toString() !== user.uid
      ),
    }).then(() => {
      toastr.success(
        "Removed user from list of Time-Off Approvers.",
        "Success"
      );
    });
  };

  const handleTableActions = (action, approver) => {
    switch (action) {
      case TableActions.DELETE:
        deleteApprover(approver);
        break;
      default:
        break;
    }
  };

  const handleAddMembershipModalClose = () => {
    setOpenAddMembershipModal(false);
    setSelectedMembership(null);
  };

  const handleTimeOffBeforeApprovalChange = (event) => {
    let timeBeforeApproval = Number(event.target.value);
    if (!timeBeforeApproval || Number.isNaN(timeBeforeApproval))
      timeBeforeApproval = 0;

    updatePreference("timeOffApproval", {
      ...preferences.timeOffApproval,
      timeBeforeApproval,
    });
  };

  const findTimeType = (value) =>
    timeTypeOptions.find((timeType) => timeType.value === value) ??
    timeTypeOptions[0];

  const handleTimeTypeChange = (event) => {
    updatePreference("timeOffApproval", {
      ...preferences.timeOffApproval,
      timeType: event.value,
    });
  };

  const handleAddApprover = async () => {
    if (!selectedUser) {
      if (nonApproverStaff && nonApproverStaff.length > 0)
        setError("Please select an approver");
      return;
    }
    if (
      approvers &&
      approvers?.some((approver) => approver === selectedUser.uid)
    ) {
      toastr.error("Unable to add this user", "Error");
      return;
    }

    const approverIds = preferences?.timeOffApproval?.approvers ?? [];
    approverIds.push(selectedUser.uid);

    await updatePreference("timeOffApproval", {
      ...preferences.timeOffApproval,
      approvers: approverIds,
    });
    setSelectedUser(null);
    setAutocompleteInputValue("");

    toastr.success("User added to Time-Off Approver list", "Success");
  };

  const tableColumns = useMemo(
    () => getTableColumns(handleTableActions),
    [approvers]
  );

  return (
    <Card
      id="time-off-approval"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Time Off Approval</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Settings for the management of staff time off
        </SoftTypography>
      </SoftBox>

      <SoftBox p={3} pb={1} lineHeight={1}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <SoftBox lineHeight={1.4}>
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
            >
              Approval Required
            </SoftTypography>
            <SoftTypography variant="caption" color="text" fontWeight="regular">
              If enabled, staff time-off will need approval before being added
              to the calendar
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={2} mr={1}>
            <Switch
              checked={preferences?.timeOffApproval?.enabled ?? false}
              onChange={toggleApprovalRequired}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {preferences?.timeOffApproval?.enabled && (
        <>
          <SoftBox pl={3} pr={3} lineHeight={1}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              lineHeight={1}
            >
              <SoftBox lineHeight={1.4}>
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Time Before Approval Required
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                >
                  Set to 0 if all time off requires approval
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                lineHeight={1}
              >
                <SoftBox ml={1} width={100}>
                  <SoftCurrencyInput
                    currencySymbol=""
                    placeholder="0"
                    decimalScale={0}
                    decimalPlaces={0}
                    outputFormat="number"
                    onBlur={handleTimeOffBeforeApprovalChange}
                    value={
                      preferences?.timeOffApproval?.timeBeforeApproval ?? 0
                    }
                  />
                </SoftBox>
                <SoftBox ml={1} mr={1} width={100}>
                  <SoftSelect
                    options={timeTypeOptions}
                    onChange={handleTimeTypeChange}
                    value={findTimeType(preferences?.timeOffApproval?.timeType)}
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox pb={3} px={3}>
            <SoftBox
              xs={12}
              padding={1}
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <DataTable
                entriesPerPage={false}
                maxWidth="100%"
                table={{
                  columns: tableColumns,
                  rows: approvers ?? [],
                }}
              />
            </SoftBox>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                justifyContent="flex-end"
                display="flex"
              >
                <SoftBox pr={2}>
                  <Autocomplete
                    value={selectedUser}
                    inputValue={autocompleteInputValue}
                    onChange={(event, newValue) => {
                      setSelectedUser(newValue);
                      if (newValue) setError(undefined);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setAutocompleteInputValue(newInputValue);
                    }}
                    selectOnFocus
                    clearOnBlur
                    label="Select a user"
                    getOptionLabel={(option) => option.displayName}
                    inputlabelprops={{ shrink: true }}
                    disablePortal
                    placeholder="Select a user"
                    options={nonApproverStaff || []}
                    sx={{ width: 300, mt: 1, border: "none" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {error && (
                    <SoftTypography
                      variant="p"
                      fontSize={14}
                      mt={2}
                      color="error"
                    >
                      {error}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox pr={2}>
                  <SoftButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={handleAddApprover}
                  >
                    Add Approver
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </>
      )}
      <Modal
        open={openAddMembershipModal}
        onClose={handleAddMembershipModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddMembershipModal
          handleClose={handleAddMembershipModalClose}
          membership={selectedMembership}
        />
      </Modal>
    </Card>
  );
}

export default TimeOffApproval;
