import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { format, isWithinInterval, endOfDay, startOfDay } from "date-fns";
import { query, orderBy, where, doc, getDoc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getAircraftCollection } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

const defaultColumns = [
  {
    Header: "Member",
    accessor: "displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: ({ value }) => <DefaultCell value={`${value || "---"}`} />,
  },
];

const renderColumn = (title) => ({
  Header: title,
  accessor: title,
  Cell: ({ value }) => <DefaultCell value={`${value || "---"}`} />,
});

function FlightTimeReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    globalUsers,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("completedAt", ">=", dateRange[0] || new Date()),
    orderBy("completedAt", "asc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const addBookingData = async (bookings) => {
    const pilotData = {};
    const columnTitles = [];
    await Promise.all(
      bookings.map(async (booking) => {
        if (
          !isWithinInterval(booking.completedAt.toDate(), {
            start: dateRange[0],
            end: dateRange[1],
          })
        )
          return;
        const pilot =
          booking?.extendedProps?.pilot?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.pilot?.value)
            : null;
        const aircraft =
          booking?.extendedProps?.aircraft?.value?.length > 0
            ? await getDoc(
                doc(
                  getAircraftCollection(selectedClubId),
                  booking?.extendedProps?.aircraft?.value
                )
              )
            : null;

        if (
          !pilot?.id ||
          !(
            aircraft?.data()?.aircraft_description?.tail_number ||
            aircraft?.data()?.tailNumber
          ) ||
          !booking?.flightData?.totalHobbsHours
        ) {
          return;
        }

        if (
          columnTitles.indexOf(
            aircraft.data().aircraft_description?.tail_number ??
              aircraft.data().tailNumber
          ) === -1
        ) {
          columnTitles.push(
            aircraft.data().aircraft_description?.tail_number ??
              aircraft.data().tailNumber
          );
        }

        const tailNumber =
          aircraft.data().aircraft_description?.tail_number ??
          aircraft.data().tailNumber;

        pilotData[pilot.id] = {
          id: pilot.id,
          ...pilotData[pilot.id],
          displayName: pilot.displayName,
          total: pilotData[pilot.id]?.total
            ? Math.round(
                (pilotData[pilot.id].total +
                  booking.flightData.totalHobbsHours) *
                  10
              ) / 10
            : Math.round(booking.flightData.totalHobbsHours * 10) / 10,
          [tailNumber]: pilotData[pilot.id]?.[tailNumber]
            ? Math.round(
                (pilotData[pilot.id][tailNumber] +
                  booking.flightData.totalHobbsHours) *
                  10
              ) / 10
            : Math.round(booking.flightData.totalHobbsHours * 10) / 10,
        };
      })
    );

    const rowData = Object.keys(pilotData).map((pilotId) => pilotData[pilotId]);
    const csvDataFromRows = rowData.map((row) => ({
      Member: row.displayName,
      Total: row.total,
      ...columnTitles.reduce(
        (acc, title) => ({ ...acc, [title]: row[title] }),
        {}
      ),
    }));

    setCsvHeaders(["Member", "Total", ...columnTitles]);
    setCsvData(csvDataFromRows);
    setRows(rowData);
    setColumns([
      ...defaultColumns,
      ...columnTitles.map((title) => renderColumn(title)),
    ]);
  };

  useEffect(() => {
    if (!bookingsLoaded || !allBookings) return;
    addBookingData(allBookings);
  }, [allBookings, bookingsLoaded, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                headers={csvHeaders}
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-flight_time-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FlightTimeReport;
