import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import SoftTypography from "src/components/SoftTypography";
import SoftSelectPaymentMethod from "src/components/SoftSelectPaymentMethod";

import { formatCurrency } from "src/features/utils";
import { Grid } from "@mui/material";
import { usePermissions } from "src/hooks/usePermissions";

function SelectPaymentMethod({
  user,
  user2,
  onPaymentMethodSelected,
  totalAmount,
  aircraft,
  onRateSelected,
  clubRates,
  selectedRate,
}) {
  const { light } = colors;
  const { borderWidth } = borders;
  const { getUserRoles } = usePermissions();

  const onSelect = (event) => {
    onPaymentMethodSelected(event.value);
  };

  const renderRateSelection = () => {
    if (aircraft?.enabledRates?.length > 1) {
      return (
        <SoftSelect
          value={selectedRate}
          onChange={onRateSelected}
          options={aircraft.enabledRates.map((id) => {
            const rateData = clubRates.find((rate) => rate.id === id);
            return { label: rateData?.label, value: id };
          })}
        />
      );
    }
    return null;
  };

  const renderTotalAmount = () => {
    if (totalAmount) {
      return (
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {`${totalAmount > 0 ? "Total:" : "Credit:"} ${formatCurrency(
            totalAmount < 0 ? -totalAmount : totalAmount
          )}`}
        </SoftTypography>
      );
    }

    return null;
  };

  if (user && user2) {
    onPaymentMethodSelected(undefined);
    return (
      <SoftBox border={`${borderWidth[1]} solid ${light.main}`} mt={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
          bgColor="light"
        >
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            Split Payment Details
          </SoftTypography>
          {renderRateSelection()}
          {renderTotalAmount()}
        </SoftBox>
        {totalAmount >= 0 ? (
          <SoftBox p={2}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <SoftBox textAlign="center">
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {user?.displayName}
                  </SoftTypography>
                </SoftBox>
                <SoftBox textAlign="center">
                  <SoftTypography variant="body2" textTransform="capitalize">
                    {`${formatCurrency(totalAmount / 2)}`}
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <SoftBox textAlign="center">
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {user2?.displayName}
                  </SoftTypography>
                </SoftBox>
                <SoftBox textAlign="center">
                  <SoftTypography variant="body2" textTransform="capitalize">
                    {`${formatCurrency(totalAmount / 2)}`}
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        ) : (
          <SoftBox p={2}>
            <SoftBox display="flex" flexDirection="column" p={0} m={0}>
              <SoftBox pr={2} textAlign="center">
                <SoftTypography variant="caption">
                  The amount owed will be credited to your account.
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    );
  }

  return (
    <SoftBox border={`${borderWidth[1]} solid ${light.main}`} mt={3}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        bgColor="light"
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Payment Method
        </SoftTypography>
        {renderRateSelection()}
        {renderTotalAmount()}
      </SoftBox>
      {totalAmount >= 0 ? (
        <SoftBox p={2}>
          <SoftBox display="flex" flexDirection="column" p={0} m={0}>
            <SoftBox>
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {user?.displayName} -{" "}
                {getUserRoles(user?.uid)
                  ?.map((r) => r.label)
                  ?.join(", ")}
              </SoftTypography>
              <SoftSelectPaymentMethod
                enableNonRev
                forUser={user}
                onChange={onSelect}
              />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      ) : (
        <SoftBox p={2}>
          <SoftBox display="flex" flexDirection="column" p={0} m={0}>
            <SoftBox pr={2} textAlign="center">
              <SoftTypography variant="caption">
                The amount owed will be credited to your account.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      )}
    </SoftBox>
  );
}

// Typechecking props for the Calendar
SelectPaymentMethod.propTypes = {
  user: PropTypes.object,
  user2: PropTypes.object,
  onPaymentMethodSelected: PropTypes.func,
  totalAmount: PropTypes.number,
  aircraft: PropTypes.object,
  onRateSelected: PropTypes.func,
  clubRates: PropTypes.array,
  selectedRate: PropTypes.object,
};

export default SelectPaymentMethod;
