import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftTagInput from "src/components/SoftTagInput";

// Settings page components
import FormField from "src/pages/user/account/components/FormField";

// Data
import selectData from "src/pages/user/account/settings/components/BasicInfo/data/selectData";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftButton from "src/components/SoftButton";
import { doc, setDoc } from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { useUser } from "src/features/user/UserProvider";
import { getYearsSelectData } from "src/features/utils";

const schema = yup
  .object({
    firstName: yup.string().required("This is required."),
    lastName: yup.string().required(),
    gender: yup.object().required(),
    birthMonth: yup.object().required(),
    birthDate: yup.object().required(),
    birthYear: yup.object().required(),
    location: yup.string(),
    phoneNumber: yup.string(),
    languages: yup.string(),
  })
  .required();

function BasicInfo() {
  const { user, userId } = useUser();

  const years = getYearsSelectData();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      gender:
        selectData.gender.find((c) => c.value === user.gender) ?? '',
      birthMonth:
        selectData.birthDate.find((c) => c.value === user.birthMonth) ||
        selectData.birthDate[0],
      birthDate:
        selectData.days.find((c) => c.value === user.birthDate) ||
        selectData.days[0],
      birthYear: years.find((c) => c.value === user.birthYear) || years[0],
      location: user.location,
      phoneNumber: user.phoneNumber,
      languages: user.languages,
      skills: user.skills || [],
    },
  });
  const onSubmit = async (data) => {
    try {
      await setDoc(
        doc(getUsersCollection(), userId),
        {
          ...data,
          gender: data.gender.value,
          birthMonth: data.birthMonth.value,
          birthDate: data.birthDate.value,
          birthYear: data.birthYear.value,
          displayName: `${data.firstName} ${data.lastName}`,
          skills: data.skills || [],
        },
        { merge: true }
      );
      toastr.success("Your profile has been updated.", "Success");
    } catch (e) {
      toastr.error("There was an error updating your profile.", "Error");
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <Card
      id="basic-info"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormField
                  {...field}
                  label="first name"
                  placeholder="John"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.firstName}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="last name"
                  placeholder="Doe"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.lastName}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      I&apos;m
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        placeholder=""
                        options={selectData.gender}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        error={!!errors.gender}
                      />
                    )}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          birth date
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="birthMonth"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder="February"
                            options={selectData.birthDate}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthMonth}
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <Controller
                        control={control}
                        name="birthDate"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder={1}
                            options={selectData.days}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthDate}
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <Controller
                        control={control}
                        name="birthYear"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder={currentYear}
                            options={years}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthYear}
                          />
                        )}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="email"
              placeholder="example@email.com"
              inputProps={{
                type: "email",
              }}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="location"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="location"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="phone number"
                  placeholder="+40 735 631 620"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="languages"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="language"
                  placeholder="English, Spanish"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, value, ref } }) => (
                  <SoftTagInput
                    tags={value}
                    placeholder=" "
                    removeOnBackspace
                    onChange={onChange}
                    inputRef={ref}
                  />
                )}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={!isDirty}
            >
              Save
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
