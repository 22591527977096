// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

function ActionsCell({ onEdit, onDelete }) {
  return (
    <SoftBox color="darkgrey" lineHeight={0}>
      <Icon
        sx={{
          fontWeight: "bold",
        }}
        fontSize="default"
        onClick={onEdit}
      >
        <EditIcon />
      </Icon>
      <Icon
        sx={{
          fontWeight: "bold",
        }}
        fontSize="default"
        onClick={onDelete}
      >
        <DeleteForeverIcon />
      </Icon>
    </SoftBox>
  );
}

// Typechecking props for the RefundsCell
ActionsCell.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ActionsCell;
