import PropTypes from "prop-types";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import Grade from "src/pages/lms/components/Grade";
import { GradeLessonModal } from "src/pages/lms/modals/GradeLessonModal";
import Modal from "@mui/material/Modal";

import { useState, useEffect } from "react";
import { useLms } from "src/features/lms/LmsProvider";
import { CircularProgress } from "@mui/material";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { getCourseURL } from "src/pages/lms/utils";
import SoftTypography from "src/components/SoftTypography";

// const { borderRadius } = borders;
const { grey } = colors;

function Row({
  lesson,
  handleEditLesson = () => {},
  editableStage,
  isLoading,
}) {
  const { studentId, enrollmentId, enrolledCourseId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { checkIsApprovedInstructor } = useLms();

  return (
    <>
      <TableRow
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            getCourseURL(
              pathname,
              studentId,
              "lessons",
              enrollmentId,
              enrolledCourseId,
              lesson.id
            )
          )
        }
      >
        <TableCell>{lesson.index + 1}</TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress size="1.5rem" color="secondary" />
          ) : (
            <Grade value={lesson.grade.lessonGrade} />
          )}
        </TableCell>
        <TableCell
          style={{
            minWidth: "150px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {lesson.title}
          {lesson.isOptional ? (
            <SoftTypography
              display="inline-block"
              fontSize="medium"
              fontWeight="light"
              color="secondary"
              sx={{ pl: 1 }}
            >
              (optional)
            </SoftTypography>
          ) : null}
        </TableCell>
        <TableCell>{lesson.flightTime}</TableCell>
        <TableCell>{lesson.groundTime}</TableCell>
        <TableCell>{lesson.simTime}</TableCell>
        <TableCell>{lesson.instrumentTime || 0}</TableCell>
        <TableCell>{lesson.tasks?.length || 0}</TableCell>
        {editableStage && pathname.includes("/lms") && (
          <WithPermissions permissions={systemPermissions.LMS_GRADE_ANY_LESSON}>
            <TableCell align="center">
              {checkIsApprovedInstructor(lesson) && (
                <IconButton
                  aria-label="edit"
                  sx={iconButtonStyles}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditLesson(lesson);
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
              )}
            </TableCell>
          </WithPermissions>
        )}
        <TableCell>
          <IconButton
            onClick={() =>
              navigate(
                getCourseURL(
                  pathname,
                  studentId,
                  "lessons",
                  enrollmentId,
                  enrolledCourseId,
                  lesson.id
                )
              )
            }
            sx={iconButtonStyles}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  lesson: PropTypes.object.isRequired,
  handleEditLesson: PropTypes.func,
  editableStage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const LessonTable = ({ stage, refreshCourseData }) => {
  const lessons = stage.lessons;
  const { pathname } = useLocation();
  const [gradeLessonModalIsOpen, setGradeLessonModalIsOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const openGradeLessonModal = () => setGradeLessonModalIsOpen(true);
  const closeGradeLessonModal = () => {
    refreshCourseData();
    setIsLoading(true);
    setGradeLessonModalIsOpen(false);
  };

  const handleEditLesson = (lesson) => {
    setSelectedLesson(lesson);
    openGradeLessonModal();
  };

  useEffect(() => {
    setIsLoading(false);
  }, [stage]);

  return (
    <TableContainer
      sx={{
        maxHeight: "calc(100vh - 210px)",
        boxShadow: "none",
        borderRadius: "0",
        overflowX: "visible",
        marginTop: 3,
      }}
    >
      <Table
        stickyHeader
        aria-label="collapsible table"
        style={{ borderSpacing: "0 0.2rem" }}
      >
        <TableHead>
          <TableRow
            sx={{
              "& > *": { backgroundColor: "white!important" },
            }}
          >
            <HeaderCell>
              <HeaderCellText>#</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Grade</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Lesson</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Flight Time (h)</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Ground Time (h)</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Sim Time (h)</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Instrument Time (h)</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Tasks</HeaderCellText>
            </HeaderCell>
            {stage.editable && pathname.includes("/lms") && (
              <WithPermissions
                permissions={systemPermissions.LMS_GRADE_ANY_LESSON}
              >
                <HeaderCell>
                  <HeaderCellText>Actions</HeaderCellText>
                </HeaderCell>
              </WithPermissions>
            )}
            <HeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {lessons?.map((lesson) => (
            <Row
              lesson={lesson}
              handleEditLesson={handleEditLesson}
              editableStage={stage.editable}
              key={lesson.index}
              isLoading={isLoading && selectedLesson.id === lesson.id}
            />
          )) ?? (
            <TableRow>
              <TableCell sx={{ color: grey[500] }}>
                <i>No Lessons Added</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        open={gradeLessonModalIsOpen}
        onClose={closeGradeLessonModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <GradeLessonModal
          lesson={selectedLesson}
          handleClose={closeGradeLessonModal}
        />
      </Modal>
    </TableContainer>
  );
};

LessonTable.propTypes = {
  stage: PropTypes.object.isRequired,
  refreshCourseData: PropTypes.func,
};

export default LessonTable;
