// @mui material components
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";

function Users({ register, formErrors }) {
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Who owns the club?
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          We will send an invitation to the club owner to join and complete
          their clubs profile.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        First Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("adminFirstName", {
                        required: true,
                      })}
                      placeholder="Eg. Robin"
                    />
                    {formErrors?.adminFirstName?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.adminFirstName.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Last Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("adminLastName", {
                        required: true,
                      })}
                      placeholder="Eg. Crorie"
                    />
                    {formErrors?.adminLastName?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.adminLastName.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("adminEmail", {
                    required: true,
                  })}
                  placeholder="Eg. name@clubdomain.com"
                />
                {formErrors?.adminEmail?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {formErrors.adminEmail.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Users.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default Users;
