import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import DocumentsList from "src/components/DocumentsList";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { query, where } from "firebase/firestore";
import { openModal$ } from "src/modals/modalConfiguration";
import { getUserDocumentsCollection } from "src/features/user/collections";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import Swal from "sweetalert2";
import { doc, updateDoc } from "firebase/firestore";
import toastr from "toastr";

const standardDocumentTypes = [
  { label: "Photo ID", value: "photoId" },
  { label: "Pilot Certificate (FAA)", value: "certificate" },
  { label: "Medical Certificate / BasicMed (FAA)", value: "medical" },
  {
    label: "TSA Citizenship Verification",
    value: "tsaCitizenshipVerification",
  },
];

function Documents() {
  const { selectedClubId } = useClubs();
  const { userId } = useUser();
  const [allDocumentTypes, setAllDocumentTypes] = useState();

  const clubDocumentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: clubDocumentTypes, isDataLoaded: clubDocumentTypesLoaded } =
    useRealtimeCollectionData(
      query(clubDocumentTypesRef, where("deleted", "==", false))
    );

  useEffect(() => {
    const types = [...standardDocumentTypes];
    if (clubDocumentTypesLoaded) {
      clubDocumentTypes.forEach((element) => {
        types.push({
          label: element.name,
          value: element.id,
        });
      });
    }
    setAllDocumentTypes(types);
  }, [clubDocumentTypes, clubDocumentTypesLoaded]);

  const deleteDocument = async (document) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(
            doc(getUserDocumentsCollection(userId), document.id),
            { deleted: true }
          );
          toastr.success("Document deleted successfully");
        }
      });
  };

  return (
    <Card
      id="documents"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Documents</SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} marginTop={2} borderRadius="sm">
            {allDocumentTypes?.length > 0 && clubDocumentTypesLoaded && (
              <DocumentsList
                title="Documents"
                documentsCollection={getUserDocumentsCollection(userId)}
                documentTypes={allDocumentTypes}
                ownerId={userId}
                deleteDocument={deleteDocument}
                openUploadModal={() =>
                  openModal$.next({
                    modalName: "NEW_UPLOAD_DOCUMENT",
                    modalProps: {
                      storagePath: `users/${userId}/documents`,
                      memberId: userId,
                    },
                  })
                }
                clickHandler={(doc) =>
                  openModal$.next({
                    modalName: "NEW_UPLOAD_DOCUMENT",
                    modalProps: {
                      storagePath: `users/${userId}/documents`,
                      memberId: userId,
                      document: doc
                    },
                  })
                }
                hasDocumentsWithExpiration
              />
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Documents;
