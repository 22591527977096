import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import FlightIcon from "@mui/icons-material/Flight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function AircraftData({ aircraft }) {
  return (
    <Grid container spacing={1} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4}>
        <SoftBox display="flex" alignItems="center" mt={3}>
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <FlightIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="dark"
              fontSize="large"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Aircraft
            </SoftTypography>
            <SoftTypography
              variant="caption"
              color="text"
              fontSize="1.3rem"
              fontWeight="bold"
            >
              {aircraft?.aircraft_description?.tail_number ??
                aircraft?.tailNumber}
            </SoftTypography>
            <SoftTypography variant="caption" fontSize="0.8rem" color="text">
              {aircraft?.displayName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid
        item
        xs={12}
        mt={{ xs: 3, sm: 0 }}
        sm={aircraft?.tachFollowsHobbs ? 4 : 8}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          py={1}
          borderRadius="md"
          bgColor="light"
          mt={3}
        >
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            ml={2}
          >
            <ErrorOutlineIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="error"
              fontSize="large"
            />
          </SoftBox>
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent="center"
            pl={{ xs: 3, sm: 0 }}
          >
            <Grid item xs={12} sm={aircraft?.tachFollowsHobbs ? 8 : 4}>
              <SoftBox display="flex" alignItems="center">
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    variant="button"
                    color="dark"
                    fontWeight="medium"
                    gutterBottom
                  >
                    Hobbs Out
                  </SoftTypography>
                  <SoftTypography variant="h6" color="text">
                    {aircraft?.hobbs}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
            {!aircraft?.tachFollowsHobbs && (
              <Grid item xs={12} sm={4}>
                <SoftBox display="flex" alignItems="center">
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography
                      variant="button"
                      color="dark"
                      fontWeight="medium"
                      gutterBottom
                    >
                      Tach Out
                    </SoftTypography>
                    <SoftTypography variant="h6" color="text">
                      {aircraft?.tach1 ?? "N/A"}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
AircraftData.propTypes = {
  aircraft: PropTypes.object,
};

export default AircraftData;
