const UserType = {
  SUPERADMIN: 'Super Admin',
  ADMIN: 'admin',
  RECEPTIONIST: 'receptionist',
  INSTRUCTOR: 'instructor',
  MECHANIC: 'mechanic',
  MEMBER: 'member',
  NONMEMBER: 'nonMember',
};

export default UserType;
