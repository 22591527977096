import { IPermission } from "./permission.interface";

export enum aircraftRoleCategory {
  AIRCRAFT_SQUAWKS = "AIRCRAFT_SQUAWKS",
  AIRCRAFT_MAINTENANCE = "AIRCRAFT_MAINTENANCE",
}

export enum aircraftPermissionTypes {
  ADD_AIRCRAFT = "ADD_AIRCRAFT",
  EDIT_AIRCRAFT = "EDIT_AIRCRAFT",
  VIEW_AIRCRAFT = "VIEW_AIRCRAFT",
  VIEW_AIRCRAFT_DETAILS = "VIEW_AIRCRAFT_DETAILS",
  DELETE_AIRCRAFT = "DELETE_AIRCRAFT",
  DISABLE_AIRCRAFT = "DISABLE_AIRCRAFT",
  TRACK_AIRCRAFT = "TRACK_AIRCRAFT",
  ADD_AIRCRAFT_SQUAWKS = "ADD_AIRCRAFT_SQUAWKS",
}

export const aircraftPermissions: IPermission[] = [
  {
    group: aircraftRoleCategory.AIRCRAFT_SQUAWKS,
    label: "Aircraft",
    tooltipGroup: "",
    permissions: {
      [aircraftPermissionTypes.VIEW_AIRCRAFT]: {
        label: "View All Aircraft",
        tooltip:
          "Disabling this permission will hide the Aircraft menu option from Resources",
      },
      [aircraftPermissionTypes.VIEW_AIRCRAFT_DETAILS]: {
        label: "View Aircraft Details",
        tooltip:
          "Toggle if the user can view the details of an aircraft from any page",
      },
      [aircraftPermissionTypes.ADD_AIRCRAFT]: {
        label: "Add Aircraft",
        tooltip: "",
      },
      [aircraftPermissionTypes.EDIT_AIRCRAFT]: {
        label: "Edit Aircraft",
        tooltip: "",
      },
      [aircraftPermissionTypes.DELETE_AIRCRAFT]: {
        label: "Delete Aircraft",
        tooltip: "",
      },
      [aircraftPermissionTypes.DISABLE_AIRCRAFT]: {
        label: "Disable Aircraft",
        tooltip: "",
      },
      [aircraftPermissionTypes.TRACK_AIRCRAFT]: {
        label: "Track Aircraft",
        tooltip: "Enabling this will allow a user to turn on tracking aircraft",
      },
      [aircraftPermissionTypes.ADD_AIRCRAFT_SQUAWKS]: {
        label: "Add Aircraft Squawks",
        tooltip: "",
      },
    },
  },
  // {
  //   group: aircraftRoleCategory.AIRCRAFT_SQUAWKS,
  //   label: "Aircraft Squawks",
  //   tooltipGroup: "",
  //   permissions: {
  //     [aircraftPermissionTypes.VIEW_AIRCRAFT_SQUAWKS]: {
  //       label: "View Aircraft Squawks",
  //       tooltip: "",
  //     },
  //   },
  // },
  // {
  //   group: aircraftRoleCategory.AIRCRAFT_MAINTENANCE,
  //   label: "Aircraft Maintenance",
  //   tooltipGroup: "",
  //   permissions: {
  //     [aircraftPermissionTypes.ADD_AIRCRAFT_SQUAWKS]: {
  //       label: "Add Aircraft Squawks",
  //       tooltip: "",
  //     },
  //     [aircraftPermissionTypes.MANAGE_MAINTENANCE_REMINDERS]: {
  //       label: "Manage Maintenance Reminders",
  //       tooltip: "Manage Maintenance Reminders tooltip",
  //     },
  //   },
  // },
];
