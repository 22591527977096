import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "src/components/SoftInput";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { addDoc, doc, setDoc } from "firebase/firestore";
import { getClubHobbsMultiplierCollection } from "src/features/club/collections";

const schema = yup
  .object({
    label: yup.string().required("A label is required."),
    multiplier: yup.number().required("A multiplier is required."),
    requireDetails: yup.boolean(),
  })
  .required();

export function AddHobbsMultiplierModal({ handleClose, existingMultiplier }) {
  const { selectedClubId, selectedLocationId } = useClubs();

  let defaultValues;
  if (existingMultiplier) {
    defaultValues = {
      multiplier: existingMultiplier.multiplier
        ? existingMultiplier.multiplier
        : 1,
      label: existingMultiplier.label,
      requiresPermission: existingMultiplier.requiresPermission || false,
    };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const newMultiplier = {
      multiplier: data.multiplier || 1,
      label: data.label,
      requiresPermission: data.requiresPermission || false,
      deleted: false,
    };

    if (existingMultiplier?.id) {
      await setDoc(
        doc(
          getClubHobbsMultiplierCollection(selectedClubId, selectedLocationId),
          existingMultiplier?.id
        ),
        newMultiplier
      );
    } else {
      await addDoc(
        getClubHobbsMultiplierCollection(selectedClubId, selectedLocationId),
        newMultiplier
      );
    }

    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {existingMultiplier
              ? "Edit Hobbs Multiplier"
              : "Add Hobbs Multiplier"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Label
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Pattern Work"
                  {...register("label", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Multiplier
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="multiplier"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftCurrencyInput
                      currencySymbol="x"
                      placeholder="0.0"
                      outputFormat="number"
                      onBlur={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.multiplier?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.multiplier.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} display="flex">
                <SoftBox ml={0.5} pt={0} display="inline-block">
                  <Controller
                    control={control}
                    name="requiresPermission"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          inputRef={ref}
                        />
                        <SoftTypography variant="button" fontWeight="regular">
                          Requires Permission?
                        </SoftTypography>
                      </>
                    )}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {existingMultiplier ? "Save" : "Add"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddHobbsMultiplierModal.propTypes = {
  handleClose: PropTypes.func,
  existingMultiplier: PropTypes.object,
};
