import { Icon } from "@mui/material";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import SoftButton from "src/components/SoftButton";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import SoftTypography from "src/components/SoftTypography";
import { useClubs } from "src/features/club/ClubProvider";
import useSquawks from "src/hooks/useSquawks";
import { IAircraft, IUseClub } from "src/interfaces";
import { openModal$ } from "src/modals/modalConfiguration";

interface IAircraftMaintenance {
  aircraft: IAircraft;
}
function AircraftMaintenance({ aircraft }: IAircraftMaintenance) {
  const { selectedClubId } = useClubs() as IUseClub;
  const { squawks, loadingSquawks } = useSquawks({
    clubId: selectedClubId,
    aircraftId: aircraft.id,
  });

  return (
    <Stack direction="column" gap="4px" flex={1}>
      <SoftLoader isLoading={loadingSquawks}>
        <Stack direction="row" justifyContent="space-between">
          <SoftTypography sx={{ fontWeight: 700, fontSize: 14 }}>
            {squawks.length ? "Squawks" : "No Squawks"}
          </SoftTypography>
          <SoftButton
            variant="text"
            color="primary"
            size="small"
            className="clickable"
            onClick={() =>
              openModal$.next({
                modalName: "ADD_SQUAWK",
                modalProps: { aircraft },
              })
            }
          >
            <Icon>add</Icon>&nbsp; add new
          </SoftButton>
        </Stack>
        {squawks.map((squawk) => (
          <Stack
            direction="column"
            key={squawk.id}
            sx={{
              padding: "8px 0",
              "&:hover": { background: "#FAFAFA", cursor: "pointer" },
            }}
            onClick={() =>
              openModal$.next({
                modalName: "VIEW_SQUAWK",
                modalProps: { squawk },
              })
            }
          >
            <SoftTypography sx={{ fontWeight: 700, fontSize: 13 }}>
              {format(squawk.date.toDate(), "MM/dd/yyyy")}
            </SoftTypography>
            <SoftTypography sx={{ fontWeight: 400, fontSize: 14 }}>
              {squawk.description}
            </SoftTypography>
          </Stack>
        ))}
      </SoftLoader>
    </Stack>
  );
}
export default AircraftMaintenance;
