// @mui material components
import { styled } from "@mui/material/styles";
import { IOwnerState } from "./softInput.config";

export default styled("div")<{ ownerState: Partial<IOwnerState> }>(
  ({ theme, ownerState }) => {
    const { palette, functions } = theme as any;
    const { size } = ownerState;

    const { dark } = palette;
    const { pxToRem } = functions;

    return {
      lineHeight: 0,
      padding:
        size === "small"
          ? `${pxToRem(4)} ${pxToRem(10)}`
          : `${pxToRem(8)} ${pxToRem(10)}`,
      width: pxToRem(39),
      height: "100%",
      color: dark.main,
    };
  }
);
