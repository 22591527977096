import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SoftTypography from "src/components/SoftTypography";
// import SoftBadge from 'src/components/SoftBadge';
import { UserAvatar } from "../UserAvatar";

function UserCard({ user, membership }) {
  return (
    <SoftBox padding={1} borderRadius="md">
      <SoftBox
        display="grid"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
      >
        <UserAvatar user={user} size="xxl" />
        <SoftTypography mt={2} sx={{ textAlign: "center" }} variant="h4">
          {user.displayName}
        </SoftTypography>
        {membership && (
          <SoftTypography variant="caption">
            {membership.membershipPlan.label}{" "}
            {membership.nextMembershipPlan &&
              `-> ${membership.nextMembershipPlan.label}`}
            <IconButton
              aria-label="delete"
              // sx={iconButtonStyles}
              // onClick={() => setOpenEditMembership(true)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </SoftTypography>
        )}
        {/* <SoftBox>
          <SoftBadge
            variant="contained"
            color="success"
            size="xs"
            badgeContent="Online"
            container
            sx={{
              marginRight: 1,
            }}
          />
        </SoftBox> */}
      </SoftBox>
    </SoftBox>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  membership: PropTypes.object,
};

export default UserCard;
