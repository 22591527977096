// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import visaLogo from "src/assets/images/logos/visa.png";

function Bill({
  card,
  cardId,
  name,
  billingAddress,
  defaultCard,
  setDefaultCard,
  noGutter = false,
  deleteCard,
}) {
  const { borderWidth, borderColor } = borders;

  return (
    <SoftBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SoftBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftBox
                component="img"
                src={visaLogo}
                alt="master card"
                width="10%"
                mr={2}
              />
              <SoftTypography variant="h6" mr={2} fontWeight="medium">
                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{card.last4}
              </SoftTypography>
              <SoftTypography variant="caption" mr={2} fontWeight="medium">
                Expires: {card.exp_month}/{card.exp_year}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item sm={12} md={6}>
          <SoftBox
            width="100%"
            display="flex"
            sx={{ position: "relative" }}
            flexDirection="column"
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              mb={2}
            >
              <SoftTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {name}
              </SoftTypography>

              <SoftBox
                display="flex"
                alignItems="center"
                mt={{ xs: 2, sm: 0 }}
                ml={{ xs: -1.5, sm: 0 }}
              >
                {!defaultCard && (
                  <SoftBox mr={1}>
                    <SoftButton
                      variant="text"
                      color="error"
                      onClick={deleteCard}
                    >
                      <Icon>delete</Icon>&nbsp;delete
                    </SoftButton>
                  </SoftBox>
                  /* <SoftButton variant="text" color="dark">
                  <Icon>edit</Icon>&nbsp;edit
                </SoftButton> */
                )}
              </SoftBox>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Billing Address:
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" fontWeight="medium">
                {billingAddress.line1}{" "}
                {billingAddress.line2 && `, ${billingAddress.line2}`}
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" fontWeight="medium">
                {billingAddress.city}, {billingAddress.state},{" "}
                {billingAddress.postal_code}, {billingAddress.country}
              </SoftTypography>
            </SoftBox>
            {!defaultCard && (
              <SoftButton
                variant="text"
                sx={{ position: "absolute", right: "0px", bottom: "0px" }}
                color="dark"
                onClick={() => setDefaultCard(cardId)}
              >
                <Icon>edit</Icon>&nbsp;set default
              </SoftButton>
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the Bill
Bill.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  billingAddress: PropTypes.object.isRequired,
  defaultCard: PropTypes.bool,
  noGutter: PropTypes.bool,
  deleteCard: PropTypes.func.isRequired,
};

export default Bill;
