import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { getFunctions, httpsCallable } from "firebase/functions";
import PaymentItem from "../PaymentItem";
import { format } from "date-fns";

function PendingPayments({ userId }) {
  const [pendingPayments, setPendingPayments] = useState([]);

  const refreshPayments = async () => {
    const functions = getFunctions();
    const getPayments = httpsCallable(functions, "getPayments");

    getPayments({ userId })
      .then((result) => {
        if (result?.data) {
          setPendingPayments(
            result.data.data.filter(
              (payment) =>
                payment.status !== "requires_payment_method" &&
                payment.status !== "succeeded"
            )
          );
        } else {
          setPendingPayments([]);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    refreshPayments();
  }, [userId]);

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Pending Payments
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        {pendingPayments.length === 0 && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>You don&apos;t have any payments pending.</i>
            </SoftTypography>
          </SoftBox>
        )}
        {pendingPayments.length > 0 && (
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {pendingPayments.map((payment) => (
              <PaymentItem
                date={format(new Date(payment.created * 1000), "MM/dd/yyyy")}
                key={payment.id}
                data={payment}
                id={payment.id}
                refreshPayments={refreshPayments}
                totalAmount={payment.amount / 100}
                status={payment.status}
              />
            ))}
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

export default PendingPayments;
