import "./PrintTemplate.scss";
import { ReactNode } from "react";
import SoftBox from "../SoftBox";
import { Stack } from "@mui/material";
import SoftTypography from "../SoftTypography";
import { getBrandIcons } from "src/features/utils";

interface IPrintTemplateProps {
  children: ReactNode;
  footerContent?: ReactNode;
  barcode?: string;
  footerType?: FooterType;
}

const footerText = "Powered by PreFlight";

export enum FooterType {
  WHITE,
  BLACK,
}

const BlackFooter = ({
  footerContent,
}: {
  barcode?: string;
  footerContent?: ReactNode;
}) => {
  return (
    <footer className="print-template-footer-black">
      <SoftBox>
        <Stack gap="2rem">
          <SoftBox
            component="img"
            src={getBrandIcons().brand}
            alt="PreFlight Logo"
            width="2rem"
          />
          <SoftTypography
            size="0.5rem"
            c="#AEAFAF"
            style={{ textAlign: "center" }}
            w="25rem"
          >
            {footerText}
          </SoftTypography>
          {footerContent}
        </Stack>
      </SoftBox>
    </footer>
  );
};

const WhiteFooter = ({
  footerContent,
}: {
  barcode?: string;
  footerContent?: ReactNode;
}) => {
  return (
    <footer className="print-template-footer-white">
      <Stack gap="1rem">
        <SoftBox>
          <SoftBox
            component="img"
            src={getBrandIcons().brand}
            alt="PreFlight Logo"
            width="12rem"
          />
        </SoftBox>
        <SoftTypography fontSize="1rem" color="secondary">
          Powered by PreFlight
        </SoftTypography>
        {footerContent}
      </Stack>
    </footer>
  );
};

const PrintTemplate = ({
  children,
  footerContent,
  barcode,
  footerType = FooterType.BLACK,
}: IPrintTemplateProps) => {
  return (
    <SoftBox
      className="print-template"
      style={{
        pageBreakAfter: "always",
      }}
      sx={{ backgroundColor: "#FFFFFF" }}
    >
      {children}
      {footerType === FooterType.BLACK ? (
        <BlackFooter barcode={barcode} footerContent={footerContent} />
      ) : (
        <WhiteFooter barcode={barcode} footerContent={footerContent} />
      )}
    </SoftBox>
  );
};

export default PrintTemplate;
