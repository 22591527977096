import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import Modal from "@mui/material/Modal";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import { FileIcon, defaultStyles } from "react-file-icon";
import SoftAvatar from "src/components/SoftAvatar";
import ModalContainer from "src/components/ModalContainer";
import { useDocuments } from "src/hooks/useDocument";

const ListItem = ({ document, clickDocument }) => {
  const { primary } = colors;
  const { borderWidth } = borders;
  const asAvatar = ["png", "jpeg"].includes(document.fileType);
  return (
    <SoftBox
      display="flex"
      alignItems="center"
      gap="8px"
      ml={2}
      mb={2}
      sx={{
        cursor: "pointer",
        borderBottom: `${borderWidth[1]} solid ${primary.main}`,
      }}
      onClick={() => clickDocument(document)}
    >
      {asAvatar ? (
        <SoftAvatar src={document.url} alt="image" variant="rounded" />
      ) : (
        <SoftBox sx={{ width: "1rem" }}>
          <FileIcon
            extension={document.fileType}
            {...defaultStyles[document.fileType]}
          />
        </SoftBox>
      )}
      <SoftTypography fontSize="small" fontWeight="regular" color="primary">
        {document.fileName}
      </SoftTypography>
    </SoftBox>
  );
};

function CompactDocumentsList({
  documentsCollection,
  openUploadModal,
  title,
  noAdd,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedDocument, setSelectedDocument] = useState([]);
  const handleDocumentSelected = (row) => {
    setSelectedDocument(row);
    handleOpen(true);
  };
  const { documents, loadingDocuments } = useDocuments({
    documentsCollection,
  });

  const documentHeader = (state) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null;
    }

    return <div>{selectedDocument.fileName || ""}</div>;
  };

  return (
    <SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pb={1}
      >
        <SoftTypography
          fontSize="small"
          fontWeight="medium"
          textTransform="uppercase"
          color="text"
        >
          {title || "Documents"}
        </SoftTypography>
        {!noAdd ? (
          <SoftButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => openUploadModal()}
          >
            <Icon>add</Icon>&nbsp; add new
          </SoftButton>
        ) : null}
      </SoftBox>
      <SoftBox
        xs={12}
        padding={1}
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        {documents.length < 1 ? (
          <SoftBox p={2}>
            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <SoftTypography variant="button" fontWeight="light">
                {loadingDocuments ? (
                  <i>Loading Documents...</i>
                ) : (
                  "No Documents Uploaded"
                )}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        ) : (
          <SoftBox display="flex" flexWrap="wrap">
            {documents.map((document) => (
              <ListItem
                key={document.id}
                document={document}
                clickDocument={handleDocumentSelected}
              />
            ))}
          </SoftBox>
        )}
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <ModalContainer handleClose={handleClose}>
          <DocViewer
            documents={[
              {
                uri: selectedDocument.url,
                fileType: selectedDocument.fileType,
              },
            ]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                overrideComponent: documentHeader,
                disableFileName: false,
              },
            }}
          />
        </ModalContainer>
      </Modal>
    </SoftBox>
  );
}

// Typechecking props for the Calendar
CompactDocumentsList.propTypes = {
  documentsCollection: PropTypes.any,
  openUploadModal: PropTypes.func,
  title: PropTypes.string,
  noAdd: PropTypes.bool,
};

export default CompactDocumentsList;
