import * as toastr from "toastr";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Setting pages components
import TableCell from "src/components/TableCell";

import { doc, setDoc } from "firebase/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubsCollection } from "src/features/club/collections";

function Notifications() {
  const { selectedClub, selectedClubId } = useClubs();
  const { preferences } = selectedClub;

  const updatePreference = async (key, value) => {
    preferences[key] = value;
    try {
      await setDoc(
        doc(getClubsCollection(), selectedClubId),
        {
          preferences,
        },
        { merge: true, mergeFields: true }
      );
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const toggleEmailPreference = (event, key, type) => {
    updatePreference("notifications", {
      [`${key}`]: { [`${type}`]: event.target.checked },
    });
  };

  return (
    <Card id="notifications">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Notifications</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Choose how the club sends notifications. These notification settings
          will be overriden by the individual users settings for what they
          receive.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          minWidth="auto"
          sx={{
            overflow: "scroll",
          }}
        >
          <Table
            sx={{
              minWidth: "36rem",
            }}
          >
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Push
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  SMS
                </TableCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      New Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when another user makes a booking for/with you.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.newBookings?.email}
                    onChange={(event) =>
                      toggleEmailPreference(event, "newBookings", "email")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.newBookings?.push}
                    onChange={(event) =>
                      toggleEmailPreference(event, "newBookings", "push")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.newBookings?.sms}
                    onChange={(event) =>
                      toggleEmailPreference(event, "newBookings", "sms")
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Booking Reminders
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Reminders for upcoming bookings.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.bookingReminders?.email
                    }
                    onChange={(event) =>
                      toggleEmailPreference(event, "bookingReminders", "email")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.bookingReminders?.push}
                    onChange={(event) =>
                      toggleEmailPreference(event, "bookingReminders", "push")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.bookingReminders?.sms}
                    onChange={(event) =>
                      toggleEmailPreference(event, "bookingReminders", "sms")
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Booking Modifications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a modification is made to a reservation.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.bookingModifications?.email
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "bookingModifications",
                        "email"
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.bookingModifications?.push
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "bookingModifications",
                        "push"
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.bookingModifications?.sms
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "bookingModifications",
                        "sms"
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Notications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a club posts a notification/update.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.clubNotifications?.email
                    }
                    onChange={(event) =>
                      toggleEmailPreference(event, "clubNotifications", "email")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={
                      preferences?.notifications?.clubNotifications?.push
                    }
                    onChange={(event) =>
                      toggleEmailPreference(event, "clubNotifications", "push")
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch
                    checked={preferences?.notifications?.clubNotifications?.sms}
                    onChange={(event) =>
                      toggleEmailPreference(event, "clubNotifications", "sms")
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Billing Notifications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a transaction is added to your account.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch
                    checked={
                      preferences?.notifications?.billingNotifications?.email
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "billingNotifications",
                        "email"
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch
                    checked={
                      preferences?.notifications?.billingNotifications?.push
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "billingNotifications",
                        "push"
                      )
                    }
                  />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch
                    checked={
                      preferences?.notifications?.billingNotifications?.sms
                    }
                    onChange={(event) =>
                      toggleEmailPreference(
                        event,
                        "billingNotifications",
                        "sms"
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Notifications;
