import { useLocation, useParams } from "react-router-dom";
import { useLms } from "src/features/lms/LmsProvider";

import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import Card from "@mui/material/Card";
import SoftBox from "src/components/SoftBox";
import { LessonHeader, LessonInfo } from "src/pages/lms/components";
import TaskTable from "./components/TaskTable";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import LessonFooter from "./components/LessonFooter";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import LectureNotes from "../../components/LectureNotes";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

export default function LessonOverview() {
  const { getSingleEnrolledCourse } = useLms();
  const { studentId, enrollmentId, enrolledCourseId, lessonId } = useParams();
  const { pathname } = useLocation();
  const [editable, setEditable] = useState(false);
  const [lesson, setLesson] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [studentName, setStudentName] = useState("");
  const [lectureNotesOpen, setLectureNotesOpen] = useState(false);

  const getLessonData = async (quietly) => {
    !quietly && setIsLoading(true);
    const course = await getSingleEnrolledCourse(
      studentId,
      enrollmentId,
      enrolledCourseId
    );
    course?.stages?.forEach((stage) => {
      const selectedLesson = stage?.lessons?.find((l) => l.id === lessonId);
      if (selectedLesson) {
        setLesson(selectedLesson);
        setStudentName(course.member.label);
        setEditable(stage.index <= course.currentStageIndex);
      }
    });
    setIsLoading(false);
  };

  const toggleLectureNotes = () => {
    setLectureNotesOpen(!lectureNotesOpen);
  };

  const closeLectureNotes = () => {
    setLectureNotesOpen(false);
  };

  useEffect(() => {
    getLessonData();
  }, [lessonId]);

  return (
    <DashboardLayout>
      {lectureNotesOpen ? (
        <LectureNotes
          onClose={closeLectureNotes}
          content={lesson.lectureNotes}
        />
      ) : null}
      <DashboardNavbar pageTitle="Lesson" />
      <SoftBox py={3}>
        <Card>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <Card>
                  <Skeleton variant="rounded" height="50vh" />
                </Card>
              ) : (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <SoftBox>
                    <LessonHeader lesson={lesson} />
                    <TaskTable
                      refreshLesson={getLessonData}
                      lesson={lesson}
                      editable={editable}
                    />
                  </SoftBox>
                  {pathname.includes("/lms") ? (
                    <WithPermissions
                      permissions={systemPermissions.LMS_GRADE_ANY_LESSON}
                    >
                      <LessonFooter
                        refreshLesson={getLessonData}
                        lesson={lesson}
                        studentName={studentName}
                      />
                    </WithPermissions>
                  ) : null}
                </SoftBox>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <Card>
                  <Skeleton variant="rounded" height="50vh" />
                </Card>
              ) : (
                <LessonInfo lesson={lesson}>
                  {lesson.lectureNotes ? (
                    <WithPermissions
                      permissions={systemPermissions.VIEW_LMS_STUDENTS}
                    >
                      <SoftButton
                        onClick={toggleLectureNotes}
                        color="secondary"
                        variant="outlined"
                        size="small"
                      >
                        <SoftTypography
                          fontSize="small"
                          ml="4px"
                          fontWeight="medium"
                          color="secondary"
                        >
                          Lecture Notes
                        </SoftTypography>
                      </SoftButton>
                    </WithPermissions>
                  ) : null}
                </LessonInfo>
              )}
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}
