import { Stack } from "@mui/material";
import { allPermissions } from "src/interfaces/roles/permissions";
import { systemPermissionsType } from "src/interfaces/roles/role.interface";
import { UserRoleEntry } from "./UserRoleEntry";

export interface PermissionTabProps {
  permissions: systemPermissionsType[];
  setPermissions: React.Dispatch<React.SetStateAction<systemPermissionsType[]>>;
}

export const PermissionTab = ({
  permissions,
  setPermissions,
}: PermissionTabProps) => {
  return (
    <Stack direction="column" height={"100%"} gap="16px">
      {allPermissions.map((p) => {
        return (
          <UserRoleEntry
            key={p.label}
            permissions={permissions}
            onChange={(isChecked, key) => {
              setPermissions((prev) => {
                if (isChecked) return prev.concat(key);
                return prev.filter((p) => p !== key);
              });
            }}
            permission={p}
          />
        );
      })}
    </Stack>
  );
};
