import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

const StageHeader = ({
  index,
  children
}) => {

  return (
    <SoftBox
      display="flex"
      justifyContent="space-between"
      color="text"
      alignItems="center"
    >
      <SoftTypography maxWidth="190px" variant="h4">
        Stage {index + 1}
      </SoftTypography>
      {children}
    </SoftBox>
  );
};

StageHeader.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node
};

export default StageHeader;
