import { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import DataTable from "src/components/Tables/DataTable";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";

import ActionsCell from "./components/ActionsCell";
import DefaultCell from "./components/DefaultCell";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubMeetingRoomsCollection } from "src/features/club/collections";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";
import Swal from "sweetalert2";
import { AddEditMeetingRoomModal } from "./components/AddEditMeetingRoomModal";

const columns = [
  {
    Header: "Name",
    accessor: "name",
    width: "60%",
  },
  {
    Header: "actions",
    accessor: "actions",
    align: "center",
    width: "40%",
  },
];

export function ManageMeetingRoomsModal({ handleClose }) {
  const { deleteData } = entityCrudUtils();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [tableRows, setTableRows] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState(null);

  const handleDeleteMeetingRoom = async (meetingRoom) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover the meeting room!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteData({
            entity: getClubMeetingRoomsCollection(
              selectedClubId,
              selectedLocationId
            ),
            pathSegmentsArr: [meetingRoom.id],
          });
        }
      });
  };

  const handleEditMeetingRoom = (meetingRoom) => {
    setSelectedMeetingRoom(meetingRoom);
    setShowAddEditModal(true);
  };

  useEffect(() => {
    if (!meetingRooms) return;

    const rows = [];

    meetingRooms.forEach((element) => {
      rows.push({
        name: <DefaultCell>{element.title}</DefaultCell>,
        actions: (
          <ActionsCell
            onEdit={() => handleEditMeetingRoom(element)}
            onDelete={() => handleDeleteMeetingRoom(element)}
          />
        ),
      });
    });

    setTableRows(rows);
  }, [meetingRooms]);

  const handleAddEditModalClose = () => {
    setShowAddEditModal(false);
    setSelectedMeetingRoom(null);
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Manage Meeting Rooms
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid display="flex" justifyContent="right">
              <SoftButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => setShowAddEditModal(true)}
              >
                + new meeting room
              </SoftButton>
            </Grid>
            <Grid item xs={12}>
              <SoftBox py={1}>
                <DataTable
                  table={{ columns, rows: tableRows }}
                  entriesPerPage={false}
                />
              </SoftBox>
            </Grid>
            <Grid
              mt={0.3}
              container
              spacing={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5} xl={3}>
                <SoftBox mt={1} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Close
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Modal
        open={showAddEditModal}
        onClose={handleAddEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddEditMeetingRoomModal
          selectedMeetingRoom={selectedMeetingRoom}
          handleClose={handleAddEditModalClose}
        />
      </Modal>
    </ModalContainer>
  );
}

ManageMeetingRoomsModal.propTypes = {
  handleClose: PropTypes.func,
};
