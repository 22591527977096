import SoftTypography from "src/components/SoftTypography";
import TableCell from "@mui/material/TableCell";
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

const { borderWidth } = borders;
const { secondary } = colors;

export const HeaderCellText = ({ children, alignRight, color }) => {
  return (
    <SoftTypography
      color={color || "secondary"}
      variant="h6"
      fontSize="small"
      textTransform="uppercase"
      sx={{ pr: alignRight ? 1.5 : 0 }}
    >
      {children}
    </SoftTypography>
  );
};

export const HeaderCell = ({ children, alignRight }) => {
  return (
    <TableCell
      sx={{
        borderBottom: `${borderWidth[1]} solid ${secondary.main}44`,
        backgroundColor: "transparent",
      }}
      align={alignRight ? "right" : "inherit"}
      pr={alignRight ? 1 : 0}
    >
      {children}
    </TableCell>
  );
};

export default HeaderCell;
