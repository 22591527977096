import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

import SoftBadge from "src/components/SoftBadge";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
};

export const getTableColumns = (onAction) => [
  {
    Header: "Name",
    accessor: "label",
  },
  {
    Header: "Location",
    accessor: "location.display_name",
  },
  {
    Header: "IP Address",
    accessor: "ip_address",
  },
  {
    Header: "Serial Number",
    accessor: "serial_number",
  },
  {
    Header: "SW Version",
    accessor: "device_sw_version",
  },
  {
    Header: "Status",
    width: "10%",
    align: "center",
    Cell: ({ row: { original } }) => (
      <SoftBadge
        variant="contained"
        color={original.status === "online" ? "success" : "error"}
        size="xs"
        badgeContent={original.status || "No status"}
        container
        sx={{
          marginRight: 1,
          textTransform: "capitalize",
        }}
      />
    ),
  },
  {
    Header: "Actions",
    width: "10%",
    align: "center",
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftBox sx={{ display: "flex" }}>
          <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={() => onAction(TableActions.DELETE, original)}
          >
            <Icon>delete</Icon>
          </IconButton>
          <IconButton
            aria-label="edit"
            sx={iconButtonStyles}
            onClick={() => onAction(TableActions.EDIT, original)}
          >
            <Icon>edit</Icon>
          </IconButton>
        </SoftBox>
      </SoftBox>
    ),
  },
];
