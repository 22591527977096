import {
  collectionGroup,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";
import { uniqBy } from "lodash-es";

export const getClubsFromLocations = async (userLocationIds) => {
  const locationsQ = query(collectionGroup(db, "locations"));

  const locs = await getDocs(locationsQ);
  const parentsPromises = [];
  const parentPaths = [];
  locs.docs.forEach((doc) => {
    if (userLocationIds.includes(doc.id)) {
      const path = doc.ref.parent.parent;
      parentPaths.push(path);
    }
  });
  const uniqueParentPaths = uniqBy(parentPaths, (path) => path.id);
  uniqueParentPaths.forEach((path) => parentsPromises.push(getDoc(path)));
  const arrayOfParentsDocumentSnapshots = await Promise.all(parentsPromises);
  return arrayOfParentsDocumentSnapshots;
};

export const getUsersInClub = async (
  clubUserPermissionsSnap,
  userMap,
  excludedUserTypes = ["none"]
) => {
  if (!clubUserPermissionsSnap || !userMap) return [];
  const permissions = clubUserPermissionsSnap?.filter(
    (data) => !excludedUserTypes.includes(data?.data().userType)
  );

  const parentPaths = [];
  if (!permissions || permissions.length === 0) return [];
  permissions.forEach((doc) => {
    if (!doc.data()?.deleted) {
      const path = doc.ref.parent.parent;
      parentPaths.push(path);
    }
  });

  const uniqueParentPaths = uniqBy(parentPaths, (path) => path.id);
  const arrayOfParentsDocuments = [];

  for (const key in uniqueParentPaths) {
    if (Object.hasOwnProperty.call(uniqueParentPaths, key)) {
      const path = uniqueParentPaths[key];
      const doc = userMap.get(path.id);
      if (doc) {
        arrayOfParentsDocuments.push(doc);
      }
    }
  }
  return arrayOfParentsDocuments;
};

export const getUsersInLocationArray = async (
  clubUserPermissionsSnap,
  userMap,
  locationIds
) => {
  if (!clubUserPermissionsSnap || !userMap) return [];
  const permissions = clubUserPermissionsSnap?.filter((data) =>
    locationIds.includes(data?.data().locationId)
  );

  const parentPaths = [];
  if (!permissions || permissions.length === 0) return [];
  permissions.docs.forEach((doc) => {
    if (locationIds.includes(doc.data().locationId)) {
      if (doc?.data()?.deleted) return;
      const path = doc.ref.parent.parent;
      parentPaths.push(path);
    }
  });

  const uniqueParentPaths = uniqBy(parentPaths, (path) => path.id);
  const arrayOfParentsDocuments = [];

  for (const key in uniqueParentPaths) {
    if (Object.hasOwnProperty.call(uniqueParentPaths, key)) {
      const path = uniqueParentPaths[key];
      const doc = userMap.get(path.id);
      if (doc) {
        arrayOfParentsDocuments.push(doc);
      }
    }
  }

  return arrayOfParentsDocuments;
};

export const getUsersInLocation = async (
  clubUserPermissionsSnap,
  userMap,
  locationId
) => {
  if (!clubUserPermissionsSnap || !userMap) {
    console.log("getUsersInLocation: No clubUserPermissionsSnap or userMap");
    return [];
  }
  const permissions = clubUserPermissionsSnap?.filter(
    (data) => data?.data().locationId === locationId
  );
  const parentPaths = [];
  permissions?.forEach((doc) => {
    if (doc?.data()?.deleted) return;
    const path = doc.ref.parent.parent;
    parentPaths.push(path);
  });

  const uniqueParentPaths = uniqBy(parentPaths, (path) => path.id);
  const arrayOfParentsDocuments = [];

  for (const key in uniqueParentPaths) {
    if (Object.hasOwnProperty.call(uniqueParentPaths, key)) {
      const path = uniqueParentPaths[key];
      const doc = userMap.get(path.id);
      if (doc) {
        arrayOfParentsDocuments.push(doc);
      }
    }
  }

  return arrayOfParentsDocuments;
};

export const getUserTypeInLocation = async (locationId, userId) => {
  const permissionsSubCollectionQuery = query(
    collectionGroup(db, "permissions"),
    where("locationId", "==", locationId),
    where("id", "==", userId)
  );

  const permissions = await getDocs(permissionsSubCollectionQuery);

  return permissions.docs[0].data();
  /*
  const parentsPromises = [];
  const parentPaths = [];
  permissions.docs.forEach((doc) => {
    const path = doc.ref.parent.parent;
    parentPaths.push(path);
  });

  const uniqueParentPaths = uniqBy(parentPaths, (path) => path.id);
  uniqueParentPaths.forEach((path) => parentsPromises.push(getDoc(path)));
  const arrayOfParentsDocuments = await Promise.all(parentsPromises);
  return arrayOfParentsDocuments.map((doc) => ({ ...doc.data(), id: doc.id })); */
};
