import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

// Timeline context
import { TimelineProvider } from "src/components/Timeline/context";

import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function TimelineList({ title, dark = false, children }) {
  const navigate = useNavigate();

  const addNewAnnouncement = () => {
    navigate("/club-announcements/new-announcement");
  };

  return (
    <TimelineProvider value={dark}>
      <Card>
        <SoftBox bgColor={dark ? "dark" : "white"} variant="gradient">
          <SoftBox pt={3} px={3}>
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              color={dark ? "white" : "dark"}
            >
              {title}
            </SoftTypography>
            <WithPermissions
              permissions={systemPermissions.SEND_NEW_ANNOUNCEMENT}
            >
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="text"
                  color="info"
                  onClick={addNewAnnouncement}
                >
                  + Add New
                </SoftButton>
              </SoftBox>
            </WithPermissions>
          </SoftBox>
          <SoftBox p={2}>{children}</SoftBox>
        </SoftBox>
      </Card>
    </TimelineProvider>
  );
}

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
