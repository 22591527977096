import CoverLayout from "src/pages/auth/Layout";
import loginBg2 from "src/assets/images/login-bg2.jpg";
import SoftTypography from "src/components/SoftTypography";
import PropTypes from "prop-types";

export function AuthLoadingPage({ text }) {
  return (
    <CoverLayout
      title="Welcome back"
      description={text ?? "Please wait while we load your data."}
      image={loginBg2}
    >
      <SoftTypography component="label" variant="caption" fontWeight="bold">
        Loading...
      </SoftTypography>
    </CoverLayout>
  );
}

AuthLoadingPage.propTypes = {
  text: PropTypes.string,
};
