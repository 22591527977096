import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';

export const BookingInfoDynamicProps = [
  {
    label: 'Aircraft',
    accessor: (booking) => booking.extendedProps.aircraft.label,
    icon: AirplanemodeActiveIcon,
  },
  {
    label: 'Reservation #',
    accessor: (booking) => booking.id,
    icon: ConfirmationNumberIcon,
  },
  {
    label: 'Type',
    accessor: (booking) => booking.extendedProps.type.label,
    icon: AirplaneTicketIcon,
  },
];
