import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";

// Analytics application components
import AircraftFlightHoursChart from "./components/AircraftFlightHoursChart";
import InstructorBillableHoursChart from "./components/InstructorBillableHoursChart";

import { useClubs } from "src/features/club/ClubProvider";
import {
  startOfWeek,
  startOfDay,
  differenceInDays,
  subDays,
  isWithinInterval,
  endOfDay,
} from "date-fns";
import {
  query,
  orderBy,
  getCountFromServer,
  where,
  getDocs,
} from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getUserPermissionsCollectionGroup } from "src/features/user/collections";

function ReportsSummary() {
  const { selectedClubId, selectedLocationId } = useClubs();
  const [dateRange, setDateRange] = useState([
    startOfWeek(new Date()),
    new Date(),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const [numberOfBookings, setNumberOfBookings] = useState(0);
  const [numberOfBookingsPercentage, setNumberOfBookingsPercentage] =
    useState(0);
  const [numberOfCancellations, setNumberOfCancellations] = useState(0);
  const [numberOfCancellationsPercentage, setNumberOfCancellationsPercentage] =
    useState(0);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [numberOfNewMembers, setNumberOfNewMembers] = useState(0);
  const [numberOfNewMembersPercentage, setNumberOfNewMembersPercentage] =
    useState(0);

  const getBookingCount = async () => {
    const locationBookingsCollectionRef = query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", false),
      where(
        "completedAt",
        ">=",
        startOfDay(
          subDays(dateRange[0], differenceInDays(dateRange[1], dateRange[0]))
        )
      ),
      orderBy("completedAt", "asc")
    );
    const allBookings = (await getDocs(locationBookingsCollectionRef)).docs;
    if (allBookings.length > 0) {
      let bookingCount = 0;
      let bookingCountPrevious = 0;
      allBookings
        .filter(
          (booking) =>
            ![
              "maintenance",
              "unavailable",
              "requestOnly",
              "meetingRoom",
            ].includes(booking?.data?.()?.extendedProps?.type?.value)
        )
        .map((booking) => {
          const bookingStartDate = booking.data().start.toDate();
          if (
            isWithinInterval(bookingStartDate, {
              start: dateRange[0],
              end: dateRange[1],
            })
          ) {
            bookingCount += 1;
          }
          if (
            isWithinInterval(bookingStartDate, {
              start: startOfDay(
                subDays(
                  dateRange[0],
                  differenceInDays(dateRange[1], dateRange[0])
                )
              ),
              end: dateRange[0],
            })
          ) {
            bookingCountPrevious += 1;
          }
        });
      setNumberOfBookings(bookingCount);

      setNumberOfBookingsPercentage(
        Math.min((bookingCount / bookingCountPrevious) * 100 - 100, 999) || 0
      );
    }
  };

  const getCancelledBookingCount = async () => {
    const locationCancelledBookingsCollectionRef = query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", true),
      where(
        "cancelledAt",
        ">=",
        startOfDay(
          subDays(dateRange[0], differenceInDays(dateRange[1], dateRange[0]))
        )
      )
    );

    const cancelledBookings = (
      await getDocs(locationCancelledBookingsCollectionRef)
    ).docs;

    const cancelledBookingCount = cancelledBookings.filter(
      (booking) =>
        booking.data().cancelledAt.toDate() >= dateRange[0] &&
        booking.data().cancelledAt.toDate() <= dateRange[1]
    ).length;
    setNumberOfCancellations(cancelledBookingCount);

    const cancelledBookingsPrevious = (
      await getDocs(locationCancelledBookingsCollectionRef)
    ).docs;

    const cancelledBookingCountPrevious = cancelledBookingsPrevious.filter(
      (booking) => {
        if (
          ["maintenance", "unavailable", "requestOnly", "meetingRoom"].includes(
            booking.extendedProps?.type?.value
          )
        ) {
          return false;
        }
        return (
          booking.data().cancelledAt.toDate() >=
            startOfDay(
              subDays(
                dateRange[0],
                differenceInDays(dateRange[1], dateRange[0])
              )
            ) && booking.data().cancelledAt.toDate() < dateRange[0]
        );
      }
    ).length;

    setNumberOfCancellationsPercentage(
      Math.round(
        Math.min(
          (cancelledBookingCount / cancelledBookingCountPrevious) * 100 - 100,
          999
        )
      ) || 0
    );
  };

  const getRecentlyAddedMemberCount = async () => {
    const recentlyAddedMemberCollectionRef = query(
      getUserPermissionsCollectionGroup(),
      where("locationId", "==", selectedLocationId),
      where(
        "addedAt",
        ">=",
        startOfDay(
          subDays(dateRange[0], differenceInDays(dateRange[1], dateRange[0]))
        )
      )
    );
    const membersAdded = (await getDocs(recentlyAddedMemberCollectionRef)).docs;

    const recentlyAddedMemberCount = membersAdded.filter(
      (member) =>
        member.data().addedAt.toDate() >= dateRange[0] &&
        member.data().addedAt.toDate() <= dateRange[1]
    ).length;
    setNumberOfNewMembers(recentlyAddedMemberCount);

    const membersAddedPrevious = (
      await getDocs(recentlyAddedMemberCollectionRef)
    ).docs;

    const recentlyAddedMemberCountPrevious = membersAddedPrevious.filter(
      (member) =>
        member.data().addedAt.toDate() >=
          startOfDay(
            subDays(dateRange[0], differenceInDays(dateRange[1], dateRange[0]))
          ) && member.data().addedAt.toDate() < dateRange[0]
    ).length;

    setNumberOfNewMembersPercentage(
      Math.round(
        Math.min(
          (recentlyAddedMemberCount / recentlyAddedMemberCountPrevious) * 100 -
            100,
          999
        ) || 0
      )
    );
  };

  const totalMemberCollectionRef = query(
    getUserPermissionsCollectionGroup(),
    where("locationId", "==", selectedLocationId)
  );
  const getMemberCount = async () => {
    const memberCount = await getCountFromServer(totalMemberCollectionRef);
    setNumberOfMembers(memberCount.data().count);
  };

  useEffect(() => {
    getMemberCount();
  }, []);

  useEffect(() => {
    getBookingCount();
    getCancelledBookingCount();
    getRecentlyAddedMemberCount();
  }, [dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftDateRangePicker
          value={dateRange}
          onChange={(newDates) => updateDateRange(newDates)}
        />
        <SoftBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Members",
                  fontWeight: "medium",
                }}
                count={numberOfMembers}
                icon={{
                  color: "success",
                  component: "person",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "New Members",
                  fontWeight: "medium",
                }}
                count={numberOfNewMembers}
                percentage={{
                  color: numberOfNewMembersPercentage < 0 ? "error" : "success",
                  text: `${
                    numberOfNewMembersPercentage < 0 ? "" : "+"
                  }${Math.round(numberOfNewMembersPercentage)}%`,
                }}
                icon={{
                  color: "success",
                  component: "person_add",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Bookings",
                  fontWeight: "medium",
                }}
                count={numberOfBookings}
                percentage={{
                  color: numberOfBookingsPercentage < 0 ? "error" : "success",
                  text: `${
                    numberOfBookingsPercentage < 0 ? "" : "+"
                  }${Math.round(numberOfBookingsPercentage)}%`,
                }}
                icon={{
                  color: "info",
                  component: "event",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Cancellations",
                  fontWeight: "medium",
                }}
                count={numberOfCancellations}
                percentage={{
                  color:
                    numberOfCancellationsPercentage > 0 ? "error" : "success",
                  text: `${
                    numberOfCancellationsPercentage < 0 ? "" : "+"
                  }${Math.round(numberOfCancellationsPercentage)}%`,
                }}
                icon={{
                  color: "error",
                  component: "close",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <AircraftFlightHoursChart dateRange={dateRange} />
            </Grid>
            <Grid item xs={12} lg={5}>
              <InstructorBillableHoursChart dateRange={dateRange} />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} />
          <Grid item xs={12} lg={6}>
            {/* <MembersData /> */}
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReportsSummary;
