import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import axios from "axios";

import PropTypes from "prop-types";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import { Divider, Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

// Authentication layout components
import CoverLayout from "../Layout";

// Images
import { useForm } from "react-hook-form";
import { getInvitesCollection } from "src/features/invites/collections";
import loginBg2 from "src/assets/images/login-bg2.jpg";
import {
  auth,
  finaliseUserInfoAdding,
  registerWithEmailAndPassword,
} from "src/features/firebase/auth/utils";

import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";
import { useUser } from "src/features/user/UserProvider";
import { getUsersCollection } from "src/features/user/collections";
// import { getInstructorsCollection } from 'src/features/instructor/collections';
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";

import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import MicrosoftSSO from "src/assets/images/sso/microsoft-logo.svg";
import GoogleSSO from "src/assets/images/sso/google-logo.svg";
import { usePermissions } from "src/hooks/usePermissions";

const provider = new OAuthProvider("microsoft.com");
const providerGoogle = new GoogleAuthProvider();

const schema = yup
  .object({
    firstName: yup.string().required("Your first name is required."),
    lastName: yup.string().required("Your last name is required."),
    email: yup.string().email().required("An email is required."),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    toc: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions."),
  })
  .required();

const dividerStyle = {
  margin: "2rem 0",
};

function AcceptInvite({ authenticated = false }) {
  const { inviteId } = useParams();
  const { userId, user, signOut } = useUser();
  const { userPermissions } = useUserPermissions();
  const { setSelectedClubId, selectedClub } = useClubs();
  const [agreement, setAgreement] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [firebaseInProgress, setFirebaseInProgress] = useState(true);
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [invite, setInvite] = useState();
  const navigate = useNavigate();
  const [inviteSnapshot, loadingInvite] = useDocument(
    doc(getInvitesCollection(), inviteId)
  );
  const { isSuperAdmin } = usePermissions();

  const addClubsToUser = async (mInvite, mUserId) => {
    if (mInvite.permissions) {
      const setClubPermsPromises = Object.entries(mInvite.permissions).map(
        ([key, value]) => {
          setDoc(doc(getUsersCollection(), `${mUserId}/permissions`, key), {
            clubId: mInvite.clubId,
            locationId: key,
            addedAt: new Date(),
            userType: value,
          });
        }
      );

      await Promise.all(setClubPermsPromises);
    }
  };

  const addMembershipToUser = async (mInvite, mUserId) => {
    if (mInvite.membership)
      await setDoc(
        doc(getUsersCollection(), `${mUserId}/memberships`, mInvite.clubId),
        {
          active: true,
          createdAt: new Date(),
          membershipPlan: mInvite.membership,
          nextBillDate: new Date(),
          status: "active",
          updatedAt: new Date(),
        }
      );
  };

  // const addInstructorToClub = async (mInvite, mUserId) => {
  //   if (mInvite.clubId)
  //     Object.entries(mInvite.permissions).map(async ([key]) => {
  //       await setDoc(doc(getInstructorsCollection(mInvite.clubId, key), mUserId), {
  //         hourlyRate: mInvite.preferences?.hourlyRate || 0, // TODO: get club default instructor hourly rate
  //         instructorType: mInvite.preferences?.instructorType || '',
  //       });
  //     });
  // };

  useEffect(() => {
    if (loadingInvite || !inviteSnapshot || !selectedClub) return;

    if (authenticated && !firebaseInProgress) {
      if (
        user &&
        inviteSnapshot.data() &&
        !inviteSnapshot.data().accepted &&
        user.email === inviteSnapshot.data().email
      ) {
        const func = async () => {
          await addClubsToUser(inviteSnapshot.data(), userId);
          await addMembershipToUser(inviteSnapshot.data(), userId);
          setSelectedClubId(inviteSnapshot.data().clubId);
          if (
            selectedClub &&
            !selectedClub.stripeAccountId &&
            isSuperAdmin(user.uid)
          ) {
            navigate("/club-setup");
          } else {
            navigate("/dashboard");
          }
        };
        func();
        return;
      }
      // user is logged in but not the same as the invite
      const newSwal = Swal.mixin({
        customClass: {
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });

      newSwal
        .fire({
          title: "Incorrect User",
          text: "You cannot accept an invite sent to a different email!",
          showCancelButton: true,
          confirmButtonText: "Logout",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            await signOut();
          }
        });
    }

    setInvite(inviteSnapshot.data());

    if (inviteSnapshot.data() && inviteSnapshot.data().accepted) {
      setSignUpSuccessful(true);
    }

    if (inviteSnapshot.data()) {
      setFirebaseInProgress(false);
    }
  }, [inviteSnapshot]);

  const handleSetAgremment = () => setAgreement(!agreement);

  const handleOpenTerms = () => {
    window.open("https://preflightfbo.app/terms", "_blank");
  };

  const onSubmit = async (data) => {
    try {
      setFirebaseInProgress(true);
      let uuid;
      if (invite?.userId) {
        const functions = getFunctions();
        const createAuthUser = httpsCallable(functions, "createAuthUser");
        await createAuthUser({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          userId: invite.userId,
        }).catch((error) => {
          console.error("Add User Error:", error);
          setFirebaseInProgress(false);
        });
        await signInWithEmailAndPassword(auth, data.email, data.password);
        uuid = invite.userId;
      } else {
        const userCreated = await registerWithEmailAndPassword(
          data.firstName,
          data.lastName,
          data.email,
          data.password,
          true
        );
        uuid = userCreated.id;

        await addClubsToUser(invite, uuid);
        await addMembershipToUser(invite, uuid);
      }

      await finaliseUserInfoAdding(uuid);
      await deleteDoc(inviteSnapshot.ref);
    } catch (err) {
      setFirebaseInProgress(false);

      switch (err.code) {
        case "auth/email-already-in-use":
          setError("email", {
            type: "custom",
            message: "A user with this email already exists",
          });
          break;
        case "auth/invalid-password":
          setError("password", {
            type: "custom",
            message: "Password must be at least 6 characters long",
          });
          break;
        default:
          console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!selectedClub || !user || !userPermissions) return;
    if (
      selectedClub &&
      !selectedClub.stripeAccountId &&
      isSuperAdmin(user.uid)
    ) {
      navigate("/club-setup");
    } else {
      navigate("/dashboard");
    }
  }, [selectedClub, firebaseInProgress, user]);

  if (auth.currentUser) {
    return (
      <AuthLoadingPage text="Please wait while we add you to the new club!" />
    );
  }

  const signInWithMicrosoft = async () => {
    const authMicrosoft = getAuth();
    signInWithPopup(authMicrosoft, provider)
      .then((result) => {
        setFirebaseInProgress(true);
        const credential = OAuthProvider.credentialFromResult(result);
        const { accessToken } = credential;
        axios
          .get("https://graph.microsoft.com/v1.0/me", {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(async (res) => {
            const functions = getFunctions();
            const addSsoUser = httpsCallable(functions, "addSsoUser");
            await addSsoUser({
              firstName: res.data.givenName,
              lastName: res.data.surname,
              displayName: res.data.displayName,
              email: res.data.userPrincipalName,
              uid: authMicrosoft.currentUser.uid,
            });
            await addClubsToUser(invite, authMicrosoft.currentUser.uid);
            await addMembershipToUser(invite, authMicrosoft.currentUser.uid);
            await finaliseUserInfoAdding(authMicrosoft.currentUser.uid);
            await deleteDoc(inviteSnapshot.ref);
          });
      })
      .catch((error) => {
        setFirebaseInProgress(false);
        console.error("ERROR: ", error);
      }); // td2742153@gmail.com k2AUgNPejMSHc99vrAPdjntsqPC2
  };

  const signInWithGoogle = async () => {
    const authGoogle = getAuth();
    signInWithPopup(authGoogle, providerGoogle)
      .then(async (result) => {
        setFirebaseInProgress(true);
        const ssoUser = result.user;
        ssoUser.firstName = result._tokenResponse.firstName;
        ssoUser.lastName = result._tokenResponse.lastName;
        const functions = getFunctions();
        const addSsoUser = httpsCallable(functions, "addSsoUser");
        await addSsoUser({
          firstName: ssoUser.firstName,
          lastName: ssoUser.lastName,
          displayName: ssoUser.displayName,
          email: ssoUser.email,
          photoURL: ssoUser.photoURL,
          uid: ssoUser.uid,
        });
        await addClubsToUser(invite, ssoUser.uid);
        await addMembershipToUser(invite, ssoUser.uid);
        await finaliseUserInfoAdding(ssoUser.uid);
        await deleteDoc(inviteSnapshot.ref);
      })
      .catch((error) => {
        setFirebaseInProgress(false);
        console.error("ERROR: ", error);
      });
  };

  if (!loadingInvite && !inviteSnapshot.data()) {
    return (
      <CoverLayout
        title="Oops!"
        description="We are unable to find your invite..."
        image={loginBg2}
      >
        <SoftTypography variant="caption">
          This is normally because the invite has already been accepted or it
          has expired. Please check the link and try again, or contact the club
          to resend a new invite.
        </SoftTypography>
        <SoftBox mt={3}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Already accepted this invite?{" "}
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in here!
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </CoverLayout>
    );
  }

  if (firebaseInProgress) {
    return (
      <CoverLayout
        title="Join us today"
        description="Enter your email and password to sign in"
        image={loginBg2}
      >
        <SoftTypography component="label" variant="caption" fontWeight="bold">
          Loading
        </SoftTypography>
      </CoverLayout>
    );
  }

  if (signUpSuccessful)
    return (
      <CoverLayout
        title="Woohoo!"
        description="Your account has been created successfully!"
        image={loginBg2}
      >
        <SoftBox mt={1} textAlign="left">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Proceed to sign in &nbsp;
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </CoverLayout>
    );

  return (
    <CoverLayout
      title={`Join ${invite?.clubName}`}
      description="Enter your email and password to register"
      image={loginBg2}
      top={7}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  First Name
                </SoftTypography>
              </SoftBox>
              <SoftInput
                placeholder="First Name"
                {...register("firstName", {
                  required: true,
                })}
                defaultValue={`${invite?.firstName}`}
              />
              {errors?.firstName?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.firstName.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Last Name
                </SoftTypography>
              </SoftBox>
              <SoftInput
                placeholder="Last Name"
                {...register("lastName", {
                  required: true,
                })}
                defaultValue={`${invite?.lastName}`}
              />
              {errors?.lastName?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.lastName.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
        </Grid>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            {...register("email", {
              required: true,
            })}
            defaultValue={invite?.email}
          />
          {errors?.email?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.email.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            {...register("password", {
              required: true,
            })}
          />
          {errors?.password?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.password.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Checkbox
            checked={agreement}
            onClick={handleSetAgremment}
            {...register("toc", {
              required: true,
            })}
          />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgremment}
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
            color={errors?.toc?.message && "error"}
          >
            &nbsp;&nbsp;I agree to the&nbsp;
          </SoftTypography>
          <SoftTypography
            component="a"
            href="#"
            variant="button"
            onClick={handleOpenTerms}
            fontWeight="bold"
            color={errors?.toc?.message && "error"}
          >
            Terms and Conditions
          </SoftTypography>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            sign up
          </SoftButton>
        </SoftBox>
        <Divider style={dividerStyle}>
          <SoftTypography
            variant="caption"
            style={{
              position: "relative",
              top: "-19px",
              backgroundColor: "white",
              padding: "0 10px",
            }}
          >
            or using
          </SoftTypography>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftButton
              id="google-auth-button"
              type="button"
              variant="outlined"
              onClick={signInWithGoogle}
              style={{ borderColor: "#dedede", padding: "0rem 1.5rem" }}
              fullWidth
            >
              <SoftBox
                component="img"
                src={GoogleSSO}
                alt="Sign in with Google"
                width="20px"
                mr={2}
              />
              <SoftTypography variant="h6" fontWeight="regular" m={0}>
                Google
              </SoftTypography>
            </SoftButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftButton
              id="microsoft-auth-button"
              type="button"
              variant="outlined"
              onClick={signInWithMicrosoft}
              style={{ borderColor: "#dedede", padding: "0rem 1.5rem" }}
              fullWidth
            >
              <SoftBox
                component="img"
                src={MicrosoftSSO}
                alt="Sign in with Microsoft"
                width="20px"
                mr={2}
              />
              <SoftTypography variant="h6" fontWeight="regular" m={0}>
                Microsoft
              </SoftTypography>
            </SoftButton>
          </Grid>
        </Grid>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

AcceptInvite.propTypes = {
  authenticated: PropTypes.bool,
};

export default AcceptInvite;
