import { useEffect, useMemo, useState } from "react";
import { Radio } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "src/components/TableCell";

import { addDoc } from "firebase/firestore";
import { getUserTaskGradingsCollection } from "src/features/user/collections";

const schema = yup.object({}).required();

export function GradeTaskModal({ handleClose, lesson, taskId }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const task = useMemo(
    () => lesson.tasks?.find((t) => t.id === taskId),
    [taskId, lesson]
  );

  const { handleSubmit } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const [grade, setGrade] = useState("");
  const [oldTaskGrade, setOldTaskGrade] = useState({});

  const handleChangeTaskGrade = (event) => {
    setGrade(event.target.value);
  };

  useEffect(() => {
    // set initial values
    const task = lesson.tasks.find((lessonTask) => lessonTask.id == taskId);
    if (task?.grade) {
      setGrade(task.grade.grade);
      setOldTaskGrade(task.grade);
    }
  }, [lesson]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (!oldTaskGrade || oldTaskGrade.grade !== grade) {
      // create doc for taskGrade with new grade value
      await addDoc(
        getUserTaskGradingsCollection(lesson.studentId, lesson.enrollmentId),
        {
          taskId,
          grade,
          deleted: false,
          enrollmentId: lesson.enrollmentId,
          lessonId: lesson.id,
          studentId: lesson.studentId,
          createdAt: new Date(),
        }
      );
    }
    setIsSubmitting(false);
    handleClose();
  };

  const controlProps = (value) => ({
    checked: grade === value,
    onChange: handleChangeTaskGrade,
    value,
    name: taskId,
    inputProps: { "aria-label": value },
  });

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h6" color="primary" fontWeight="bold">
            Grade Task
          </SoftTypography>
          <SoftTypography variant="h5" fontWeight="bold">
            {task?.title}
          </SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SoftBox pb={2}>
                  <SoftBox
                    minWidth="auto"
                    sx={{
                      overflow: "scroll",
                    }}
                  >
                    <Table
                      sx={{
                        minWidth: "36rem",
                      }}
                    >
                      <SoftBox component="thead">
                        <TableRow>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            1
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            2
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            3
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            4
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            5
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            I
                          </TableCell>
                        </TableRow>
                      </SoftBox>
                      <TableBody>
                        <TableRow key={task.id}>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("1")} color="success" />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("2")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("3")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("4")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("5")} color="error" />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps("I")} color="warning" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

GradeTaskModal.propTypes = {
  lesson: PropTypes.object.isRequired,
  taskId: PropTypes.string,
};
