import colors from "src/assets/theme/base/colors";
import boxShadows from "src/assets/theme/base/boxShadows";

const { grey, primary } = colors;
const { sm, buttonBoxShadow } = boxShadows;

const fab = {
  styleOverrides: {
    root: {
      backgroundColor: grey[100],
      boxShadow: sm,
      "&:hover": {
        backgroundColor: primary.background,
        boxShadow: buttonBoxShadow.stateOf,
      },

      "&:focus": {
        boxShadow: buttonBoxShadow.stateOf,
      },
    },
  },
};

export default fab;