import { collection } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getTransactionsCollection = (userId) =>
  collection(db, `billing/${userId}/transactions`);

const getClubTransactionsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/billing`);

export { getTransactionsCollection, getClubTransactionsCollection };
