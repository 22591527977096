import * as toastr from "toastr";
import { useNavigate, Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

// Authentication layout components
import CoverLayout from "../Layout";

// Images
import loginBg2 from "src/assets/images/login-bg2.jpg";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const schema = yup
  .object({
    email: yup.string().email("Please enter a valid email address."),
  })
  .required();

function PasswordReset() {
  const auth = getAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (e) => {
    sendPasswordResetEmail(auth, e.email)
      .then(() => {
        toastr.success(
          "Your request for a password reset was sent.",
          "Success"
        );
        navigate("/auth/login");
      })
      .catch((error) => {
        toastr.error(
          "There was an error sending your password reset.",
          "Error"
        );
        console.error(error);
      });
  };

  return (
    <CoverLayout
      title="Reset Password"
      description="If an account is found in our system, you will receive an email with a link to reset your password."
      image={loginBg2}
      top={30}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Enter your e-mail"
            {...register("email", {
              required: true,
            })}
          />
          {errors?.email?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.email.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            reset
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Remembered your details?&nbsp;
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default PasswordReset;
