import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  startOfMonth,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where } from "firebase/firestore";
import { getAircraftCheckoutsCollection } from "src/features/aircraft/collections";
import { useCollection } from "react-firebase-hooks/firestore";

const columns = [
  {
    Header: "date given",
    accessor: "date",
    Cell: ({ value }) =>
      value ? (
        <DefaultCell value={format(value?.toDate(), "eee, MMM do yyyy")} />
      ) : null,
  },
  {
    Header: "Aircraft",
    accessor: "tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Pilot",
    accessor: "pilot.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "instructor.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Expiration Date",
    accessor: "expires",
    Cell: ({ value }) => (
      <DefaultCell
        value={`${
          value?.toDate() ? format(value.toDate(), "MM/dd/yyyy") : "Never"
        }`}
      />
    ),
  },
];

function CheckOutsReport() {
  const { selectedClubId, selectedClub, selectedLocation } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const checkoutsCollectionRef = query(
    getAircraftCheckoutsCollection(),
    where("clubId", "==", selectedClubId),
    where("date", ">=", dateRange[0] || startOfMonth(new Date())),
    orderBy("date", "asc")
  );
  const [allCheckouts, loadingAllCheckouts] = useCollection(
    checkoutsCollectionRef,
    {
      snapshotListenOptions: {
        includeMetadataChanges: false,
      },
    }
  );

  useEffect(() => {
    // setRows([{ tailNumber: 'sdsdsd' }, { tailNumber: 'sdsdsd' }]);
    if (!loadingAllCheckouts && allCheckouts) {
      const rowData = allCheckouts.docs.map((checkout) => {
        isWithinInterval(checkout.data().date.toDate(), {
          start: dateRange[0],
          end: dateRange[1],
        });
        return isWithinInterval(checkout.data().date.toDate(), {
          start: dateRange[0],
          end: dateRange[1],
        })
          ? { ...checkout.data(), id: checkout.id }
          : null;
      });

      setRows(rowData);
      setCsvData(
        rowData.map((checkout) => ({
          "Date Given": format(checkout.date.toDate(), "MM/dd/yyyy HH:mm"),
          Aircraft: checkout.tailNumber,
          Pilot: checkout.pilot.displayName,
          Instructor: checkout.instructor.displayName,
          "Expiration Date": checkout.expires?.toDate()
            ? format(checkout.expires.toDate(), "MM/dd/yyyy")
            : "Never",
        }))
      );
    }
  }, [allCheckouts, loadingAllCheckouts, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-check_outs-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CheckOutsReport;
