// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

function Sidenav() {
  const sidenavItems = [
    {
      icon: <PriceCheckIcon />,
      label: "rental rates",
      href: "rental-rates",
    },
    {
      icon: <LocalGasStationIcon />,
      label: "fuel reimbursement",
      href: "fuel-reimbursement",
    },
    {
      icon: <CreditCardIcon />,
      label: "payment terminals",
      href: "payment-terminals",
    },
    {
      icon: <AccountBalanceIcon />,
      label: "billing",
      href: "club-billing",
    },
    {
      icon: <IntegrationInstructionsIcon />,
      label: "integrations",
      href: "integrations",
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "135px",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{
          listStyle: "none",
        }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default Sidenav;
