import { useEffect, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "src/components/SoftTypography";
import Footer from "src/components/Footer";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import { getAnnouncementsCollection, getClubAnnouncement } from "src/features/announcements/collection";
import { query, orderBy } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { formatRelative } from "date-fns";
import AnnouncementInfo from "./components/AnnouncementInfo";

import TimelineList from "./components/TimelineList";
import TimelineItem from "./components/TimelineItem";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";

function ClubAnnouncements() {
  const { selectedClub, selectedClubId, selectedLocationId } = useClubs();
  const [showPanel, setShowPanel] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const { deleteDataFromRef } = entityCrudUtils();

  const clubAnnouncementsCollectionRef = query(
    getAnnouncementsCollection(selectedClubId),
    orderBy("createdAt", "desc")
  );
  const { data: announcements, isDataLoaded: announcementsLoaded } =
    useRealtimeCollectionData(clubAnnouncementsCollectionRef, false);

  const handleRowSelection = (announcement) => {
    setSelectedAnnouncement(announcement);
    setShowPanel(true);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  useEffect(() => {
    if (announcementsLoaded && announcements.length > 0) {
      handleRowSelection(announcements[0]);
    }
  }, [announcementsLoaded, announcements]);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)} ...`;
  };

  const onDeleteHandler = async (id) => {
    await deleteDataFromRef(getClubAnnouncement(selectedClubId, id))
  }

  const renderTimelineItems = announcements
    .filter(
      (announcement) =>
        !announcement.locationId ||
        announcement.locationId === selectedLocationId
    )
    .map(
      (
        { id, color, icon, title, createdAt, message, messageText, badges },
        index
      ) => (
        <SoftBox
          key={id}
          onClick={() =>
            handleRowSelection({
              id,
              color,
              icon,
              title,
              createdAt,
              message,
              messageText,
              badges,
            })
          }
          sx={
            selectedAnnouncement.id === id
              ? {
                  border: "0 solid",
                  borderColor: "primary.main",
                  borderRightWidth: "2px",
                }
              : {}
          }
        >
          <TimelineItem
            key={id}
            color={color}
            icon={icon}
            dateTime={formatRelative(createdAt.toDate(), new Date())}
            title={title}
            description={truncateString(messageText, 200)}
            badges={badges}
            isSelected={selectedAnnouncement.id === id}
            lastItem={index === announcements.length - 1}
            onDelete={() => onDeleteHandler(id)}
            onClick={() =>
              handleRowSelection({
                id,
                color,
                icon,
                title,
                createdAt,
                message,
                messageText,
                badges,
              })
            }
          />
        </SoftBox>
      )
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Announcements
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                All announcements from {selectedClub.name}.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            leftPaneMinWidth={400}
            leftPaneMaxWidth={600}
          >
            <TimelineList title="">{renderTimelineItems}</TimelineList>
            <AnnouncementInfo announcement={selectedAnnouncement} />
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClubAnnouncements;
