import { collection, collectionGroup, doc } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getUsersCollection = () => collection(db, "users");

const getUserPermissionsCollection = (userId) =>
  collection(db, `users/${userId}/permissions`);

const getUserById = (userId) => doc(db, `users/${userId}`);

const getUserPermissionsDocByLocation = (userId, locationId) =>
  doc(db, `users/${userId}/permissions/${locationId}`);

const getUserPermissionsCollectionGroup = () =>
  collectionGroup(db, `permissions`);

const getUserCheckoutsCollection = (userId) =>
  collection(db, `users/${userId}/checkouts`);

// Only being used to delete old version of data if it still exists in the wrong structure
const getUserCoursesCollection = (userId) =>
  collection(db, `users/${userId}/enrolledCourses`);

const getUserTaskListsCollection = (userId) =>
  collection(db, `users/${userId}/taskLists`);

const getUserEnrollmentsCollection = (userId) =>
  collection(db, `users/${userId}/enrollments`);

const getUserEnrollmentsCollectionGroup = () =>
  collectionGroup(db, `enrollments`);

const getUserEnrolledCoursesCollection = (userId, enrollmentId) =>
  collection(db, `users/${userId}/enrollments/${enrollmentId}/enrolledCourses`);

const getUserStagesCollection = (userId, courseId) =>
  collection(db, `users/${userId}/enrolledCourses/${courseId}/stageGrading`);

const getUserLessonsCollection = (userId, courseId) =>
  collection(db, `users/${userId}/enrolledCourses/${courseId}/lessonGrading`);

const getUserLessonGradingsCollection = (userId, enrollmentId) =>
  collection(db, `users/${userId}/enrollments/${enrollmentId}/lessonGrading`);

const getUserTaskGradingsCollection = (userId, enrollmentId) =>
  collection(db, `users/${userId}/enrollments/${enrollmentId}/taskGrading`);

const getUserCoursesCollectionGroup = () =>
  collectionGroup(db, `enrolledCourses`);

const getUserDocumentsCollection = (userId) =>
  collection(db, `users/${userId}/documents`);

const getMembershipsCollection = (userId) =>
  collection(db, `users/${userId}/memberships`);

const getClubPrefsCollection = (userId) =>
  collection(db, `users/${userId}/clubPrefs`);

const getMembershipsCollectionGroup = () => collectionGroup(db, `memberships`);

export {
  getUserPermissionsDocByLocation,
  getUserPermissionsCollection,
  getUserPermissionsCollectionGroup,
  getUsersCollection,
  getUserCheckoutsCollection,
  getUserCoursesCollection,
  getUserTaskListsCollection,
  getUserTaskGradingsCollection,
  getUserEnrollmentsCollection,
  getUserEnrollmentsCollectionGroup,
  getUserEnrolledCoursesCollection,
  getUserCoursesCollectionGroup,
  getUserLessonsCollection,
  getUserStagesCollection,
  getUserLessonGradingsCollection,
  getUserDocumentsCollection,
  getClubPrefsCollection,
  getMembershipsCollection,
  getMembershipsCollectionGroup,
  getUserById,
};
