import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Settings page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import Notifications from "./components/Notifications";
import DeleteAccount from "./components/DeleteAccount";
import ChangePassword from "./components/ChangePassword";

import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { getUsersCollection } from "src/features/user/collections";

function ManageUser() {
  const { userId } = useParams();
  const [user, setUser] = useState({});

  const [userSnapshot, loadingUser] = useDocument(
    doc(getUsersCollection(), userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingUser) return;
    if (userSnapshot) {
      setUser(userSnapshot);
    }
  }, [userSnapshot]);

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            {loadingUser && (
              <SoftBox mb={3}>
                <SoftBox p={2} mx={3} display="flex" justifyContent="center">
                  <SoftTypography
                    variant="caption"
                    color="text"
                    textAlign="center"
                  >
                    <i>Loading Users..</i>
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            {!loadingUser && user.id && (
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header userSnapshot={user} />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInfo />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid> */}
                  <Grid item xs={12}>
                    <Notifications />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Sessions />
                  </Grid> */}
                  <Grid item xs={12}>
                    <DeleteAccount />
                  </Grid>
                </Grid>
              </SoftBox>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default ManageUser;
