import { IPermission } from "./permission.interface";

export enum eventRoleCategory {
  EVENTS = "EVENTS",
}

export enum eventPermissionTypes {
  VIEW_EVENTS = "VIEW_EVENTS",
  CREATE_EVENT = "CREATE_EVENT",
  MODIFY_EVENT = "MODIFY_EVENT",
  CANCEL_EVENT = "CANCEL_EVENT",
}

export const eventPermissions: IPermission[] = [
  {
    group: eventRoleCategory.EVENTS,
    label: "Events",
    tooltipGroup: "",
    permissions: {
      [eventPermissionTypes.VIEW_EVENTS]: {
        label: "View Events",
        tooltip: "",
      },
      [eventPermissionTypes.CREATE_EVENT]: {
        label: "Create Events",
        tooltip: "",
      },
      [eventPermissionTypes.MODIFY_EVENT]: {
        label: "Modify Events",
        tooltip: "",
      },
      [eventPermissionTypes.CANCEL_EVENT]: {
        label: "Cancel Events",
        tooltip: "",
      },
    },
  },
];
