import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import { Stack } from "@mui/material";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { query, where } from "firebase/firestore";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import { standardDocumentTypes } from "src/config/localDocuments";
import {
  SoftCollapsibleSelect,
  SoftCollapsibleSelectRef,
} from "src/components/SoftCollapsibleSelect/SoftCollapsibleSelect";
import { useRef } from "react";

interface RequiredDocumentsProps {
  requiredDocs: Array<string>;
  setRequiredDocs: React.Dispatch<React.SetStateAction<string[]>>;
}

export const RequiredDocuments = ({
  requiredDocs,
  setRequiredDocs,
}: RequiredDocumentsProps) => {
  const collapsibleRef = useRef<SoftCollapsibleSelectRef>(null);

  const { selectedClubId } = useClubs() as IUseClub;
  const documentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: documentTypes, isDataLoaded: documentTypesLoaded } =
    useRealtimeCollectionData(
      query(documentTypesRef, where("deleted", "==", false))
    );

  return (
    <SoftLoader isLoading={!documentTypesLoaded}>
      <Stack direction="column" gap="5px" mt="12px">
        <SoftCollapsibleSelect
          ref={collapsibleRef}
          title="Required Documents"
          selectedItemsByDefault={requiredDocs}
          items={standardDocumentTypes.concat(documentTypes ?? []).map((d) => ({
            id: d.id,
            label: d.name,
          }))}
          onChange={(e) => setRequiredDocs(e)}
        />
      </Stack>
    </SoftLoader>
  );
};
