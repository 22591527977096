import { useEffect, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

function useRealtimeDocumentData(docRef) {
  const [isDataLoaded, setDataIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  const [snapshot] = useDocument(docRef, {
    snapshotListenOptions: {
      includeMetadataChanges: false,
    },
  });

  const getData = () => {
    if (!snapshot?.data()) return null;
    return { ...snapshot.data(), id: snapshot.id };
  };

  const loadData = async () => {
    const newData = getData();
    setData(newData);
    setDataIsLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, [JSON.stringify(getData())]);

  return {
    data,
    isDataLoaded,
    getData,
  };
}

export default useRealtimeDocumentData;
