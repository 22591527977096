import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

interface ISoftLoader extends CircularProgressProps {
  isLoading: boolean;
  children?: React.ReactNode;
}
function SoftLoader({ isLoading, children, ...rest }: ISoftLoader) {
  return isLoading ? (
    <CircularProgress {...rest} sx={{ margin: "auto", ...rest.sx }} />
  ) : (
    <>{children}</>
  );
}
export default SoftLoader;
