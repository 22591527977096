import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import Checkbox from "@mui/material/Checkbox";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const schema = yup
  .object({
    firstName: yup.string().required("Owners first name is required."),
    lastName: yup.string().required("Owners last name is required."),
    number: yup.string(),
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    address: yup.object({
      line1: yup.string().required("An address is required."),
      line2: yup.string(),
      city: yup.string().required("A city is required."),
      state: yup.string().required("A state is required."),
      zip: yup.string().required("A zip code is required."),
    }),
    dob: yup.string().required("A date of birth is required."),
    ssn: yup.string().required("SSN is required."),
  })
  .required();

export function AddOwnerModal({ handleClose, owner }) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: owner,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    handleClose(data);
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {owner ? "Edit Owner" : "Add Owner"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1} mb={3}>
              <Grid item xs={12}>
                <SoftBox>
                  <Checkbox
                    defaultChecked={watch("relationship.owner")}
                    {...register("relationship.owner", {
                      required: true,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color={errors?.relationship?.owner?.message && "error"}
                  >
                    This person owns 25% or more of the company.
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox>
                  <Checkbox
                    defaultChecked={watch("relationship.director")}
                    {...register("relationship.director", {
                      required: true,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color={errors?.relationship?.director?.message && "error"}
                  >
                    This person is a member of the governing board of the
                    company.
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox>
                  <Checkbox
                    defaultChecked={watch("relationship.executive")}
                    {...register("relationship.executive", {
                      required: true,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color={errors?.relationship?.executive?.message && "error"}
                  >
                    This person is an executive or senior manager with
                    significant management responsibility.
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          First Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("firstName", {
                          required: true,
                        })}
                      />
                      {errors?.firstName?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.firstName.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Last Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("lastName", {
                          required: true,
                        })}
                      />
                      {errors?.lastName?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.lastName.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Job Title
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("relationship.jobTitle", {
                          required: true,
                        })}
                      />
                      {errors?.relationship?.jobTitle?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.relationship.jobTitle.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          % Ownership
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="relationship.percentOwnership"
                        render={({
                          field: { onChange, value, ref: inputRef },
                        }) => (
                          <SoftCurrencyInput
                            style={{
                              ".MuiInputAdornment-root": { right: "-54px" },
                            }}
                            currencySymbol="%"
                            placeholder="0"
                            decimalPlaces={0}
                            decimalPlacesShownOnBlur={0}
                            decimalPlacesShownOnFocus={0}
                            outputFormat="number"
                            adornmentOnRight
                            onBlur={onChange}
                            inputRef={inputRef}
                            value={value}
                          />
                        )}
                      />
                      {errors?.relationship?.percentOwnership?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.relationship.percentOwnership.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Date Of Birth
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("dob", {
                          required: true,
                        })}
                        type="date"
                      />
                      {errors?.dob?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.dob.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Email
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("email", {
                          required: true,
                        })}
                        type="email"
                      />
                      {errors?.email?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.email.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Contact Number
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("phone", {
                          required: true,
                        })}
                        type="phone"
                      />
                      {errors?.phone?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.phone.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Last 4 Digits of SSN
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("ssn", {
                          required: true,
                        })}
                        type="number"
                      />
                      {errors?.ssn?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.ssn.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Address Line 1
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("address.line1", {
                          required: true,
                        })}
                      />
                      {errors?.address?.line1?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.address.line1.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Address Line 2
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("address.line2", {
                          required: true,
                        })}
                      />
                      {errors?.address?.line2?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.address.line2.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          City
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("address.city", {
                          required: true,
                        })}
                      />
                      {errors?.address?.city?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.address.city.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          State
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("address.state", {
                          required: true,
                        })}
                      />
                      {errors?.address?.state?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.address.state.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Zip
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("address.zip", {
                          required: true,
                        })}
                      />
                      {errors?.address?.zip?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.address.zip.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {owner ? "Save" : "Add"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddOwnerModal.propTypes = {
  handleClose: PropTypes.func,
  owner: PropTypes.object,
};
