import { useState } from "react";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import PropTypes from "prop-types";
import SoftSelect from "src/components/SoftSelect";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { getFunctions, httpsCallable } from "firebase/functions";

const schema = yup
  .object({
    type: yup.object(),
    amount: yup.number().when("type.value", {
      is: "custom",
      then: (elementSchema) =>
        elementSchema
          .positive("Refunds must be a positive amount")
          .required("An amount is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    note: yup.string(),
  })
  .required();

export function RefundModal({ handleClose, transaction }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const refundOptions = [
    {
      label: "Full Refund",
      value: "full",
    },
    {
      label: "Specified Amount",
      value: "custom",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      type: { label: "Full Refund", value: "full" },
    },
    resolver: yupResolver(schema),
  });

  const refundType = watch("type");

  const submitRefund = (data) => {
    setIsSubmitting(true);

    const functions = getFunctions();
    const refundTransaction = httpsCallable(functions, "refundTransaction");
    refundTransaction({
      clubId: transaction.club.id,
      transactionId: transaction.id,
      type: data.type.value,
      amount: data.amount,
      note: data.note,
    }).then(() => {
      setIsSubmitting(false);
      handleClose(true);
    });
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(submitRefund)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Refund Transaction
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Refund Type
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={refundOptions}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.type?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.type.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {refundType.value === "custom" && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Refund Amount
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox flexGrow={1} ml={2}>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftCurrencyInput
                          currencySymbol="$"
                          placeholder="0.00"
                          outputFormat="number"
                          onBlur={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.amount?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.amount.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Internal Note
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <SoftInput
                    placeholder="Write your comment"
                    multiline
                    {...register("note", {
                      required: true,
                    })}
                    rows={5}
                  />
                  {errors?.note?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.note.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={4}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton disabled={isSubmitting} type="submit" color="error">
              Refund Transaction
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

RefundModal.propTypes = {
  handleClose: PropTypes.func,
  transaction: PropTypes.object,
};
