import { add, sub } from "date-fns";

const validClassNames = [
  "maintenance",
  "maintenance-dispatched",
  "maintenance-dispatched-other",
  "maintenance-completed",
  "maintenance-completed-other",
  "requestOnly",
  "unavailable",
  "checkride",
  "checkout",
  "checkout-other",
  "reservation",
  "reservation-other",
  "introFlight",
  "introFlight-other",
  "dispatched",
  "dispatched-other",
  "completed",
  "completed-other",
  "warning",
  "error",
  "light",
  "dark",
];

const getClassName = (el, prefix) => {
  if (!el.confirmed)
    return prefix ? `${prefix}-event-unconfirmed` : "event-unconfirmed";

  if (prefix) {
    return validClassNames.find((item) => item === el.className)
      ? `${prefix}-event-${el.className}`
      : `${prefix}-event-info`;
  }
  return validClassNames.find((item) => item === el.className)
    ? `event-${el.className}`
    : "event-info";
};

const prepareEventForCalendar = (event) => {
  const preparedEvent = {
    ...event,
    display: "block",
    overlap:
      event.extendedProps.type.value !== "unavailable" &&
      event.extendedProps.type.value !== "maintenance",
  };

  if (
    event.extendedProps.instructor?.value &&
    event.extendedProps.instructor?.value !== "" &&
    event.preGround > 0 &&
    event.postGround > 0
  ) {
    preparedEvent.start = sub(event.start, { minutes: event.preGround });
    preparedEvent.end = add(event.end, { minutes: event.postGround });
  }

  return preparedEvent;
};

export { getClassName, prepareEventForCalendar };
