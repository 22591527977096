import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import SchoolIcon from "@mui/icons-material/School"
import ViewListIcon from "@mui/icons-material/ViewList";
import ClassIcon from "@mui/icons-material/Class";

import { useClubs } from "src/features/club/ClubProvider";
import { 
  getCourseLessonsCollection,
  getCourseStagesCollection,
  getClubCoursesCollection
} from "src/features/lms/collections";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";




function BookingLessonData({ bookingLesson }) {
  const { selectedClubId } = useClubs();

  const { data: course } = useRealtimeDocumentData(
    doc(getClubCoursesCollection(selectedClubId), bookingLesson.courseId)
  );
  const { data: stage } = useRealtimeDocumentData(
    doc(
      getCourseStagesCollection(
        selectedClubId,
        bookingLesson.courseId
      ),
      bookingLesson.stageId
    )
  );
  const { data: lesson } = useRealtimeDocumentData(
    doc(
      getCourseLessonsCollection(
        selectedClubId,
        bookingLesson.courseId,
        bookingLesson.stageId
      ),
      bookingLesson.lessonId
    )
  );

  if (!stage || !lesson || !course) {
    return null;
  }

  return (
    <Grid
      container
      spacing={1}
      display="flex"
      justifyContent="center"
    >
      <Grid item xs={12} sm={4}>
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              alignItems="center"
              justifyContent="center"
              borderRadius="lg"
              width="2rem"
              height="2rem"
              mr={2}
            >
              <SchoolIcon
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                color="dark"
                fontSize="medium"
              />
            </SoftBox>
            <SoftBox
              display="flex"
              flexDirection="column"
            >
              <SoftTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                gutterBottom
              >
                Course
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {course.title}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              alignItems="center"
              justifyContent="center"
              width="2rem"
              height="2rem"
              mr={2}
            >
              <ViewListIcon
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                color="dark"
                fontSize="medium"
              />
            </SoftBox>
            <SoftBox
              display="flex"
              flexDirection="column"
            >
              <SoftTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                gutterBottom
              >
                Stage
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {stage.index + 1}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              alignItems="center"
              justifyContent="center"
              width="2rem"
              height="2rem"
              mr={2}
            >
              <ClassIcon
                sx={{
                  display: "grid",
                  placeItems: "center",
                }}
                color="dark"
                fontSize="medium"
              />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                gutterBottom
              >
                Lesson
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {lesson.title}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

BookingLessonData.propTypes = {
  bookingLesson: PropTypes.object.isRequired
}

export default BookingLessonData
