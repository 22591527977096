import { Timestamp } from "firebase/firestore";
import {
  aircraftPermissionTypes,
  billingPermissionTypes,
  meetingRoomsPermissionTypes,
  eventPermissionTypes,
  maintenanceSquawksPermissionTypes,
  maintenanceRemindersPermissionTypes,
  maintenanceOverviewPermissionTypes,
  simulatorPermissionTypes,
  instructorPermissionTypes,
  userManagementPermissionTypes,
  lmsPermissionTypes,
  reportsPermissionTypes,
  settingsPermissionTypes,
  clubAnnouncementPermissionTypes,
} from "./permissions";
import {
  schedulePagesPermissionTypes,
  schedulingPermissionTypes,
} from "./permissions/schedule.permission";

export interface IRole {
  createdAt: Timestamp;
  label: string;
  id: string;
  deleted?: boolean;
  canDelete?: boolean;
  configuration?: {
    reservationTypes: Array<string>;
    requiredDocuments: Array<string>;
    roleTypes: Array<string>;
    requiredUserDetails: Array<string>;
  };
  permissions: Array<{
    grantedAt: Timestamp;
    grantedBy: string;
    key: systemPermissionsType;
  }>;
}

export const systemPermissions = {
  ...schedulePagesPermissionTypes,
  ...eventPermissionTypes,
  ...meetingRoomsPermissionTypes,
  ...schedulingPermissionTypes,
  ...aircraftPermissionTypes,
  ...simulatorPermissionTypes,
  ...instructorPermissionTypes,
  ...billingPermissionTypes,
  ...maintenanceOverviewPermissionTypes,
  ...maintenanceSquawksPermissionTypes,
  ...maintenanceRemindersPermissionTypes,
  ...userManagementPermissionTypes,
  ...lmsPermissionTypes,
  ...reportsPermissionTypes,
  ...clubAnnouncementPermissionTypes,
  ...settingsPermissionTypes,
  PREFLIGHT_ADMIN: "PREFLIGHT_ADMIN",
};
export type systemPermissionsType =
  typeof systemPermissions[keyof typeof systemPermissions];
