import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import parse from "html-react-parser";

function AnnouncementInfo({ announcement }) {
  return (
    <SoftBox px={5}>
      {announcement && announcement.createdAt && (
        <>
          <SoftBox mb={1}>
            <SoftTypography variant="h3" color="primary" fontWeight="bold">
              {announcement.title}
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1}>
            <SoftTypography variant="h5" color="secondary" fontWeight="medium">
              {format(announcement.createdAt.toDate(), "MMMM dd, yyyy")}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={3} mb={1} ml={0.5}>
            <SoftTypography variant="caption" fontWeight="bold">
              Message:
            </SoftTypography>
          </SoftBox>
          <SoftBox m={0} pl={4} mb={2}>
            <SoftTypography variant="body2" color="text" verticalAlign="middle">
              {parse(announcement.message)}
            </SoftTypography>
          </SoftBox>
        </>
      )}
    </SoftBox>
  );
}

AnnouncementInfo.propTypes = {
  announcement: PropTypes.object.isRequired,
};

export default AnnouncementInfo;
