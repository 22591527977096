import { collection, collectionGroup } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getInstructorsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/instructors`);

const getInstructorDocumentsCollection = (clubId, locationId, instructorId) =>
  collection(
    db,
    `clubs/${clubId}/locations/${locationId}/instructors/${instructorId}/documents`
  );

const getInstructorsCollectionGroup = () => collectionGroup(db, "instructors");

export {
  getInstructorsCollectionGroup,
  getInstructorsCollection,
  getInstructorDocumentsCollection,
};
