import { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Settings page components
import ClubBilling from "./components/ClubBilling";
import BaseLayout from "src/components/BaseLayout";
import Sidenav from "./components/Sidenav";
import Integrations from "./components/Integrations";
import PaymentTerminals from "./components/PaymentTerminals";
import FuelReimbursement from "./components/FuelReimbursement";
import PreHeatFees from "./components/PreHeatFees";
import RentalRates from "./components/RentalRates";
import Accounts from "./components/Accounts";
import { useClubs } from "src/features/club/ClubProvider";

function BillingSettings() {
  const functions = getFunctions();
  const { selectedClubId } = useClubs();
  const [quickbooksItems, setQuickbooksItems] = useState([]);
  const [quickbooksAccounts, setQuickbooksAccounts] = useState([]);
  const [quickbooksPrefs, setQuickbooksPrefs] = useState({});

  useEffect(() => {
    const getItems = httpsCallable(functions, "getQuickbooksItems-default");
    getItems({ clubId: selectedClubId })
      .then((result) => {
        if (typeof result.data !== "string") {
          setQuickbooksItems(result.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
    const getAccounts = httpsCallable(
      functions,
      "getQuickbooksAccounts-default"
    );
    getAccounts({ clubId: selectedClubId })
      .then((result) => {
        if (typeof result.data !== "string") {
          setQuickbooksAccounts(result.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });

    const getQuickbooksPreferences = httpsCallable(
      functions,
      "getQuickbooksPreferences-default"
    );
    getQuickbooksPreferences({ clubId: selectedClubId })
      .then((result) => {
        if (typeof result.data !== "string") {
          setQuickbooksPrefs(result.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [selectedClubId]);

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Accounts
                    qbItems={quickbooksItems}
                    qbPrefs={quickbooksPrefs}
                    qbAccounts={quickbooksAccounts}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RentalRates
                    qbItems={quickbooksItems}
                    qbPrefs={quickbooksPrefs}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FuelReimbursement />
                </Grid>
                <Grid item xs={12}>
                  <PreHeatFees />
                </Grid>
                <Grid item xs={12}>
                  <PaymentTerminals />
                </Grid>
                <Grid item xs={12}>
                  <ClubBilling />
                </Grid>
                <Grid item xs={12}>
                  <Integrations
                    qbItems={quickbooksItems}
                    qbAccounts={quickbooksAccounts}
                    qbPrefs={quickbooksPrefs}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default BillingSettings;
