import { aircraftPermissions } from "./aircraft.permission";
import { simulatorPermissions } from "./simulator.permission";
import { billingPermissions } from "./billing.permission";
import { schedulePermissions } from "./schedule.permission";
import { maintenancePermissions } from "./maintenance.permission";
import { userManagementPermissions } from "./userManagement.permission";
import { eventPermissions } from "./event.permission";
import { meetingRoomPermissions } from "./meetingRoom.permission";
import { instructorPermissions } from "./instructor.permission";
import { lmsPermissions } from "./lms.permission";
import { reportsPermissions } from "./reports.permission";
import { settingsPermissions } from "./settings.permission";
import { clubAnnouncementPermissions } from "./clubAnnouncement.permission";

export * from "./aircraft.permission";
export * from "./simulator.permission";
export * from "./instructor.permission";
export * from "./userManagement.permission";
export * from "./billing.permission";
export * from "./schedule.permission";
export * from "./maintenance.permission";
export * from "./event.permission";
export * from "./meetingRoom.permission";
export * from "./lms.permission";
export * from "./reports.permission";
export * from "./settings.permission";
export * from "./clubAnnouncement.permission";

export const allPermissions = [
  ...schedulePermissions,
  ...eventPermissions,
  ...meetingRoomPermissions,
  ...aircraftPermissions,
  ...simulatorPermissions,
  ...instructorPermissions,
  ...billingPermissions,
  ...maintenancePermissions,
  ...userManagementPermissions,
  ...lmsPermissions,
  ...reportsPermissions,
  ...settingsPermissions,
  ...clubAnnouncementPermissions,
];
