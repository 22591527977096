import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// react-router-dom components
import { Link, useSearchParams } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

// Authentication layout components
import CoverLayout from "../Layout";

// Images
import { useForm } from "react-hook-form";
import {
  finaliseUserInfoAdding,
  registerWithEmailAndPassword,
} from "src/features/firebase/auth/utils";
import loginBg2 from "src/assets/images/login-bg2.jpg";

const schema = yup
  .object({
    firstName: yup.string().required("Your first name is required."),
    lastName: yup.string().required("Your last name is required."),
    email: yup.string().email().required("An email is required."),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    toc: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions."),
  })
  .required();

function SignUp() {
  const [queryParameters] = useSearchParams();
  const referredLocationId = queryParameters.get("lid");
  console.debug("referredLocationId", referredLocationId);
  const [agreement, setAgreement] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);

  const handleSetAgremment = () => setAgreement(!agreement);

  const onSubmit = async (data) => {
    try {
      setFirebaseInProgress(true);
      const userCreated = await registerWithEmailAndPassword(
        data.firstName,
        data.lastName,
        data.email,
        data.password,
        true
      );
      await setSignUpSuccessful(true);
      await finaliseUserInfoAdding(userCreated.id, referredLocationId);
    } catch (err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          setError("email", {
            type: "custom",
            message: "A user with this email already exists",
          });
          break;
        case "auth/invalid-password":
          setError("password", {
            type: "custom",
            message: "Password must be at least 6 characters long",
          });
          break;
        default:
      }
    } finally {
      setFirebaseInProgress(false);
    }
  };

  if (firebaseInProgress) {
    return (
      <CoverLayout
        title="Join us today"
        description="Enter your email and password to sign in"
        image={loginBg2}
      >
        <SoftTypography component="label" variant="caption" fontWeight="bold">
          Loading
        </SoftTypography>
      </CoverLayout>
    );
  }

  if (signUpSuccessful)
    return (
      <CoverLayout
        title="Congratulations!"
        description="Your account has been created successfully!"
        image={loginBg2}
      >
        <SoftBox mt={1} textAlign="left">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Proceed to sign in &nbsp;
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </CoverLayout>
    );
  return (
    <CoverLayout
      title="Join us today"
      description="Enter your email and password to register"
      image={loginBg2}
      top={12}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              First Name
            </SoftTypography>
          </SoftBox>
          <SoftInput
            placeholder="First Name"
            {...register("firstName", {
              required: true,
            })}
          />
          {errors?.firstName?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.firstName.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Last Name
            </SoftTypography>
          </SoftBox>
          <SoftInput
            placeholder="Last Name"
            {...register("lastName", {
              required: true,
            })}
          />
          {errors?.lastName?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.lastName.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            {...register("email", {
              required: true,
            })}
          />
          {errors?.email?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.email.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            {...register("password", {
              required: true,
            })}
          />
          {errors?.password?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.password.message}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Checkbox
            checked={agreement}
            onClick={handleSetAgremment}
            {...register("toc", {
              required: true,
            })}
          />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgremment}
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
            color={errors?.toc?.message && "error"}
          >
            &nbsp;&nbsp;I agree to the&nbsp;
          </SoftTypography>
          <SoftTypography
            component="a"
            href="#"
            variant="button"
            fontWeight="bold"
            color={errors?.toc?.message && "error"}
          >
            Terms and Conditions
          </SoftTypography>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            sign up
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/auth/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignUp;
