import PropTypes from "prop-types";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubInstructorPreferencesCollection } from "src/features/club/collections";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { EditWorkHours } from "src/components/EditWorkHours";
import ModalContainer from "src/components/ModalContainer";

export function EditWorkHoursModal({ handleClose, instructor }) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { updateData } = entityCrudUtils();
  const [instructorPrefs] = useDocument(
    doc(
      getClubInstructorPreferencesCollection(
        selectedClubId,
        selectedLocationId
      ),
      instructor.id || "noid"
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const saveWorkHours = async (data) => {
    await updateData(
      {
        entity: getClubInstructorPreferencesCollection(
          selectedClubId,
          selectedLocationId
        ),
        pathSegmentsArr: [instructor.id],
      },
      { workingHours: data }
    );
    handleClose();
  };

  let workingHours = instructorPrefs?.data()?.workingHours;
  if (workingHours === undefined) {
    workingHours = {};
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <EditWorkHours
        handleSave={saveWorkHours}
        workingHours={workingHours}
        showRequestOnlyOption
      />
    </ModalContainer>
  );
}

EditWorkHoursModal.propTypes = {
  handleClose: PropTypes.func,
  instructor: PropTypes.object,
};
