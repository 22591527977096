import { IPermission } from "./permission.interface";

export enum settingsRoleCategory {
  SETTINGS = "SETTINGS",
}

export enum settingsPermissionTypes {
  VIEW_CLUB_SETTINGS = "VIEW_CLUB_SETTINGS",
  VIEW_BILLING_SETTINGS = "VIEW_BILLING_SETTINGS",
  VIEW_INTRO_FLIGHT_SETTINGS = "VIEW_INTRO_FLIGHT_SETTINGS",
  VIEW_ROLES_SETTINGS = "VIEW_ROLES_SETTINGS",
}

export const settingsPermissions: IPermission[] = [
  {
    group: settingsRoleCategory.SETTINGS,
    label: "Settings",
    tooltipGroup: "",
    permissions: {
      [settingsPermissionTypes.VIEW_CLUB_SETTINGS]: {
        label: "View Club Settings",
        tooltip: "",
      },
      [settingsPermissionTypes.VIEW_BILLING_SETTINGS]: {
        label: "View Billing Settings",
        tooltip: "",
      },
      [settingsPermissionTypes.VIEW_INTRO_FLIGHT_SETTINGS]: {
        label: "View Intro Flight Settings",
        tooltip: "",
      },
      [settingsPermissionTypes.VIEW_ROLES_SETTINGS]: {
        label: "View User Roles Settings",
        tooltip: "",
      },
    },
  },
];
