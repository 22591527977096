// Soft UI Dashboard PRO React helper functions
import pxToRem from "src/assets/theme/functions/pxToRem";

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
    },
  },
};

export default tableCell;
