import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function PaymentError({ transaction }) {
  return (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Last Payment Error:
      </SoftTypography>
      <SoftBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor="grey-100"
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <SoftBox
          width="100%"
          display="flex"
          flexDirection="column"
          lineHeight={1}
        >
          {/* <SoftBox mb={2}>
            <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {transaction.charge.billing_details.name}
            </SoftTypography>
          </SoftBox> */}
          {transaction.payments?.[0]?.last_payment_error?.payment_method && (
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Card Number:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="medium">
                  XXXX&nbsp;&nbsp;XXXX&nbsp;&nbsp;XXXX&nbsp;&nbsp;
                  {
                    transaction.payments?.[0]?.last_payment_error
                      ?.payment_method?.card_present?.last4
                  }
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" color="text">
              Reason:&nbsp;&nbsp;&nbsp;
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color="error"
              >
                {transaction.payments?.[0]?.last_payment_error?.message} (
                {transaction.payments?.[0]?.last_payment_error?.code})
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}

PaymentError.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default PaymentError;
