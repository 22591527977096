import { format, toDate } from "date-fns";
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import TimelineItem from "./components/TimelineItem";

function TrackOrder({ transaction }) {
  const renderBookingFlow = () => (
    <SoftBox mt={2}>
      <TimelineItem
        color="secondary"
        icon="event_available"
        title="Booking Made"
        dateTime={format(
          transaction.booking?.createdAt?.toDate(),
          "eee, MMM do yyyy @ HH:mm"
        )}
      />
      {transaction.booking?.dispatchedAt && (
        <TimelineItem
          color="secondary"
          icon="flight_takeoff"
          title="Check Out"
          description={
            transaction?.bookingData?.previousHobbs &&
            `Hobbs / Tach Out: ${transaction?.bookingData?.previousHobbs} / ${
              transaction?.bookingData?.previousTach1 || "N/A"
            }`
          }
          dateTime={format(
            transaction.booking.dispatchedAt.toDate(),
            "eee, MMM do yyyy @ HH:mm"
          )}
        />
      )}
      {transaction.booking?.completedAt && (
        <TimelineItem
          color="secondary"
          icon="flight_land"
          title="Checked In"
          description={
            transaction?.bookingData?.hobbs &&
            `Hobbs / Tach Out: ${transaction?.bookingData?.hobbs} / ${
              transaction?.bookingData?.tach1 || "N/A"
            }`
          }
          dateTime={format(
            transaction.booking.completedAt.toDate(),
            "eee, MMM do yyyy @ HH:mm"
          )}
        />
      )}
      <TimelineItem
        color="secondary"
        icon="description"
        title="Invoiced"
        description={
          transaction.bookingData?.totalHobbsHours &&
          `Flight Time (${
            transaction.aircraft?.billFrom === "tach" ? "Tach" : "Hobbs"
          }): ${
            Math.round(
              (transaction.aircraft?.billFrom === "tach"
                ? transaction.bookingData.totalTach1Hours
                : transaction.bookingData.totalHobbsHours) * 100
            ) / 100
          } hours`
        }
        description2={
          (transaction.bookingData?.instructionHours ||
            transaction.bookingData?.instructionHoursGround) &&
          `Instruction Time: ${
            Math.round(
              ((transaction.bookingData.instructionHours || 0) +
                (transaction.bookingData?.instructionHoursGround || 0)) *
                100
            ) / 100
          } hours`
        }
        dateTime={format(
          transaction.createdAt?.toDate(),
          "eee, MMM do yyyy @ HH:mm"
        )}
      />
      {transaction.payments &&
        transaction.payments.map((payment) => (
          <TimelineItem
            key={payment.id}
            color={payment?.amount ? "success" : "error"}
            icon={payment?.amount ? "done" : "close"}
            title="Paid"
            description={`Amount: $${(payment?.amount ?? 0) / 100}`}
            dateTime={
              payment?.created
                ? `${format(
                    toDate(payment.created * 1000),
                    "eee, MMM do yyyy @ HH:mm"
                  )}`
                : "N/A"
            }
          />
        ))}
      {transaction.refunds &&
        transaction.refunds.map((refund) => (
          <TimelineItem
            key={refund.id}
            color="error"
            icon="close"
            title={`Refunded $${(refund?.amount || 0) / 100}`}
            dateTime={
              refund.created
                ? `${format(
                    toDate(refund.created * 1000),
                    "eee, MMM do yyyy @ HH:mm"
                  )}`
                : "N/A"
            }
          />
        ))}
    </SoftBox>
  );

  const renderMembershipFlow = () => (
    <SoftBox mt={2}>
      <TimelineItem
        color="secondary"
        icon="notifications"
        title="Order received"
        dateTime="22 DEC 7:20 PM"
      />
      <TimelineItem
        color="secondary"
        icon="inventory_2"
        title="Generate order id #1832412"
        dateTime="22 DEC 7:21 AM"
      />
      <TimelineItem
        color="secondary"
        icon="shopping_cart"
        title="Order transmited to courier"
        dateTime="22 DEC 8:10 AM"
      />
      <TimelineItem
        color="success"
        icon="done"
        title="Order delivered"
        dateTime="22 DEC 4:54 PM"
      />
    </SoftBox>
  );

  const renderOrderFlow = () => (
    <SoftBox mt={2}>
      <TimelineItem
        color="secondary"
        icon="notifications"
        title="Order received"
        dateTime="22 DEC 7:20 PM"
      />
      <TimelineItem
        color="secondary"
        icon="inventory_2"
        title="Generate order id #1832412"
        dateTime="22 DEC 7:21 AM"
      />
      <TimelineItem
        color="secondary"
        icon="shopping_cart"
        title="Order transmited to courier"
        dateTime="22 DEC 8:10 AM"
      />
      <TimelineItem
        color="success"
        icon="done"
        title="Order delivered"
        dateTime="22 DEC 4:54 PM"
      />
    </SoftBox>
  );

  return (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Track order
      </SoftTypography>
      {(transaction.type === "booking" || transaction.type === "introFlight") &&
        renderBookingFlow()}
      {transaction.type === "membership" && renderMembershipFlow()}
      {transaction.type === "order" && renderOrderFlow()}
    </>
  );
}

TrackOrder.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TrackOrder;
