import { useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import Modal from "@mui/material/Modal";
import { useDocuments } from "src/hooks/useDocument";
import { useLocation } from "react-router-dom";

import { getCourseDocumentsCollection } from "src/features/lms/collections";
import CompactDocumentsList from "../CompactDocumentsList";
import { Card, Popover } from "@mui/material";
import { UploadGenericDocumentModal } from "src/modals/UploadGenericDocument";
import SoftButton from "src/components/SoftButton";
import { AttachFile } from "@mui/icons-material";
import SoftTypography from "src/components/SoftTypography";

function CourseDocumentsPopover({ courseId, documentsCollection }) {
  const { selectedClubId } = useClubs();
  const { pathname } = useLocation();
  const [openAddDocument, setOpenAddDocument] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { addData } = entityCrudUtils();

  const { documents, loadingDocuments } = useDocuments({
    documentsCollection,
  });

  const showDocumentsButton = useMemo(() => {
    const isCourseEditPage =
      !pathname.includes("classroom") && !pathname.includes("students");
    // looks weird but there's a ghost doc in the db that can't be deleted
    const documentsPresent =
      documents.length && documents[0].id !== "V54DsQdXzdjQdaechc3P";
    return (!loadingDocuments && documentsPresent) || isCourseEditPage;
  }, [pathname, documents, loadingDocuments]);

  const handleOpenAddDocument = () => setOpenAddDocument(true);
  const handleCloseAddDocument = async (documentsAdded) => {
    if (documentsAdded && documentsAdded.length > 0) {
      await Promise.all(
        documentsAdded.map(async (document) => {
          await addData(
            getCourseDocumentsCollection(selectedClubId, courseId),
            document
          );
        })
      );
    }
    setOpenAddDocument(false);
  };

  const handleDocumentsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SoftButton
        onClick={handleDocumentsClick}
        color="primary"
        variant="outlined"
        size="small"
        sx={showDocumentsButton ? {} : { display: "none" }}
      >
        <AttachFile />
        <SoftTypography
          fontSize="small"
          ml="4px"
          fontWeight="medium"
          color="primary"
        >
          Documents ({documents.length})
        </SoftTypography>
      </SoftButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card sx={{ backgroundColor: "grey.100", minWidth: "200px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} marginTop={2} borderRadius="sm">
              <CompactDocumentsList
                title="Documents"
                documentsCollection={documentsCollection}
                openUploadModal={handleOpenAddDocument}
                noAdd={
                  pathname.includes("classroom") ||
                  pathname.includes("students")
                }
              />
            </Grid>
          </Grid>
        </Card>
        <Modal
          open={openAddDocument}
          onClose={handleCloseAddDocument}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <UploadGenericDocumentModal
            handleClose={handleCloseAddDocument}
            storagePath={`clubs/${selectedClubId}/courses/${courseId}/documents`}
          />
        </Modal>
      </Popover>
    </>
  );
}

export default CourseDocumentsPopover;
