// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
import boxShadows from "src/assets/theme/base/boxShadows";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "src/assets/theme/functions/pxToRem";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: grey[100],
      borderRadius: borderRadius.lg,
      minHeight: "unset",
      padding: pxToRem(4),

      "&.bottomConnect": {
        border: "none",
        borderRadius: `${borderRadius.lg} ${borderRadius.lg} 0 0`,
        padding: `${pxToRem(4)} ${pxToRem(4)} 0 ${pxToRem(4)}`,
        borderBottom: `#329baa solid 1px`,
        overflow: "visible",

        // "& .MuiTab-root": {
        //   borderRadius: "0",
        //   transition: "all 500ms ease",

        //   "&.Mui-selected": {
        //     borderBottom: `#ffffff solid 1px`,
        //   },
        // },

        "& .MuiTabs-indicator": {
          border: `#329baa solid 1px`,
          borderBottom: `#ffffff solid 1px`,
          borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
          boxShadow: "0 2px 2px -1px #FFF",

          // position: "relative" /* Ensure positioning is relative to the tab container */,
          bottom: "-1px" /* Slight overlap with content below */,
          marginTop: "auto" /* Push the indicator to the bottom */,
          // borderBottom: `2px solid #329baa`, /* Border for seamless transition */
        },
      },
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius: borderRadius.md,
      backgroundColor: white.main,
      boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
    },
  },
};

export default tabs;
