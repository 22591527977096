import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import SoftDatePicker from "src/components/SoftDatePicker";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getAircraftCollection,
  getAircraftReminder,
  getAircraftRemindersCollection,
} from "src/features/aircraft/collections";
import moment from "moment/moment";

import { Form100hrInspection } from "./components/Form100hrInspection";
import { Form50hrInspection } from "./components/Form50hrInspection";
import { FormAnnualInspection } from "./components/FormAnnualInspection";
import { FormEltBatInspection } from "./components/FormEltBatInspection";
import { FormEltInspection } from "./components/FormEltInspection";
import { FormEngineOverhaulInspection } from "./components/FormEngineOverhaulInspection";
import { FormGpsInspection } from "./components/FormGpsInspection";
import { FormPitotStaticInspection } from "./components/FormPitotStaticInspection";
import { FormRegistrationInspection } from "./components/FormRegistrationInspection";
import { FormXponderInspection } from "./components/FormXponderInspection";

const schema = yup
  .object({
    name: yup.string().required("You must add a name for this reminder."),
    type: yup.object().required("You must select a type for the reminder."),
    aircraft: yup.object().required("You must select an aircraft."),
    meterBasedType: yup.object().when("type", {
      is: (val) => val.value === "meter" || val.value === "meterAndTime",
      then: yup.object().required("This field is required"),
    }),
    meterBasedRemindEveryUnit: yup.object().when("type", {
      is: (val) => val.value === "meter" || val.value === "meterAndTime",
      then: yup.object().required("This field is required"),
    }),
    meterBasedRemindEvery: yup.number().when("type", {
      is: (val) => val.value === "meter" || val.value === "meterAndTime",
      then: (elementSchema) => elementSchema.required("This field is required"),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    meterBasedLastCompleted: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("type", {
        is: (val) => val.value === "meter" || val.value === "meterAndTime",
        then: (elementSchema) =>
          elementSchema
            .required("This field is required")
            .typeError("This must be a number"),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    timeBasedLastCompletedOn: yup.date().when("type", {
      is: (val) => val.value === "time" || val.value === "meterAndTime",
      then: yup
        .date()
        .required("This field is required")
        .typeError("This must be a date"),
    }),
    timeBasedRemindEveryUnit: yup.object().when("type", {
      is: (val) => val.value === "time" || val.value === "meterAndTime",
      then: yup.object().required("This field is required"),
    }),
    timeBasedRemindEvery: yup.number().when("type", {
      is: (val) => val.value === "time" || val.value === "meterAndTime",
      then: yup
        .number()
        .required("This field is required")
        .typeError("This must be a number"),
    }),
    nonGrounded: yup.bool(),
    notes: yup.string(),
    alerts: yup.array().of(
      yup.object().shape({
        value: yup
          .number()
          .required("This is required")
          .typeError("This must be a number"),
        type: yup.object().required("This is required"),
      })
    ),
  })
  .required();

export const RemindAtIntervalTypes = {
  percent: {
    label: "Percent Remaining",
    value: "percent",
  },
  hours: {
    label: "Hours Remaining",
    value: "hours",
  },
  days: {
    label: "Days Remaining",
    value: "days",
  },
  months: {
    label: "Months Remaining",
    value: "months",
  },
};

const meterTypes = [
  { value: "tach1", label: "Tach" },
  { value: "hobbs", label: "Hobbs" },
  { value: "tt", label: "TTAF" },
];

const timeBasedTimeOptions = [
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
];

export const meterBasedTimeOptions = [{ value: "hour", label: "Hours" }];

export function RemindAtIntervalForm({
  control,
  errors,
  register,
  unregister,
  reminderTypes,
  watch,
}) {
  const [alerts, setAlerts] = useState([1]);
  const handleAddNewAlert = () => {
    setAlerts([...alerts, alerts.length + 1]);
  };

  const handleRemoveAlert = (index) => () => {
    setAlerts((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index + 1),
    ]);
    unregister(`alerts[${index}].value`);
    unregister(`alerts[${index}].type`);
  };

  useEffect(
    () => () => {
      const alertCount = watch("alerts").length;
      const alertArray = Array.from(Array(alertCount).keys());

      setAlerts(alertArray);
    },
    [watch("alerts")]
  );

  return (
    <Grid container mt={2}>
      {alerts.map((z, i) => (
        <SoftBox key={i} display="flex" alignItems="flex-start" mb={1}>
          <SoftTypography
            mt={2}
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            width="200px"
          >
            Alert at
          </SoftTypography>
          <SoftBox sx={{ minWidth: "35%" }}>
            <SoftInput
              type="number"
              {...register(`alerts[${i}].value`, {
                required: true,
              })}
            />
            {errors?.alerts?.[i]?.value?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors?.alerts?.[i]?.value.message}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox ml={1} sx={{ minWidth: "35%" }}>
            <Controller
              control={control}
              name={`alerts[${i}].type`}
              render={({ field: { onChange, value, ref } }) => (
                <SoftSelect
                  options={reminderTypes}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
            {errors?.alerts?.[i]?.type?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.alerts?.[i]?.type.message}
              </SoftTypography>
            )}
          </SoftBox>

          <IconButton
            onClick={handleRemoveAlert(i)}
            sx={{ marginLeft: "auto !important" }}
          >
            <CloseIcon />
          </IconButton>
        </SoftBox>
      ))}
      <Grid item xs={12} display="flex" mt={1}>
        <SoftButton
          sx={{ marginLeft: "auto !important" }}
          variant="outlined"
          color="primary"
          onClick={handleAddNewAlert}
        >
          + Add Alert
        </SoftButton>
      </Grid>
    </Grid>
  );
}

function MeterBasedReminderForm({
  control,
  errors,
  watch,
  register,
  unregister,
  showReminderIntervals,
  selectedAircraft,
}) {
  const type = "meterBased";

  const meterType = watch(`${type}Type`);
  const lastCompleted = parseFloat(watch(`${type}LastCompleted`));
  const remindEvery = parseFloat(watch(`${type}RemindEvery`));

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Track From
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={`${type}Type`}
          render={({ field: { onChange, value, ref } }) => (
            <SoftSelect
              options={meterTypes}
              onChange={onChange}
              inputRef={ref}
              value={value}
              defaultValue={meterTypes[0]}
            />
          )}
        />
        {errors?.[`${type}Type`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}Type`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Interval
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex">
          <SoftInput
            placeholder="0"
            type="number"
            {...register(`${type}RemindEvery`, {
              type: "number",
              required: true,
              defaultValue: 0,
            })}
          />
          <Controller
            control={control}
            name={`${type}RemindEveryUnit`}
            render={({ field: { onChange, value, ref } }) => (
              <SoftSelect
                options={meterBasedTimeOptions}
                onChange={onChange}
                inputRef={ref}
                value={value}
                defaultValue={meterBasedTimeOptions[0]}
              />
            )}
          />
        </SoftBox>
        {errors?.[`${type}RemindEvery`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}RemindEvery`].message}
          </SoftTypography>
        )}
        {errors?.[`${type}RemindEveryUnit`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}RemindEveryUnit`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Last Completed
          </SoftTypography>
        </SoftBox>
        <SoftInput
          placeholder="0.0"
          {...register(`${type}LastCompleted`, {
            type: "number",
            required: true,
          })}
        />
        {errors?.[`${type}LastCompleted`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}LastCompleted`].message}
          </SoftTypography>
        )}
        <SoftBox>
          <SoftTypography fontSize={12} color="secondary">
            {selectedAircraft?.value?.[`${meterType?.value}`] &&
              `Current ${meterType?.label}: ${
                selectedAircraft?.value?.[`${meterType?.value}`]
              }`}
            {selectedAircraft?.[`${meterType?.value}`] &&
              `Current ${meterType?.label}: ${
                selectedAircraft?.[`${meterType?.value}`]
              }`}
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Next Due
          </SoftTypography>
        </SoftBox>
        <SoftInput
          placeholder={
            lastCompleted && remindEvery
              ? `${lastCompleted + remindEvery}`
              : "0.0"
          }
          type="number"
          disabled
        />
      </Grid>
      {showReminderIntervals && (
        <Grid item xs={12}>
          <RemindAtIntervalForm
            control={control}
            errors={errors}
            register={register}
            unregister={unregister}
            reminderTypes={[
              RemindAtIntervalTypes.percent,
              RemindAtIntervalTypes.hours,
            ]}
            watch={watch}
          />
        </Grid>
      )}
    </>
  );
}
function TimeBasedReminderForm({
  control,
  errors,
  register,
  unregister,
  watch,
}) {
  const type = "timeBased";
  const [nextDue, setNextDue] = useState();

  useEffect(() => {
    const lastCompletedOn = watch(`${type}LastCompletedOn`);
    const remindEvery = watch(`${type}RemindEvery`);
    const remindEveryUnit = watch(`${type}RemindEveryUnit`);
    const endOfMonth = watch(`${type}EndOfMonth`);
    if (lastCompletedOn && remindEvery && remindEveryUnit) {
      const nextDueDate = moment(lastCompletedOn).add(
        parseInt(remindEvery, 10),
        remindEveryUnit.value
      );

      if (endOfMonth) {
        nextDueDate.endOf("month");
      }
      const formattedNextDue = nextDueDate.format("MM/DD/yyyy");
      setNextDue(formattedNextDue);
    }
  }, [
    watch(`${type}LastCompletedOn`),
    watch(`${type}RemindEvery`),
    watch(`${type}RemindEveryUnit`),
    watch(`${type}EndOfMonth`),
  ]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Last Completed Date
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={`${type}LastCompletedOn`}
          render={({ field: { onChange, onBlur, value } }) => (
            <SoftDatePicker
              dateFormat="Pp"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
            />
          )}
        />
        {errors?.[`${type}LastCompletedOn`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}LastCompletedOn`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Interval
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex">
          <SoftInput
            placeholder="0"
            type="number"
            {...register(`${type}RemindEvery`, {
              defaultValue: 0,
              required: true,
            })}
          />
          <Controller
            control={control}
            name={`${type}RemindEveryUnit`}
            render={({ field: { onChange, value, ref } }) => (
              <SoftSelect
                options={timeBasedTimeOptions}
                onChange={onChange}
                inputRef={ref}
                value={value}
                defaultValue={timeBasedTimeOptions[0]}
              />
            )}
          />
        </SoftBox>
        {errors?.[`${type}RemindEvery`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}RemindEvery`].message}
          </SoftTypography>
        )}
        {errors?.[`${type}RemindEveryUnit`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}RemindEveryUnit`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Next Due
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex">
          <SoftInput disabled placeholder="0" value={nextDue} />
        </SoftBox>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        mt={2}
        bgcolor="dark"
        display="flex"
        alignItems="center"
      >
        <SoftBox pt={2} px={2}>
          <Controller
            control={control}
            name={`${type}EndOfMonth`}
            render={({ field: { onChange, value, ref } }) => (
              <SoftBox display="flex" alignItems="center">
                <Checkbox checked={value} inputRef={ref} onChange={onChange} />
                <SoftTypography variant="button">
                  End of the month?
                </SoftTypography>
              </SoftBox>
            )}
          />
          {errors?.[`${type}EndOfMonth`]?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors[`${type}EndOfMonth`].message}
            </SoftTypography>
          )}
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <RemindAtIntervalForm
          control={control}
          errors={errors}
          register={register}
          unregister={unregister}
          reminderTypes={[
            RemindAtIntervalTypes.percent,
            RemindAtIntervalTypes.days,
            RemindAtIntervalTypes.months,
          ]}
          watch={watch}
        />
      </Grid>
    </>
  );
}

export function AddReminderModal({
  handleClose,
  existingData,
  existingReminders,
}) {
  const { selectedClubId } = useClubs();
  const { addData, updateDataFromRef } = entityCrudUtils();
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [reminderTypeOptions] = useState([
    { value: "meter", label: "Meter Based" },
    { value: "time", label: "Time Based" },
    { value: "meterAndTime", label: "Meter and Time Based" },
  ]);

  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(getAircraftCollection(selectedClubId), false);

  useEffect(() => {
    if (aircraftLoaded) {
      const aircraftOptionsValues = allAircraft.map((aircraft) => ({
        value: aircraft,
        label: `${
          aircraft?.aircraft_description?.tail_number ?? aircraft?.tailNumber
        } - ${aircraft.displayName}`,
      }));
      setAircraftOptions(aircraftOptionsValues);
    }
  }, [aircraftLoaded, allAircraft]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    unregister,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: reminderTypeOptions[0],
      meterBasedRemindEvery: 0,
      meterBasedRemindEveryUnit: meterBasedTimeOptions[0],
      timeBasedRemindEveryUnit: timeBasedTimeOptions[0],
      timeBasedEndOfMonth: false,
      meterBasedType: meterTypes[0],
      ...existingData,
      timeBasedLastCompletedOn:
        existingData?.timeBasedLastCompletedOn instanceof Date
          ? existingData?.timeBasedLastCompletedOn
          : existingData?.timeBasedLastCompletedOn?.toDate(),
      nonGrounded: existingData?.nonGrounded || false,
      notes: existingData?.notes || "",
    },
  });

  const selectedAircraft = watch("aircraft");
  const selectedTemplate = watch("template");

  useEffect(() => {
    if (existingData) return;
    if (selectedTemplate?.value === "100hr") {
      setValue("name", "100hr Inspection");
      setValue("type", { value: "meter", label: "Meter Based" });
      setValue("meterBasedRemindEvery", 100);
    }
    if (selectedTemplate?.value === "50hr") {
      setValue("name", "50hr Inspection");
      setValue("type", { value: "meter", label: "Meter Based" });
      setValue("meterBasedRemindEvery", 50);
    }
    if (selectedTemplate?.value === "annual") {
      setValue("name", "Annual Inspection");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 12);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "xponder") {
      setValue("name", "Xponder Inspection");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 24);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "pitot-static") {
      setValue("name", "Pitot Static Inspection");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 24);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "elt") {
      setValue("name", "ELT");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 12);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "elt-battery") {
      setValue("name", "ELT Battery");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 12);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "gps") {
      setValue("name", "GPS Update");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 12);
      setValue("timeBasedRemindEveryUnit", {
        value: "months",
        label: "Months",
      });
    }
    if (selectedTemplate?.value === "registration") {
      setValue("name", "Aircraft Registration");
      setValue("type", { value: "time", label: "Time Based" });
      setValue("timeBasedRemindEvery", 7);
      setValue("timeBasedRemindEveryUnit", {
        value: "years",
        label: "Years",
      });
    }
    if (selectedTemplate?.value === "engine-overhaul") {
      setValue("name", "Engine Overhaul");
      setValue("type", { value: "meter", label: "Meter Based" });
      setValue("meterBasedRemindEvery", selectedAircraft?.value?.tbo || 2000);
    }
  }, [selectedTemplate]);

  const onSubmit = async (data) => {
    if (!existingData) {
      const reminder = {
        ...data,
        aircraft: {
          ...data.aircraft,
          value: data.aircraft.value.id,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      await addData(
        getAircraftRemindersCollection(selectedClubId, reminder.aircraft.value),
        reminder
      );

      handleClose({
        ...reminder,
        aircraft: data.aircraft.value,
      });
    } else {
      const reminder = {
        ...existingData,
        ...data,
        updatedAt: new Date(),
      };

      await updateDataFromRef(
        getAircraftReminder(
          selectedClubId,
          reminder.aircraft.id,
          existingData.id
        ),
        reminder
      );

      handleClose({
        ...reminder,
        aircraft: reminder.aircraft,
      });
    }
  };

  const getRemindersTemplateOptions = (aircraft) => {
    const aircraftData = aircraft?.value;

    const existingAircraftReminders = existingReminders.filter(
      (value) => value.aircraft?.id === aircraftData?.id
    );

    const templateOptions = [
      { label: "100hr Inspection", value: "100hr" },
      { label: "50hr Inspection", value: "50hr" },
      { label: "Annual Inspection", value: "annual" },
      { label: "Xponder", value: "xponder" },
      { label: "Pitot Static", value: "pitot-static" },
      { label: "ELT", value: "elt" },
      { label: "ELT Battery", value: "elt-battery" },
      { label: "GPS", value: "gps" },
      { label: "Registration", value: "registration" },
      {
        label: "Engine Overhaul",
        value: "engine-overhaul",
      },
    ];

    return [
      ...templateOptions.filter(
        (option) =>
          !existingAircraftReminders.some(
            (e) => e.template?.value === option.value
          )
      ),
      { label: "Custom", value: "custom" },
    ];
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {!existingData
              ? "New Maintenance Reminder"
              : `Edit ${existingData?.name || "Maintenance"} Reminder`}
            {existingData?.aircraft?.tailNumber &&
              ` for N${existingData?.aircraft?.tailNumber}`}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              {!existingData && (
                <>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Aircraft
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="aircraft"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={aircraftOptions}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                          defaultValue={aircraftOptions[0]}
                        />
                      )}
                    />
                    {errors?.aircraft?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.aircraft.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Reminder
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="template"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={getRemindersTemplateOptions(
                            selectedAircraft
                          )}
                          onChange={onChange}
                          inputRef={ref}
                          isDisabled={!selectedAircraft}
                          value={value}
                        />
                      )}
                    />
                    {errors?.aircraft?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.aircraft.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </>
              )}
              {selectedTemplate?.value && (
                <>
                  {selectedTemplate?.value === "custom" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Reminder Name
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          placeholder="Eg. 100hr Inspection"
                          {...register("name", {
                            required: true,
                          })}
                        />
                        {errors?.name?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.name.message}
                          </SoftTypography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Reminder type
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={reminderTypeOptions}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                              defaultValue={reminderTypeOptions[0]}
                            />
                          )}
                        />
                        {errors?.type?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.type.message}
                          </SoftTypography>
                        )}
                      </Grid>
                    </>
                  )}

                  {selectedTemplate?.value === "100hr" && (
                    <Form100hrInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "50hr" && (
                    <Form50hrInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "annual" && (
                    <FormAnnualInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "xponder" && (
                    <FormXponderInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "pitot-static" && (
                    <FormPitotStaticInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "elt" && (
                    <FormEltInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "elt-battery" && (
                    <FormEltBatInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "gps" && (
                    <FormGpsInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "registration" && (
                    <FormRegistrationInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}
                  {selectedTemplate?.value === "engine-overhaul" && (
                    <FormEngineOverhaulInspection
                      control={control}
                      errors={errors}
                      register={register}
                      watch={watch}
                      unregister={unregister}
                      selectedAircraft={selectedAircraft}
                      showReminderIntervals
                    />
                  )}

                  {selectedTemplate?.value === "custom" && (
                    <>
                      {watch("type")?.value === "meter" && (
                        <MeterBasedReminderForm
                          control={control}
                          errors={errors}
                          register={register}
                          watch={watch}
                          unregister={unregister}
                          selectedAircraft={selectedAircraft}
                          showReminderIntervals
                        />
                      )}

                      {watch("type")?.value === "time" && (
                        <TimeBasedReminderForm
                          control={control}
                          errors={errors}
                          watch={watch}
                          register={register}
                          selectedAircraft={selectedAircraft}
                          unregister={unregister}
                        />
                      )}

                      {watch("type")?.value === "meterAndTime" && (
                        <>
                          <MeterBasedReminderForm
                            control={control}
                            errors={errors}
                            register={register}
                            watch={watch}
                            unregister={unregister}
                            selectedAircraft={selectedAircraft}
                            showReminderIntervals={false}
                          />
                          <TimeBasedReminderForm
                            control={control}
                            errors={errors}
                            watch={watch}
                            register={register}
                            selectedAircraft={selectedAircraft}
                            unregister={unregister}
                          />
                        </>
                      )}
                    </>
                  )}

                  <Grid item xs={12} sm={6} mt={2}>
                    <Controller
                      control={control}
                      name="nonGrounded"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftBox display="flex" alignItems="center">
                          <Checkbox
                            checked={value}
                            inputRef={ref}
                            onChange={onChange}
                          />
                          <SoftTypography variant="button">
                            Non-Grounded (warning only)
                          </SoftTypography>
                        </SoftBox>
                      )}
                    />
                    {errors?.nonGrounded?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.nonGrounded.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Controller
                      control={control}
                      name="notes"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftBox display="flex" alignItems="center">
                          <SoftInput
                            inputRef={ref}
                            value={value}
                            placeholder="Write your notes here"
                            multiline
                            rows={4}
                            onChange={onChange}
                            width="100%"
                          />
                        </SoftBox>
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {!existingData ? "Add Reminder" : "Update Reminder"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

TimeBasedReminderForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any,
  selectedAircraft: PropTypes.any,
  unregister: PropTypes.any,
  watch: PropTypes.any,
};
MeterBasedReminderForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any,
  selectedAircraft: PropTypes.any,
  unregister: PropTypes.any,
  showReminderIntervals: PropTypes.bool,
  watch: PropTypes.any,
};
RemindAtIntervalForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any,
  selectedAircraft: PropTypes.any,
  unregister: PropTypes.any,
  reminderTypes: PropTypes.any,
  watch: PropTypes.any,
};

AddReminderModal.propTypes = {
  handleClose: PropTypes.func,
  existingData: PropTypes.object,
  existingReminders: PropTypes.array,
};
