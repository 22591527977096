import { useEffect } from "react";
import { Observable } from "rxjs";

export const useObservable = <T>(
  observer$: Observable<T>,
  fn: (event: T) => any,
  deps: Array<any> = []
) => {
  useEffect(() => {
    const sub = observer$.subscribe((event) => {
      fn(event);
    });
    return () => sub.unsubscribe();
  }, [...deps]);
};
