import { Flaky } from "@mui/icons-material";
import { Grid } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import SoftTypography from "src/components/SoftTypography";

interface PassFailFormProps {
  value: boolean;
  onChange: (val: boolean) => {};
}

export default function PassFailForm({ value, onChange }: PassFailFormProps) {
  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            mr={2}
          >
            <Flaky
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              fontSize="medium"
            />
          </SoftBox>
          <SoftTypography
            variant="button"
            color="dark"
            fontWeight="medium"
            fontSize="0.8rem"
            ml={1.2}
          >
            Grade Checkride
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8} display="flex" alignItems="center">
        <SoftBox display="inline-block" py={2}>
          <SoftSelect
            value={value}
            onChange={onChange}
            placeholder="Grade Checkride"
            options={[
              {
                value: "pass",
                label: "Pass",
              },
              {
                value: "fail",
                label: "Fail",
              },
              {
                value: "incomplete",
                label: "Incomplete",
              },
            ]}
          />
        </SoftBox>
      </Grid>
    </Grid>
  );
}
