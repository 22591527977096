// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";

function FileTypeCell({ onChange, documentTypes, rowData }) {
  const defaultValue = documentTypes.find(
    ({ value }) => value === rowData.fileType
  );
  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" px={2}>
      <SoftBox color="red" sx={{ lineHeight: 1.625 }}>
        <SoftSelect
          options={documentTypes}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the RefundsCell
FileTypeCell.propTypes = {
  onChange: PropTypes.func,
  documentTypes: PropTypes.array,
  rowData: PropTypes.object,
};

export default FileTypeCell;
