import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftBadge from "src/components/SoftBadge";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where } from "firebase/firestore";
import { getAircraftSquawksCollectionGroup } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

const columns = [
  {
    Header: "Aircraft",
    accessor: "tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Reported By",
    accessor: "reporter.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Created",
    accessor: "date",
    Cell: ({ value }) => (
      <DefaultCell
        value={`${value?.toDate() ? format(value.toDate(), "Pp") : "Never"}`}
      />
    ),
  },
  {
    Header: "Grounded",
    accessor: "grounded",
    Cell: ({ row: { original } }) => (
      <SoftBadge
        variant="contained"
        color={original.grounded ? "error" : "success"}
        size="xs"
        badgeContent={original.grounded ? "Yes" : "No"}
        container
        sx={{
          marginRight: 1,
        }}
      />
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={value || "---"} />
    ),
  },
];

function SquawksReport() {
  const { selectedClubId, selectedClub, selectedLocation } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const squawksCollectionRef = query(
    getAircraftSquawksCollectionGroup(),
    where("clubId", "==", selectedClubId),
    where("date", ">=", dateRange[0]),
    orderBy("date", "asc")
  );
  const { data: allSquawks, isDataLoaded: squawksLoaded } =
    useRealtimeCollectionData(squawksCollectionRef, false);

  useEffect(() => {
    if (squawksLoaded && allSquawks?.length > 0) {
      const filteredSquawks = allSquawks.filter((squawk) => {
        const squawkDate = squawk.date?.toDate();
        return isWithinInterval(squawkDate, {
          start: dateRange[0],
          end: dateRange[1],
        });
      });
      setCsvData(
        filteredSquawks.map((checkout) => ({
          aircraft: checkout.tailNumber,
          description: checkout.description,
          reporter: checkout.reporter.displayName,
          created: checkout.date?.toDate()
            ? format(checkout.date.toDate(), "Pp")
            : "Never",
          grounded: checkout.grounded ? "Yes" : "No",
          status: checkout.status,
        }))
      );
      setRows(filteredSquawks);
    }
  }, [allSquawks, squawksLoaded, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-squawks-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SquawksReport;
