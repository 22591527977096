// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "src/assets/theme/base/typography";

// Custom styles for the SoftSnackbar
import SoftSnackbarIconRoot from "src/components/SoftSnackbar/SoftSnackbarIconRoot";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
function SoftSnackbar({
  color = "info",
  icon,
  title,
  dateTime,
  content,
  close,
  bgWhite = false,
  duration = 5000,
  slideUp = false,
  ...rest
}) {
  const { size } = typography;
  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = "dark";
    dateTimeColor = "text";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  return (
    <Snackbar
      TransitionComponent={slideUp ? TransitionUp : Fade}
      autoHideDuration={duration > 0 ? duration : null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...rest}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={close}
        >
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <SoftBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <SoftBox display="flex" alignItems="center" lineHeight={0}>
            <SoftSnackbarIconRoot
              fontSize="small"
              ownerState={{
                color,
                bgWhite,
              }}
            >
              {icon}
            </SoftSnackbarIconRoot>
            <SoftTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" lineHeight={0}>
            {dateTime ? (
              <SoftTypography variant="caption" color={dateTimeColor}>
                {dateTime}
              </SoftTypography>
            ) : null}
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  bgWhite || color === "light" ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) =>
                  fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={close}
            >
              close
            </Icon>
          </SoftBox>
        </SoftBox>
        <Divider
          sx={{
            margin: 0,
          }}
          light={dividerColor}
        />
        <SoftBox
          p={1.5}
          color={bgWhite || color === "light" ? "text" : "white"}
          fontSize={size.sm}
        >
          {content}
        </SoftBox>
      </SoftBox>
    </Snackbar>
  );
}

// Typechecking props for SoftSnackbar
SoftSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  content: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
  duration: PropTypes.number,
  slideUp: PropTypes.bool,
};

export default SoftSnackbar;
