// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

function TableCell({
  width = "auto",
  align = "left",
  padding = [],
  noBorder = false,
  children,
}) {
  const { light } = colors;
  const { borderWidth } = borders;

  return (
    <SoftBox
      component="th"
      width={width}
      pt={padding[0]}
      pr={padding[1]}
      pb={padding[2]}
      pl={padding[3]}
      textAlign={align}
      borderBottom={noBorder ? 0 : `${borderWidth[1]} solid ${light.main}`}
    >
      {children !== undefined && (
        <SoftTypography component="div" variant="body2" color="text">
          {children}
        </SoftTypography>
      )}
    </SoftBox>
  );
}

// Typechecking props for TableCell
TableCell.propTypes = {
  width: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.arrayOf(PropTypes.number),
  noBorder: PropTypes.bool,
};

export default TableCell;
