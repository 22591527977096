// Soft UI Dashboard PRO React Base Styles
import colors from "src/assets/theme/base/colors";

const { info, dark, primary } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited, a:-webkit-any-link":
    {
      color: `${dark.main} !important`,
      transition: "color 150ms ease-in !important",
    },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  a: {
    color: primary.main,
  },
  "img, svg": {
    verticalAlign: "unset",
  },
  "*::-webkit-scrollbar": {
    width: "0px",
    display: "none",
  },
};

export default globals;
