import { useEffect, useMemo, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "src/components/SoftTypography";
import Stack from "@mui/material/Stack";
import SoftButton from "src/components/SoftButton";
import DataTable from "src/components/Tables/DataTable";
import Footer from "src/components/Footer";
import { getSimulatorsCollection } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import AircraftInfo from "src/pages/resources/aircrafts/AircraftInfo";
import Modal from "@mui/material/Modal";
import NewSimulatorModal from "src/pages/resources/simulators/NewSimulatorModal";

import {
  getTableColumns,
  getTableColumnsAdmin,
  TableActions,
} from "src/pages/resources/simulators/tableColumns";
import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { query, orderBy, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { usePermissions } from "src/hooks/usePermissions";

function SimulatorsCrud() {
  const [showPanel, setShowPanel] = useState(false);
  const { hasAccess } = usePermissions();
  const [selectedSimulator, setSelectedSimulator] = useState(null);
  const [simulatorToEdit, setSimulatorToEdit] = useState(null);
  const [simulators, setSimulators] = useState([]);
  const [showNewSimulatorModal, setShowNewSimulatorModal] = useState(false);
  const { selectedClubId } = useClubs();

  const [simulatorsSnap, loadingSimulators] = useCollection(
    query(
      getSimulatorsCollection(selectedClubId),
      where("deleted", "==", false),
      orderBy("index", "asc")
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (!simulatorsSnap || loadingSimulators) return;
    const simulatorArray = [];
    const simulatorDocs = simulatorsSnap.docs;
    if (simulatorDocs?.length > 0) {
      simulatorDocs.forEach((element) => {
        simulatorArray.push({ ...element.data(), id: element.id });
      });
      setSimulators(simulatorArray);
    }
    if (simulatorDocs?.length > 0) {
      if (selectedSimulator) {
        const found = simulatorDocs.find((a) => a.id === selectedSimulator.id);
        if (found) {
          setSelectedSimulator({ ...found.data(), id: found.id });
        }
      } else {
        setSelectedSimulator({
          ...simulatorDocs[0].data(),
          id: simulatorDocs[0].id,
        });
      }
    }
  }, [simulatorsSnap, loadingSimulators]);

  const handleRowSelection = (aircraft) => {
    setSelectedSimulator(aircraft);
    setShowPanel(true);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  const handleSimulatorDelete = (simulatorToDelete) => {
    const { deleteData } = entityCrudUtils();

    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deleteData({
            entity: getSimulatorsCollection(selectedClubId),
            pathSegmentsArr: [simulatorToDelete?.id],
          }).then((deleteResult) => {
            if (deleteResult?.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const handleToggleSimulator = async (simulator) => {
    const { updateData } = entityCrudUtils();

    await updateData(
      {
        entity: getSimulatorsCollection(selectedClubId),
        pathSegmentsArr: [simulator.id],
      },
      { disabled: !simulator.disabled }
    );
  };

  const handleOrderAircraft = async (rows) => {
    const { updateData } = entityCrudUtils();

    rows.forEach(async (element, index) => {
      await updateData(
        {
          entity: getSimulatorsCollection(selectedClubId),
          pathSegmentsArr: [element.id],
        },
        { index }
      );
    });
  };

  const handleTableActions = (action, simulator) => {
    switch (action) {
      case TableActions.DELETE:
        handleSimulatorDelete(simulator);
        break;
      case TableActions.EDIT:
        setSimulatorToEdit(simulator);
        setShowNewSimulatorModal(true);
        break;
      case TableActions.TOGGLE:
        handleToggleSimulator(simulator);
        break;
      default:
        break;
    }
  };

  const handleNewSimulatorModalClose = () => {
    setSimulatorToEdit(null);
    setShowNewSimulatorModal(false);
  };
  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);
  const tableColumnsAdmin = useMemo(
    () => getTableColumnsAdmin(handleTableActions, hasAccess),
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={showNewSimulatorModal}
        onClose={handleNewSimulatorModalClose}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <NewSimulatorModal
          onCompleted={handleNewSimulatorModalClose}
          simulator={simulatorToEdit}
        />
      </Modal>
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Simulators
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Manage all training simulators here.
              </SoftTypography>
            </SoftBox>
            <WithPermissions permission={systemPermissions.CREATE_SIMULATORS}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => setShowNewSimulatorModal(true)}
                >
                  + new simulator
                </SoftButton>
                <SoftButton variant="outlined" color="info" size="small">
                  import
                </SoftButton>
                <SoftButton variant="outlined" color="info" size="small">
                  export
                </SoftButton>
              </Stack>
            </WithPermissions>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            rightMinWidth={550}
          >
            <DataTable
              table={{
                columns: hasAccess([
                  systemPermissions.EDIT_SIMULATOR,
                  systemPermissions.DELETE_SIMULATOR,
                  systemPermissions.DISABLE_SIMULATOR,
                ])
                  ? tableColumnsAdmin
                  : tableColumns,
                rows: simulators,
              }}
              entriesPerPage={{
                defaultValue: 7,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              canSearch
              isSorted={false}
              onRowSelect={handleRowSelection}
              selectedRowId={selectedSimulator?.id}
              canDrag
              onRowMove={handleOrderAircraft}
            />
            <AircraftInfo aircraft={selectedSimulator} />
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_SIMULATORS,
  SimulatorsCrud
);
