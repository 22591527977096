import { getDoc } from "firebase/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserById,
  getUserPermissionsDocByLocation,
} from "src/features/user/collections";
import { IUseClub } from "src/interfaces";

export const useUserAttributeChecker = () => {
  const { clubRolesBySelectedLocation, selectedLocationId } =
    useClubs() as IUseClub;

  const getUserMandatoryFields = async (userId: string) => {
    const [userData, permissionsData] = await Promise.all([
      getDoc(getUserById(userId)),
      getDoc(getUserPermissionsDocByLocation(userId, selectedLocationId)),
    ]);

    const userRoles = permissionsData.data()?.userRoles ?? [];
    const user = userData.data();
    if (!user) {
      return { errors: ["error while getting user"], hasErrors: true };
    }
    const docIds = new Set<string>();
    userRoles.forEach((roleId: string) => {
      const role = clubRolesBySelectedLocation.find((r) => r.id === roleId);
      if (role) {
        (role.configuration?.requiredUserDetails ?? []).forEach((id) => {
          docIds.add(id);
        });
      }
    });
    const errors: string[] = [];
    const checkForAttribute = (
      id: string,
      key: string,
      userKey: string,
      errorKey: string
    ) => {
      if (id === key) {
        if (!user[userKey]) {
          errors.push(`${user.displayName} has no ${errorKey} configured`);
        }
      }
    };

    docIds.forEach((id) => {
      checkForAttribute(id, "phone", "phoneNumber", "phone number");
      checkForAttribute(id, "height", "height", "height");
      checkForAttribute(id, "weight", "weight", "weight");
      if (id === "dateOfBirth") {
        if (!user.birthYear || !user.birthMonth || !user.birthDate) {
          errors.push(`${user.displayName} has no date of birth configured`);
        }
      }

      if (id === "emergencyContact") {
        if (
          !user.emergencyContact?.phoneNumber ||
          !user.emergencyContact?.firstName
        ) {
          errors.push(
            `${user.displayName} has no emergency contact configured`
          );
        }
      }
    });
    return { errors, hasErrors: !!errors.length };
  };

  return { getUserMandatoryFields };
};
