import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { formatCurrency } from "src/features/utils";

import { query, doc } from "firebase/firestore";
import { getBillingCollection } from "src/features/billing/collections";
import { useDocument } from "react-firebase-hooks/firestore";

// Soft UI Dashboard PRO React components
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import HoursRemainingCard from "src/containers/Cards/HoursRemainingCard";

function AccountBalance({ userId, accountId }) {
  const { selectedClubId } = useClubs();
  const { isStudent } = useLms();
  const [balance, setBalance] = useState(0);

  let queryRef;
  if (accountId) {
    queryRef = query(
      doc(getBillingCollection(), userId, "clubAccounts", selectedClubId)
    );
  } else {
    queryRef = query(doc(getBillingCollection(), userId));
  }

  const [balanceSnapshot, loadingBalance] = useDocument(queryRef, {
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  useEffect(() => {
    if (loadingBalance) return;
    if (accountId) {
      setBalance(balanceSnapshot.data()?.[accountId]);
    } else {
      setBalance(balanceSnapshot.data()?.[selectedClubId]);
    }
  }, [balanceSnapshot]);

  return selectedClubId === "WIiWPjziBp5WbB27y0Y2" ||
    selectedClubId === "v1Xj2DtMWGnCJkp" ? (
    <>
      <>
        {isStudent ? (
          <HoursRemainingCard
            title={{
              text: "Lessons Remaining",
              fontWeight: "bold",
            }}
            // count={formatCurrency(billingSnapshot?.data()?.balance)}
            lessonsRemaining={(balance || 0) / 170}
            balance={formatCurrency(balance || 0)}
            showBalance
            icon={{
              color: "info",
              component: "paid",
            }}
          />
        ) : (
          <HoursRemainingCard
            title={{
              text: "Hours Remaining",
              fontWeight: "bold",
            }}
            // count={formatCurrency(billingSnapshot?.data()?.balance)}
            dualRemaining={(balance || 0) / 115}
            soloRemaining={(balance || 0) / 230}
            balance={formatCurrency(balance || 0)}
            showBalance
            icon={{
              color: "info",
              component: "paid",
            }}
          />
        )}
      </>
    </>
  ) : (
    <MiniStatisticsCard
      title={{
        text: "Account Balance",
        fontWeight: "bold",
      }}
      // count={formatCurrency(billingSnapshot?.data()?.balance)}
      count={formatCurrency(balance || 0)}
      icon={{
        color: "info",
        component: "paid",
      }}
    />
  );
}

AccountBalance.propTypes = {
  userId: PropTypes.string.isRequired,
  accountId: PropTypes.string,
};

export default AccountBalance;
