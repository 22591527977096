import { useState } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { addDoc, setDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getClubsCollection,
  getClubLocationsCollection,
  getClubCustomRatesCollection,
  getClubWorkOrderColumnCollection,
} from "src/features/club/collections";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// Wizard page components
import About from "./components/About";
import Location from "./components/Location";
import Users from "./components/Users";
import { useNavigate } from "react-router-dom";
// import UserType from 'src/features/user-permissions/UserType';

const schema = yup
  .object({
    clubName: yup.string().required("Your name is required."),
    clubEmail: yup.string().email().required("An email is required."),
    website: yup.string().required("Club website is required."),
    clubNumber: yup.string().required("Club phone number is required."),
    icao: yup.string().required("ICAO is required."),
    locationName: yup.string().required("Location name is required."),
    addressLine1: yup.string().required("Address line 1 is required."),
    city: yup.string().required("City is required."),
    state: yup.string().required("State is required."),
    zip: yup.string().required("Zip is required."),
  })
  .required();

function getSteps() {
  return ["About", "Location", "Users"];
}

function getStepContent(stepIndex, register, control, errors) {
  switch (stepIndex) {
    case 0:
      return <About register={register} formErrors={errors} />;
    case 1:
      return <Location register={register} formErrors={errors} />;
    case 2:
      return <Users register={register} formErrors={errors} />;
    default:
      return null;
  }
}

function AddClub() {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const functions = getFunctions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);
  const onSubmit = async (data) => {
    const addClubResult = await addDoc(getClubsCollection(), {
      email: data.clubEmail,
      name: data.clubName,
      number: data.clubNumber,
      website: data.website,
      preferences: {
        calendar: {
          showOpenSquawks: true,
        },
        cancellationReasons: [
          { cost: 0, title: "Weather" },
          { cost: 0, title: "Other" },
        ],
        notifications: {
          billingNotifications: {
            email: true,
          },
          bookingModifications: {
            email: true,
          },
          bookingReminders: {
            email: true,
          },
          clubNotifications: {
            email: true,
          },
          newBookings: {
            email: true,
          },
        },
        requireCardOnFile: true,
        clubPaysFees: false,
        clubPaysAchFees: false,
        defaultInstructorHourlyRate: 60,
        minPreGround: 0,
        minPostGround: 0,
        minBookingHours: 0,
      },
      setupComplete: false,
      deleted: false,
    });

    // const addLocationResult = await addDoc(getClubLocationsCollection(addClubResult.id), {
    await addDoc(getClubLocationsCollection(addClubResult.id), {
      email: data.locationEmail,
      name: data.locationName,
      number: data.locationNumber,
      icao: data.icao,
      deleted: false,
      address: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
      },
    });

    await setDoc(
      doc(getClubCustomRatesCollection(addClubResult.id), "owners"),
      {
        deleted: false,
        label: "Owners Rate",
      }
    );
    await setDoc(
      doc(getClubCustomRatesCollection(addClubResult.id), "standard"),
      {
        deleted: false,
        label: "Standard Rate",
      }
    );

    await addDoc(getClubWorkOrderColumnCollection(addClubResult.id), {
      cards: [],
      index: 0,
      title: "Backlog",
    });

    await addDoc(getClubWorkOrderColumnCollection(addClubResult.id), {
      cards: [],
      index: 1,
      title: "In Progress",
    });

    await addDoc(getClubWorkOrderColumnCollection(addClubResult.id), {
      cards: [],
      index: 2,
      title: "Completed",
    });

    try {
      const createStripeClubAccountFunction = httpsCallable(
        functions,
        "createStripeClubAccount"
      );
      createStripeClubAccountFunction({
        clubId: addClubResult.id,
      });
    } catch (error) {
      console.error("STRIPE ACCOUNT CREATION ERROR", error);
    }

    // const sendInvitation = httpsCallable(functions, 'sendInvitation');
    // await sendInvitation({
    //   clubId: addClubResult.id,
    //   clubName: data.clubName,
    //   firstName: data.adminFirstName,
    //   lastName: data.adminLastName,
    //   email: data.adminEmail,
    //   locationId: addLocationResult.id,
    //   permissions: {
    //     [addLocationResult.id]: {}
    //   },
    // });

    setSuccess(true);
    setTimeout(() => {
      navigate("/manage-clubs");
    }, 5000);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        pt={3}
        pb={8}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container justifyContent="center">
          {success && (
            <Grid item xs={12} lg={8}>
              <SoftBox mt={6} mb={1} textAlign="center">
                <SoftBox mb={1}>
                  <SoftTypography variant="h3" fontWeight="bold">
                    New Club Added!
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h5"
                  fontWeight="regular"
                  color="secondary"
                >
                  An invite has been sent to the club admin. Redirecting you to
                  the club page in 5 seconds.
                </SoftTypography>
              </SoftBox>
            </Grid>
          )}
          {!success && (
            <Grid item xs={12} lg={8}>
              <SoftBox mt={6} mb={1} textAlign="center">
                <SoftBox mb={1}>
                  <SoftTypography variant="h3" fontWeight="bold">
                    Add A New Club
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h5"
                  fontWeight="regular"
                  color="secondary"
                >
                  This will create a club and send an invitation to new club
                  admins.
                </SoftTypography>
              </SoftBox>

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Card>
                <SoftBox p={2}>
                  <SoftBox>
                    {getStepContent(activeStep, register, errors)}
                    <SoftBox
                      mt={3}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {activeStep === 0 ? (
                        <SoftBox />
                      ) : (
                        <SoftButton
                          variant="gradient"
                          color="light"
                          onClick={handleBack}
                        >
                          back
                        </SoftButton>
                      )}
                      <SoftButton
                        variant="gradient"
                        color="dark"
                        onClick={
                          !isLastStep ? handleNext : handleSubmit(onSubmit)
                        }
                      >
                        {isLastStep ? "add club" : "next"}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddClub;
