import PropTypes from 'prop-types'
import SoftBox from 'src/components/SoftBox';
import SoftTypography from 'src/components/SoftTypography';

import { 
  DescriptionOutlined, 
  TimelapseOutlined, 
  CheckCircleOutline, 
  CircleOutlined 
} from '@mui/icons-material';

function CourseCardFooter({status, totalLessons, totalStages, currentLesson}) {
  return (
    <SoftBox
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mx: 2,
        py: 2,
        color: "text",
        borderTop: 1,
        borderColor: "grey.500",
      }}
    >
      {typeof(totalStages) === "number" ? (
        <SoftBox display="flex" gap="4px" alignItems="center">
          <DescriptionOutlined fontSize="small" color="secondary" />
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography fontSize="small" color="text" fontWeight="bold">
              {totalStages}
            </SoftTypography>
            <SoftTypography fontSize="small" color="text">
              {totalStages === 1 ? "Stage" : "Stages"}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ): null}
      {currentLesson && status?.text !== "Complete" ? (
        <SoftBox display="flex" gap="4px" alignItems="center">
          <DescriptionOutlined fontSize="small" color="secondary" />
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography fontSize="small" color="text">
              On Lesson
            </SoftTypography>
            <SoftTypography fontSize="small" color="text" fontWeight="bold">
              {currentLesson + 1}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ): null}
      {typeof(totalLessons) === "number" && (!currentLesson || status?.text === "Complete") ? (
        <SoftBox display="flex" gap="4px" alignItems="center">
          <DescriptionOutlined fontSize="small" color="secondary" />
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography fontSize="small" color="text" fontWeight="bold">
              {totalLessons}
            </SoftTypography>
            <SoftTypography fontSize="small" color="text">
              {totalLessons === 1 ? "Lesson" : "Lessons"}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ): null}
      {status ? (
        <SoftBox display="flex" gap="4px" minWidth="92px" alignItems="center">
          {status.text === "In progress" && (
            <TimelapseOutlined fontSize="small" color={status.color} />
          )}
          {status.text === "Complete" && (
            <CheckCircleOutline fontSize="small" color={status.color} />
          )}
          {status.text === "Not started" && (
            <CircleOutlined fontSize="small" color={status.color} />
          )}
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography
              fontSize="small"
              color={status.color}
              fontWeight="medium"
            >
              {status.text}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ): null}
    </SoftBox>
  );
}

CourseCardFooter.propTypes = {
  status: PropTypes.object,
  totalLessons: PropTypes.number,
  totalStages: PropTypes.number,
  currentLesson: PropTypes.number
}

export default CourseCardFooter
