import { useRef } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftEditor from "src/components/SoftEditor";
import SoftInput from "src/components/SoftInput";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import SoftTypography from "src/components/SoftTypography";
import Footer from "src/components/Footer";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import { getAnnouncementsCollection } from "src/features/announcements/collection";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SoftSelect from "src/components/SoftSelect";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { doc } from "firebase/firestore";

const schema = yup
  .object({
    title: yup.string().required("A title for your announcement is required!"),
    location: yup
      .object()
      .required("A location for your announcement is required!"),
  })
  .required();

function NewClubAnnouncement() {
  const { userId } = useUser();
  const { selectedClub, selectedClubId, clubLocations } = useClubs();
  const { updateDataFromRef } = entityCrudUtils();
  const navigate = useNavigate();
  const storage = getStorage();
  const editorRef = useRef(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sendEmail: true,
    },
    resolver: yupResolver(schema),
  });

  const publishAnnouncement = async (data) => {
    const announcementRef = doc(getAnnouncementsCollection(selectedClubId));
    const attachmentUrls = [];
    if (data.attachments && data.attachments.length > 0) {
      for (const attachment of data.attachments) {
        const fileName = attachment?.name;
        const storagePath = `clubs/${selectedClubId}/announcements/${announcementRef.id}`;
        const storageRef = ref(
          storage,
          `${storagePath}/${fileName.replace(/\s/g, "")}`
        );

        const fi = await uploadBytes(storageRef, attachment);
        attachmentUrls.push(
          await getDownloadURL(ref(storage, fi.ref.fullPath))
        );
      }
    }

    await updateDataFromRef(announcementRef, {
      title: data.title,
      message: editorRef.current.getContent(),
      messageText: editorRef.current.getContent({ format: "text" }),
      attachments: attachmentUrls,
      createdAt: new Date(),
      createdBy: userId,
      deleted: false,
      icon: "notifications",
      color: "info",
      sendEmail: data.sendEmail,
      locationId: data.location.value !== "" ? data.location.value : undefined,
    });

    navigate("/club-announcements");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Create New Announcement
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Publish a new announcement from {selectedClub.name}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <SoftBox p={3} sx={{ height: "650px" }}>
                <SoftEditor
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  initialValue="<p>Create your new announcement here, select publish on the right to send to the club members.</p>"
                  fileStoagePath={`clubs/${selectedClubId}/announcements`}
                  init={{
                    height: 500,
                  }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SoftBox
                p={3}
                component="form"
                role="form"
                onSubmit={handleSubmit(publishAnnouncement)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Title/Subject
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("title", {
                        required: true,
                      })}
                    />
                    {errors?.title?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.title.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Attachments
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      height="1.6rem"
                      size="large"
                      placeholder="0"
                      type="file"
                      {...register("attachments", {
                        required: true,
                      })}
                    />
                    {errors?.attachments?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.attachments.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Location
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, ref, value } }) => (
                        <SoftSelect
                          options={[
                            { value: "", label: "All Locations" },
                            ...clubLocations.map((location) => ({
                              value: location.id,
                              label: location.name,
                            })),
                          ]}
                          inputRef={ref}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    {errors?.location?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.location.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                      width={"100%"}
                    >
                      <Controller
                        control={control}
                        name="sendEmail"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftBox
                            display="flex"
                            flexDirection="row-reverse"
                            alignItems="center"
                          >
                            <Checkbox
                              checked={value}
                              inputRef={ref}
                              onChange={onChange}
                            />
                            <SoftTypography mr={1} variant="button">
                              Send Email?
                            </SoftTypography>
                          </SoftBox>
                        )}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftButton fullWidth color="primary" type="submit">
                      Publish Announcement
                    </SoftButton>
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewClubAnnouncement;
