import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";

import { format } from "date-fns";

const typesToHideFlightIcons = ["meetingRoom"];

function ReservationDate({ start, end, type }) {
  const showFlightIcons = type ? !typesToHideFlightIcons.includes(type) : true;
  return (
    <Grid
      container
      alignItems="center"
      textAlign="center"
      spacing={1}
      display="flex"
      justifyContent="center"
    >
      <Grid item xs={12} sm={4}>
        <SoftBox>
          {showFlightIcons && (
            <FlightTakeoffIcon color="primary" fontSize="medium" />
          )}
          <SoftTypography variant="h6" fontWeight="bold">
            {format(start, "eee, MMM do yyyy")}
          </SoftTypography>
          {format(start, "hh:mm a")}
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={1}>
        <SoftTypography variant="h6" fontWeight="bold">
          <DoubleArrowIcon color="primary" fontSize="large" />
        </SoftTypography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox>
          {showFlightIcons && (
            <FlightLandIcon color="primary" fontSize="medium" />
          )}
          <SoftTypography variant="h6" fontWeight="bold">
            {format(end, "eee, MMM do yyyy")}
          </SoftTypography>
          {format(end, "hh:mm a")}
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
ReservationDate.propTypes = {
  start: PropTypes.any,
  end: PropTypes.any,
};

export default ReservationDate;
