import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// custom styles for the UpcomingEventsListItem
import {
  defaultItemIconBox,
  defaultItemIcon,
} from "src/components/Items/DefaultItem/styles";

const UpcomingEventsListItem = forwardRef(
  ({ color = "info", icon, title, description, ...rest }, ref) => (
    <SoftBox {...rest} ref={ref} display="flex" alignItems="center">
      <SoftBox
        sx={(theme) =>
          defaultItemIconBox(theme, {
            color,
          })
        }
      >
        <Icon
          fontSize="default"
          sx={(theme) =>
            defaultItemIcon(theme, {
              color,
            })
          }
        >
          {icon}
        </Icon>
      </SoftBox>
      <SoftBox ml={2} lineHeight={1}>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {title}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          {description}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  )
);

// Typechecking props for the UpcomingEventsListItem
UpcomingEventsListItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default UpcomingEventsListItem;
