import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import { ArrowBack } from "@mui/icons-material";
import SplitPane, {
  Divider,
  SplitPaneLeft,
  SplitPaneRight,
} from "src/components/SplitPane/SplitPane";

function DualView({
  children,
  onClosePanel,
  showPanel,
  leftPaneMinWidth,
  leftPaneMaxWidth,
  rightMinWidth,
}) {
  const [firstChild, secondChild] = children;
  return (
    <SoftBox sx={{ flexGrow: 1 }} width="100%">
      <SoftBox
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
      >
        <Grid
          container
          columns={{ xs: 12, lg: 12 }}
          alignItems="stretch"
          direction="row"
          justifyContent="space-between"
        >
          <SplitPane
            className="split-pane-row"
            leftPaneMinWidth={leftPaneMinWidth}
            rightMinWidth={rightMinWidth ?? 500}
          >
            <SplitPaneLeft
              style={{
                maxWidth: leftPaneMaxWidth,
                minWidth: leftPaneMinWidth,
              }}
            >
              {firstChild}
            </SplitPaneLeft>
            {showPanel && (
              <>
                <Divider className="separator-col" />
                <SplitPaneRight>{secondChild}</SplitPaneRight>
              </>
            )}
          </SplitPane>
        </Grid>
      </SoftBox>

      <SoftBox sx={{ display: { lg: "none", xxl: "none" } }}>
        <Grid container>
          {!showPanel && (
            <Grid item xs={12} xxl={12}>
              {firstChild}
            </Grid>
          )}
          {showPanel && (
            <SoftBox width="100%">
              <SoftBox width="100%">
                <SoftButton
                  onClick={() => onClosePanel()}
                  sx={{ marginLeft: 1 }}
                  color="primary"
                >
                  <ArrowBack />
                  Go Back
                </SoftButton>
              </SoftBox>
              {secondChild}
            </SoftBox>
          )}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

DualView.propTypes = {
  children: PropTypes.any,
  onClosePanel: PropTypes.func,
  showPanel: PropTypes.bool,
  leftPaneMinWidth: PropTypes.number,
  leftPaneMaxWidth: PropTypes.number,
  rightMinWidth: PropTypes.number,
};

export default DualView;
