// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Stack from "@mui/material/Stack";

function BreadcrumbsDisplay({ icon, title, routes, light = false }) {
  const buildUrl = (index) => {
    const url = routes.slice(0, index + 1).join("/");
    return `/${url}`;
  };

  return (
    <MuiBreadcrumbs
      sx={{
        "& .MuiBreadcrumbs-separator": {
          color: ({ palette: { white, grey } }) =>
            light ? white.main : grey[600],
        },
      }}
    >
      <HomeIcon icon={icon} light={light} />

      {routes.map((el, index) => (
        <Link to={buildUrl(index)} key={el}>
          <SoftTypography
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{
              lineHeight: 0,
            }}
          >
            {el}
          </SoftTypography>
        </Link>
      ))}
      <SoftTypography
        variant="button"
        fontWeight="regular"
        textTransform="capitalize"
        color={light ? "white" : "dark"}
        sx={{
          lineHeight: 0,
        }}
      >
        {title.replace("-", " ")}
      </SoftTypography>
    </MuiBreadcrumbs>
  );
}

function HomeIcon({ icon, light }) {
  return (
    <Link to="/">
      <SoftTypography
        component="span"
        variant="body2"
        color={light ? "white" : "dark"}
        opacity={light ? 0.8 : 0.5}
        sx={{
          lineHeight: 0,
        }}
      >
        <Icon>{icon}</Icon>
      </SoftTypography>
    </Link>
  );
}

function Title({ title, light }) {
  return (
    <SoftTypography
      fontWeight="bold"
      textTransform="capitalize"
      variant="h6"
      color={light ? "white" : "dark"}
      noWrap
    >
      {title.replace("-", " ")}
    </SoftTypography>
  );
}

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);

  return (
    <SoftBox
      mr={{
        xs: 0,
        xl: 8,
      }}
    >
      <SoftBox sx={{ display: { xs: "none", md: "block" } }}>
        <BreadcrumbsDisplay
          icon={icon}
          title={title}
          routes={routes}
          light={light}
        />
        <Title title={title} light={light} />
      </SoftBox>
      <SoftBox sx={{ display: { xs: "block", md: "none" } }}>
        <Stack direction={{ xs: "row" }} spacing={1}>
          <Link to="/">
            <SoftTypography
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
            >
              <Icon>{icon}</Icon>
            </SoftTypography>
          </Link>
          <Title title={title} light={light} />
        </Stack>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
