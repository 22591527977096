// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";

function Location({ register, formErrors }) {
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Now, where is this location based?
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        ICAO
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("icao", {
                        required: true,
                      })}
                      placeholder="Eg. KRDU"
                    />
                    {formErrors?.icao?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.icao.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Location Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("locationName", {
                        required: true,
                      })}
                      placeholder="Eg. Raleigh Durham Airport"
                    />
                    {formErrors?.locationName?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.locationName.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Location Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("locationNumber")}
                      placeholder="Eg. +1 (919) 123-4567"
                    />
                    {formErrors?.locationNumber?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.locationNumber.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Location Email
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("locationEmail")}
                      placeholder="Eg. rdu@clubname.com"
                    />
                    {formErrors?.locationEmail?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.locationEmail.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={3}>
              <Grid item xs={12}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address Line 1
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("addressLine1", {
                        required: true,
                      })}
                    />
                    {formErrors?.addressLine1?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.addressLine1.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Address Line 2
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput {...register("addressLine2")} />
                    {formErrors?.addressLine2?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.addressLine2.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        City
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("city", {
                        required: true,
                      })}
                    />
                    {formErrors?.city?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.city.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        State
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("state", {
                        required: true,
                      })}
                    />
                    {formErrors?.state?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.state.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Zip
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("zip", {
                        required: true,
                      })}
                    />
                    {formErrors?.zip?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.zip.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Location.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
};

export default Location;
