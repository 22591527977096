import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import RoomIcon from "@mui/icons-material/Room";
import PersonIcon from "@mui/icons-material/Person";

export const MeetingRoomDynamicProps = [
  {
    label: "Meeting Room",
    accessor: (booking) => booking?.extendedProps?.meetingRoom?.label,
    icon: RoomIcon,
  },
  {
    label: "Member",
    accessor: (booking) => booking?.extendedProps?.member?.label,
    icon: PersonIcon,
  },
  {
    label: "Reservation #",
    accessor: (booking) => booking.id,
    icon: ConfirmationNumberIcon,
  },
];
