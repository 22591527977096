import { Subject } from "rxjs";
import { IAircraft } from "src/interfaces";

export interface IAircraftModal {
  isOpen?: boolean;
  aircraft: IAircraft | null;
}

export enum tabsEnum {
  AIRCRAFT = "AIRCRAFT",
  BOOK = "BOOK",
  TOOLS = "TOOLS",
  FOLDER = "FOLDER",
  SEARCH = "SEARCH",
  EDIT = "EDIT",
}

export const openAircraftModal$ = new Subject<
  IAircraftModal & { tab: tabsEnum }
>();

export const aircraftModalOpener = ({
  aircraft,
  tab = tabsEnum.AIRCRAFT,
}: {
  aircraft: IAircraft;
  tab?: tabsEnum;
}) => {
  openAircraftModal$.next({
    aircraft,
    tab,
  });
};

export const getAircraftModalTab = (
  currenTabs: tabsEnum[],
  newTab: tabsEnum
) => {
  if (newTab !== tabsEnum.SEARCH)
    return currenTabs.includes(tabsEnum.SEARCH)
      ? [newTab, tabsEnum.SEARCH]
      : [newTab];
  if (currenTabs.includes(newTab))
    return currenTabs.filter((t) => t !== newTab);
  return currenTabs.concat(newTab);
};

export const getAircraftCurrentTab = (currenTabs: tabsEnum[]): tabsEnum => {
  const noSearchTabs = currenTabs.filter((t) => t !== tabsEnum.SEARCH);
  return noSearchTabs[0];
};

export const getAircraftTabTitle = (currentTabs: tabsEnum[]) => {
  if (currentTabs.includes(tabsEnum.BOOK)) return "Logs";
  if (currentTabs.includes(tabsEnum.TOOLS)) return "Maintenance";
  if (currentTabs.includes(tabsEnum.FOLDER)) return "Documents";
};
