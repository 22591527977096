import { useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import Icon from "@mui/material/Icon";

import { addDoc, doc, updateDoc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import { ArrowBack } from "@mui/icons-material";
import AddNewCard from "src/modals/AddNewCard";

export function BookingErrorModal({
  booking,
  permissionErrors,
  handleClose,
  fromModal,
}) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { canOverrideBooking } = useUserPermissions();
  const [displayAddCard, setDisplayAddCard] = useState(false);
  const [addCardToUserId, setAddCardToUserId] = useState(null);

  const overrideSaveBooking = async () => {
    if (!booking.id) {
      await addDoc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        booking
      );
    } else {
      await updateDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          booking.id
        ),
        booking,
        {
          merge: true,
        }
      );
    }
    handleClose(false);
  };

  const showAddNewCard = () => {
    setDisplayAddCard(true);
  };
  const handleCloseAddNewCard = (value) => {
    setDisplayAddCard(false);
    if (value.success) {
      overrideSaveBooking();
    }
  };

  if (displayAddCard) {
    return (
      <AddNewCard
        userId={addCardToUserId || booking.extendedProps.pilot.value}
        handleClose={handleCloseAddNewCard}
      />
    );
  }

  const renderPermissionErrors = permissionErrors.map((item, key) => {
    const itemKey = `element-${key}`;
    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1.25}
      >
        <SoftTypography
          variant="button"
          color="error"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
        {booking?.extendedProps?.pilot?.value &&
          item.includes("No card on file") && (
            <SoftButton
              variant="text"
              color="dark"
              onClick={() => {
                if (item.includes(booking?.extendedProps?.pilot.label))
                  setAddCardToUserId(booking?.extendedProps?.pilot.value);
                else if (item.includes(booking?.extendedProps?.pilot2.label))
                  setAddCardToUserId(booking?.extendedProps?.pilot2.value);
                showAddNewCard();
              }}
            >
              <Icon
                sx={{
                  fontWeight: "bold",
                }}
              >
                add
              </Icon>
              &nbsp;add new card
            </SoftButton>
          )}
      </SoftBox>
    );
  });

  return (
    <ModalContainer handleClose={() => handleClose(true)}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold" mt={5}>
            Checklist Failed
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SoftTypography variant="h6">
                  You are not allowed to {booking.id ? "edit" : "create"} this
                  reservation for the following reasons:
                </SoftTypography>
                <SoftBox mt={1.625}>
                  <SoftBox p={2}>
                    <SoftBox
                      component="ul"
                      m={0}
                      pl={3.25}
                      mb={{
                        xs: 8,
                        sm: 0,
                      }}
                    >
                      {renderPermissionErrors}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {canOverrideBooking(booking) ? (
                <>
                  <Grid item xs={6}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        onClick={() => handleClose(true)}
                        color="dark"
                        fullWidth
                        variant="outlined"
                      >
                        {fromModal ? (
                          <>
                            <ArrowBack
                              fontSize="small"
                              lineHeight={2}
                              marginRight={2}
                            />
                            &nbsp;&nbsp;&nbsp;Return to Booking
                          </>
                        ) : (
                          "Close"
                        )}
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="gradient"
                        color="error"
                        fullWidth
                        onClick={() => overrideSaveBooking()}
                      >
                        Override & Book
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <SoftBox
                    mt={1}
                    display="flex"
                    alignItems="center"
                    flexDirection="row-reverse"
                  >
                    <SoftButton
                      variant="outlined"
                      color="dark"
                      onClick={() => handleClose(true)}
                    >
                      Close
                    </SoftButton>
                  </SoftBox>
                </Grid>
              )}
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

BookingErrorModal.propTypes = {
  booking: PropTypes.object.isRequired,
  permissionErrors: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  fromModal: PropTypes.bool,
};
