import { createContext, useContext, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { auth, logout } from "src/features/firebase/auth/utils";
import { setRouter, useSoftUIController } from "src/context";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";
import { getUsersCollection } from "src/features/user/collections";
import { createStripeCustomer } from "src/features/user/utils";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import axios from "axios";

import {
  getDatabase,
  goOnline,
  ref,
  onValue,
  onDisconnect,
  set,
  push,
  serverTimestamp,
} from "firebase/database";

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const UserContext = createContext(null);
UserContext.displayName = "UserContext";

let destroyPresenceListener;

const setupPresence = (userId) => {
  const db = getDatabase();
  goOnline(db);
  const connectedRef = ref(db, ".info/connected");
  destroyPresenceListener = onValue(connectedRef, async (snap) => {
    if (snap.val() === true) {
      const res = await axios.get("https://geolocation-db.com/json/");

      const myConnectionsRef = ref(db, `status/${userId}/connections`);
      const con = push(myConnectionsRef);
      onDisconnect(con)
        .remove()
        .then(() => {
          set(con, {
            last_changed: serverTimestamp(),
            ...res.data,
            browserName,
            browserVersion,
            osName,
            osVersion,
          });
        });
    }
  });
};

function UserProvider({ children }) {
  const [userId, setUserId] = useState("");
  const [user, isUserLoading] = useDocument(
    userId && doc(getUsersCollection(), userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: false,
      },
    }
  );
  const navigate = useNavigate();
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUserId(firebaseUser.uid);
        setupPresence(firebaseUser.uid);
      } else {
        setUserId("");
        if (destroyPresenceListener) {
          destroyPresenceListener();
        }
        if (
          pathname.split("/")[1] !== "auth" &&
          pathname.split("/")[1] !== "terms" &&
          pathname.split("/")[1] !== "pay" &&
          pathname.split("/")[1] !== "intro-flight"
        ) {
          navigate("/auth/sign-in");
          setRouter(dispatch, "auth");
        }
      }
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (
      user &&
      user.data()?.email &&
      user.data()?.firstName &&
      user.data()?.lastName &&
      !user.data()?.stripeId
    ) {
      createStripeCustomer(user.data());
    }

    if (user && user.data()?.email && user.data().displayName) {
      datadogRum.setUser({
        id: user.id,
        email: user.data().email,
        name: user.data().displayName,
      });
      datadogLogs.setUser({
        id: user.id,
        email: user.data().email,
        name: user.data().displayName,
      });
    }
  }, [user]);

  if (
    (userId && !user?.data()) ||
    user?.data()?.isUserInfoPending ||
    isUserLoading
  ) {
    return <AuthLoadingPage />;
  }

  const signOut = async () => {
    navigate("/auth/sign-in");
    await logout();
    destroyPresenceListener();
    datadogRum.clearUser();
    datadogLogs.clearUser();
    setRouter(dispatch, "auth");
  };

  const value = {
    user: user?.data(),
    userId,
    setUserId,
    signOut,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser should be used inside the UserContextProvider.");
  }

  return context;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider, useUser };
