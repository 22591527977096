import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";

import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";
import * as yup from "yup";
import CurrencyFormat from "react-currency-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import { calculateFees } from "src/features/utils";

import { getFunctions, httpsCallable } from "firebase/functions";
import { ThreeDots } from "react-loader-spinner";
import {
  useStripe,
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";

import SoftInput from "src/components/SoftInput";

const stylePayment = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  maxWidth: "100vw",
  p: 4,
};

const schema = yup
  .object({
    amount: yup
      .number()
      .positive("Amount must be a positive number.")
      .required("An amount is required."),
    email: yup.string().email("Please enter a valid email address.").required(),
  })
  .required();

function ProcessPaymentModal({
  stripe,
  elements,
  paymentIntent,
  handleClose,
  email,
}) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { userId } = useUser();
  const [stripeError, setStripeError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const completePayment = async () => {
    setIsSubmitting(true);
    const stripeResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/`,
      },
      redirect: "if_required",
    });

    if (stripeResult.error) {
      console.error("Stripe Payment Error:", stripeResult.error.message);
      setStripeError(stripeResult.error.message);
      setIsSubmitting(false);
    } else {
      const functions = getFunctions();
      const completePosPayment = httpsCallable(functions, "completePosPayment");
      completePosPayment({
        clubId: selectedClubId,
        locationId: selectedLocationId,
        userId,
        paymentIntent,
        email,
      }).then((result) => {
        if (result.data.success) {
          handleClose();
        }
        setIsSubmitting(false);
      });
    }
  };

  return (
    <Card sx={stylePayment}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <SoftTypography variant="h3" p={2} alignSelf="flex-start">
            Process Payment
          </SoftTypography>
        </Grid>
        <Grid item md={4} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mt={2} mb={2}>
              <SoftTypography mb={1} variant="h5">
                Your Order
              </SoftTypography>
              <hr />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="medium">
                Cost Summary
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Amount:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body2" fontWeight="medium">
                  <CurrencyFormat
                    value={paymentIntent?.calculatedFees?.totalCharge || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Payment Fees:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body2" fontWeight="medium">
                  <CurrencyFormat
                    value={
                      (paymentIntent?.calculatedFees?.costToPilot || 0) -
                      (paymentIntent?.calculatedFees?.totalCharge || 0)
                    }
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={paymentIntent?.calculatedFees?.costToPilot || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item md={8} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" p={2}>
            <SoftBox my={2}>
              <PaymentElement />
              {stripeError && (
                <SoftTypography marginTop={1} fontSize={14} color="error">
                  {stripeError}
                </SoftTypography>
              )}
              <SoftBox display="flex" flexDirection="row-reverse" mt={4}>
                <SoftButton
                  onClick={completePayment}
                  disabled={isSubmitting}
                  color="primary"
                >
                  Proceed with payment
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export function POSModal({ handleClose }) {
  const { selectedClubId, selectedClub } = useClubs();
  const { userId } = useUser();
  const stripe = useStripe();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const clubPaysCCFees = selectedClub?.preferences?.clubPaysFees || false;
  const [fees, setFees] = useState({ stripeFees: 0, totalAmount: 0 });

  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { amount } = watch();

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const functions = getFunctions();
    const createPosPaymentIntent = httpsCallable(
      functions,
      "createPosPaymentIntent"
    );
    createPosPaymentIntent({
      amount: data.amount,
      clubId: selectedClubId,
      email: data.email,
      userId,
      paymentType: "card",
    })
      .then((result) => {
        setPaymentIntent(result.data);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("error", error);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    const fundingAmount = parseInt(amount, 10);
    if (amount) {
      setFees(
        calculateFees(
          fundingAmount,
          undefined,
          undefined,
          clubPaysCCFees,
          true,
          "pm_card"
        )
      );
    }
  }, [amount]);

  if (isSubmitting) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (paymentIntent) {
    return (
      <Elements
        stripe={stripe}
        options={{ clientSecret: paymentIntent.secret }}
      >
        <ElementsConsumer>
          {({ elements }) => (
            <ProcessPaymentModal
              stripe={stripe}
              elements={elements}
              paymentIntent={paymentIntent}
              handleClose={handleClose}
              email={watch("email")}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Add Funds
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <SoftInput
                    placeholder="Eg. joe.bloggs@example.com"
                    {...register("email")}
                  />
                  {errors?.email?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.email.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Amount
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.amount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.amount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mt={2}>
            {!clubPaysCCFees && (
              <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                <SoftBox>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Payment Fees:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography variant="body2" fontWeight="medium">
                    <CurrencyFormat
                      value={fees?.totalStripeFees || 0}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={fees?.chargeToPilot || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton disabled={isSubmitting} type="submit" color="primary">
              Next
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

ProcessPaymentModal.propTypes = {
  handleClose: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  paymentIntent: PropTypes.object,
  email: PropTypes.string,
};

POSModal.propTypes = {
  handleClose: PropTypes.func,
};
