import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Fade from "@mui/material/Fade";

function TabPanel({ children, value, index, sx  }) {
  return (
    value === index && (
      <Fade in timeout={850}>
        <SoftBox p={2} sx={sx}>{children}</SoftBox>
      </Fade>
    )
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sx: PropTypes.object,
};

export default TabPanel;
