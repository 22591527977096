import { IPermission } from "./permission.interface";

export enum meetingRoomsRoleCategory {
  MEETING_ROOMS = "MEETING_ROOMS",
}

export enum meetingRoomsPermissionTypes {
  VIEW_MEETING_ROOMS = "VIEW_MEETING_ROOMS",
  BOOK_MEETING_ROOMS = "BOOK_MEETING_ROOMS",
  MANAGE_MEETING_ROOMS = "MANAGE_MEETING_ROOMS",
}

export const meetingRoomPermissions: IPermission[] = [
  {
    group: meetingRoomsRoleCategory.MEETING_ROOMS,
    label: "Meeting Rooms",
    tooltipGroup: "",
    permissions: {
      [meetingRoomsPermissionTypes.VIEW_MEETING_ROOMS]: {
        label: "View Meeting Rooms Schedule",
        tooltip: "",
      },
      [meetingRoomsPermissionTypes.BOOK_MEETING_ROOMS]: {
        label: "Book Meeting Rooms",
        tooltip: "",
      },
      [meetingRoomsPermissionTypes.MANAGE_MEETING_ROOMS]: {
        label: "Manage Meeting Rooms",
        tooltip: "",
      },
    },
  },
];
