import { Clear } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import Draggable from "react-draggable";
import SoftTypography from "src/components/SoftTypography";
import parse from "html-react-parser";
import colors from "src/assets/theme/base/colors";
import linearGradient from "src/assets/theme/functions/linearGradient";

export default function LectureNotes({ onClose = () => {}, content = 0 }) {
  return (
    <Draggable handle=".handle-lecture">
      <Card
        sx={{
          zIndex: 10000,
          position: "absolute",
          top: "10rem",
          right: "4rem",
          minWidth: "160px",
          maxWidth: "600px",
          backgroundColor: colors.grey[100],
          border: 1,
          borderColor: colors.gradients.secondary.state,
        }}
      >
        <CardHeader
          className="handle-lecture"
          action={
            <IconButton
              aria-label="close"
              size="small"
              color="white"
              onClick={onClose}
            >
              <Clear />
            </IconButton>
          }
          title={
            <SoftTypography
              fontSize="medium"
              fontWeight="medium"
              color="white"
              style={{ p: 0 }}
            >
              Lecture Notes
            </SoftTypography>
          }
          sx={{
            cursor: "pointer",
            backgroundImage: linearGradient(
              colors.gradients.secondary.main,
              colors.gradients.secondary.state,
              320
            ),
            py: 1,
            mb: 1,
          }}
        />
        <CardContent>
          <SoftTypography fontSize="small" fontWeight="regular">
            {parse(content)}
          </SoftTypography>
        </CardContent>
      </Card>
    </Draggable>
  );
}
