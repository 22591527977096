import { useState, useImperativeHandle, forwardRef, useMemo } from "react";
import { motion } from "framer-motion";
import { IconButton } from "@mui/material";
import { Add, Remove, Done } from "@mui/icons-material";

interface PermissionItem {
  label: string | React.ReactNode;
  id: string;
}

interface SoftCollapsibleSelectProps {
  title: string | React.ReactNode;
  items: PermissionItem[];
  selectedItemsByDefault?: string[];
  onChange?: (
    ids: string[],
    options: {
      type: "added" | "removed" | "removedAll" | "selectedAll";
      ids: string[];
    }
  ) => void;
}

export interface SoftCollapsibleSelectRef {
  collapse: () => void;
  expand: () => void;
  selectAll: () => void;
  deselectAll: () => void;
  getSelectedItems: () => string[];
}

export const SoftCollapsibleSelect = forwardRef<
  SoftCollapsibleSelectRef,
  SoftCollapsibleSelectProps
>(({ title, items, selectedItemsByDefault = [], onChange }, ref) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedItemsByDefault
  );
  const allIds = useMemo(() => items.map((item) => item.id), [items]);

  const handleToggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      onChange?.([], { type: "removedAll", ids: allIds });
    } else {
      setSelectedItems(allIds);
      onChange?.(allIds, { type: "selectedAll", ids: allIds });
    }
    setSelectAll(!selectAll);
  };

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemToggle = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems((prev) => {
        const newValue = prev.filter((itemId) => itemId !== id);
        onChange?.(newValue, { type: "removed", ids: [id] });
        return newValue;
      });
    } else {
      setSelectedItems((prev) => {
        const newValue = [...prev, id];
        onChange?.(newValue, { type: "added", ids: [id] });
        return newValue;
      });
    }
  };

  // Expose functions to parent via forwardRef
  useImperativeHandle(ref, () => ({
    collapse: () => setIsCollapsed(true),
    expand: () => setIsCollapsed(false),
    selectAll: () => {
      setSelectAll(true);
      handleToggleSelectAll();
    },
    deselectAll: () => {
      setSelectAll(false);
      handleToggleSelectAll();
    },
    getSelectedItems: () => selectedItems,
  }));

  const areAllSelected = items.every((i) => selectedItems.includes(i.id));

  return (
    <div
      style={{ border: "1px solid #ddd", borderRadius: "4px", padding: "10px" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 15,
        }}
      >
        <IconButton
          style={{
            border: "2px solid #329baa ",
            borderRadius: "4px",
            height: 20,
            width: 20,
            backgroundColor: selectedItems.length > 0 ? "#329baa" : "#FFF",
          }}
          onClick={handleToggleSelectAll}
        >
          {areAllSelected ? (
            <Done style={{ stroke: "white", height: 15, strokeWidth: 2 }} />
          ) : selectedItems.length > 0 ? (
            <Remove style={{ stroke: "white", height: 15, strokeWidth: 2 }} />
          ) : null}
        </IconButton>
        <span style={{ fontSize: 18, fontWeight: 600 }}>{title}</span>
        <IconButton
          onClick={handleToggleCollapse}
          style={{
            border: "2px solid #329baa ",
            borderRadius: "4px",
            height: 20,
            width: 20,
            marginLeft: "auto",
          }}
        >
          {isCollapsed ? (
            <Add style={{ stroke: "#329baa", height: 15 }} />
          ) : (
            <Remove style={{ stroke: "#329baa", height: 15 }} />
          )}
        </IconButton>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isCollapsed ? 0 : "auto",
          opacity: isCollapsed ? 0 : 1,
        }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div style={{ padding: "10px 0", paddingLeft: "25px" }}>
          {items.map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 0",
              }}
            >
              <IconButton
                onClick={() => handleItemToggle(item.id)}
                style={{
                  border: "2px solid #329baa ",
                  borderRadius: "4px",
                  height: 20,
                  width: 20,
                  marginRight: "6px",
                  backgroundColor: selectedItems.includes(item.id)
                    ? "#329baa"
                    : "white",
                }}
              >
                {selectedItems.includes(item.id) ? (
                  <Done
                    style={{ stroke: "white", height: 15, strokeWidth: 2 }}
                  />
                ) : null}
              </IconButton>
              <span style={{ fontSize: 16 }}>{item.label}</span>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
});
