import { useCallback, useState } from "react";
import useScanDetection from "use-scan-detection";

export const useRfidScanner = (
  onScan: (symbol: string) => void,
  initialEnabled = false,
  minLength = 5
): {
  toggleEnabled: (state: boolean) => void;
  setEnabled: (state: boolean) => void;
} => {
  const [enabled, setEnabled] = useState<boolean>(initialEnabled);

  useScanDetection({
    preventDefault: false,
    stopPropagation: true,
    minLength,
    onComplete: (code: String) => {
      if (!code || !enabled) return;

      // Remove unwanted characters and trim the result
      code = code
        .replace(
          /Shift|Enter|Control|Alt|Meta|Tab|ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Backspace|Escape/g,
          ""
        )
        .trim();
      // scan = scan.replace(/[^a-zA-Z0-9]/g, "").trim();

      onScan(code as string);
    },
  });

  const toggleEnabled = useCallback((state: boolean) => {
    setEnabled(state);
  }, []);

  return { toggleEnabled, setEnabled };
};

export default useRfidScanner;
