import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "regenerator-runtime";

import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary";
import { SoftUIControllerProvider } from "./context";
import "src/features/firebase/auth/utils";
import { UserProvider } from "src/features/user/UserProvider";
import { ClubProvider } from "src/features/club/ClubProvider";
import theme from "src/assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "src/index.css";
import "toastr/build/toastr.css"; // You need style and css loader installed and set
import "tippy.js/dist/tippy.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isDev } from "src/features/utils";

import ReactGA from "react-ga4";
import RouteChangeTracker from "src/routes/routeChangeTracker";

import { Elements } from "@stripe/react-stripe-js";
import { stripe } from "src/features/stripe";

import { getApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { UserPermissionsProvider } from "src/features/user-permissions/UserPermissionsProvider";
import { NotificationsProvider } from "src/features/notifications/NotificationsProvider";
import { LmsProvider } from "src/features/lms/LmsProvider";
import { WeatherProvider } from "src/features/weather/WeatherProvider";

import { LicenseInfo } from "@mui/x-license-pro";

import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
// import "@fullcalendar/react/dist/vdom";
import AircraftModal from "./modals/AircraftModal/AircraftModal";
import ModalManager from "src/modals/ModalManager";
import { DevOnly } from "./components/DevOnly/DevOnly";

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { IdleTimer } from "./features/idle-timer/IdleTimer";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

datadogRum.init({
  applicationId: "828af35d-bf1a-4fc5-a89c-1aaf1ecd4328",
  clientToken: "pub6b86937de02f9b75e3acc1b55e003fe4",
  site: "us5.datadoghq.com",
  service: "preflight-web-app",
  env: import.meta.env.VITE_RELEASE_STAGE,
  // eslint-disable-next-line no-undef
  version: APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
});

datadogLogs.init({
  clientToken: "pub6b86937de02f9b75e3acc1b55e003fe4",
  site: "us5.datadoghq.com",
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  forwardReports: "all",
  trackingConsent: "granted",
  sessionSampleRate: 100,
});

yup.addMethod(yup.string, "phone", function (message) {
  return this.test("phone", message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    return isValidPhoneNumber(value) ? true : createError({ path, message });
  });
});

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY); // MUI Pro

ReactGA.initialize(import.meta.env.VITE_FIREBASE_MEASUREMENT_ID);

const stripeOptions = {
  // Pass stripe options in here
  // E.G. pass in the client secret obtained from the server
  // clientSecret: '{{CLIENT_SECRET}}',
};

const functions = getFunctions(getApp());
const firestore = getFirestore(getApp());
const auth = getAuth(getApp());
const storage = getStorage(getApp());
const db = getDatabase(getApp());
if (isDev && import.meta.env.VITE_EMULATE_FIREBASE)
  connectFunctionsEmulator(functions, "localhost", 5001);
if (isDev && import.meta.env.VITE_EMULATE_FIREBASE)
  connectFirestoreEmulator(firestore, "localhost", 8080);
if (isDev && import.meta.env.VITE_EMULATE_FIREBASE)
  connectAuthEmulator(auth, "http://localhost:9099");
if (isDev && import.meta.env.VITE_EMULATE_FIREBASE)
  connectStorageEmulator(storage, "localhost", 9199);
if (isDev && import.meta.env.VITE_EMULATE_FIREBASE)
  connectDatabaseEmulator(db, "localhost", 9000);

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Elements stripe={stripe} options={stripeOptions}>
            <CssBaseline />
            <UserProvider>
              <IdleTimer />
              <ClubProvider>
                <LmsProvider>
                  <UserPermissionsProvider>
                    <NotificationsProvider>
                      <WeatherProvider>
                        <DevOnly>
                          <AircraftModal />
                        </DevOnly>

                        <ModalManager />
                        <RouteChangeTracker />
                        <App />
                      </WeatherProvider>
                    </NotificationsProvider>
                  </UserPermissionsProvider>
                </LmsProvider>
              </ClubProvider>
            </UserProvider>
          </Elements>
        </ErrorBoundary>
      </ThemeProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
