import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { getDoc, doc } from "firebase/firestore";
import {
  getBillingCollection,
  getUsersClubAccountCollection,
} from "src/features/billing/collections";
import { AppBar, Tabs, Tab, Modal } from "@mui/material";
import { AddDepositModal } from "src/modals/AddDepositModal";

import TabPanel from "src/components/TabPanel";
import breakpoints from "src/assets/theme/base/breakpoints";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function AccountList() {
  const navigate = useNavigate();
  const [openAddNewDeposit, setOpenAddNewDeposit] = useState(false);
  const { locationUsers, selectedClubId, clubAccounts } = useClubs();
  const [tableRows, setTableRows] = useState([]);
  // const [locationUsers, setLocationUsers] = useState([]);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const addUserAccountBalance = async () => {
    const accountBalances = [];
    const usersWithBalance = await Promise.all(
      locationUsers.map(async (user) => {
        const accountBalance = await getDoc(
          doc(getBillingCollection(), user.id)
        );

        const clubAccountBalances = await getDoc(
          doc(getUsersClubAccountCollection(user.id), selectedClubId)
        );
        clubAccounts.map((account) => {
          accountBalances.push({
            ...user,
            accountId: account.id,
            accountBalance: clubAccountBalances.data()?.[account.id] ?? 0,
          });
        });
        return {
          ...user,
          accountBalance: accountBalance.data()?.[selectedClubId] ?? 0,
        };
      })
    );

    setTableRows(
      [...usersWithBalance, ...accountBalances].sort(
        (a, b) => a.accountBalance - b.accountBalance
      )
    );
  };

  useEffect(() => {
    addUserAccountBalance();
  }, [locationUsers]);

  const onRowSelected = (row) => {
    if (row?.accountId)
      navigate(`/billing/accounts/${row.id}?accountId=${row.accountId}`);
    else navigate(`/billing/accounts/${row.id}`);
  };

  const addDeposit = () => {
    setOpenAddNewDeposit(true);
  };

  const handleNewDepositClose = () => {
    setTimeout(() => {
      addUserAccountBalance();
    }, 3000);
    setOpenAddNewDeposit(false);
  };

  const getTableRows = (accountId) => {
    if (accountId) {
      return tableRows.filter((row) => row.accountId === accountId);
    } else {
      return tableRows.filter((row) => !row.accountId);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <WithPermissions permissions={systemPermissions.DEPOSIT_FUNDS}>
            <SoftBox>
              {/* <SoftButton variant="gradient" color="info" onClick={addNewTransaction}>
              New Transaction
            </SoftButton> */}
              <SoftButton
                variant="gradient"
                color="secondary"
                // sx={{ marginLeft: 3 }}
                onClick={addDeposit}
              >
                Deposit Funds
              </SoftButton>
            </SoftBox>
          </WithPermissions>
        </SoftBox>
        <Card>
          {clubAccounts?.length >= 1 && (
            <SoftBox mt={2} display="flex">
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Tab label="Standard" />
                  {clubAccounts.map((account) => {
                    return <Tab label={`${account.name}`} />;
                  })}
                </Tabs>
              </AppBar>
            </SoftBox>
          )}
          <SoftBox mt={2}>
            <TabPanel value={tabValue} index={0}>
              {tableRows && tableRows.length > 0 && (
                <DataTable
                  onRowSelect={onRowSelected}
                  table={{
                    columns: getTableColumns(),
                    rows: getTableRows(),
                  }}
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [10, 20, 50, 100],
                  }}
                  canSearch
                />
              )}
            </TabPanel>
            {clubAccounts.map((account, index) => {
              return (
                <TabPanel value={tabValue} key={account?.id} index={index + 1}>
                  {tableRows && tableRows.length > 0 && (
                    <DataTable
                      onRowSelect={onRowSelected}
                      table={{
                        columns: getTableColumns(),
                        rows: getTableRows(account?.id),
                      }}
                      entriesPerPage={{
                        defaultValue: 10,
                        entries: [10, 20, 50, 100],
                      }}
                      canSearch
                    />
                  )}
                </TabPanel>
              );
            })}
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewDeposit}
        onClose={handleNewDepositClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddDepositModal handleClose={handleNewDepositClose} />
      </Modal>
    </DashboardLayout>
  );
}

export default AccountList;
