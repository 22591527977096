import { useEffect, useState } from "react";
import { Radio } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "src/components/TableCell";

import { addDoc } from "firebase/firestore";
import SoftInput from "src/components/SoftInput";
import {
  getUserLessonGradingsCollection,
  getUserTaskGradingsCollection,
} from "src/features/user/collections";

const schema = yup
  .object({
    reservationType: yup.object(),
    startDate: yup.date(),
    endDate: yup.date(),
  })
  .required();

export function GradeLessonModal({ handleClose, lesson }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFormDefaultValues = () => ({
    notes: "",
  });

  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(schema),
  });

  const notes = watch("notes");

  const [lessonGrade, setLessonGrade] = useState("");
  const [oldTaskGrades, setOldTaskGrades] = useState({});
  const [taskGrades, setTaskGrades] = useState({});
  const [taskGradeErrors, setTaskGradeErrors] = useState({});

  const handleChangeTaskGrade = (event) => {
    if (event.target.value === "5" && lessonGrade === "S") {
      setLessonGrade("");
    }
    setTaskGrades({ ...taskGrades, [event.target.name]: event.target.value });
  };
  const handleChangeLessonGrade = (event) => {
    setLessonGrade(event.target.value);
  };

  useEffect(() => {
    if (lesson?.grade) {
      const taskGradesObj = {};
      const oldTaskGradesObj = {};
      lesson.tasks.forEach((task) => {
        if (task.grade) {
          oldTaskGradesObj[task.id] = task.grade; // stores full gradeDoc data for less iteration during submit
          taskGradesObj[task.id] = task.grade.grade; // stores actual grade value for easier form functionality
        }
      });
      setOldTaskGrades(oldTaskGradesObj);
      setTaskGrades(taskGradesObj);
      setLessonGrade(lesson.grade.lessonGrade);
      setValue("notes", lesson.grade.notes);
    }
  }, [lesson]);

  const getDataToSave = () => {
    const dataToSave = {
      lessonId: lesson?.id,
      courseId: lesson?.courseId,
      stageId: lesson?.stageId,
      notes,
      savedAt: new Date(),
      deleted: false,
    };
    if (lessonGrade) {
      dataToSave.lessonGrade = lessonGrade;
    }
    return dataToSave;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (
      (Object.keys(taskGrades).length >= 1 &&
        Object.keys(taskGrades).length !== lesson?.tasks?.length) ||
      !lessonGrade
    ) {
      lesson?.tasks?.forEach((task) => {
        if (!taskGrades[task.id]) {
          setTaskGradeErrors({ ...taskGradeErrors, [task.id]: true });
        }
      });
      setIsSubmitting(false);
      return;
    }
    const dataToSave = getDataToSave();

    await addDoc(
      getUserLessonGradingsCollection(lesson.studentId, lesson.enrollmentId),
      { ...dataToSave, completedAt: new Date(), submitted: true }
    );

    // Iterate over taskGrades (coming from form values)
    for (const taskId in taskGrades) {
      const oldTaskGrade = oldTaskGrades[taskId];
      if (!oldTaskGrade || taskGrades[taskId] !== oldTaskGrade.grade) {
        // create doc for taskGrade with new grade value
        await addDoc(
          getUserTaskGradingsCollection(lesson.studentId, lesson.enrollmentId),
          {
            taskId,
            grade: taskGrades[taskId],
            deleted: false,
            enrollmentId: lesson.enrollmentId,
            lessonId: lesson.id,
            studentId: lesson.studentId,
            createdAt: new Date(),
          }
        );
      }
    }

    setIsSubmitting(false);
    handleClose();
  };

  const controlProps = (id, value) => ({
    checked: taskGrades?.[id] === value,
    onChange: handleChangeTaskGrade,
    value,
    name: id,
    inputProps: { "aria-label": value },
  });

  const controlPropsLesson = (value) => ({
    checked: lessonGrade === value,
    onChange: handleChangeLessonGrade,
    value,
    name: "lessonGrade",
    inputProps: { "aria-label": value },
  });

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h6" color="primary" fontWeight="bold">
            Grade Lesson
          </SoftTypography>
          <SoftTypography variant="h5" fontWeight="bold">
            {lesson?.title}
          </SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={1}>
              {lesson?.tasks?.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <SoftBox pb={2}>
                    <SoftBox
                      minWidth="auto"
                      sx={{
                        overflow: "scroll",
                      }}
                    >
                      <Table
                        sx={{
                          minWidth: "36rem",
                        }}
                      >
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell
                              width="100%"
                              padding={[1.5, 3, 1.5, 0.5]}
                            >
                              Task
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              1
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              2
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              3
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              4
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              5
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              I
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {lesson?.tasks?.map((task) => (
                            <TableRow key={task.id}>
                              <TableCell padding={[1, 1, 1, 0.5]}>
                                <SoftBox lineHeight={1.4}>
                                  <SoftTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                  >
                                    {task.title}
                                  </SoftTypography>
                                  {/* <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  Notify when another user makes a booking for/with you.
                                </SoftTypography> */}
                                </SoftBox>
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "1")}
                                  color="success"
                                />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "2")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "3")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "4")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "5")}
                                  color="error"
                                />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "I")}
                                  color="warning"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <SoftBox pb={2}>
                  <SoftBox
                    minWidth="auto"
                    sx={{
                      overflow: "scroll",
                    }}
                  >
                    <Table
                      sx={{
                        minWidth: "36rem",
                      }}
                    >
                      <SoftBox component="thead">
                        <TableRow>
                          <TableCell
                            width="100%"
                            padding={[1.5, 3, 1.5, 0.5]}
                          />
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            S
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            U
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            I
                          </TableCell>
                        </TableRow>
                      </SoftBox>
                      <TableBody>
                        <TableRow>
                          <TableCell padding={[1, 1, 1, 0.5]}>
                            <SoftBox lineHeight={1.4}>
                              <SoftTypography
                                display="block"
                                variant="button"
                                fontWeight="regular"
                              >
                                Lesson Grade
                              </SoftTypography>
                              <SoftTypography
                                variant="caption"
                                color="text"
                                fontWeight="regular"
                              >
                                If a Task has a score of 5, the lesson can not
                                be graded &rdquo;S&rdquo;.
                              </SoftTypography>
                            </SoftBox>
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio
                              {...controlPropsLesson("S")}
                              color="success"
                              disabled={
                                taskGrades &&
                                Object.values(taskGrades).includes("5")
                              }
                            />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlPropsLesson("U")} color="error" />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio
                              {...controlPropsLesson("I")}
                              color="warning"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Notes
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftInput
                      inputRef={ref}
                      value={value}
                      placeholder="Write your notes"
                      multiline
                      rows={4}
                      onChange={onChange}
                      width="100%"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    disabled={
                      (taskGrades
                        ? Object.keys(taskGrades).length !==
                          lesson?.tasks?.length
                        : false) ||
                      !lessonGrade ||
                      isSubmitting
                    }
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

GradeLessonModal.propTypes = {
  lesson: PropTypes.object.isRequired,
};
