import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import ModalContainer from "src/components/ModalContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { convertStringToCamelCase } from "src/features/utils";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import { doc, setDoc } from "firebase/firestore";

const schema = yup
  .object({
    name: yup.string().required("A name for the document type is required."),
    hasExpiration: yup.boolean(),
  })
  .required();

export function AddDocumentTypeModal({ handleClose, selectedDocumentType }) {
  const { selectedClubId } = useClubs();
  const [hasExpiration, setHasExpiration] = useState(
    !!selectedDocumentType?.hasExpiration
  );
  const handleSetRequired = () => setHasExpiration((prev) => !prev);

  let defaultValues;
  if (selectedDocumentType) {
    defaultValues = {
      name: selectedDocumentType.name,
      hasExpiration: selectedDocumentType.hasExpiration,
    };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValues || {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("hasExpiration", hasExpiration);
  }, [hasExpiration]);

  const onSubmit = async (data) => {
    const id = convertStringToCamelCase(data.name);
    await setDoc(doc(getClubDocumentTypesCollection(selectedClubId), id), {
      id,
      name: data.name,
      hasExpiration: data.hasExpiration,
      deleted: false,
    });

    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {selectedDocumentType ? "Edit Document Type" : "Add Document Type"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Club Agreement"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={3}>
                <SoftBox ml={0.5} pt={5} display="inline-block">
                  <Checkbox
                    checked={hasExpiration}
                    onClick={handleSetRequired}
                    {...register("hasExpiration", {
                      required: false,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRequired}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Has Expiration?
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add Document Type
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddDocumentTypeModal.propTypes = {
  handleClose: PropTypes.func,
  selectedDocumentType: PropTypes.object,
};
