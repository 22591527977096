// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Images
import curved14 from "src/assets/images/curved-images/curved14.jpg";
import masterCardLogo from "src/assets/images/logos/mastercard.png";

function MasterCard({ color = "dark", number, holder, expires }) {
  const numbers = [...`${number}`];

  if (numbers.length < 4 || numbers.length > 4) {
    throw new Error(
      "Invalid value for the prop number, the value for the number prop shouldn't be greater than or less than 4 digits"
    );
  }

  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { linearGradient, rgba },
        boxShadows: { xl },
      }) => ({
        background: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.8),
              rgba(gradients[color].state, 0.8)
            )}, url(${curved14})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${curved14})`,
        boxShadow: xl,
      })}
    >
      <SoftBox p={2}>
        <SoftBox color="white" p={1} lineHeight={0} display="inline-block">
          <Icon fontSize="default">wifi</Icon>
        </SoftBox>
        <SoftTypography
          variant="h5"
          color="white"
          fontWeight="medium"
          sx={{
            mt: 3,
            mb: 5,
            pb: 1,
          }}
        >
          XXXX&nbsp;&nbsp;&nbsp;XXXX&nbsp;&nbsp;&nbsp;XXXX&nbsp;&nbsp;&nbsp;
          {number}
        </SoftTypography>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox display="flex" alignItems="center">
            <SoftBox mr={3} lineHeight={1}>
              <SoftTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
              >
                Card Holder
              </SoftTypography>
              <SoftTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {holder}
              </SoftTypography>
            </SoftBox>
            <SoftBox lineHeight={1}>
              <SoftTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
              >
                Expires
              </SoftTypography>
              <SoftTypography variant="h6" color="white" fontWeight="medium">
                {expires}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-end" width="20%">
            <SoftBox
              component="img"
              src={masterCardLogo}
              alt="master card"
              width="60%"
              mt={1}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the MasterCard
MasterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  number: PropTypes.number.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

export default MasterCard;
