export const getLocalUserSetting = (userId) =>
    JSON.parse(localStorage.getItem(`${userId}-user`) ?? '{}');

export const setLocalUserSetting = (userId, data) => {
    const savedUser = getLocalUserSetting(userId);
    if (savedUser) {
        const stringified = JSON.stringify({
            ...savedUser,
            ...data,
        });
        localStorage.setItem(`${userId}-user`, stringified);
    } else {
        localStorage.setItem(`${userId}-user`, JSON.stringify(data));
    }
};