// react-router components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftAvatar from "src/components/SoftAvatar";
import SoftBadge from "src/components/SoftBadge";

function AnnouncementCard({ by = {}, badge = {}, title, description, action }) {
  return (
    <Card sx={{ height: "100%", position: "relative" }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        p={2}
      >
        {by.image || by.name || by.date ? (
          <SoftBox display="flex" mr={2}>
            {by.image ? (
              <SoftAvatar
                src={by.image}
                alt={by.name}
                size="sm"
                variant="rounded"
              />
            ) : null}
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              ml={1}
            >
              {by.name ? (
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {by.name}
                </SoftTypography>
              ) : null}
              {by.date ? (
                <SoftTypography variant="caption" color="text">
                  {by.date}
                </SoftTypography>
              ) : null}
            </SoftBox>
          </SoftBox>
        ) : null}
        {badge.color && badge.label ? (
          <SoftBadge
            color={badge.color}
            badgeContent={badge.label}
            size="sm"
            container
          />
        ) : null}
      </SoftBox>
      <SoftBox pt={0.5} pb={2} px={2} height="100%">
        <SoftTypography variant="h6" color="primary">
          {title}
        </SoftTypography>
        <SoftBox mt={1} mb={2} lineHeight={0}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="lg"
        p={2}
        flexDirection="row-reverse"
      >
        {action.type === "internal" ? (
          <SoftButton
            component={Link}
            to={action.route}
            variant="text"
            color="primary"
          >
            {action.label}
          </SoftButton>
        ) : (
          <SoftButton
            component="a"
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="text"
            color="primary"
          >
            {action.label}
          </SoftButton>
        )}
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the AnnouncementCard
AnnouncementCard.propTypes = {
  by: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    label: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["enternal", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default AnnouncementCard;
