import { useClubs } from '../../features/club/ClubProvider';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { orderBy, query, where } from 'firebase/firestore';
import { getClubEventsCollection } from '../../features/club/collections';

function useClubEvents() {
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const { selectedClubId } = useClubs();
  const [events, setEvents] = useState([]);
  const [nowDate] = useState(new Date());

  const [eventsListSnapshot] = useCollection(
    query(
      getClubEventsCollection(selectedClubId),
      where('deleted', '==', false),
      where('endDate', '>=', nowDate),
      orderBy('endDate', 'desc')
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (!eventsListSnapshot) return;

    setEventsLoaded(true);
    setEvents(eventsListSnapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })) || []);
  }, [eventsListSnapshot]);

  return {
    events,
    eventsLoaded,
  };
}

export default useClubEvents;
