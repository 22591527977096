import { setRouter, useSoftUIController } from "src/context";
// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";

// Authentication layout components
import CoverLayout from "src/pages/auth/Layout";

// Images
import curved9 from "src/assets/images/login-bg2.jpg";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useClubs } from "src/features/club/ClubProvider";
import { useState } from "react";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";

function ClubSelect() {
  const [, dispatch] = useSoftUIController();
  const { userClubs, setSelectedClubId, selectedClubId } = useClubs();
  const [selectedClub, setSelectedClub] = useState("");

  const handleAuthCompletion = () => {
    setSelectedClubId(selectedClub);
    setRouter(dispatch, "main");
  };

  if (selectedClubId && selectedClubId !== "") {
    return <AuthLoadingPage />;
  }

  return (
    <CoverLayout
      title="Club/School selection"
      description="Select your club"
      image={curved9}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={2}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                ml={2}
                onChange={(e) => setSelectedClub(e.target.value)}
              >
                {userClubs.map((club) => (
                  <SoftBox mb={1} key={club.id}>
                    <FormControlLabel
                      value={club.id}
                      control={<Radio />}
                      label={club.data().name}
                    />
                  </SoftBox>
                ))}
              </RadioGroup>
            </FormControl>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleAuthCompletion}
            disabled={!selectedClub}
          >
            Select Club
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ClubSelect;
