import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "src/features/firebase/auth/utils";
import { useIdleTimer as useIdleTimerLib } from "react-idle-timer";
import SoftBox from "src/components/SoftBox";
import ModalWrapper from "src/modals/ModalWrapper/ModalWrapper";
import ModalContainer from "src/components/ModalContainer";
import SoftTypography from "src/components/SoftTypography";
import { useUser } from "src/features/user/UserProvider";
import SoftButton from "src/components/SoftButton";

const IdleTimerContext = createContext(null);
IdleTimerContext.displayName = "IdleTimerContext";

const timeout = 30_000;
const promptBeforeIdle = 15_000;

function IdleTimer() {
  const { signOut }: { signOut: Function } = useUser();

  const [remaining, setRemaining] = useState(timeout);
  const [openTimeoutPrompt, setOpenTimeoutPrompt] = useState(false);

  const onIdle = () => {
    console.log("User is idle");
    signOut();
    setOpenTimeoutPrompt(false);
  };

  const onActive = () => {
    console.log("User is Active");
    setOpenTimeoutPrompt(false);
  };

  const onPrompt = () => {
    console.log("User has been Prompted");
    setOpenTimeoutPrompt(true);
  };

  const {
    getRemainingTime,
    activate,
    start: startIdleTimer,
    pause: pauseIdleTimer,
  } = useIdleTimerLib({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startManually: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const getToken = async (firebaseUser: any) => {
    const token = await firebaseUser.getIdTokenResult();
    if (token.signInProvider === "custom") {
      console.log("User is logged in, starting idle timer");
      startIdleTimer();
    } else {
      pauseIdleTimer();
    }
  };

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        getToken(firebaseUser);
      } else {
        console.log("User is not logged in, stopping idle timer");
        pauseIdleTimer();
      }
    });

    return unsub;
  }, []);

  return (
    <div>
      <ModalWrapper isOpen={openTimeoutPrompt} onClose={handleStillHere}>
        <ModalContainer handleClose={handleStillHere}>
          <SoftBox p={2}>
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <SoftTypography variant="h3" fontWeight="bold">
                Are you still there?
              </SoftTypography>
              <SoftTypography variant="h6">
                If not, you will be logged out in {remaining} seconds
              </SoftTypography>
              <SoftButton
                sx={{ marginTop: "25px" }}
                color="primary"
                size="large"
                onClick={handleStillHere}
              >
                Im still here!
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </ModalContainer>
      </ModalWrapper>
    </div>
  );
}

function useIdleTimer() {
  const context = useContext(IdleTimerContext);

  if (!context) {
    throw new Error("useIdleTimer should be used inside the IdleTimerContext.");
  }

  return context;
}

export { IdleTimer, useIdleTimer };
