import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function Header({
  paymentRequest,
  onCancelPaymentRequest,
  onResendPaymentRequest,
  onMarkAsPaid,
}) {
  const amountPaid =
    paymentRequest?.payments?.reduce((acc, payment) => {
      if (payment?.status === "succeeded")
        return acc + (payment?.amount ?? 0) / 100;
      else return acc;
    }, 0) ?? 0;
  const paid = amountPaid >= paymentRequest.totalCost;
  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          {/* <SoftBox component="img" src={logoCT} width="25%" p={1} mb={1} /> */}
          <SoftTypography variant="h4" fontWeight="medium">
            {paymentRequest?.club?.name}
          </SoftTypography>
          {paymentRequest?.club?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {paymentRequest.club.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
            {paymentRequest?.pilot?.displayName && (
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billed to: {paymentRequest.pilot.displayName}
                </SoftTypography>
              </SoftBox>
            )}
            {paymentRequest?.pilot?.email && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {paymentRequest.pilot.email}
                </SoftTypography>
              </SoftBox>
            )}
            {paymentRequest?.pilot?.address && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {paymentRequest?.pilot?.address?.addressLine1}
                  <br />
                  {paymentRequest?.pilot?.address?.addressLine2 && (
                    <>
                      {paymentRequest?.pilot?.address?.addressLine2}
                      <br />
                    </>
                  )}
                  {paymentRequest?.pilot?.address?.city},{" "}
                  {paymentRequest?.pilot?.address?.state},{" "}
                  {paymentRequest?.pilot?.address?.zip}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Payment Request
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                Transaction no.{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {paymentRequest?.id}
                </span>
              </SoftTypography>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {paymentRequest?.createdAt
                    ? format(
                        paymentRequest.createdAt.toDate(),
                        "eee, MMM do yyyy"
                      )
                    : "N/A"}
                </span>
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              {onMarkAsPaid && !paid && (
                <WithPermissions permissions={systemPermissions.MARK_AS_PAID}>
                  <SoftButton
                    color="info"
                    onClick={onMarkAsPaid}
                    style={{ marginLeft: "10px" }}
                  >
                    Mark as Paid
                  </SoftButton>
                </WithPermissions>
              )}
              {onResendPaymentRequest && !paid && (
                <SoftButton
                  color="info"
                  onClick={onResendPaymentRequest}
                  style={{ marginLeft: "10px" }}
                >
                  Resend
                </SoftButton>
              )}
              {onCancelPaymentRequest && !paid && (
                <SoftButton
                  variant="outlined"
                  color="error"
                  onClick={onCancelPaymentRequest}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </SoftButton>
              )}
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Header.propTypes = {
  paymentRequest: PropTypes.object,
  onResendPaymentRequest: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  onCancelPaymentRequest: PropTypes.func,
};

export default Header;
