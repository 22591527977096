import { ChevronLeft, ChevronRight, FlightOutlined } from "@mui/icons-material";
import { Card, IconButton, Skeleton } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftSlider from "src/components/SoftSlider";
import SoftTypography from "src/components/SoftTypography";
import LessonSliderCard from "./components/LessonSliderCard";
import { useLms } from "src/features/lms/LmsProvider";
import { useUser } from "src/features/user/UserProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ProgressTracker from "./components/ProgressTracker";

export default function LMSWidget() {
  const { getStudentEnrollmentsData }: any = useLms();
  const { userId } = useUser();
  const [courses, setCourses]: any = useState([]);
  const [currentCourseIndex, setCurrentCourseIndex] = useState(0);
  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef: any = useRef();

  const enrolledCourse: any = useMemo(() => {
    return courses[currentCourseIndex];
  }, [courses, currentCourseIndex]);

  const initialSlideIndex: any = useMemo(() => {
    return (
      enrolledCourse?.progress?.stages?.byIndex[currentStageIndex]
        ?.lessonsCompleted || 0
    );
  }, [currentStageIndex, currentCourseIndex, courses]);

  const getCourses = async () => {
    const enrollments = await getStudentEnrollmentsData(userId);
    const enrolledCourses = enrollments.flatMap(
      (enrollment: any) => enrollment.enrolledCourses
    );
    setCourses(
      enrolledCourses.filter(
        (course: any) =>
          course.progress.stages.completed < course.progress.stages.total
      )
    );
    setIsLoading(false);
  };

  const isUpcomingLesson = (stageIndex: number, lessonIndex: number) => {
    const { progress }: any = courses[currentCourseIndex];
    return (
      stageIndex === progress?.stages?.completed &&
      lessonIndex === progress?.stages?.byIndex[stageIndex]?.lessonsCompleted
    );
  };

  const clickPrevStage = () => {
    if (currentStageIndex > 0) {
      setCurrentStageIndex(currentStageIndex - 1);
    }
  };

  const clickNextStage = () => {
    if (currentStageIndex < courses[currentCourseIndex]?.stages?.length - 1) {
      setCurrentStageIndex(currentStageIndex + 1);
    }
  };

  const clickPrevCourse = () => {
    if (currentCourseIndex > 0) {
      setCurrentCourseIndex(currentCourseIndex - 1);
    }
  };

  const clickNextCourse = () => {
    if (currentCourseIndex < courses.length - 1) {
      setCurrentCourseIndex(currentCourseIndex + 1);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    setCurrentStageIndex(enrolledCourse?.currentStageIndex);
  }, [enrolledCourse]);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(initialSlideIndex);
  }, [initialSlideIndex, sliderRef]);

  return (
    <Card>
      <SoftBox m={2} display="flex" justifyContent="space-between">
        <SoftBox>
          <SoftTypography fontSize="large" fontWeight="medium">
            {enrolledCourse?.course.label || "Loading..."}
          </SoftTypography>
          {isLoading ? (
            <Skeleton variant="text" width="5rem" />
          ) : (
            <SoftBox display="flex" gap="4px">
              <SoftTypography fontSize="small" fontWeight="regular">
                Lessons:
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="medium">
                {enrolledCourse?.progress.lessons.total}
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="regular">
                /
                <FlightOutlined
                  color="secondary"
                  sx={{ mb: "-2px", mx: "4px" }}
                />
                Current:
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="medium">
                {enrolledCourse?.progress.lessons.completed + 1}
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
        <SoftBox
          display="flex"
          alignItems="center"
          gap={1}
          pt={0.25}
          alignSelf="baseline"
        >
          <IconButton sx={{ p: 0 }} onClick={clickPrevCourse}>
            <ChevronLeft />
          </IconButton>
          {isLoading ? (
            <Skeleton variant="text" width="3rem" />
          ) : (
            <SoftTypography
              sx={{ textWrap: "nowrap" }}
              fontSize="small"
              fontWeight="regular"
            >
              {currentCourseIndex + 1}/{courses.length} courses
            </SoftTypography>
          )}
          <IconButton sx={{ p: 0 }} onClick={clickNextCourse}>
            <ChevronRight />
          </IconButton>
        </SoftBox>
      </SoftBox>
      <SoftBox px={2} pb={2}>
        {isLoading ? <Skeleton /> : <ProgressTracker course={enrolledCourse} />}
      </SoftBox>
      {isLoading ? (
        <Skeleton height="10rem" />
      ) : (
        <>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2}
          >
            <SoftTypography fontSize="large" fontWeight="medium">
              Stage {currentStageIndex + 1}
            </SoftTypography>
            <SoftBox display="flex">
              <IconButton sx={{ p: 0 }} onClick={clickPrevStage}>
                <ChevronLeft />
              </IconButton>
              <IconButton sx={{ p: 0 }} onClick={clickNextStage}>
                <ChevronRight />
              </IconButton>
            </SoftBox>
          </SoftBox>
          <SoftSlider
            sx={{ width: "100%" }}
            settings={{
              centerMode: true,
              infinite: true,
              initialSlide: initialSlideIndex,
              ref: sliderRef,
            }}
          >
            {enrolledCourse?.stages[currentStageIndex]?.lessons.map(
              (lesson: any) => (
                <LessonSliderCard
                  lesson={lesson}
                  key={lesson?.id}
                  isUpcoming={isUpcomingLesson(currentStageIndex, lesson.index)}
                />
              )
            )}
          </SoftSlider>
        </>
      )}
      {isLoading || !enrolledCourse?.graduationDate ? (
        <Skeleton variant="text" sx={{ m: 2, mt: 0 }} />
      ) : (
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
        >
          <SoftBox display="flex" gap={2}>
            <SoftBox display="flex" gap="4px">
              <SoftTypography fontSize="small" fontWeight="regular">
                Graduation date:
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="medium">
                {format(enrolledCourse?.graduationDate?.toDate(), "MM/dd/yy")}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" gap="4px">
              <SoftTypography fontSize="small" fontWeight="regular">
                Course Completion:
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="medium">
                {format(enrolledCourse?.completionDate?.toDate(), "MM/dd/yy")}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          {/* <SoftTypography
            fontSize="small"
            fontWeight="regular"
            sx={{ borderBottom: 1, cursor: "pointer" }}
          >
            Request Time Off
          </SoftTypography> */}
        </SoftBox>
      )}
    </Card>
  );
}
