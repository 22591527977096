import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import PaymentMethod from "./components/PaymentMethod";
import MasterCard from "src/components/CreditCards/MasterCard";

import { useUser } from "src/features/user/UserProvider";
import { getFunctions, httpsCallable } from "firebase/functions";

// Billing page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";

import { doc, updateDoc } from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";

function PaymentMethods() {
  const { user, userId } = useUser();
  const [card, setCard] = useState();
  const [cards, setCards] = useState([]);
  // const [bankAccounts, setBankAccounts] = useState([]);

  const fetchDefaultCard = async (cardId) => {
    const functions = getFunctions();
    const getDefaultCard = httpsCallable(functions, "getCustomerCard");
    getDefaultCard({
      cardId: cardId || user.defaultCard,
    }).then((result) => {
      setCard(result.data);
    });
  };

  const fetchCards = () => {
    const functions = getFunctions();
    const getCustomerCards = httpsCallable(functions, "getCustomerCards");
    getCustomerCards({
      customerId: user.stripeId,
    }).then((paymentMethods) => {
      const paymentCards = [];
      const paymentAccounts = [];
      paymentMethods.data.forEach((paymentMethod) => {
        if (paymentMethod.type === "card") {
          paymentCards.push(paymentMethod);
        }
        if (paymentMethod.type === "us_bank_account") {
          paymentAccounts.push(paymentMethod);
        }
      });
      setCards(paymentCards);
      // setBankAccounts(paymentAccounts);
    });
  };

  const setDefaultCard = async (cardId) => {
    await updateDoc(doc(getUsersCollection(), userId), { defaultCard: cardId });
    await fetchDefaultCard(cardId);
  };

  useEffect(() => {
    fetchCards();
    // fetchDefaultCard();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PaymentMethod
                    cards={cards}
                    fetchCards={fetchCards}
                    setDefaultCard={setDefaultCard}
                  />
                </Grid>
              </Grid>
            </Grid>
            {card && (
              <Grid item xs={12} xl={4}>
                <SoftTypography variant="h6" mb={3}>
                  Default Card:
                </SoftTypography>
                <MasterCard
                  number={card.card.last4}
                  holder={card.billing_details.name}
                  expires={`${card.card.exp_month}/${card.card.exp_year}`}
                />
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}

export default PaymentMethods;
