import Card from "@mui/material/Card";
import Slide, { SlideProps } from "@mui/material/Slide";
import { ReactNode, CSSProperties } from "react";
import SoftBox from "src/components/SoftBox";

interface IUniversalPopup extends Omit<SlideProps, "children"> {
  open: boolean;
  children: ReactNode;
  containerStyles?: CSSProperties;
  wrapperStyle?: CSSProperties;
}

function UniversalPopup({
  children,
  open,
  containerStyles = {},
  wrapperStyle = {},
  direction = "left",
  ...rest
}: IUniversalPopup) {
  return (
    <SoftBox
      sx={{
        ...wrapperStyle,
        zIndex: 999,
      }}
    >
      <Slide
        direction={direction}
        in={open}
        mountOnEnter
        unmountOnExit
        {...rest}
      >
        <Card sx={{ ...containerStyles, padding: "10px" }}>{children}</Card>
      </Slide>
    </SoftBox>
  );
}

export default UniversalPopup;
