import { List, ListItem, Stack } from "@mui/material";
import ImageLoader from "src/components/ImageLoader/ImageLoader";
import SoftTypography from "src/components/SoftTypography";
import { IAircraft } from "src/interfaces";
import { AnimatePresence } from "framer-motion";
import AnimatedItem from "src/components/AnimatedItem/AnimatedItem";

interface ISearchAircraftHistory {
  onAircraftSelected: (aircraft: IAircraft) => void;
  selectedAircraftId?: string;
  aircrafts: IAircraft[];
}
const selectedStyle = { border: "2px solid #5299A7", borderRadius: 2 };
function SearchAircraftHistory({
  aircrafts,
  onAircraftSelected,
  selectedAircraftId,
}: ISearchAircraftHistory) {
  return (
    <Stack
      direction="column"
      minWidth={230}
      padding={"5px 20px"}
      style={{
        background: "#F5F5F6",
        overflowY: "auto",
        transition: "all 2s ease",
      }}
    >
      <List>
        <AnimatePresence>
          {aircrafts.map((aircraft) => (
            <AnimatedItem key={aircraft.id}>
              <ListItem
                onClick={() => onAircraftSelected(aircraft)}
                sx={{
                  gap: "15px",
                  padding: "10px 0",
                  margin: "5px 0",
                  "&:hover": { background: "#C4C4C5", borderRadius: 2 },
                  ...(aircraft.id === selectedAircraftId ? selectedStyle : {}),
                }}
                className="clickable"
              >
                <ImageLoader
                  src={aircraft.photo}
                  loaderVariant="small"
                  style={{
                    width: 36,
                    borderRadius: 5,
                    height: 36,
                  }}
                />
                <SoftTypography fontSize="14px">
                  {aircraft.displayName}
                </SoftTypography>
              </ListItem>
            </AnimatedItem>
          ))}
        </AnimatePresence>
      </List>
    </Stack>
  );
}
export default SearchAircraftHistory;
