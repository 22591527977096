import { isPast } from "date-fns";
import { getDoc, getDocs, query, where } from "firebase/firestore";
import {
  getAircraftById,
  getSimulatorById,
} from "src/features/aircraft/collections";
import { getUserDocumentsCollection } from "src/features/user/collections";
import { IAircraft } from "src/interfaces";

export const useAircraftDocuments = () => {
  const checkUserDocuments = async (userId: string) => {
    const querySnapshot = await getDocs(
      query(getUserDocumentsCollection(userId), where("deleted", "==", false))
    );

    const userDocs = new Set(
      querySnapshot.docs.map((d) => d.data().documentType)
    );

    const expiredDocs = new Set(
      querySnapshot.docs
        .filter((d) => {
          const docData = d.data();
          const expirationDate =
            docData?.documentType === "medical"
              ? docData?.medicalData?.thirdExpiration?.toDate?.()
              : docData?.expireDate?.toDate?.();
          return expirationDate ? isPast(expirationDate) : false;
        })
        .map((d) => d.data().documentType)
    );

    return { userDocs, expiredDocs };
  };

  const getAircraftDocsAgainstUser = async ({
    aircraftId,
    userIds,
    clubId,
  }: {
    aircraftId: string;
    userIds: string[];
    clubId: string;
  }) => {
    if (!aircraftId || aircraftId === "") {
      return {
        usersHasAllDocs: true,
        missingAircraftDocs: [],
        expiredAircraftDocs: [],
      };
    }
    let aircraftDoc = await getDoc(getAircraftById(clubId, aircraftId));
    if (!aircraftDoc.exists()) {
      aircraftDoc = await getDoc(getSimulatorById(clubId, aircraftId));
      if (!aircraftDoc.exists()) {
        throw new Error("Aircraft not found");
      }
    }
    const aircraft = aircraftDoc.data() as IAircraft;

    const usersDocsPromises = userIds.map((userId) =>
      checkUserDocuments(userId)
    );
    const usersDocsResults = await Promise.all(usersDocsPromises);

    const combinedUserDocs = new Set<string>();
    const combinedExpiredDocs = new Set<string>();

    // Combine all user documents and expired docs
    usersDocsResults.forEach(({ userDocs, expiredDocs }) => {
      userDocs.forEach((doc) => combinedUserDocs.add(doc));
      expiredDocs.forEach((doc) => combinedExpiredDocs.add(doc));
    });

    // Check against aircraft.requiredDocs
    const missingAircraftDocs = (aircraft.requiredDocs ?? []).filter(
      (requiredDoc) => !combinedUserDocs.has(requiredDoc.id)
    );
    const expiredAircraftDocs = (aircraft.requiredDocs ?? []).filter(
      (requiredDoc) => combinedExpiredDocs.has(requiredDoc.id)
    );

    const usersHasAllDocs = missingAircraftDocs.length === 0;

    return {
      usersHasAllDocs,
      missingAircraftDocs,
      expiredAircraftDocs,
    };
  };

  return {
    getAircraftDocsAgainstUser,
  };
};
