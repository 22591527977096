import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import SoftButton from "src/components/SoftButton";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import SoftTypography from "src/components/SoftTypography";
import { getAircraftDocumentsCollection } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import { useDocuments } from "src/hooks/useDocument";
import { IAircraft, documentTypeUploadModal } from "src/interfaces";
import { openModal$ } from "src/modals/modalConfiguration";
import { FileIcon } from "react-file-icon";
import { format } from "date-fns";
import SoftBox from "src/components/SoftBox";
import { Download } from "@mui/icons-material";

interface IAircraftDocuments {
  aircraft: IAircraft;
}
function AircraftDocuments({ aircraft }: IAircraftDocuments) {
  const { selectedClubId } = useClubs();
  const { documents, loadingDocuments } = useDocuments({
    documentsCollection: getAircraftDocumentsCollection(
      selectedClubId,
      aircraft.id
    ),
    documentTypes: documentTypeUploadModal,
  });

  const downloadFile = async (downloadURL: string) => {
    try {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadURL;
      downloadLink.target = "_blank";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <Stack direction="column" gap="4px" flex={1}>
      <SoftLoader isLoading={loadingDocuments}>
        <Stack direction="row" justifyContent="space-between">
          <SoftTypography sx={{ fontWeight: 700, fontSize: 14 }}>
            {!documents.length && "No Documents"}
          </SoftTypography>
          <SoftButton
            variant="text"
            color="primary"
            size="small"
            className="clickable"
            onClick={() =>
              openModal$.next({
                modalName: "UPLOAD_DOCUMENT",
                modalProps: {
                  aircraft,
                  storagePath: getAircraftDocumentsCollection(
                    selectedClubId,
                    aircraft.id
                  ),
                },
              })
            }
          >
            <Icon>add</Icon>&nbsp; add new
          </SoftButton>
        </Stack>
        {documents.map((document) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            key={document.id}
            sx={{
              padding: "8px 0",
              "&:hover": { background: "#FAFAFA", cursor: "pointer" },
            }}
            gap="22px"
            onClick={() =>
              openModal$.next({
                modalName: "DOCUMENT_VIEWER",
                modalProps: {
                  url: document.url,
                  fileType: document.fileType,
                  fileName: document.fileName,
                },
              })
            }
          >
            <SoftBox sx={{ height: 36, width: 36 }}>
              <FileIcon extension={document.fileType} />
            </SoftBox>
            <Stack direction="column" gap="6px">
              <SoftTypography sx={{ fontWeight: 700, fontSize: 14 }}>
                {document.fileName}
              </SoftTypography>
              <SoftTypography sx={{ fontWeight: 500, fontSize: 13 }}>
                {document.documentTypeLabel}
              </SoftTypography>
              <SoftTypography sx={{ fontWeight: 700, fontSize: 13 }}>
                {format(document.createdAt.toDate(), "MM/dd/yyyy")}
              </SoftTypography>
            </Stack>
            <Download
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                downloadFile(document.url);
              }}
              sx={{
                marginLeft: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "15px",
                height: 30,
                width: 30,
              }}
            />
          </Stack>
        ))}
      </SoftLoader>
    </Stack>
  );
}
export default AircraftDocuments;
