import {
  AirplanemodeActive,
  AirplaneTicket,
  Event,
  ConfirmationNumber,
  Person,
  EventAvailable,
} from "@mui/icons-material";
import { format } from "date-fns";

export const MaintenanceDynamicProps = [
  {
    label: "Aircraft",
    accessor: (booking) => booking.extendedProps.aircraft.label,
    icon: AirplanemodeActive,
  },
  {
    label: "Reservation #",
    accessor: (booking) => booking.id,
    icon: ConfirmationNumber,
  },
  {
    label: "Type",
    accessor: (booking) => booking.extendedProps.type.label,
    icon: AirplaneTicket,
  },
  {
    label: "Created At",
    accessor: (booking) =>
      format(booking.createdAt.toDate(), "MM/dd/yyyy HH:mm"),
    icon: Event,
  },
  {
    label: "Created By",
    accessor: (booking) => booking?.createdBy?.displayName || "Unknown",
    icon: Person,
  },
  {
    label: "Expected RTS",
    accessor: (booking) => format(booking.end.toDate(), "MM/dd/yyyy HH:mm"),
    icon: EventAvailable,
  },
];
