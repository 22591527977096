// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
import { TableCell } from "@mui/material";

function MxTableHeaderCell({
  noBorder = false,
  label,
  align = "center",
  width = "30px",
  padding = "none",
}) {
  const { border } = colors;
  const { borderWidth } = borders;

  return (
    <TableCell
      align={align}
      padding={padding}
      width={width}
      border={noBorder ? 0 : `${borderWidth[1]} dotted ${border.light}`}
      style={{ padding: "10px 5px" }}
    >
      {label !== undefined ? (
        <SoftTypography
          sx={{ fontSize: "12px", fontWeight: 400, whiteSpace: "nowrap" }}
          variant="h6"
          color="text"
        >
          {label}
        </SoftTypography>
      ) : null}
    </TableCell>
  );
}

// Typechecking props for TableCell
MxTableHeaderCell.propTypes = {
  width: PropTypes.string,
  align: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.arrayOf(PropTypes.number),
  noBorder: PropTypes.bool,
};

export default MxTableHeaderCell;
