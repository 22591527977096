import { useEffect, useMemo, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "src/components/SoftTypography";
import Stack from "@mui/material/Stack";
import SoftButton from "src/components/SoftButton";
import DataTable from "src/components/Tables/DataTable";
import Footer from "src/components/Footer";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import AircraftInfo from "src/pages/resources/aircrafts/AircraftInfo";
import Modal from "@mui/material/Modal";
import NewAircraftDetails from "src/pages/resources/aircrafts/NewAircraftDetails";

import {
  getTableColumns,
  getTableColumnsAdmin,
  TableActions,
} from "src/pages/resources/aircrafts/tableColumns";
import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import EditAircraftDetails from "src/pages/resources/aircrafts/EditAircraftDetails";
import { getFunctions, httpsCallable } from "firebase/functions";
import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { useAircraft } from "src/hooks/useAircraft";
// import { aircraftModalOpener } from "../../../modals/AircraftModal/AircraftModal.config";
// import { isDev } from "src/features/utils";

function AircraftsCrud() {
  const [showPanel, setShowPanel] = useState(false);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const { aircrafts } = useAircraft();
  const [showNewAircraftModal, setShowNewAircraftModal] = useState(false);
  const [showEditAircraftModal, setShowEditAircraftModal] = useState(false);
  const { selectedClubId, currentUserPermission } = useClubs();
  const functions = getFunctions();
  const { hasAccess } = usePermissions();

  useEffect(() => {
    if (aircrafts?.length > 0) {
      if (selectedAircraft) {
        const found = aircrafts.find((a) => a.id === selectedAircraft.id);
        if (found) {
          setSelectedAircraft({ ...found });
        }
      } else {
        setSelectedAircraft({
          ...aircrafts[0],
        });
      }
    }
  }, [aircrafts]);

  const handleRowSelection = (aircraft) => {
    // isDev() && aircraftModalOpener({ aircraft });
    setSelectedAircraft(aircraft);
    setShowPanel(true);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  const handleAircraftDelete = (aircraftToDelete) => {
    const { deleteData } = entityCrudUtils();

    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deleteData({
            entity: getAircraftCollection(selectedClubId),
            pathSegmentsArr: [aircraftToDelete?.id],
          }).then((deleteResult) => {
            if (deleteResult?.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const handleToggleAircraft = async (aircraft) => {
    const { updateData } = entityCrudUtils();

    await updateData(
      {
        entity: getAircraftCollection(selectedClubId),
        pathSegmentsArr: [aircraft.id],
      },
      { disabled: !aircraft.disabled }
    );
  };

  const handleTrackAircraft = async (aircraft) => {
    if (aircraft.flightAwareAlertId) {
      const deleteFlightAwareAlert = httpsCallable(
        functions,
        "deleteFlightAwareAlert"
      );
      await deleteFlightAwareAlert({
        alertId: aircraft.flightAwareAlertId,
      });
    } else {
      const addFlightAwareAlert = httpsCallable(
        functions,
        "addFlightAwareAlert"
      );
      await addFlightAwareAlert({
        tailNumber: aircraft.tailNumber,
        aircraftId: aircraft.id,
      });
    }
  };

  const handleOrderAircraft = async (rows) => {
    const { updateData } = entityCrudUtils();

    rows.forEach(async (element, index) => {
      await updateData(
        {
          entity: getAircraftCollection(selectedClubId),
          pathSegmentsArr: [element.id],
        },
        { index }
      );
    });
  };

  const handleTableActions = (action, aircraft) => {
    switch (action) {
      case TableActions.DELETE:
        handleAircraftDelete(aircraft);
        break;
      case TableActions.EDIT:
        setSelectedAircraft(aircraft);
        setShowEditAircraftModal(true);
        break;
      case TableActions.TOGGLE:
        handleToggleAircraft(aircraft);
        break;
      case TableActions.TRACK:
        handleTrackAircraft(aircraft);
        break;
      default:
        break;
    }
  };

  const handleNewAircraftModalClose = () => setShowNewAircraftModal(false);
  const handleEditAircraftModalClose = () => setShowEditAircraftModal(false);
  const tableColumns = useMemo(
    () => getTableColumns(handleTableActions),
    [currentUserPermission]
  );
  const tableColumnsAdmin = useMemo(
    () => getTableColumnsAdmin(handleTableActions, hasAccess),
    [currentUserPermission]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={showNewAircraftModal}
        onClose={handleNewAircraftModalClose}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <NewAircraftDetails
          onCompleted={handleNewAircraftModalClose}
          onClose={handleNewAircraftModalClose}
          aircrafts={aircrafts}
        />
      </Modal>
      <Modal
        open={showEditAircraftModal}
        onClose={handleEditAircraftModalClose}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <EditAircraftDetails
          onCompleted={handleEditAircraftModalClose}
          aircraft={selectedAircraft}
          onClose={handleEditAircraftModalClose}
        />
      </Modal>
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Aircraft
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                View all aircraft.
              </SoftTypography>
            </SoftBox>
            <WithPermissions permissions={systemPermissions.ADD_AIRCRAFT}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => setShowNewAircraftModal(true)}
                >
                  + new aircraft
                </SoftButton>
                {/* <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton> */}
              </Stack>
            </WithPermissions>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            rightMinWidth={550}
          >
            <DataTable
              table={{
                columns: hasAccess([
                  systemPermissions.EDIT_AIRCRAFT,
                  systemPermissions.DELETE_AIRCRAFT,
                  systemPermissions.DISABLE_AIRCRAFT,
                  systemPermissions.TRACK_AIRCRAFT,
                ])
                  ? tableColumnsAdmin
                  : tableColumns,
                rows: aircrafts,
              }}
              entriesPerPage={{
                defaultValue: 15,
                entries: [10, 15, 20, 50],
              }}
              canSearch
              isSorted={false}
              onRowSelect={handleRowSelection}
              selectedRowId={selectedAircraft?.id}
              canDrag
              onRowMove={handleOrderAircraft}
            />
            <AircraftInfo aircraft={selectedAircraft} />
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_AIRCRAFT,
  AircraftsCrud
);
