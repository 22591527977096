import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftPhoneNumberInput from "src/components/SoftPhoneNumberInput";

// Settings page components
import FormField from "src/pages/user/account/components/FormField";

// Data
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftButton from "src/components/SoftButton";
import { doc, setDoc } from "firebase/firestore";
import { useUser } from "src/features/user/UserProvider";
import { getUsersCollection } from "src/features/user/collections";

const schema = yup
  .object({
    firstName: yup.string().required("A first name is required."),
    lastName: yup.string().required("A last name is required."),
    phoneNumber: yup.string(),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required."),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
  })
  .required();

function EmergencyContact() {
  const { user, userId } = useUser() as any;
  const emergencyContact = user?.emergencyContact ?? {};

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: emergencyContact.firstName,
      lastName: emergencyContact.lastName,
      email: emergencyContact.email,
      phoneNumber: emergencyContact.phoneNumber,
      addressLine1: emergencyContact.address?.addressLine1,
      addressLine2: emergencyContact.address?.addressLine2,
      city: emergencyContact.address?.city,
      state: emergencyContact.address?.state,
      zip: emergencyContact.address?.zip,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await setDoc(
        doc(getUsersCollection(), userId),
        {
          emergencyContact: {
            displayName: `${data.firstName} ${data.lastName}`,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber || "",
            address: {
              addressLine1: data.addressLine1 || "",
              addressLine2: data.addressLine2 || "",
              city: data.city || "",
              state: data.state || "",
              zip: data.zip || "",
            },
          },
        },
        { merge: true }
      );

      toastr.success("Your profile has been updated.", "Success");
    } catch (e) {
      toastr.error("There was an error updating your profile.", "Error");
    }
  };

  return (
    <Card
      id="emergency-contact"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Emergency Contact</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormField
                  {...field}
                  label="first name"
                  placeholder="John"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.firstName}
                />
              )}
            />
            {errors?.firstName?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.firstName.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="last name"
                  placeholder="Doe"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.lastName}
                />
              )}
            />
            {errors?.lastName?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.lastName.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="email"
                  onChange={onChange}
                  inputRef={ref}
                  placeholder="example@email.com"
                  inputProps={{
                    type: "email",
                  }}
                  value={value}
                />
              )}
            />
            {errors?.email?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.email.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Phone Number
                </SoftTypography>
              </SoftBox>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { onChange, value, ref: inputRef } }) => (
                  <SoftPhoneNumberInput
                    value={value}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    onChange={onChange}
                    inputRef={inputRef}
                  />
                )}
              />
              {errors?.phoneNumber?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.phoneNumber.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="addressLine1"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="address line 1"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.addressLine1?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.addressLine1.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="addressLine2"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="address line 2"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.addressLine2?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.addressLine2.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="city"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.city?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.city.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="state"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.state?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.state.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="zip"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.zip?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.zip.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={!isDirty}
            >
              Save
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default EmergencyContact;
