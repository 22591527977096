import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";

// Wizard page components
import About from "./components/About";
import BankAccount from "./components/BankAccount";
import Representative from "./components/Representative";
import Owners from "./components/Owners";
import Agreement from "./components/Agreement";

const schema = {
  About: yup
    .object({
      clubName: yup.string(),
      business: yup.object({
        type: yup.object().required("Business type is required."),
        structure: yup.object().when("type", {
          is: (value) =>
            ["company", "non-profit", "government_entity"].includes(
              value?.value
            ),
          then: yup.object().required("Business structure is required."),
          otherwise: yup.object(),
        }),
      }),
      company: yup
        .object({})
        .when("business", {
          is: (value) => value?.type?.value === "company",
          then: (elementSchema) =>
            elementSchema.shape({
              taxId: yup.string().required("Tax ID is required."),
              name: yup.string().required("Company name is required."),
              url: yup.string().required("Company URL is required."),
              phone: yup.string().required("Company phone is required."),
              address: yup.object({
                line1: yup.string().required("Address Line 1 is required."),
                city: yup.string().required("City is required."),
                state: yup
                  .string()
                  .length(2, "State should be a 2 letter code")
                  .required("State is required."),
                zip: yup
                  .string()
                  .length(5, "Zip code should be 5 digits")
                  .required("Zip code is required."),
              }),
            }),
        })
        .when("business", {
          is: (value) => value?.type?.value === "non_profit",
          then: (elementSchema) =>
            elementSchema.shape({
              url: yup.string().required("URL is required."),
              phone: yup.string().required("Phone is required."),
              address: yup.object({
                line1: yup.string().required("Address Line 1 is required."),
                city: yup.string().required("City is required."),
                state: yup
                  .string()
                  .length(2, "State should be a 2 letter code")
                  .required("State is required."),
                zip: yup
                  .string()
                  .length(5, "Zip code should be 5 digits")
                  .required("Zip code is required."),
              }),
            }),
        }),
    })
    .required(),
  "Bank Account": yup
    .object({
      account: yup.object({
        name: yup.string().required("Account holder name is required."),
        type: yup.object().required("Account type is required."),
        number: yup
          .string()
          .min(12, "Account number must be at least 12 digits")
          .required("Account number is required."),
        routing: yup
          .string()
          .min(9, "Routing number must be at least 9 digits")
          .required("Routing number is required."),
      }),
    })
    .required(),
  Representative: yup
    .object({
      representative: yup.object({
        relationship: yup.object({}).when("business", {
          is: (value) => {
            return value?.type?.value === "individual";
          },
          then: (elementSchema) =>
            elementSchema.shape({
              owner: yup.boolean(),
              executive: yup.boolean().when("owner", {
                is: false,
                then: yup
                  .boolean()
                  .required("You must be either an owner or executive."),
                otherwise: yup.boolean(),
              }),
              jobTitle: yup.string().required("Job title is required."),
              percentOwnership: yup
                .number()
                .required("Percent ownership is required.")
                .max(100, "Percent ownership cannot be greater than 100%.")
                .min(0, "Percent ownership cannot be less than 0%."),
            }),
        }),
        firstName: yup.string().required("First name is required."),
        lastName: yup.string().required("Last name is required."),
        email: yup.string().email().required("Email is required."),
        phone: yup.string().required("Phone number is required."),
        ssn: yup.string().required("SSN is required."),
      }),
    })
    .required(),
  Owners: yup.object({
    owners: yup
      .array()
      .min(1, "You must have at least one owner.")
      .required("Owners are required."),
  }),
  Agreement: yup
    .object({
      stripeAgreement: yup
        .boolean()
        .oneOf([true], "You must accept the terms and conditions."),
    })
    .required(),
};

function getStepContent(
  stepIndex,
  register,
  control,
  watch,
  errors,
  resetField,
  setValue
) {
  switch (stepIndex) {
    case 0:
      return (
        <About
          register={register}
          formErrors={errors}
          formControl={control}
          formWatch={watch}
          resetField={resetField}
        />
      );
    case 1:
      return (
        <BankAccount
          register={register}
          formErrors={errors}
          formControl={control}
          formWatch={watch}
        />
      );
    case 2:
      return (
        <Representative
          register={register}
          formErrors={errors}
          formControl={control}
          formWatch={watch}
        />
      );
    case 3:
      if (
        watch("business.structure")?.value === "multi_member_llc" ||
        watch("business.structure")?.value === "private_partnership" ||
        watch("business.structure")?.value === "private_corporation" ||
        watch("business.structure")?.value === "unincorporated_association"
      ) {
        return (
          <Owners
            register={register}
            setValue={setValue}
            formErrors={errors}
            formControl={control}
            formWatch={watch}
            owners={watch("owners") || []}
          />
        );
      }
      return (
        <Agreement
          register={register}
          formErrors={errors}
          formControl={control}
          formWatch={watch}
          formSetValue={setValue}
        />
      );
    case 4:
      return (
        <Agreement
          register={register}
          formErrors={errors}
          formControl={control}
          formWatch={watch}
          formSetValue={setValue}
        />
      );
    default:
      return null;
  }
}

function SetupWizard({ onSubmit, isSubmitting }) {
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepTitle, setActiveStepTitle] = useState("About");
  const [steps, setSteps] = useState([]);

  const isLastStep = activeStep === steps.length - 1;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    resetField,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema[activeStepTitle]),
  });

  const handleNext = async () => {
    const valid = await trigger();
    if (valid) {
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    if (activeStep && steps) {
      setActiveStepTitle(steps[activeStep]);
    }
  }, [activeStep, steps]);

  const businessStructure = watch("business.structure")?.value;
  useEffect(() => {
    const stepsArr = ["About", "Bank Account", "Representative", "Agreement"];
    if (
      businessStructure === "multi_member_llc" ||
      businessStructure === "private_partnership" ||
      businessStructure === "private_corporation" ||
      businessStructure === "unincorporated_association"
    ) {
      stepsArr.splice(3, 0, "Owners");
    }

    setSteps(stepsArr);
  }, [businessStructure]);

  return (
    <SoftBox pb={3}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps &&
              steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
          </Stepper>
          <Card>
            <SoftBox
              p={2}
              component="form"
              role="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <SoftBox>
                {getStepContent(
                  activeStep,
                  register,
                  control,
                  watch,
                  errors,
                  resetField,
                  setValue
                )}
                <SoftBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  {activeStep === 0 ? (
                    <SoftBox />
                  ) : (
                    <SoftButton
                      variant="gradient"
                      color="light"
                      disabled={isSubmitting}
                      onClick={handleBack}
                    >
                      back
                    </SoftButton>
                  )}
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    disabled={isSubmitting}
                    onClick={!isLastStep ? handleNext : handleSubmit(onSubmit)}
                  >
                    {isLastStep ? "send" : "next"}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

SetupWizard.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default SetupWizard;
