// import * as toastr from "toastr";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Settings page components
import FormField from "src/pages/user/account/components/FormField";

function ChangePassword() {
  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <SoftTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
      </SoftBox>
    );
  });

  const updatePassword = () => {
    // Need to authenticate again with the password supplied, if correct then we call the update password. Something like this:
    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword("email", "oldPassword")
    //   .then(() => {
    //     firebase
    //       .auth()
    //       .currentUser.updatePassword("newPassword")
    //       .then(() => {
    //         toastr.success("Your password was updated.", "Success");
    //       })
    //       .catch((err) => {
    //         toastr.error(
    //           "There was an error setting your new password.",
    //           "Error"
    //         );
    //         console.error(err);
    //       });
    //   })
    //   .catch((err) => {
    //     toastr.error("You have entered an incorrect password.", "Error");
    //     console.error(err);
    //   });
  };

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">Change Password</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="current password"
              placeholder="Current Password"
              inputProps={{
                type: "password",
                autoComplete: "",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="new password"
              placeholder="New Password"
              inputProps={{
                type: "password",
                autoComplete: "",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="confirm new password"
              placeholder="Confirm Password"
              inputProps={{
                type: "password",
                autoComplete: "",
              }}
            />
          </Grid>
        </Grid>
        <SoftBox mt={6} mb={1}>
          <SoftTypography variant="h5">Password requirements</SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox
            component="ul"
            m={0}
            pl={3.25}
            mb={{
              xs: 8,
              sm: 0,
            }}
          >
            {renderPasswordRequirements}
          </SoftBox>
          <SoftBox ml="auto">
            <SoftButton
              variant="gradient"
              onClick={updatePassword}
              color="dark"
              size="small"
            >
              update password
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
