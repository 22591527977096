import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// import PieChart from './components/PieChart';
// import polarChartData from 'template-app/layouts/pages/charts/data/polarChartData';

import { useClubs } from "src/features/club/ClubProvider";
import {
  collection,
  getDocs,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { getAircraftCollection } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { calculateReminderRemainingTime } from "src/features/aircraft/utils";
import { add, format } from "date-fns";
import MxTableCell from "./components/MxTableCell/MxTableCell";
import MxTableHeaderCell from "./components/MxTableCell/MxTableHeaderCell";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Icon } from "@mui/material";
import colors from "src/assets/theme/base/colors";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even) td": {
    backgroundColor: theme.palette.action.hover,
  },
  "td:first-of-type": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "td:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    border: "none !important",
  },
  "&, td, th": {
    border: "none",
  },
  td: {
    borderRight: `1px solid ${theme.palette.border.light}`,
  },
}));

function MxOverview() {
  const { selectedClubId } = useClubs();
  // const [aircraft, setAircraft] = useState([]);
  // const [rowsTemplates, setRowsTemplates] = useState([]);
  const [rowsAircraft, setRowsAircraft] = useState([]);
  const handle = useFullScreenHandle();
  const { warning, error } = colors;

  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(getAircraftCollection(selectedClubId), false);

  const fetchAircraftReminders = async (aircrafts) => {
    let aircraftRows = [];
    const aircraftSquawksCount = await fetchAircraftSquawksCount(aircrafts);
    await Promise.all(
      aircrafts.map(async (aircraftDoc) => {
        const remindersDocsArray = await getDocs(
          collection(
            getAircraftCollection(selectedClubId),
            aircraftDoc.id,
            "maintenanceReminders"
          )
        );

        const reminders = remindersDocsArray.docs
          .filter((doc) => !doc.data().deleted)
          .map((reminderDoc) => {
            const reminder = reminderDoc.data();
            const remaining = calculateReminderRemainingTime({
              ...reminder,
              aircraft: aircraftDoc,
              timeBasedLastCompletedOn:
                reminder?.timeBasedLastCompletedOn?.toDate(),
            });

            return {
              ...reminderDoc.data(),
              remaining,
              id: reminderDoc.id,
            };
          });
        reminders.sort((a, b) => {
          if (a.remaining.percentComplete > b.remaining.percentComplete) {
            return -1;
          }
          if (a.remaining.percentComplete < b.remaining.percentComplete) {
            return 1;
          }
          return 0;
        });

        aircraftRows.push({
          ...aircraftDoc,
          id: aircraftDoc.id,
          reminders,
          squawks: aircraftSquawksCount?.[aircraftDoc.id],
        });
      })
    );
    setRowsAircraft(aircraftRows);
  };

  const fetchAircraftSquawksCount = async (aircrafts) => {
    let squawkCounts = {};
    await Promise.all(
      aircrafts.map(async (aircraftDoc) => {
        const squawkCount = await getCountFromServer(
          query(
            collection(
              getAircraftCollection(selectedClubId),
              aircraftDoc.id,
              "squawks"
            ),
            where("resolved", "==", false)
          )
        );
        squawkCounts[aircraftDoc.id] = squawkCount.data().count;
      })
    );
    return squawkCounts;
  };

  useEffect(() => {
    if (aircraftLoaded && allAircraft) {
      // setAircraft(allAircraft);
      fetchAircraftReminders(allAircraft);
      fetchAircraftSquawksCount(allAircraft);
    }
  }, [allAircraft]);

  const getReminderOutput = (reminder, aircraft) => {
    if (!reminder) return;
    const remainingTime = calculateReminderRemainingTime({
      ...reminder,
      aircraft,
      timeBasedLastCompletedOn: reminder?.timeBasedLastCompletedOn?.toDate(),
    });

    if (
      reminder?.type?.value === "time" &&
      reminder?.timeBasedLastCompletedOn &&
      reminder?.timeBasedRemindEveryUnit?.value &&
      reminder?.timeBasedRemindEvery
    ) {
      const primary = format(
        add(reminder.timeBasedLastCompletedOn.toDate(), {
          [`${reminder.timeBasedRemindEveryUnit?.value}`]:
            reminder.timeBasedRemindEvery,
        }),
        "MMM yy"
      );

      const secondary = "";
      const color =
        remainingTime.percentRemaining <= 0
          ? error.main
          : remainingTime.percentRemaining <= 10
          ? warning.main
          : undefined;

      return { primary, secondary, color };
    }

    if (
      reminder?.type?.value === "meter" &&
      reminder?.meterBasedLastCompleted &&
      reminder?.meterBasedRemindEvery
    ) {
      const primary =
        reminder?.meterBasedLastCompleted + reminder?.meterBasedRemindEvery;

      const secondary = remainingTime?.timeRemaining;
      const color =
        remainingTime.percentRemaining <= 0
          ? error.main
          : remainingTime.percentRemaining <= 10
          ? warning.main
          : null;
      return { primary, secondary, color };
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FullScreen handle={handle}>
        <SoftBox my={handle.active ? 0 : 3}>
          <Grid container spacing={handle.active ? 0 : 3}>
            <Grid item xs={12}>
              <Card sx={{ height: handle.active ? "100vh" : "inherit" }}>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  pl={3}
                  pr={3}
                  pt={3}
                >
                  <SoftBox lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      MX Overview
                    </SoftTypography>
                  </SoftBox>
                  {handle.active ? (
                    <IconButton variant="text" onClick={handle.exit}>
                      <Icon>fullscreen_exit</Icon>
                    </IconButton>
                  ) : (
                    <IconButton variant="contained" onClick={handle.enter}>
                      <Icon>fullscreen</Icon>
                    </IconButton>
                  )}
                </SoftBox>
                <TableContainer component={Paper} sx={{ padding: 2 }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <StyledTableRow>
                        <MxTableHeaderCell noBorder label="Tail No" />
                        <MxTableHeaderCell label={"Model"} />
                        <MxTableHeaderCell label={"Squawks"} />
                        <MxTableHeaderCell label={"Tach"} />
                        <MxTableHeaderCell label={"Hobbs"} />
                        <MxTableHeaderCell label={"100hr Due"} />
                        <MxTableHeaderCell label={"50hr Due"} />
                        <MxTableHeaderCell label={"Annual Due"} />
                        <MxTableHeaderCell label={"Xponder Due"} />
                        <MxTableHeaderCell label={"Pitot Static Due"} />
                        <MxTableHeaderCell label={"ELT Due"} />
                        <MxTableHeaderCell label={"ELT Batt Due"} />
                        <MxTableHeaderCell label={"Registration"} />
                        <MxTableHeaderCell label={"TSMOH"} />
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {rowsAircraft.map((row) => {
                        const annual = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "annual"
                          ),
                          row
                        );
                        const xponder = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "xponder"
                          ),
                          row
                        );
                        const pStatic = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "pitot-static"
                          ),
                          row
                        );
                        const elt = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "elt"
                          ),
                          row
                        );
                        const eltBat = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "elt-battery"
                          ),
                          row
                        );
                        const registration = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "registration"
                          ),
                          row
                        );

                        const tsmoh = getReminderOutput(
                          row?.reminders?.find(
                            (value) =>
                              value?.template?.value === "engine-overhaul"
                          ),
                          row
                        );

                        const reminder100hr = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "100hr"
                          ),
                          row
                        );

                        const reminder50hr = getReminderOutput(
                          row?.reminders?.find(
                            (value) => value?.template?.value === "50hr"
                          ),
                          row
                        );

                        return (
                          <StyledTableRow
                            key={row.id}
                            // sx={{
                            //   "&:last-child td, &:last-child th": { border: 0 },
                            //   border: "none",
                            // }}
                          >
                            <MxTableCell
                              scope="row"
                              primaryText={`${row.tailNumber}`}
                            />
                            <MxTableCell
                              align="right"
                              primaryText={`${row.details.model}`}
                            />
                            <MxTableCell
                              align="right"
                              primaryText={`${row.squawks}`}
                              color={row.squawks > 0 ? warning.main : undefined}
                            />
                            <MxTableCell
                              align="right"
                              primaryText={`${row.tach1}`}
                            />
                            <MxTableCell
                              align="right"
                              primaryText={`${row.hobbs}`}
                            />
                            <MxTableCell
                              align="right"
                              color={reminder100hr?.color}
                              primaryText={`${reminder100hr?.primary}`}
                              secondaryText={`${reminder100hr?.secondary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={reminder50hr?.color}
                              primaryText={`${reminder50hr?.primary}`}
                              secondaryText={`${reminder50hr?.secondary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={annual?.color}
                              primaryText={`${annual?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={xponder?.color}
                              primaryText={`${xponder?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={pStatic?.color}
                              primaryText={`${pStatic?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={elt?.color}
                              primaryText={`${elt?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={eltBat?.color}
                              primaryText={`${eltBat?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={registration?.color}
                              primaryText={`${registration?.primary}`}
                            />
                            <MxTableCell
                              align="right"
                              color={tsmoh?.color}
                              primaryText={`${tsmoh?.primary}`}
                            />
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </FullScreen>
      <Footer />
    </DashboardLayout>
  );
}

export default MxOverview;
