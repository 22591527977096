import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function Agreement({ register, formErrors, formWatch }) {
  const handleOpenTerms = () => {
    window.open("https://stripe.com/legal/connect-account", "_blank");
  };

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Almost there...
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          One last box to tick! Please confirm that you have read and agree to
          the terms and conditions.
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          <Grid item xs={12} md={8}>
            <SoftBox>
              <Checkbox
                defaultChecked={formWatch("stripeAgreement")}
                {...register("stripeAgreement", {
                  required: true,
                })}
              />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color={formErrors?.stripeAgreement?.message && "error"}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                onClick={handleOpenTerms}
                fontWeight="bold"
                color={formErrors?.stripeAgreement?.message && "error"}
              >
                Stripe Connected Account Agreement
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Agreement.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
};

export default Agreement;
