import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

function Representative({ register, formErrors, formWatch, formControl }) {
  const businessType = formWatch("business.type")?.value;

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        {businessType === "individual" ? (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h5" fontWeight="regular">
                Representative Details
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
              This account needs to be activated by a representative. You will
              need to provide your details and agree to the terms in the next
              step.
            </SoftTypography>
          </>
        ) : (
          <>
            <SoftBox mb={1}>
              <SoftTypography variant="h5" fontWeight="regular">
                {businessType === "individual"
                  ? "Representative Details"
                  : "Company Representative Details"}
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
              This account needs to be activated by a representative with
              significant responsibility to control, manage, or direct the
              organization; and is authorized by the organization to agree to
              the terms.
            </SoftTypography>
            <SoftTypography variant="body2" fontWeight="bold" color="text">
              The representative must either be an owner or an executive.
            </SoftTypography>
          </>
        )}
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          {businessType !== "individual" && (
            <Grid container spacing={1} mt={2} mb={2} pl={3} pr={3}>
              <Grid item xs={12}>
                <SoftBox>
                  <Checkbox
                    defaultChecked={formWatch(
                      "representative.relationship.owner"
                    )}
                    {...register("representative.relationship.owner", {
                      required: true,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color={
                      formErrors?.representative?.relationship?.owner
                        ?.message && "error"
                    }
                  >
                    This person owns 25% or more of the company.
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox>
                  <Checkbox
                    defaultChecked={formWatch(
                      "representative.relationship.executive"
                    )}
                    {...register("representative.relationship.executive", {
                      required: true,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color={
                      formErrors?.representative?.relationship?.executive
                        ?.message && "error"
                    }
                  >
                    This person is an executive or senior manager with
                    significant management responsibility.
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.firstName", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.firstName?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.firstName.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.lastName", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.lastName?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.lastName.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {businessType !== "individual" && (
                <>
                  <Grid item xs={12} md={8}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Job Title
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        {...register("representative.relationship.jobTitle", {
                          required: true,
                        })}
                      />
                      {formErrors?.representative?.relationship?.jobTitle
                        ?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {
                            formErrors.representative.relationship.jobTitle
                              .message
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox lineHeight={1.25}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          % Ownership
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={formControl}
                        name="representative.relationship.percentOwnership"
                        render={({
                          field: { onChange, value, ref: inputRef },
                        }) => (
                          <SoftCurrencyInput
                            style={{
                              ".MuiInputAdornment-root": { right: "-54px" },
                            }}
                            currencySymbol="%"
                            placeholder="0"
                            decimalPlaces={0}
                            decimalPlacesShownOnBlur={0}
                            decimalPlacesShownOnFocus={0}
                            outputFormat="number"
                            adornmentOnRight
                            onBlur={onChange}
                            inputRef={inputRef}
                            value={value}
                          />
                        )}
                      />
                      {formErrors?.representative?.relationship
                        ?.percentOwnership?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {
                            formErrors.representative.relationship
                              .percentOwnership.message
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={4}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Date Of Birth
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.dob", {
                      required: true,
                    })}
                    type="date"
                  />
                  {formErrors?.representative?.dob?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.dob.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={8}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.email", {
                      required: true,
                    })}
                    type="email"
                  />
                  {formErrors?.representative?.email?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.email.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={8}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Contact Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.phone", {
                      required: true,
                    })}
                    type="phone"
                  />
                  {formErrors?.representative?.phone?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.phone.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last 4 Digits of SSN
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.ssn", {
                      required: true,
                    })}
                    type="number"
                  />
                  {formErrors?.representative?.ssn?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.ssn.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 1
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.address.line1", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.address?.line1?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.address.line1.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 2
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.address.line2", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.address?.line2?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.address.line2.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.address.city", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.address?.city?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.address.city.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={2}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.address.state", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.address?.state?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.address.state.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Zip
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    {...register("representative.address.zip", {
                      required: true,
                    })}
                  />
                  {formErrors?.representative?.address?.zip?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {formErrors.representative.address.zip.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Representative.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
};

export default Representative;
