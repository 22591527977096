import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import MemberNameCell from "./MemberNameCell";
import { format } from "date-fns";
import { getProfileImageURL } from "src/features/utils";
import Switch from "@mui/material/Switch";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
};

export const getTableColumns = (onAction, hasAccess) => [
  {
    Header: "name",
    accessor: "displayName",
    width: "30%",
    Cell: ({ row: { original } }) => {
      const { displayName } = original;
      return (
        <MemberNameCell
          name={displayName}
          image={getProfileImageURL({ original })}
        />
      );
    },
  },
  {
    Header: "email",
    accessor: "email",
  },
  {
    Header: "date added",
    accessor: "createdAt",
    width: "15%",
    Cell: ({ value }) => format(value.toDate(), "MM/dd/yyyy"),
  },
  {
    Header: "Hidden",
    accessor: "hidden",
    width: "15%",
    align: "center",
    Cell: ({ row: { original } }) => {
      const { hidden } = original.preferences;
      return (
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <Switch
            onChange={() => onAction(TableActions.TOGGLE, original)}
            checked={hidden}
            disabled={!hasAccess([systemPermissions.DISABLE_INSTRUCTOR])}
          />
        </SoftBox>
      );
    },
  },
  {
    Header: "Actions",
    width: "8%",
    Cell: ({ row: { original } }) => (
      <SoftBox sx={{ display: "flex" }}>
        <WithPermissions permissions={systemPermissions.DELETE_INSTRUCTOR}>
          <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAction(TableActions.DELETE, original);
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </WithPermissions>
      </SoftBox>
    ),
  },
];
