// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import typography from "src/assets/theme/base/typography";
import borders from "src/assets/theme/base/borders";
import boxShadows from "src/assets/theme/base/boxShadows";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "src/assets/theme/functions/pxToRem";

// @emotion/react components
import { keyframes } from "@emotion/react";

export default (selectSize, borderless, errored, customStyles) => {
  const {
    dark,
    white,
    text,
    light,
    inputColors,
    gradients,
    transparent,
    error,
  } = colors;
  const { size, fontWeightRegular } = typography;
  const { borderWidth, borderRadius } = borders;
  const { md } = boxShadows;

  let selectSizeValue;

  if (selectSize === "small") {
    selectSizeValue = pxToRem(32);
  } else if (selectSize === "large") {
    selectSizeValue = pxToRem(48);
  } else {
    selectSizeValue = pxToRem(40);
  }

  const borderStyles = borderless
    ? {
        border: "none",
        borderBottom: `0.0625rem solid ${
          errored ? inputColors.error : inputColors.borderColor.main
        }`,
        borderRadius: 0,
      }
    : {
        border: `${borderWidth[1]} solid ${
          errored ? inputColors.error : inputColors.borderColor.main
        }`,
      };

  // animations
  const prespective = keyframes`
    from {
      opacity: 0;
      transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 0, 5px);
    }
  `;

  return {
    control: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: selectSizeValue,
      padding: 0,
      fontSize: selectSize === "small" ? size.xs : size.sm,
      fontWeight: fontWeightRegular,
      backgroundColor: transparent.main,
      backgroundClip: "padding-box",
      appearance: "none",
      borderRadius: borderRadius.md,
      transition: "box-shadow 150ms ease, border-color 150ms ease",
      cursor: "pointer",
      ...borderStyles,
      ...(customStyles?.control ?? {}),
    }),

    valueContainer: () => ({
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "max-content",
      padding: `0 ${pxToRem(12)}`,
      color: dark.main,
    }),

    placeholder: () => ({
      position: "absolute",
      top: selectSize === "small" ? "52%" : "51%",
      transform: "translateY(-50%)",
      marginLeft: 0,
      marginRight: 0,
      color: dark.main,
      opacity: 0.5,
    }),

    singleValue: () => ({
      position: "absolute",
      top: selectSize === "small" ? "52%" : "51%",
      transform: "translateY(-50%)",
      color: text.main,
      height: "1.625em",
      overflow: "hidden",
      textOverflow: "ellipses",
    }),

    input: () => ({
      color: dark.main,
      position: "relative",
      margin: 0,
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      display: "flex",
      padding: pxToRem(12),

      "& svg": {
        fill: text.main,
        width: pxToRem(14),
        height: pxToRem(14),
        strokeWidth: 1,
        stroke: errored ? error.main : text.main,
        transform: state.selectProps.menuIsOpen
          ? "rotate(180deg)"
          : "rotate(0)",
        transition: "transform 250ms ease",
      },
    }),

    menu: (provided) => ({
      ...provided,
      minWidth: pxToRem(160),
      boxShadow: md,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: errored ? error.main : text.main,
      zIndex: 25,
      textAlign: "left",
      backgroundColor: white.main,
      borderRadius: borderRadius.md,
      transformOrigin: "50% 0",
      animation: `${prespective} 250ms ease forwards !important`,
      ...(customStyles?.menu ?? {}),
    }),

    menuList: (provided, state) => ({
      ...provided,
      maxHeight: pxToRem(130),
      overflowY: "scroll",
      padding: 0,

      "& div:first-of-type": {
        backgroundColor: transparent.main,

        "&:active": {
          backgroundColor: light.main,
        },

        "&:hover, &:focus": {
          backgroundColor: state.isDisabled ? transparent.main : light.main,
          color: state.isDisabled ? "currentColor" : dark.main,

          "&:after": {
            content: state.isDisabled && "''",
            opacity: 0.5,
          },
        },
      },
      ...(customStyles?.menuList ?? {}),
    }),

    option: (provided, state) => ({
      ...provided,
      position: "relative",
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(4.8)} ${pxToRem(16)} ${pxToRem(4.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      color: text.main,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      opacity: state.isDisabled ? 0.5 : 1,
      userSelect: state.isDisabled ? "none" : "auto",
      transition: "background-color 300ms ease, color 300ms ease",

      // '&:after': {
      //   content: "'Press to select'",
      //   display: 'block',
      //   fontSize: size.xs,
      //   position: 'absolute',
      //   right: pxToRem(10),
      //   top: '52%',
      //   color: text.main,
      //   opacity: 0,
      //   transform: 'translateY(-50%)',
      //   transition: 'opacity 300ms ease',
      // },

      "&:hover, &:focus": {
        backgroundColor: state.isDisabled ? transparent.main : light.main,
        color: state.isDisabled ? "currentColor" : dark.main,

        "&:after": {
          content: state.isDisabled && "''",
          opacity: 0.5,
        },
      },
      ...(customStyles?.option ?? {}),
    }),

    multiValue: (provided) => ({
      ...provided,
      margin: 0,
      marginRight: pxToRem(4),
      borderRadius: borderRadius.section,
      display: "flex",
      alignItems: "center",
      backgroundColor: gradients.dark.state,
      color: white.main,
      padding: `${pxToRem(2)} 0 ${pxToRem(2)} ${pxToRem(4)}`,

      "& div:first-of-type": {
        color: white.main,
        paddingTop: pxToRem(4),
        paddingBottom: pxToRem(2),
      },

      "& div:last-of-type": {
        paddingTop: pxToRem(1.5),
        opacity: 0.8,

        "&:hover": {
          backgroundColor: transparent.main,
          color: white.main,
        },
      },
    }),
  };
};
