// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Divider from "@mui/material/Divider";
import UpcomingEventsList from "./UpcomingEventsList";
import useClubEvents from "../eventsHelper";
import SoftButton from "../../SoftButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function UpcomingEventsWidget() {
  const { events } = useClubEvents();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        lineHeight={1}
      >
        <SoftBox pt={2} px={2} lineHeight={1}>
          <SoftTypography variant="h6" fontWeight="medium">
            Upcoming Events
          </SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="medium">
            Don&rsquo;t miss out!
          </SoftTypography>
        </SoftBox>
        <WithPermissions permissions={systemPermissions.VIEW_EVENTS}>
          <SoftButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              navigate(`/events`);
            }}
          >
            <Icon>add</Icon>&nbsp; View All
          </SoftButton>
        </WithPermissions>
      </SoftBox>
      <Divider />
      <SoftBox p={2}>
        <UpcomingEventsList events={events} />
      </SoftBox>
    </Card>
  );
}

export default UpcomingEventsWidget;
