// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Setting pages components
import TableCell from "src/components/TableCell";

function Notifications() {
  return (
    <Card id="notifications">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Notifications</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Choose how you receive notifications. These notification settings
          apply to the things you’re watching.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          minWidth="auto"
          sx={{
            overflow: "scroll",
          }}
        >
          <Table
            sx={{
              minWidth: "36rem",
            }}
          >
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  Push
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                  SMS
                </TableCell>
              </TableRow>
            </SoftBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      New Bookings
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when another user makes a booking for/with you.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Booking Reminders
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Reminders for upcoming bookings.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Booking Modifications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a modification is made to a reservation.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Message Received
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when another user messages you.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Club/School Notications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a club/school posts a notification/update.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >
                      Billing Notifications
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Notify when a transaction is added to your account.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                  <SoftTypography
                    display="block"
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Log in from a new device
                  </SoftTypography>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Notifications;
