import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material";
import { useState } from "react";
import { CancelOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import { openConfirmModal } from "src/modals/ConfirmModal/ConfirmModalOpener";
import { useLms } from "src/features/lms/LmsProvider";

const StageActions = ({ stage, studentId, enrollmentId, refreshCourseData = () => {} }) => {
  const { completeStage, undoCompleteStage } = useLms();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickCompleteStage = async (e) => {
    e.stopPropagation();
    const shouldContinue = await openConfirmModal({
      title: "Are you sure?",
      body: (
        <>
          <SoftTypography fontSize="small" fontWeight="regular">
            This will create a new <b>Satisfactory</b> grade for each ungraded
            lesson in this stage.
          </SoftTypography>
          <SoftTypography fontSize="small" fontWeight="regular">
            Any lesson currently graded as <b>Unsatisfactory</b> or{" "}
            <b>Incomplete</b> will have to be updated manually.
          </SoftTypography>
        </>
      ),
    });

    shouldContinue && await handleCompleteStage()
    setAnchorEl(null)
  }

  const handleCompleteStage = async () => {
    await completeStage(studentId, enrollmentId, stage)
    refreshCourseData()
  }

  const handleUndo = async (e) => {
    e.stopPropagation();
    undoCompleteStage(studentId, enrollmentId, stage);
  }

  return (
    <>
      <IconButton onClick={handleMenuClick} size="small" sx={{ p: "0px" }}>
        <MoreVert fontSize="small" color="text" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card
          sx={{
            width: "180px",
            mt: "-0.7rem",
            mr: "-1.9rem",
            backgroundColor: "grey.200",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            pt={1}
          >
            <SoftTypography variant="h6" fontWeight="medium">
              Actions
            </SoftTypography>
            <IconButton
              onClick={handleClosePopover}
              aria-label="Close"
              sx={{ p: 0 }}
            >
              <CancelOutlined color="text" fontSize="small" />
            </IconButton>
          </SoftBox>
          {stage.id && (
            <SoftBox px={2} pb={2}>
              <SoftButton
                variant="outlined"
                color="primary"
                onClick={handleClickCompleteStage}
                sx={{ mb: 2 }}
              >
                Complete Stage
              </SoftButton>
              <SoftButton
                variant="outlined"
                color="primary"
                onClick={handleUndo}
              >
                Undo Complete Stage
              </SoftButton>
            </SoftBox>
          )}
        </Card>
      </Popover>
    </>
  );
};

StageActions.propTypes = {
  stage: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  enrollmentId: PropTypes.string.isRequired,
  refreshCourseData: PropTypes.func,
};

export default StageActions;
