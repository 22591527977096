import { IPermission } from "./permission.interface";

export enum clubAnnouncementRoleCategory {
  CLUB_ANNOUNCEMENT = "CLUB_ANNOUNCEMENT",
}

export enum clubAnnouncementPermissionTypes {
  SEND_NEW_ANNOUNCEMENT = "SEND_NEW_ANNOUNCEMENT",
  DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT",
}

export const clubAnnouncementPermissions: IPermission[] = [
  {
    group: clubAnnouncementRoleCategory.CLUB_ANNOUNCEMENT,
    label: "Club Announcements",
    tooltipGroup: "",
    permissions: {
      [clubAnnouncementPermissionTypes.SEND_NEW_ANNOUNCEMENT]: {
        label: "Send New Announcement",
        tooltip: "",
      },
      [clubAnnouncementPermissionTypes.DELETE_ANNOUNCEMENT]: {
        label: "Delete Club Announcement",
        tooltip: "",
      },
    },
  },
];
