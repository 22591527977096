import { useRef } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftEditor from "src/components/SoftEditor";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getCourseStagesCollection } from "src/features/lms/collections";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddStageModal({
  handleClose,
  courseId,
  stageCount,
  stageData,
}) {
  const { selectedClubId } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const editorRefObjective = useRef(null);
  const editorRefCompletionStandards = useRef(null);

  const onSubmit = async () => {
    if (stageData?.id) {
      await updateData(
        {
          entity: getCourseStagesCollection(selectedClubId, courseId),
          pathSegmentsArr: [stageData.id],
        },
        {
          ...stageData,
          completionStandards:
            editorRefCompletionStandards.current.getContent(),
          completionStandardsText:
            editorRefCompletionStandards.current.getContent({ format: "text" }),
          objective: editorRefObjective.current.getContent(),
          objectiveText: editorRefObjective.current.getContent({
            format: "text",
          }),
        },
        stageData.id
      );
      handleClose();
    } else {
      await addData(getCourseStagesCollection(selectedClubId, courseId), {
        completionStandards: editorRefCompletionStandards.current.getContent(),
        completionStandardsText:
          editorRefCompletionStandards.current.getContent({ format: "text" }),
        objective: editorRefObjective.current.getContent(),
        objectiveText: editorRefObjective.current.getContent({
          format: "text",
        }),
        courseId,
        index: stageCount,
      });
      handleClose(stageCount);
    }
  };

  return (
    <Card sx={style}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {stageData?.id ? "Edit" : "Add New"} Stage{" "}
            {stageData && stageData.index + 1}
          </SoftTypography>
          <SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Stage Objective
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "100%" }}>
                  <SoftEditor
                    onInit={(_evt, editor) =>
                      (editorRefObjective.current = editor)
                    }
                    fileStoagePath={`clubs/${selectedClubId}/stages`}
                    initialValue={stageData?.objective}
                    init={{
                      height: 220,
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Stage Completion Standards
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "100%" }}>
                  <SoftEditor
                    onInit={(_evt, editor) =>
                      (editorRefCompletionStandards.current = editor)
                    }
                    fileStoagePath={`clubs/${selectedClubId}/stages`}
                    initialValue={stageData?.completionStandards}
                    init={{
                      height: 220,
                    }}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={onSubmit}
                  >
                    {stageData?.id ? "Update Stage" : "Add Stage"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddStageModal.propTypes = {
  handleClose: PropTypes.func,
  courseId: PropTypes.string,
  stageCount: PropTypes.number,
  stageData: PropTypes.object,
};
