import { useEffect, useState, useMemo } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import AddNewCard from "src/modals/AddNewCard";

import {
  addDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  or,
} from "firebase/firestore";
import {
  isWithinInstructorWorkingHours,
  isOverlappingAnotherBooking,
  getOverlappingBookings,
} from "src/pages/book-flight/utils";
import { getClubBookingsCollection } from "src/features/club/collections";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import { ArrowBack } from "@mui/icons-material";
import SoftInput from "src/components/SoftInput";
import { differenceInHours, isBefore, startOfToday } from "date-fns";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { getUserTaskListsCollection } from "src/features/user/collections";
import TaskTable from "./TaskTable";
import { useUserAttributeChecker } from "src/hooks/useUserAttributeChecker";
import { useAircraftDocuments } from "src/hooks/useAircraftDocuments";

const schema = yup
  .object({
    reservationType: yup.object(),
    startDate: yup.date(),
    endDate: yup.date(),
  })
  .required();

const GetTimeBeforeApprovalInHours = (timeBeforeApproval, timeType) => {
  switch (timeType) {
    case "hours":
      return timeBeforeApproval;
    case "days":
    default:
      return timeBeforeApproval * 24;
  }
};

const TimeOffApprovalNeeded = (
  timeOffApprovalEnabled,
  timeBeforeApproval,
  timeType,
  bookingStartDate,
  bookingEndDate
) => {
  if (!timeOffApprovalEnabled) return false;
  if (timeBeforeApproval === undefined) return false;
  if (timeBeforeApproval === 0) return true;

  const bookingHours = differenceInHours(bookingEndDate, bookingStartDate);
  const hoursBeforeApproval = GetTimeBeforeApprovalInHours(
    timeBeforeApproval,
    timeType
  );

  return bookingHours > hoursBeforeApproval;
};

export function BookingModal({
  handleClose,
  selection,
  isEditReservation,
  oldBookingData,
  resources = [],
  ...rest
}) {
  const { user, userId } = useUser();
  const { selectedClub, selectedClubId, selectedLocationId, instructors } =
    useClubs();
  const {
    getUserEnrollmentsData,
    getStudentEnrollmentsData,
    getTasksFromFullEnrollmentsData,
  } = useLms();
  const { preferences } = selectedClub;
  const {
    // userPermissions,
    canCreateBooking,
    canEditBooking,
    canOverrideBooking,
    isUserInAircraftMembershipLevel,
    userReservationTypes,
  } = useUserPermissions();
  const [savedBooking, setSavedBooking] = useState();
  const [permissionErrors, setPermissionErrors] = useState([]);
  const [lessons, setLessons] = useState(null);
  const [displayAddCard, setDisplayAddCard] = useState(false);
  const [addCardToUserId, setAddCardToUserId] = useState(null);
  const [taskList, setTaskList] = useState("");
  const [taskValues, setTaskValues] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const { hasAccess } = usePermissions();
  const today = useMemo(() => startOfToday(), []);

  const { getAircraftDocsAgainstUser } = useAircraftDocuments();

  const reservationTypes = rest.reservationType
    ? [rest.reservationType]
    : Array.from(userReservationTypes).map((type) => ({
        ...type,
        value: type.id,
      })) || [];

  const getPreGroundTimes = () => {
    const clubMinimum = selectedClub.preferences?.minPreGround || 0;
    const groundTimes = [];
    let startTime = 120;
    while (startTime > clubMinimum) {
      groundTimes.unshift({
        value: startTime,
        label: `${startTime} mins`,
      });
      startTime -= 30;
    }
    if (clubMinimum < 15) {
      groundTimes.unshift({
        value: 15,
        label: "15 mins",
      });
    }
    groundTimes.unshift({
      value: clubMinimum,
      label: clubMinimum === 0 ? "None" : `${clubMinimum} mins`,
    });
    return groundTimes;
  };

  const getPostGroundTimes = () => {
    const clubMinimum = selectedClub.preferences?.minPostGround || 0;
    const groundTimes = [];
    let startTime = 120;
    while (startTime > clubMinimum) {
      groundTimes.unshift({
        value: startTime,
        label: `${startTime} mins`,
      });
      startTime -= 30;
    }
    if (clubMinimum < 15) {
      groundTimes.unshift({
        value: 15,
        label: "15 mins",
      });
    }
    groundTimes.unshift({
      value: clubMinimum,
      label: clubMinimum === 0 ? "None" : `${clubMinimum} mins`,
    });
    return groundTimes;
  };

  const getFormDefaultValues = () => {
    if (!isEditReservation) {
      const startDate = selection.start || selection.date;
      const endDate = selection.end || selection.date;

      let selectedAircraft = {
        value: "",
        label: "Ground",
      };

      let selectedInstructor = {
        value: "",
        label: "Solo",
      };

      let selectedPilot = {
        value: "",
        label: "None",
      };

      let selectedMeetingRoom = {
        value: "",
        label: "None",
      };

      let preGround = {
        value: 0,
        label: "None",
      };

      let postGround = {
        value: 0,
        label: "None",
      };

      const reservationType = rest.reservationType
        ? rest.reservationType
        : Array.from(reservationTypes)?.find((type) => type.default) ||
          Array.from(reservationTypes)?.[0] ||
          null;

      if (selectedClub.preferences?.minPreGround) {
        preGround = {
          value: selectedClub.preferences.minPreGround,
          label: `${selectedClub.preferences.minPreGround} mins`,
        };
      }

      if (selectedClub.preferences?.minPostGround) {
        postGround = {
          value: selectedClub.preferences.minPostGround,
          label: `${selectedClub.preferences.minPostGround} mins`,
        };
      }

      if (
        selection.resource?.extendedProps?.type === "Aircraft" ||
        selection.resource?.extendedProps?.type === "Simulators"
      ) {
        selectedAircraft = {
          value: selection.resource.id,
          label: selection.resource.title,
        };
      }

      if (selection.resource?.extendedProps?.type === "Instructors") {
        selectedInstructor = {
          value: selection.resource.id,
          label: selection.resource.title,
        };
      } else if (reservationType?.enabledResources?.instructor?.enabled) {
        selectedInstructor = {
          value: userId || "",
          label: user?.displayName || "None",
        };
      }

      if (
        selection.resource?.extendedProps?.type === "Instructors" &&
        selection.resource.id !== userId
      ) {
        selectedPilot = {
          value: userId || "",
          label: user?.displayName || "None",
        };
      } else if (!instructors.some((instructor) => instructor.id === userId)) {
        selectedPilot = {
          value: userId || "",
          label: user?.displayName || "None",
        };
      }

      if (rest.reservationType?.id === "meetingRoom") {
        if (selection?.resource) {
          selectedMeetingRoom = {
            value: selection.resource.value,
            label: selection.resource.label,
          };
        } else
          selectedMeetingRoom = {
            value: resources[0].id,
            label: resources[0].title,
          };
      }

      return {
        aircraft: selectedAircraft,
        instructor: selectedInstructor,
        meetingRoom: selectedMeetingRoom,
        preGround,
        postGround,
        endDate,
        startDate,
        pilot: selectedPilot,
        pilot2: {
          value: "",
          label: "None",
        },
        member: {
          label: "None",
          value: "",
        },
        reservationType,
      };
    }

    return {
      aircraft: oldBookingData.extendedProps.aircraft,
      instructor: oldBookingData.extendedProps.instructor,
      meetingRoom: oldBookingData.extendedProps.meetingRoom,
      preGround: {
        label:
          oldBookingData.preGround === 0 ? "None" : oldBookingData.preGround,
        value: oldBookingData.preGround,
      },
      postGround: {
        label:
          oldBookingData.postGround === 0 ? "None" : oldBookingData.postGround,
        value: oldBookingData.postGround,
      },
      endDate: oldBookingData.end?.toDate(),
      startDate: oldBookingData.start?.toDate(),
      pilot: oldBookingData.extendedProps.pilot,
      pilot2: oldBookingData.extendedProps.pilot2 || {
        value: "",
        label: "None",
      },
      member: oldBookingData.extendedProps.member,
      reservationType: oldBookingData.extendedProps.type,
      notes: oldBookingData.notes,
    };
  };

  const { getUserMandatoryFields } = useUserAttributeChecker();

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(schema),
  });

  const [formOptions, setFormOptions] = useState({});

  useEffect(() => {
    let pilots = [];
    let pilots2 = [];
    if (!hasAccess(systemPermissions.CREATE_BOOKING_FOR_OTHERS)) {
      pilots.push({
        value: userId ?? "",
        label: user?.displayName || "None",
      });
    } else {
      pilots = rest?.pilots?.map((pilot) => ({
        value: pilot.id ?? "",
        label: pilot?.displayName || "None",
      }));
    }

    if (hasAccess(systemPermissions.CREATE_BOOKING_WITH_PILOT2)) {
      pilots2 = rest?.pilots?.map((pilot) => ({
        value: pilot?.id ?? "",
        label: pilot?.displayName || "None",
      }));
    }

    const instructors = [
      {
        value: "",
        label: "Solo",
      },
      ...resources
        .filter((resource) => resource.type === "Instructors")
        .map((instructor) => ({
          value: instructor.id,
          label: instructor.title,
        })),
    ];

    const aircrafts = [
      {
        value: "",
        label: "Ground",
      },
      ...resources
        .filter(
          (resource) =>
            resource.type === "Aircraft" || resource.type === "Simulators"
        )
        .map((aircraft) => ({
          value: aircraft.id,
          label: aircraft.title,
        })),
    ];

    const meetingRooms = [
      ...resources
        .filter((resource) => resource.type === "MeetingRooms")
        .map((meetingRoom) => ({
          value: meetingRoom.id,
          label: meetingRoom.title,
        })),
    ];

    setFormOptions({
      pilots,
      pilots2,
      instructors,
      aircrafts,
      meetingRooms,
    });
  }, [resources]);

  const reservationType = watch("reservationType");
  const instructor = watch("instructor");
  const aircraft = watch("aircraft");
  const pilot = watch("pilot");
  const pilot2 = watch("pilot2");
  const startDate = watch("startDate");

  const getTaskOptions = async (pilotId) => {
    // based on pilot id, getStudentEnrollmentsData
    const enrollments = await getStudentEnrollmentsData(pilotId, [
      or(
        where("status", "==", "IN_PROGRESS"),
        where("status", "==", "NOT_STARTED")
      ),
    ]);

    const tasks = getTasksFromFullEnrollmentsData(enrollments, (task) => {
      if (aircraft) {
        return aircraft.value
          ? task.types.includes("flight")
          : task.types.includes("ground");
      }
      return true;
    });

    setTaskOptions(
      tasks.map((task) => ({
        value: task.id,
        label: task.title,
        enrollmentId: task.enrollmentId,
        enrolledCourseId: task.enrolledCourseId,
        gradeId: task.grade?.id || "",
        grade: task.grade,
      }))
    );
  };

  const getTaskListData = async () => {
    // already established that oldBookingData is populated and an instructor exists
    const taskListDocs = await getDocs(
      query(
        getUserTaskListsCollection(oldBookingData?.extendedProps?.pilot?.value),
        where("bookingId", "==", oldBookingData?.id),
        where("deleted", "==", false)
      )
    );
    if (taskListDocs.docs?.length) {
      const taskListDoc = {
        id: taskListDocs.docs[0].id,
        ...taskListDocs.docs[0].data(),
      };
      setTaskList(taskListDoc);

      if (taskListDoc.taskGrades && !taskValues.length) {
        setTaskValues(
          taskListDoc.taskGrades.map((taskGrade) =>
            taskOptions.find(
              (tOption) =>
                tOption.value === taskGrade.taskId &&
                tOption.enrollmentId === taskGrade.enrollmentId
            )
          )
        );
      }
    }
  };

  const getLessons = async () => {
    // if (userId === pilot?.value || userId === instructor?.value) {
    const enrollments = await getUserEnrollmentsData(pilot?.value);
    let stageLessons = [];
    const enrolledCourses = enrollments.flatMap(
      (enrollment) => enrollment.enrolledCourses
    );
    enrolledCourses.forEach((course) => {
      const currentStageLessons = course.stages
        ?.find((item) => item.index === course.currentStageIndex)
        ?.lessons?.map((lesson) => ({
          ...lesson,
          courseName: course.title,
          stageIndex: course.currentStageIndex,
        }));
      currentStageLessons && stageLessons.push(...currentStageLessons);
    });
    setLessons(stageLessons);
    // }
  };

  useEffect(() => {
    if (pilot.value) {
      getTaskOptions(pilot.value);
    }
  }, [pilot, aircraft]);

  useEffect(() => {
    clearErrors();
  }, [reservationType]);

  useEffect(() => {
    if (
      pilot &&
      reservationType?.enabledOptions?.instructor?.enabled &&
      reservationType?.enabledOptions?.member1?.enabled
    ) {
      getLessons();
    } else {
      setLessons(null);
    }
  }, [reservationType, pilot, instructor]);

  useEffect(() => {
    if (
      oldBookingData?.id &&
      oldBookingData.extendedProps?.instructor &&
      taskOptions.length
    ) {
      getTaskListData();
    }
  }, [oldBookingData, taskOptions]);

  const saveTaskList = async (bookingData, newBookingDataId) => {
    const pilotId = bookingData.extendedProps.pilot.value;
    if (taskValues.length) {
      const taskGrades = taskValues.map(
        ({ enrollmentId, enrolledCourseId, gradeId, value }) => ({
          enrollmentId,
          enrolledCourseId,
          gradeId,
          taskId: value,
        })
      );

      if (!taskList.id) {
        await addDoc(getUserTaskListsCollection(pilotId), {
          bookingId: newBookingDataId || oldBookingData.id,
          createdBy: userId,
          createdAt: new Date(),
          deleted: false,
          instructorId: bookingData.extendedProps.instructor.value,
          userId: pilotId,
          taskGrades,
        });
      } else {
        await updateDoc(doc(getUserTaskListsCollection(pilotId), taskList.id), {
          bookingId: newBookingDataId || oldBookingData.id,
          createdBy: userId,
          createdAt: new Date(),
          deleted: false,
          instructorId: bookingData.extendedProps.instructor.value,
          userId: pilotId,
          taskGrades,
        });
      }
    }
  };

  const checkUserDetails = async (id) => {
    if (id) {
      const { errors, hasErrors: showError } = await getUserMandatoryFields(id);

      if (showError) {
        setPermissionErrors((prev) => prev.concat(errors));
        return true;
      }
    }
    return false;
  };

  const saveBooking = async (bookingData, isWithinWorkinghours) => {
    let hasErrors = false;

    if (
      (bookingData?.extendedProps?.type?.value === "requestOnly" ||
        bookingData?.extendedProps?.type?.value === "unavailable") &&
      bookingData?.extendedProps?.instructor?.value === ""
    ) {
      setError("instructor", { message: "Please select an instructor" });
      return false;
    }

    if (
      bookingData?.extendedProps?.type?.value === "maintenance" &&
      bookingData?.extendedProps?.aircraft?.value === ""
    ) {
      setError("aircraft", { message: "Please select an aircraft" });
      return false;
    }

    if (
      !hasAccess(systemPermissions.ALLOW_BOOKING_PAST) &&
      isBefore(bookingData?.start, today)
    ) {
      setError("startDate", {
        message: "The depart date cannot be before today",
      });
      return false;
    }

    if (isBefore(bookingData?.end, bookingData?.start)) {
      setError("endDate", {
        message: "The return date cannot be before depart date",
      });
      return false;
    }

    setSavedBooking(bookingData);
    if (!hasAccess(systemPermissions.ALLOW_OVERLAPPING_BOOKINGS)) {
      const isOverlapping = await isOverlappingAnotherBooking(
        bookingData,
        selectedClub.preferences
      );
      if (isOverlapping) {
        hasErrors = true;
        setPermissionErrors((prev) =>
          prev.concat(["Overlapping another booking"])
        );
      }
    }

    if (bookingData?.extendedProps?.aircraft?.value !== "") {
      const checkUserInAircraftMembershipLevel =
        await isUserInAircraftMembershipLevel(bookingData);
      if (!checkUserInAircraftMembershipLevel.allowed) {
        hasErrors = true;
        setPermissionErrors((prev) =>
          prev.concat([
            `You must have a ${checkUserInAircraftMembershipLevel.reason} membership to book this aircraft`,
          ])
        );
      }
    }

    const permissionCheck = await canCreateBooking(bookingData);
    if (!permissionCheck.allowed) {
      hasErrors = true;
      setPermissionErrors((prev) => prev.concat(permissionCheck.reasons));
    }

    if (
      !isWithinWorkinghours &&
      !preferences?.allowBookingNonWorkingHours &&
      userId !== bookingData?.extendedProps?.instructor?.value
    ) {
      setPermissionErrors((prev) =>
        prev.concat("Booking is outside of instructors working hours")
      );
      hasErrors = true;
    }

    const userIds = new Set();
    if (bookingData?.extendedProps?.pilot?.value)
      userIds.add(bookingData.extendedProps.pilot?.value);
    if (bookingData?.extendedProps?.pilot2?.value)
      userIds.add(bookingData.extendedProps.pilot2?.value);
    if (bookingData?.extendedProps?.instructor?.value)
      userIds.add(bookingData.extendedProps.instructor?.value);

    const { usersHasAllDocs, expiredAircraftDocs, missingAircraftDocs } =
      await getAircraftDocsAgainstUser({
        aircraftId: bookingData.extendedProps.aircraft.value,
        userIds: Array.from(userIds),
        clubId: selectedClubId,
      });

    if (!usersHasAllDocs) {
      if (missingAircraftDocs.length) {
        setPermissionErrors((prev) =>
          prev.concat(
            `The following required aircraft documents are missing: ${missingAircraftDocs
              .map((d) => d.name)
              .join(", ")}`
          )
        );
      }
      if (expiredAircraftDocs.length) {
        setPermissionErrors((prev) =>
          prev.concat(
            `The following documents are expired: ${expiredAircraftDocs
              .map((d) => d.name)
              .join(", ")}`
          )
        );
      }
      hasErrors = true;
    }

    if (await checkUserDetails(bookingData?.extendedProps?.pilot?.value)) {
      hasErrors = true;
    }

    if (await checkUserDetails(bookingData?.extendedProps?.pilot2?.value)) {
      hasErrors = true;
    }
    if (await checkUserDetails(bookingData?.extendedProps?.instructor?.value)) {
      hasErrors = true;
    }

    console.log("Permission Errors:", permissionErrors, hasErrors);
    if (hasErrors) {
      console.log("hasErrors", hasErrors);
      return false;
    }

    let newBookingDataId;
    if (!isEditReservation) {
      const newBooking = await addDoc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData
      );
      newBookingDataId = newBooking.id;
    } else if (canEditBooking(bookingData)) {
      await updateDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          oldBookingData.id
        ),
        bookingData,
        {
          merge: true,
        }
      );
    }
    await saveTaskList(bookingData, newBookingDataId);
    return true;
  };

  const overrideSaveBooking = async (bookingData) => {
    let newBookingDataId;
    if (!isEditReservation) {
      const newBooking = await addDoc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData
      );
      newBookingDataId = newBooking.id;
    } else {
      await updateDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          oldBookingData.id
        ),
        bookingData,
        {
          merge: true,
        }
      );
    }
    await saveTaskList(bookingData, newBookingDataId);
    handleClose();
  };

  const onSubmit = async (data) => {
    const resourceIds = [];
    if (data?.instructor?.value && data.instructor.value !== "")
      resourceIds.push(data.instructor.value);
    if (data?.pilot?.value && data.pilot.value !== "")
      resourceIds.push(data.pilot.value);
    if (data?.aircraft?.value && data.aircraft.value !== "")
      resourceIds.push(data.aircraft.value);
    if (data?.meetingRoom?.value && data.meetingRoom.value !== "")
      resourceIds.push(data.meetingRoom.value);

    let bookingData = {
      createdAt: new Date(),
      createdBy: userId,
      ...oldBookingData,
      start: data.startDate,
      end: data.endDate,
      confirmed: oldBookingData?.confirmed || true,
      dispatched: oldBookingData?.dispatched || false,
      completed: oldBookingData?.completed || false,
      cancelled: oldBookingData?.cancelled || false,
      notes: data.notes || oldBookingData?.notes || "",
      updatedAt: new Date(),
      resourceIds,
      extendedProps: {
        type: data.reservationType,
        instructor: data.instructor,
        clubId: selectedClubId,
        locationId: selectedLocationId,
        aircraft: data.aircraft,
        meetingRoom: data.meetingRoom,
        member: data.member,
        pilot: data.pilot,
      },
    };

    if (data.lesson?.value && data.lesson?.value !== "") {
      bookingData.extendedProps.lesson = data.lesson;
    }
    if (data.instructor?.value && data.instructor?.value !== "") {
      bookingData.extendedProps.instructor = data.instructor;
      bookingData.preGround = data.preGround.value;
      bookingData.postGround = data.postGround.value;
      bookingData.pilot2 = { label: "None", value: "" };
    } else if (data.pilot2?.value && data.pilot2?.value !== "") {
      bookingData.extendedProps.pilot2 = data.pilot2;
      bookingData.extendedProps.instructor = { label: "None", value: "" };
    } else {
      bookingData.extendedProps.pilot2 = { label: "None", value: "" };
      bookingData.extendedProps.instructor = { label: "None", value: "" };
    }

    const isWithinWorking = await isWithinInstructorWorkingHours(bookingData);
    const overlappingBookings = await getOverlappingBookings(bookingData);
    if (
      reservationType?.enabledOptions?.instructor?.enabled &&
      (!isWithinWorking || overlappingBookings.requestOnly.length > 0) &&
      userId !== bookingData?.extendedProps?.instructor?.value
    ) {
      bookingData.confirmed = false;
    }

    if (
      reservationType?.id === "unavailable" &&
      TimeOffApprovalNeeded(
        preferences?.timeOffApproval?.enabled,
        preferences?.timeOffApproval?.timeBeforeApproval,
        preferences?.timeOffApproval?.timeType,
        bookingData.start,
        bookingData.end
      )
    ) {
      bookingData.confirmed = false;
    }

    const success = await saveBooking(
      bookingData,
      isWithinWorking && !overlappingBookings.unavailable.length
    );
    if (success) {
      handleClose();
    }
  };

  const showAddNewCard = () => {
    setDisplayAddCard(true);
  };
  const handleCloseAddNewCard = (value) => {
    setDisplayAddCard(false);
    if (value.success) {
      overrideSaveBooking(savedBooking);
    }
  };

  const onChangeTasks = (newValue) => {
    setTaskValues(newValue);
  };

  if (displayAddCard) {
    return (
      <AddNewCard
        userId={addCardToUserId || pilot.value}
        handleClose={handleCloseAddNewCard}
      />
    );
  }

  const renderPermissionErrors = permissionErrors.map((item, key) => {
    const itemKey = `element-${key}`;
    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1.25}
      >
        <SoftTypography
          variant="button"
          color="error"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
        {item.includes("No card on file") && (
          <SoftButton
            variant="text"
            color="dark"
            onClick={() => {
              if (item.includes(pilot.label)) setAddCardToUserId(pilot.value);
              else if (item.includes(pilot2.label))
                setAddCardToUserId(pilot2.value);
              showAddNewCard();
            }}
          >
            <Icon
              sx={{
                fontWeight: "bold",
              }}
            >
              add
            </Icon>
            &nbsp;add new card
          </SoftButton>
        )}
      </SoftBox>
    );
  });

  if (permissionErrors && permissionErrors.length > 0) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={2}
          component="form"
          role="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="bold" mt={5}>
              Checklist Failed
            </SoftTypography>
            <SoftBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">
                    You are not allowed to create this reservation for the
                    following reasons:
                  </SoftTypography>
                  <SoftBox mt={1.625}>
                    <SoftBox p={2}>
                      <SoftBox
                        component="ul"
                        m={0}
                        pl={3.25}
                        mb={{
                          xs: 8,
                          sm: 0,
                        }}
                      >
                        {renderPermissionErrors}
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {canOverrideBooking(savedBooking) ? (
                  <>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          onClick={() => setPermissionErrors([])}
                          color="dark"
                          fullWidth
                          variant="outlined"
                        >
                          <ArrowBack
                            fontSize="small"
                            lineHeight={2}
                            marginRight={2}
                          />
                          &nbsp;&nbsp;&nbsp;Return to Booking
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          variant="gradient"
                          color="error"
                          fullWidth
                          onClick={() => overrideSaveBooking(savedBooking)}
                        >
                          Override & Book
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <SoftBox
                      mt={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row-reverse"
                    >
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        onClick={handleClose}
                      >
                        Close
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                )}
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    );
  }

  const getPeople = (people, filteredBy) => {
    const filteredByIds = filteredBy
      .filter((v) => !!v?.value)
      .map((v) => v?.value);
    return (people ?? []).filter((p) => !filteredByIds.includes(p?.value));
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {isEditReservation ? "Update Reservation" : "New Reservation"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Reservation Type
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="reservationType"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={reservationTypes}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {reservationType?.enabledOptions?.aircraft?.enabled
                            ? "Depart"
                            : "Start"}
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SoftDatePicker
                            showTimeSelect
                            timeIntervals={
                              selectedClub.preferences?.calendar
                                ?.slotDuration ?? 15
                            }
                            dateFormat="Pp"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.startDate?.message && (
                        <SoftTypography fontSize={12} color="error">
                          {errors.startDate.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {reservationType?.enabledOptions?.aircraft?.enabled
                            ? "Return"
                            : "End"}
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="endDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SoftDatePicker
                            showTimeSelect
                            timeIntervals={
                              selectedClub.preferences?.calendar
                                ?.slotDuration ?? 15
                            }
                            dateFormat="Pp"
                            minDate={startDate}
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                    </SoftBox>
                    {errors?.endDate?.message && (
                      <SoftTypography fontSize={12} color="error">
                        {errors.endDate.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {reservationType?.enabledOptions?.aircraft?.enabled && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Aircraft
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="aircraft"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={formOptions.aircrafts}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.aircraft?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.aircraft.message}
                    </SoftTypography>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {reservationType?.enabledOptions?.member1?.enabled && (
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Member 1
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="pilot"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            options={getPeople(formOptions.pilots, [
                              instructor,
                              pilot2,
                            ])}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                          />
                        )}
                      />
                      {reservationType?.enabledOptions?.member2?.enabled && (
                        <WithPermissions
                          permissions={
                            systemPermissions.CREATE_BOOKING_WITH_PILOT2
                          }
                        >
                          <Grid item xs={12}>
                            <>
                              <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                              >
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Member 2
                                </SoftTypography>
                              </SoftBox>
                              <Controller
                                control={control}
                                name="pilot2"
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <SoftSelect
                                    options={[
                                      { label: "None", value: "" },
                                      ...getPeople(formOptions.pilots2 || [], [
                                        pilot,
                                        instructor,
                                      ]),
                                    ]}
                                    onChange={onChange}
                                    inputRef={ref}
                                    value={value}
                                  />
                                )}
                              />
                            </>
                          </Grid>
                        </WithPermissions>
                      )}
                    </Grid>
                  )}
                  {reservationType?.enabledOptions?.instructor?.enabled && (
                    <WithPermissions
                      permissions={systemPermissions.BOOK_INSTRUCTOR}
                    >
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Instructor
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="instructor"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={getPeople(formOptions.instructors, [
                                pilot,
                                pilot2,
                              ])}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                        {errors?.instructor?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.instructor.message}
                          </SoftTypography>
                        )}
                        {instructor?.value &&
                          instructor?.value !== "" &&
                          reservationType?.enabledOptions?.instructor
                            ?.enabled && (
                            <>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                  >
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                      textTransform="capitalize"
                                    >
                                      Pre-Ground
                                    </SoftTypography>
                                  </SoftBox>
                                  <Controller
                                    control={control}
                                    name="preGround"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <SoftSelect
                                        options={getPreGroundTimes()}
                                        onChange={onChange}
                                        inputRef={ref}
                                        value={value}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                  >
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                      textTransform="capitalize"
                                    >
                                      Post-Ground
                                    </SoftTypography>
                                  </SoftBox>
                                  <Controller
                                    control={control}
                                    name="postGround"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <SoftSelect
                                        options={getPostGroundTimes()}
                                        onChange={onChange}
                                        inputRef={ref}
                                        value={value}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                      </Grid>
                    </WithPermissions>
                  )}
                  {reservationType?.enabledOptions?.instructor?.enabled &&
                    lessons?.length >= 1 && (
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Lesson
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="lesson"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={[
                                { label: "None", value: "" },
                                ...lessons.map((lesson) => ({
                                  value: lesson.id,
                                  label: `${lesson.courseName} - ${
                                    lesson.stageIndex + 1
                                  }.${lesson.index + 1}`,
                                  lessonId: lesson.id,
                                  stageId: lesson.stageId,
                                  courseId: lesson.courseId,
                                  graded: lesson.grade?.lessonGrade === "S",
                                })),
                              ]}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  {reservationType?.enabledOptions?.meetingRoom?.enabled && (
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Meeting Room
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="meetingRoom"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            options={formOptions.meetingRooms}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Notes
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftInput
                      inputRef={ref}
                      value={value}
                      placeholder="Write your notes"
                      multiline
                      rows={4}
                      onChange={onChange}
                      width="100%"
                    />
                  )}
                />
              </Grid>
              {instructor?.value &&
              instructor?.value !== "" &&
              taskOptions.length ? (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Tasks to Perform
                    </SoftTypography>
                  </SoftBox>
                  <TaskTable
                    tasks={taskValues}
                    options={taskOptions}
                    onChange={onChangeTasks}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={!reservationType}
                  >
                    {isEditReservation ? "Update Booking" : "Create Booking"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

BookingModal.propTypes = {
  handleClose: PropTypes.func,
  selection: PropTypes.object,
  isEditReservation: PropTypes.bool,
  oldBookingData: PropTypes.object,
  resources: PropTypes.array,
};
