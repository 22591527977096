// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";

function About({ register, formErrors }) {
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Let&apos;s start with the basic information
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} />
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Club/School Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("clubName", {
                    required: true,
                  })}
                  placeholder="Eg. Raleigh Flight Center"
                />
                {formErrors?.clubName?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {formErrors.clubName.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Phone Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("clubNumber", {
                        required: true,
                      })}
                      placeholder="Eg. +1 (919) 123-4567"
                    />
                    {formErrors?.clubNumber?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.clubNumber.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Club/School Email
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("clubEmail", {
                        required: true,
                      })}
                      placeholder="Eg. info@clubname.com"
                    />
                    {formErrors?.clubEmail?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.clubEmail.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Website
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("website", {
                    required: true,
                  })}
                  placeholder="Eg. https://www.clubdomain.com"
                />
                {formErrors?.website?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {formErrors.website.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

About.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
};

export default About;
