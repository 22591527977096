// Soft UI Dashboard PRO React Base Styles
import colors from "src/assets/theme/base/colors";

const { transparent } = colors;

const iconButton = {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.modal-close-btn": {
        position: "absolute",
        top: 0,
        right: 0,
        padding: "1rem",
      },
    },
  },
};

export default iconButton;
