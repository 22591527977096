import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import {
  formatDistance,
  isBefore,
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where, doc, getDoc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getAircraftCollection } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { toTitleCase } from "src/features/utils";

const columns = [
  {
    Header: "date cancelled",
    accessor: "cancelledAt",
    Cell: ({ value }) =>
      value ? (
        <DefaultCell value={format(value?.toDate(), "eee, MMM do yyyy")} />
      ) : null,
  },
  {
    Header: "Reservation Type",
    accessor: "extendedProps.type.value",
    Cell: ({ value }) => (
      <DefaultCell value={value ? toTitleCase(value) : "---"} />
    ),
  },
  {
    Header: "Member",
    accessor: "extendedProps.pilot.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "extendedProps.instructor.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Aircraft",
    accessor: "extendedProps.aircraft.tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Departure",
    accessor: "start",
    Cell: ({ value }) => (
      <DefaultCell value={format(value.toDate(), "eee MMM do yyyy")} />
    ),
  },
  {
    Header: "Return",
    accessor: "end",
    Cell: ({ value }) => (
      <DefaultCell value={format(value.toDate(), "eee MMM do yyyy")} />
    ),
  },
  {
    Header: "Cancelled By",
    accessor: "cancelledBy.displayName",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Notice Given",
    accessor: "cancelled",
    Cell: ({ row: { original } }) => (
      <DefaultCell
        value={`${formatDistance(
          original?.cancelledAt?.toDate(),
          original?.start?.toDate()
        )}${
          isBefore(original?.cancelledAt?.toDate(), original?.start?.toDate())
            ? " before"
            : " after"
        }`}
      />
    ),
  },
  {
    Header: "Reason",
    accessor: "cancellationReason.title",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={value} />
    ),
  },
  {
    Header: "Notes",
    accessor: "cancellationReason.details",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];

function CancellationsReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    globalUsers,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelledAt", ">=", dateRange[0]),
    orderBy("cancelledAt", "desc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const addBookingData = async (bookings) => {
    const rowData = await Promise.all(
      bookings.map(async (booking) => {
        if (
          !isWithinInterval(booking.cancelledAt.toDate(), {
            start: dateRange[0],
            end: dateRange[1],
          })
        )
          return null;
        const pilot =
          booking?.extendedProps?.pilot?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.pilot?.value)
            : null;
        const cancelledBy =
          booking?.cancelledBy?.length > 0
            ? globalUsers.get(booking?.cancelledBy)
            : null;
        const instructor =
          booking?.extendedProps?.instructor?.value?.length > 0
            ? globalUsers.get(booking?.extendedProps?.instructor?.value)
            : null;
        const aircraft =
          booking?.extendedProps?.aircraft?.value?.length > 0
            ? await getDoc(
                doc(
                  getAircraftCollection(selectedClubId),
                  booking?.extendedProps?.aircraft?.value
                )
              )
            : null;
        const fullBookingData = {
          ...booking,
          cancelledBy,
          extendedProps: {
            ...booking?.extendedProps,
            pilot,
            instructor,
            aircraft: aircraft?.data(),
          },
        };
        return fullBookingData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      "Date Cancelled": format(row?.cancelledAt?.toDate(), "MM/dd/yyyy HH:mm"),
      Member: row?.extendedProps?.pilot?.displayName || "---",
      Instructor: row?.extendedProps?.instructor?.displayName || "---",
      "Aircraft Tail":
        (row?.extendedProps?.aircraft?.aircraft_description?.tail_number ??
          row?.extendedProps?.aircraft?.tailNumber) ||
        `---`,
      "Aircraft Name": row?.extendedProps?.aircraft?.displayName || `---`,
      Departure: format(row?.start?.toDate(), "MM/dd/yyyy HH:mm"),
      Return: format(row?.end?.toDate(), "MM/dd/yyyy HH:mm"),
      "Cancelled By": row?.cancelledBy?.displayName,
      "Notice Given": `${formatDistance(
        row?.cancelledAt?.toDate(),
        row?.start?.toDate()
      )}${
        isBefore(row?.cancelledAt?.toDate(), row?.start?.toDate())
          ? " before"
          : " after"
      }`,
      Reason: row?.cancellationReason?.title,
      "Reason Details": row?.cancellationReason?.details,
    }));
    setRows(rowData);
    setCsvData(csvDataFromRows);
  };

  useEffect(() => {
    if (!bookingsLoaded || !allBookings) return;
    addBookingData(allBookings);
  }, [allBookings, bookingsLoaded, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-cancellations-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CancellationsReport;
