import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function LocationAddress({ address }) {
  return (
    <SoftBox>
      <SoftBox>
        <SoftTypography variant="caption" textTransform="capitalize" mb={2}>
          {address.addressLine1}
        </SoftTypography>
        {address.addressLine2 && (
          <SoftTypography variant="caption" textTransform="capitalize" mb={2}>
            , {address.addressLine2}
          </SoftTypography>
        )}
      </SoftBox>
      <SoftBox>
        <SoftTypography variant="caption" textTransform="capitalize" mb={2}>
          {address.city}, {address.state}, {address.zip}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

LocationAddress.propTypes = {
  address: PropTypes.object,
};

export default LocationAddress;
