import { useState, useEffect } from "react";

import { Grid, Divider } from "@mui/material";

import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import LineItemModal from "src/modals/LineItemModal";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import Table from "src/components/Tables/Table";
import PropTypes from "prop-types";
import * as yup from "yup";
import CurrencyFormat from "react-currency-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";

import { getFunctions, httpsCallable } from "firebase/functions";
import { ThreeDots } from "react-loader-spinner";

const schema = yup
  .object({
    date: yup.date().required("A transaction date is required."),
    member: yup.object().required("A member must be selected."),
  })
  .required();

const columns = [
  { name: "item", width: 350, align: "left" },
  { name: "qty", width: 80, align: "left" },
  { name: "price", width: 100, align: "left", isCurrency: true },
  { name: "discount", width: 100, align: "left" },
  { name: "total", width: 100, align: "left", isCurrency: true },
  { name: "", width: 100, align: "right" },
];

export function AddTransactionModal({ handleClose }) {
  const { selectedClubId, selectedLocationId, clubUsers } = useClubs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const memberOptions = clubUsers.map((member) => ({
      value: member.uid,
      label: member.displayName,
    }));

    setMembers(memberOptions);
  }, [clubUsers]);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleLineItemChange = (event, newValue) => {
    const rows = [...lineItems];
    switch (event) {
      case "custom":
        rows.push({
          id: rows.length > 0 ? rows[rows.length - 1].id + 1 : 0,
          type: newValue.transactionType.value,
          item: newValue.description,
          qty: newValue.qty,
          price: parseFloat(newValue.amount),
          discount: 0,
          total: parseFloat(newValue.amount) * parseFloat(newValue.qty),
        });
        break;

      default:
        break;
    }
    setLineItems(rows);
  };

  useEffect(() => {
    const total = lineItems.reduce(
      (acc, item) =>
        item.type === "credit"
          ? acc - parseFloat(item.total)
          : acc + parseFloat(item.total),
      0
    );
    setTotalAmount(total);
  }, [lineItems]);

  const onSubmit = (data) => {
    setIsSubmitting(true);

    if (lineItems.length === 0) {
      handleClose();
      setIsSubmitting(false);
      return;
    }

    const functions = getFunctions();
    const createTransaction = httpsCallable(functions, "createTransaction");

    createTransaction({
      amount: totalAmount,
      date: data.date,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      userId: data.member.value,
      lineItems,
    })
      .then(() => {
        setIsSubmitting(false);
        handleClose();
      })
      .catch((error) => {
        console.error("error", error);
        setIsSubmitting(false);
      });
  };

  // useEffect(() => {
  //   const fundingAmount = parseInt(amount, 10);
  //   if (amount) {
  //     if (tabValue === 0) {
  //       setFees(calculateAchFees(fundingAmount, clubPaysFees));
  //     } else {
  //       setFees(calculateFees(fundingAmount, undefined, undefined, clubPaysFees, true));
  //     }
  //   }
  // }, [amount, tabValue]);

  // useEffect(() => {
  //   console.log('fees', fees);
  // }, [fees]);

  if (isSubmitting) {
    return (
      <ModalContainer>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  // if (paymentIntent) {
  //   return (
  //     <Elements stripe={stripe} options={{ clientSecret: paymentIntent.secret }}>
  //       <ElementsConsumer>
  //         {({ elements }) => (
  //           <AddFundsContent
  //             stripe={stripe}
  //             elements={elements}
  //             paymentIntent={paymentIntent}
  //             handleClose={handleClose}
  //           />
  //         )}
  //       </ElementsConsumer>
  //     </Elements>
  //   );
  // }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              New Transaction
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        dateFormat="Pp"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.date?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.date.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Member
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="member"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={members}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={2}
            display="flex"
            flexDirection="column"
            sx={{ border: "1px solid #d3d3d3" }}
          >
            <SoftBox>
              <Table columns={columns} rows={lineItems} />
            </SoftBox>
          </SoftBox>
          <SoftBox mt={2}>
            <LineItemModal handleCloseLineItemModal={handleLineItemChange} />
          </SoftBox>
          <SoftBox mt={2}>
            <SoftBox display="flex" justifyContent="end" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={totalAmount ?? 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton disabled={isSubmitting} type="submit" color="primary">
              {totalAmount >= 0 ? "Save" : "Next"}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

LineItemModal.propTypes = {
  handleClose: PropTypes.func,
};

AddTransactionModal.propTypes = {
  handleClose: PropTypes.func,
};
