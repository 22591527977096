import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissionsType } from "src/interfaces/roles/role.interface";

type ComponentWithPermissions<P> = React.ComponentType<P>;

const WithPermissionWrapper = <P extends object>(
  permissions: systemPermissionsType | systemPermissionsType[],
  WrappedComponent: ComponentWithPermissions<P>
): React.FC<P> => {
  const Wrapper: React.FC<P> = (props) => {
    const { hasAccess } = usePermissions();

    if (hasAccess(permissions)) {
      return <WrappedComponent {...props} />;
    } else {
      return <div>You do not have access to this resource</div>;
    }
  };

  return Wrapper;
};

export default WithPermissionWrapper;
