import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import { format } from "date-fns";
import {
  query,
  orderBy,
  where,
  doc,
  getDoc,
  getDocs,
  limit,
} from "firebase/firestore";
import { getUserPermissionsCollectionGroup } from "src/features/user/collections";
import {
  getClubBookingsCollection,
  getClubMembershipsCollection,
} from "src/features/club/collections";
import { getBillingCollection } from "src/features/billing/collections";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { useCollection } from "react-firebase-hooks/firestore";

const columns = [
  {
    Header: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "User Roles",
    accessor: "userRoles",
    Cell: ({ value }) => (
      <DefaultCell
        textTransform="capitalize"
        value={value?.join(", ") || "---"}
      />
    ),
  },
  {
    Header: "Date Added",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <DefaultCell value={format(value.toDate(), "eee MMM do yyyy")} />
    ),
  },
  {
    Header: "Membership Type",
    accessor: "membershipType",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total Membership Fees Paid",
    accessor: "totalMembershipFeesPaid",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Account Balance",
    accessor: "balance",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value)} />,
  },
  {
    Header: "Last Flight",
    accessor: "lastFlight",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? format(value.toDate(), "eee MMM do yyyy") : "---"}
      />
    ),
  },
  {
    Header: "Under Review?",
    accessor: "underReview",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={`${value || "false"}`} />
    ),
  },
];

function ExistingMembersReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    clubUserRoles,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const clubMembersCollectionRef = query(
    getUserPermissionsCollectionGroup(),
    where("locationId", "==", selectedLocationId)
  );
  const [clubMembers, loadingClubMembers] = useCollection(
    clubMembersCollectionRef,
    {
      snapshotListenOptions: {
        includeMetadataChanges: false,
      },
    }
  );

  const addMemberData = async (members) => {
    const rowData = await Promise.all(
      members.map(async (memberSnap) => {
        const memberPermissions = memberSnap.data();
        const userSnap = await getDoc(memberSnap.ref.parent.parent);
        const user = { ...userSnap.data(), id: userSnap.id };
        const userMembershipSnap = await getDoc(
          doc(userSnap.ref, "memberships", selectedClubId)
        );
        const userAccountBalanceSnap = await getDoc(
          doc(getBillingCollection(), userSnap.id)
        );
        const membershipSnap = userMembershipSnap?.data()?.membershipPlan
          ? await getDoc(
              doc(
                getClubMembershipsCollection(selectedClubId),
                userMembershipSnap.data()?.membershipPlan
              )
            )
          : null;
        const latestBooking = await getDocs(
          query(
            getClubBookingsCollection(selectedClubId, selectedLocationId),
            where("extendedProps.pilot.value", "==", userSnap.id),
            orderBy("completedAt", "desc"),
            limit(1)
          )
        );
        const membershipPayments = await getDocs(
          query(
            getClubTransactionsCollection(selectedClubId),
            where("pilot.uid", "==", userSnap.id),
            where("type", "==", "membership"),
            where("payment.status", "==", "succeeded")
          )
        );
        let totalMembershipFeesPaid = 0;
        membershipPayments.docs.forEach((transaction) => {
          const { totalCost } = transaction.data();
          totalMembershipFeesPaid += totalCost;
        });

        const fullMemberData = {
          ...user,
          userType: memberPermissions.userType,
          userRoles: memberPermissions.userRoles?.map(
            (userRole) =>
              clubUserRoles.find((clubRole) => clubRole.id === userRole)?.label
          ),
          balance: userAccountBalanceSnap.data()?.[selectedClubId] || 0,
          totalMembershipFeesPaid,
          lastFlight: latestBooking?.docs[0]?.data()?.completedAt,
          membershipType: membershipSnap?.data()?.label,
        };
        return fullMemberData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      "First Name": row.firstName,
      "Last Name": row.lastName,
      Email: row?.email,
      "User Roles": row.userRoles?.join(", ") || "",
      "Date Added": format(row.createdAt.toDate(), "MM/dd/yyyy HH:mm"),
      "Membership Type": row.membershipType,
      "Total Membership Fees Paid": formatCurrency(row.totalMembershipFeesPaid),
      "Account Balance": formatCurrency(row.balance),
      "Last Flight": row.lastFlight
        ? format(row.lastFlight.toDate(), "eee MMM do yyyy")
        : "---",
      "Under Review?": row.underReview,
    }));

    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (loadingClubMembers || !clubMembers) return;
    addMemberData(clubMembers.docs);
  }, [clubMembers, loadingClubMembers, clubUserRoles]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-existing_members-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ExistingMembersReport;
