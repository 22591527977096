import { Send } from "@mui/icons-material";
import { Checkbox, Grid } from "@mui/material";
import { useRef, useState } from "react";
import SoftAvatar from "src/components/SoftAvatar";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftEditor from "src/components/SoftEditor";
import SoftTypography from "src/components/SoftTypography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUser } from "src/features/user/UserProvider";
import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

interface INoteInputProps {
  onNewNote: (content: string, contentHTML: string, internal: boolean) => {};
  defaultInternal?: boolean;
}

export default function NoteInput({
  onNewNote,
  defaultInternal = false,
}: INoteInputProps) {
  const { user } = useUser() as any;
  const { hasAccess } = usePermissions();
  const isXLPage = useMediaQuery("(min-width:2000px)");
  const isSMPage = useMediaQuery("(min-width:576px)");
  const [internal, setInternal] = useState(defaultInternal);

  const editorRefNotes = useRef<any>();

  const submitComment = () => {
    setInternal(false);
    if (editorRefNotes.current.getContent() !== "")
      onNewNote(
        editorRefNotes.current.getContent({
          format: "text",
        }),
        editorRefNotes.current.getContent(),
        internal
      );
    if (editorRefNotes.current) editorRefNotes.current.setContent("");
  };

  const handleInternalChange = () => {
    setInternal(!internal);
  };

  // The styling sucks but the SoftEditor is a creature of eldrich horror so it's good enough for now
  return (
    <Grid container spacing={0.5} width="100%">
      {isSMPage && (
        <Grid item sm={1}>
          <SoftAvatar
            src={user?.photoURL}
            shadow="xs"
            bgColor="light"
            sx={{
              mt: isXLPage ? 0.1 : 0.75,
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={11}>
        <SoftBox width="100%" height="100%">
          <SoftEditor
            fileStoagePath={`users/${user?.id}/notes`}
            onInit={(_evt: any, editor: any) =>
              (editorRefNotes.current = editor)
            }
            init={{
              height: 150,
              menubar: false,
            }}
          />
        </SoftBox>
      </Grid>
      <Grid item xs={12} my={1}>
        <SoftBox display="flex" justifyContent="flex-end" gap="1rem">
          {hasAccess(systemPermissions.VIEW_INTERNAL_NOTES) && (
            <SoftBox display="flex" alignItems="center">
              <Checkbox
                checked={internal}
                onChange={handleInternalChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <SoftTypography fontSize="small" fontWeight="medium">
                Internal
              </SoftTypography>
            </SoftBox>
          )}
          <SoftButton
            variant="outlined"
            color="info"
            iconOnly
            onClick={submitComment}
            sx={{ width: "4rem" }}
          >
            <Send
              sx={{
                fontWeight: "bold",
              }}
            />
          </SoftButton>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
