import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import { RemindAtIntervalForm, RemindAtIntervalTypes } from "..";
import { Controller } from "react-hook-form";
import SoftSelect from "src/components/SoftSelect";

const meterTypes = [
  { value: "tach1", label: "Tach" },
  { value: "hobbs", label: "Hobbs" },
  { value: "tt", label: "TTAF" },
];

export function Form100hrInspection({
  control,
  errors,
  watch,
  register,
  unregister,
  selectedAircraft,
}) {
  const type = "meterBased";

  const meterType = watch(`${type}Type`);
  const lastCompleted = parseFloat(watch(`${type}LastCompleted`));
  const remindEvery = parseFloat(watch(`${type}RemindEvery`));

  return (
    <>
      <Grid item xs={12} sm={4}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Track From
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={`${type}Type`}
          render={({ field: { onChange, value, ref } }) => (
            <SoftSelect
              options={meterTypes}
              onChange={onChange}
              inputRef={ref}
              value={value}
              defaultValue={meterTypes[0]}
            />
          )}
        />
        {errors?.[`${type}Type`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}Type`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Last Completed
          </SoftTypography>
        </SoftBox>
        <SoftInput
          placeholder="0.0"
          {...register(`${type}LastCompleted`, {
            type: "number",
            required: true,
          })}
        />
        {errors?.[`${type}LastCompleted`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}LastCompleted`].message}
          </SoftTypography>
        )}
        <SoftBox>
          <SoftTypography fontSize={12} color="secondary">
            {selectedAircraft?.value?.[`${meterType?.value}`] &&
              `Current ${meterType?.label}: ${
                selectedAircraft?.value?.[`${meterType?.value}`]
              }`}
            {selectedAircraft?.[`${meterType?.value}`] &&
              `Current ${meterType?.label}: ${
                selectedAircraft?.[`${meterType?.value}`]
              }`}
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Next Due
          </SoftTypography>
        </SoftBox>
        <SoftInput
          placeholder={
            lastCompleted && remindEvery
              ? `${lastCompleted + remindEvery}`
              : "0.0"
          }
          type="number"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        {RemindAtIntervalForm({
          control,
          errors,
          register,
          unregister,
          reminderTypes: [
            RemindAtIntervalTypes.percent,
            RemindAtIntervalTypes.hours,
          ],
          watch,
        })}
      </Grid>
    </>
  );
}
