import PropTypes from "prop-types";
import dateFormat from "dateformat";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { AccessTimeRounded, AutoStoriesOutlined } from "@mui/icons-material";

function CourseCardHeader({ children, createdAt, title }) {
  return (
    <SoftBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      color="text"
    >
      {createdAt?.seconds && !title ? (
        <SoftBox display="flex" gap="0.2rem" alignItems="center" pl="0.2rem">
          <AccessTimeRounded fontSize="small" color="secondary" />
          <SoftTypography
            sx={{ minWidth: "max-content" }}
            fontSize="small"
            color="text"
          >
            {dateFormat(new Date(createdAt.seconds), "mm/dd/yy HH:MM")}
          </SoftTypography>
        </SoftBox>
      ) : null}
      {title ? (
        <SoftBox
          display="flex"
          gap="0.2rem"
          alignItems="center"
          pl="0.2rem"
          sx={{ overflow: "hidden" }}
        >
          <AutoStoriesOutlined fontSize="small" color="secondary" />
          <SoftTypography
            component="div"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            fontSize="small"
            color="text"
          >
            {title}
          </SoftTypography>
        </SoftBox>
      ) : null}
      {children}
    </SoftBox>
  );
}

CourseCardHeader.propTypes = {
  children: PropTypes.node,
  createdAt: PropTypes.object,
  title: PropTypes.string,
};

export default CourseCardHeader;
