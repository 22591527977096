import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubEventsCollection } from "src/features/club/collections";
import SoftDatePicker from "../../components/SoftDatePicker";
import { useMemo } from "react";

const schema = yup
  .object({
    title: yup.string().required("A name for the event is required."),
    description: yup
      .string()
      .required("A description for the event is required."),
    startDate: yup.date().required("A start date is required."),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date must be after start date.")
      .required("An end date is required."),
  })
  .required();

export function AddEventModal({ handleClose, event }) {
  const { selectedClubId } = useClubs();
  const { addData, updateData } = entityCrudUtils();
  const today = useMemo(() => new Date(), [])

  let defaultValues;
  if (event) {
    defaultValues = {
      title: event.title,
      description: event.description,
      startDate: event.startDate.toDate(),
      endDate: event.endDate.toDate(),
    };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: defaultValues || {
      startDate: new Date(),
      endDate: new Date(Date.now() + 86400000),
    },
    resolver: yupResolver(schema),
  });

  const startDate = watch('startDate')

  const onSubmit = async (data) => {
    const newEvent = {
      title: data.title,
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      icon: "notifications",
      createdAt: new Date(),
    };

    if (event) {
      await updateData(
        {
          entity: getClubEventsCollection(selectedClubId),
          pathSegmentsArr: [event.id],
        },
        newEvent
      );
    } else await addData(getClubEventsCollection(selectedClubId), newEvent);

    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose} overflow="visible">
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {event ? "Edit Event" : "Add Event"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Event Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="E.g. Coffee and Donuts"
                  {...register("title", {
                    required: true,
                  })}
                />
                {errors?.title?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.title.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="E.g. Head over for a catch up and a coffee!"
                  {...register("description", {
                    required: true,
                  })}
                />
                {errors?.description?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.description.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} mr={0.5} lineHeight={0}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Start Date
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SoftDatePicker
                      showTimeSelect
                      dateFormat="Pp"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={today}
                    />
                  )}
                />
                {errors?.startDate?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.startDate.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} mr={0.5} lineHeight={0}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    End Date
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SoftDatePicker
                      showTimeSelect
                      dateFormat="Pp"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={startDate}
                    />
                  )}
                />
                {errors?.endDate?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.endDate.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {event ? "Edit Event" : "Add Event"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddEventModal.propTypes = {
  handleClose: PropTypes.func,
  event: PropTypes.object,
};
