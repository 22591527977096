import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// custom styles for the NotificationItem
import {
  menuItem,
  menuImage,
} from "src/containers/NotificationItems/DefaultNotification/styles";

const NotificationItem = forwardRef(
  ({ color = "dark", image, title, date, read = false, ...rest }, ref) => (
    <MenuItem
      {...rest}
      ref={ref}
      className={read ? null : "unread"}
      sx={(theme) => menuItem(theme)}
    >
      <SoftBox
        width="3rem"
        height="3rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        sx={(theme) =>
          menuImage(theme, {
            color,
          })
        }
      >
        {image}
      </SoftBox>
      <SoftBox sx={{ width: "100%" }}>
        <SoftTypography
          variant="button"
          fontWeight="regular"
          sx={{ whiteSpace: "normal" }}
        >
          <strong>{title[0]}</strong> {title[1]}
        </SoftTypography>
        <SoftTypography
          variant="caption"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            width: "100%",
          }}
        >
          <SoftTypography variant="button" color="secondary">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </SoftTypography>
          {date}
        </SoftTypography>
      </SoftBox>
    </MenuItem>
  )
);

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  read: PropTypes.bool,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
