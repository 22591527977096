import { useEffect, useState } from "react";
import { getUserWithId } from "src/features/user/utils";
import { useClubs } from "src/features/club/ClubProvider";
import { useNavigate } from "react-router-dom";

import { formatDistance } from "date-fns";

import { query, orderBy } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getAnnouncementsCollection } from "src/features/announcements/collection";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import AnnouncementCard from "./components/AnnouncementCard";
import { getProfileImageURL } from "src/features/utils";

function ClubAnnouncements() {
  const { selectedClubId } = useClubs();
  const navigate = useNavigate();
  const [latestAnnouncement, setLatestAnnouncement] = useState();

  const clubAnnouncementsCollectionRef = query(
    getAnnouncementsCollection(selectedClubId),
    orderBy("createdAt", "desc")
  );
  const { data: announcements, isDataLoaded: announcementsLoaded } =
    useRealtimeCollectionData(clubAnnouncementsCollectionRef, false);

  const formatLatestAnnouncement = async (announcement) => {
    const userData = (await getUserWithId(announcement.createdBy)).data();
    if (userData) {
      setLatestAnnouncement({
        ...announcement,
        userData,
      });
    }
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)} ...`;
  };

  useEffect(() => {
    if (!announcementsLoaded) return;
    if (announcements && announcements.length > 0) {
      formatLatestAnnouncement(announcements[0]);
    }
  }, [announcements, announcementsLoaded]);

  const handleViewAllClubAnnouncements = () => {
    navigate(`/club-announcements`);
  };

  const user = latestAnnouncement?.userData;

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        lineHeight={1}
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Latest Announcement
        </SoftTypography>
        <SoftButton
          variant="text"
          color="primary"
          size="small"
          onClick={handleViewAllClubAnnouncements}
        >
          <Icon>add</Icon>&nbsp; View All
        </SoftButton>
      </SoftBox>
      <Divider />
      {latestAnnouncement ? (
        <AnnouncementCard
          by={{
            image: getProfileImageURL({ user }),
            name: user.displayName,
            date: `${formatDistance(
              latestAnnouncement.createdAt.toDate(),
              new Date()
            )} ago`,
          }}
          badge={{
            color: "error",
            label: "New",
          }}
          title={latestAnnouncement.title}
          description={truncateString(latestAnnouncement.messageText, 400)}
          action={{
            type: "internal",
            route: `/club-announcements`,
            label: "read more",
          }}
        />
      ) : (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          px={2}
          pb={2}
        >
          <SoftTypography variant="body2" color="secondary">
            No announcements yet...
          </SoftTypography>
        </SoftBox>
      )}
    </Card>
  );
}

export default ClubAnnouncements;
