import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import SoftProgress from "src/components/SoftProgress";

const StageFooter = ({ stage, progress }) => {
  return (
    <SoftBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      color="text"
    >
      {typeof progress === "number" ? (
        progress === 100 ? (
          <SoftBox
            display="flex"
            width="100%"
            gap="4px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <CheckCircleOutlineOutlined fontSize="small" />
            <SoftTypography variant="h6" fontSize="small">
              Completed
            </SoftTypography>
          </SoftBox>
        ) : (
          <SoftBox width="100%" sx={{ mb: "0.2rem" }}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftTypography fontSize="small" fontWeight="regular">
                Progress
              </SoftTypography>
              <SoftTypography fontSize="small" fontWeight="medium">
                {progress}%
              </SoftTypography>
            </SoftBox>
            <SoftProgress value={progress} color="dark" />
          </SoftBox>
        )
      ) : (
        <>
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography fontSize="small" color="text" fontWeight="bold">
              {stage.lessons?.length}
            </SoftTypography>
            <SoftTypography fontSize="small" color="text" fontWeight="regular">
              {stage.lessons?.length === 1 ? "Lesson" : "Lessons"}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" gap="4px" alignItems="center">
            <SoftTypography fontSize="small" color="text" fontWeight="bold">
              {stage.stageHours}
            </SoftTypography>
            <SoftTypography fontSize="small" color="text" fontWeight="regular">
              {stage.stageHours === 1 ? "Hour" : "Hours"}
            </SoftTypography>
          </SoftBox>
        </>
      )}
    </SoftBox>
  );
};

StageFooter.propTypes = {
  stage: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

export default StageFooter;
