export const standardDocumentTypes = [
  {
    id: "photoId",
    name: "Photo ID",
  },
  {
    id: "certificate",
    name: "Pilot Certificate (FAA)",
  },
  {
    id: "medical",
    name: "Medical Certificate / BasicMed (FAA)",
  },
  {
    id: "tsaCitizenshipVerification",
    name: "TSA Citizenship Verification",
  },
];

export const requiredUserDetails = [
  {
    id: "phone",
    label: "Phone Number",
  },
  {
    id: "height",
    label: "Height",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "dateOfBirth",
    label: "Date of Birth",
  },
  {
    id: "emergencyContact",
    label: "Emergency Contact",
  },
];
