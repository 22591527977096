import PropTypes from "prop-types";
import ModalContainer from "src/components/ModalContainer";

import AircraftInfo from "src/pages/resources/aircrafts/AircraftInfo";

export function AircraftInfoModal({ handleClose, aircraft }) {
  return (
    <ModalContainer handleClose={handleClose}>
      <AircraftInfo aircraft={aircraft} />
    </ModalContainer>
  );
}

AircraftInfoModal.propTypes = {
  handleClose: PropTypes.func,
  aircraft: PropTypes.object,
};
