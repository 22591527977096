import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
  Value,
} from "react-phone-number-input";

export const getDisplayPhoneNumber = (phoneNumber: string) => {
  const parsedNumber = parsePhoneNumber(phoneNumber);
  if (!parsedNumber) return phoneNumber;

  const isUS = parsedNumber.country === "US";
  return isUS
    ? formatPhoneNumber(phoneNumber as Value)
    : formatPhoneNumberIntl(phoneNumber as Value);
};
