import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDocs, query, where } from "firebase/firestore";
import parse from "html-react-parser";
import {
  getClubBookingsCollection,
  getClubIntroFlightsCollection,
} from "src/features/club/collections";
import {
  addDays,
  addMinutes,
  format,
  isBefore,
  setHours,
  setMinutes,
} from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import SoftPhoneNumberInput from "src/components/SoftPhoneNumberInput";
import * as yup from "yup";
import PageLayout from "src/containers/LayoutContainers/PageLayout";
import { Card, Divider } from "@mui/material";
import DualView from "src/components/DualView/DualView";
import TimelineList from "src/components/Timeline/TimelineList";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { formatCurrency } from "src/features/utils";

export function IntroFlights() {
  const { clubId, locationId, introFlightId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const hideWrapper = searchParams.get("hw") === "true";

  const { data: introFlights } = useRealtimeCollectionData(
    getClubIntroFlightsCollection(clubId, locationId),
    false
  );

  const [showPanel, setShowPanel] = useState(true);
  const [selectedIntroFlight, setSelectedIntroFlight] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();

  const schema = yup
    .object({
      firstName: yup.string().required("A first name is required"),
      lastName: yup.string().required("A last name is required"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("An email address is required"),
      phoneNumber: yup
        .string()
        .phone("Enter a valid phone number")
        .required("A phone number is required"),
      age: yup
        .number()
        .min(
          selectedIntroFlight?.minAge,
          `Must be ${selectedIntroFlight?.minAge} years or older`
        )
        .required("An age is required"),
      weight: yup
        .number()
        .max(
          selectedIntroFlight?.maxWeight,
          `Weight must be lower than ${selectedIntroFlight?.maxWeight}lbs`
        )
        .required("A weight is required"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const generateTimeSlots = (start, end, slotDurationMinutes) => {
    const timeSlots = [];
    let time = start;
    while (time <= end) {
      timeSlots.push(time);
      time = addMinutes(time, slotDurationMinutes);
    }
    return timeSlots;
  };

  const detectNoOverlap = (
    timeSlots,
    timeslotDuration,
    bookings,
    allocatedInstructors,
    allocatedAircrafts
  ) => {
    const noOverlap = [];
    const timeSlotsLength = timeSlots.length;
    for (let i = 0; i < timeSlotsLength; i++) {
      let availableInstrutors = [...allocatedInstructors];
      let availableAircrafts = [...allocatedAircrafts];

      const timeSlot = timeSlots[i];
      const timeSlotEnd = addMinutes(timeSlot, timeslotDuration);
      const bookingsLength = bookings.length;
      for (let j = 0; j < bookingsLength; j++) {
        const booking = bookings[j];
        const bookingStart = booking?.start?.toDate();
        const bookingEnd = booking?.end?.toDate();
        if (bookingStart < timeSlotEnd && bookingEnd > timeSlot) {
          const { instructor, aircraft } = booking.extendedProps;
          if (instructor) {
            availableInstrutors = availableInstrutors.filter(
              (ins) => ins !== instructor?.value
            );
          }
          if (aircraft) {
            availableAircrafts = availableAircrafts.filter(
              (a) => a !== aircraft?.value
            );
          }
        }

        if (
          availableInstrutors.length === 0 &&
          availableAircrafts.length === 0
        ) {
          break;
        }
      }

      if (availableInstrutors.length > 0 && availableAircrafts.length > 0) {
        noOverlap.push({
          timeSlot,
          aircraft: availableAircrafts[0],
          instructor: availableInstrutors[0],
        });
      }
    }

    return noOverlap;
  };

  const loadBookings = async () => {
    if (!selectedDate || !selectedIntroFlight) return;
    const selectedDateObj = new Date(selectedDate);
    const timeSlotDurationMins = 30;
    const reservationDurationMins =
      selectedIntroFlight?.groundTime && selectedIntroFlight?.flightTime
        ? selectedIntroFlight.groundTime + selectedIntroFlight.flightTime
        : 120;

    const dayOfTheWeek = format(selectedDate, "EEEE").toLowerCase();
    const startDateWorkingHours =
      selectedIntroFlight?.workHours[dayOfTheWeek]?.start?.toDate();
    const endDateWorkingHours =
      selectedIntroFlight?.workHours[dayOfTheWeek]?.end?.toDate();

    const startDate = setMinutes(
      setHours(selectedDateObj, startDateWorkingHours.getHours()),
      startDateWorkingHours.getMinutes()
    );
    const endDate = setMinutes(
      setHours(selectedDateObj, endDateWorkingHours.getHours()),
      endDateWorkingHours.getMinutes()
    );

    const bookingsQuery = query(
      getClubBookingsCollection(clubId, locationId),
      where("end", ">=", startDate),
      where(
        "resourceIds",
        "array-contains-any",
        selectedIntroFlight?.instructors?.concat(selectedIntroFlight?.aircrafts)
      )
    );

    const bookingsSnapshot = getDocs(bookingsQuery);
    const bookings = await bookingsSnapshot;

    const generatedTimeSlots = generateTimeSlots(
      startDate,
      endDate,
      timeSlotDurationMins
    );
    const freeSlots = detectNoOverlap(
      generatedTimeSlots,
      reservationDurationMins,
      bookings.docs.map((c) => c.data()),
      selectedIntroFlight.instructors,
      selectedIntroFlight.aircrafts
    );
    setAvailableTimeSlots(freeSlots);
  };

  const shouldDisableDate = (date) => {
    if (!selectedIntroFlight?.workHours || !date) return false;
    if (isBefore(date, addDays(new Date(), 1))) return true;

    const dayOfTheWeek = date ? format(date, "EEEE").toLowerCase() : "";
    return selectedIntroFlight.workHours[dayOfTheWeek].isOff;
  };

  useEffect(() => {
    loadBookings();
  }, [selectedDate]);

  const onSubmit = (data) => {
    navigate("payment", {
      state: {
        passengerData: data,
        timeAndResources: selectedTimeSlot.value,
        introFlight: selectedIntroFlight,
      },
    });
  };

  const addWrapper = (children) => {
    if (hideWrapper) return children;
    return (
      <PageLayout>
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            sx={{ width: "90vw", margin: "auto" }}
          >
            <SoftBox>{children}</SoftBox>
          </SoftBox>
        </SoftBox>
      </PageLayout>
    );
  };

  useEffect(() => {
    if (introFlightId) {
      const selectedFlight = introFlights.find(
        (flight) => flight.id === introFlightId
      );
      if (selectedFlight) {
        setSelectedIntroFlight(selectedFlight);
        return;
      }
    }
    if (!selectedIntroFlight && introFlights?.length > 0) {
      setSelectedIntroFlight(
        introFlights
          .filter((introFlight) => !introFlight.hidden)
          .sort((a, b) => a.totalPrice - b.totalPrice)[0]
      );
    }
  }, [introFlights]);

  const renderTimelineItems = introFlights
    .filter((introFlight) => !introFlight.hidden)
    .sort((a, b) => a.totalPrice - b.totalPrice)
    .map((introFlight) => (
      <>
        <SoftBox
          key={introFlight.id}
          onClick={() => {
            setSelectedIntroFlight(introFlight);
            setShowPanel(true);
          }}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          mb={2}
          sx={
            selectedIntroFlight?.id === introFlight.id
              ? {
                  border: "0 solid",
                  borderColor: "primary.main",
                  borderRightWidth: "4px",
                  cursor: "pointer",
                }
              : {
                  cursor: "pointer",
                }
          }
          height={50}
        >
          <SoftTypography
            variant="h5"
            sx={{
              fontWeight:
                selectedIntroFlight?.id === introFlight.id ? 700 : 400,
            }}
          >
            {introFlight.title} - {formatCurrency(introFlight.totalPrice)}
          </SoftTypography>
        </SoftBox>
        <Divider />
      </>
    ));

  const renderIntroFlightDetails = () => {
    return (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <Grid
          container
          spacing={6}
          width={{
            xs: "100%",
          }}
          p={5}
        >
          <Grid item xs={12} xl={6}>
            <SoftTypography variant="h3">
              {selectedIntroFlight?.title}
            </SoftTypography>
            <hr />
            <SoftBox py={2}>
              {parse(selectedIntroFlight?.description || "")}
            </SoftBox>
          </Grid>
          <Grid item xs={12} xl={6}>
            <SoftTypography variant="h3">Book Flight</SoftTypography>
            <SoftBox py={2}>
              <SoftTypography variant="body2" mt={2}>
                Flight Time: {selectedIntroFlight?.flightTime || "60"} minutes
              </SoftTypography>
              <SoftTypography variant="body2">
                Ground Time: {selectedIntroFlight?.groundTime || "30"} minutes
              </SoftTypography>
              <SoftBox mt={5}>
                <SoftTypography variant="h6" mb={1}>
                  <strong>Step 1:</strong> Pick Your Reservation Date & Time
                </SoftTypography>
                <hr />

                <SoftBox
                  display={{
                    xs: "none",
                    md: "inline-block",
                  }}
                  width="100%"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      orientation="landscape"
                      openTo="day"
                      disablePast
                      // ToolbarComponent={(props) => datePickerToolbar(props)}
                      value={selectedDate}
                      shouldDisableDate={shouldDisableDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      componentsProps={{ actionBar: { actions: [] } }}
                      renderInput={(params) => <SoftInput {...params} />}
                    />
                  </LocalizationProvider>
                </SoftBox>

                <SoftBox
                  display={{
                    xs: "inline-block",
                    md: "none",
                  }}
                  width="100%"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      openTo="day"
                      displayStaticWrapperAs="mobile"
                      orientation="portrait"
                      disablePast
                      value={selectedDate}
                      shouldDisableDate={shouldDisableDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      componentsProps={{ actionBar: { actions: [] } }}
                      renderInput={(params) => <SoftInput {...params} />}
                    />
                  </LocalizationProvider>
                </SoftBox>
                {selectedDate && availableTimeSlots.length === 0 && (
                  <SoftBox mt={2}>
                    <SoftTypography variant="body2">
                      {" "}
                      Select your time slot:
                    </SoftTypography>
                    <SoftTypography variant="caption" color="error" mt={2}>
                      - No available time slots -
                    </SoftTypography>
                  </SoftBox>
                )}
                {selectedDate && availableTimeSlots.length > 0 && (
                  <SoftBox mt={2}>
                    <SoftTypography variant="body2">
                      {" "}
                      Select your time slot
                    </SoftTypography>

                    <SoftSelect
                      options={availableTimeSlots?.map((slot) => ({
                        label: format(slot.timeSlot, "h:mm a"),
                        value: slot,
                      }))}
                      onChange={(value) => setSelectedTimeSlot(value)}
                      value={selectedTimeSlot}
                    />
                  </SoftBox>
                )}
                <SoftBox>
                  <SoftTypography mt={2} variant="body2">
                    To book within 24 hours, please call for availability.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox mt={5}>
                <SoftTypography variant="h6" mb={1}>
                  <strong>Step 2:</strong> Passenger Information
                </SoftTypography>
                <hr />
                <SoftTypography variant="body2">
                  We collect passenger information in order to comply with FAA
                  regulations.
                </SoftTypography>
                <SoftBox p={2} />
                <SoftBox
                  component="form"
                  role="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            First Name *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="firstName"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftInput
                              onChange={onChange}
                              inputRef={inputRef}
                              value={value}
                            />
                          )}
                        />
                        {errors?.firstName?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.firstName.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Last Name *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="lastName"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftInput
                              onChange={onChange}
                              inputRef={inputRef}
                              value={value}
                            />
                          )}
                        />
                        {errors?.lastName?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.lastName.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Email *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="email"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftInput
                              onChange={onChange}
                              inputRef={inputRef}
                              value={value}
                            />
                          )}
                        />
                        {errors?.email?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.email.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Phone Number *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="phoneNumber"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftPhoneNumberInput
                              value={value}
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              onChange={onChange}
                              inputRef={inputRef}
                            />
                          )}
                        />
                        {errors?.phoneNumber?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.phoneNumber.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Age *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="age"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftInput
                              onChange={onChange}
                              inputRef={inputRef}
                              value={value}
                            />
                          )}
                        />
                        {errors?.age?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.age.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Weight *
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="weight"
                          render={({
                            field: { onChange, value, ref: inputRef },
                          }) => (
                            <SoftInput
                              onChange={onChange}
                              inputRef={inputRef}
                              value={value}
                            />
                          )}
                        />
                        {errors?.weight?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.weight.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>

                  <SoftButton
                    disabled={!selectedTimeSlot}
                    type="submit"
                    color="primary"
                  >
                    Proceed
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    );
  };

  return addWrapper(
    <SoftBox my={3}>
      <Card>
        {introFlights?.length > 1 && !introFlightId ? (
          <DualView
            showPanel={showPanel}
            onClosePanel={() => setShowPanel(false)}
            leftPaneMinWidth={400}
            leftPaneMaxWidth={600}
          >
            <SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                justifyItems="center"
                textAlign="center"
                alignContent="center"
                flexWrap="wrap"
                alignItems="flex-start"
                p={3}
              >
                <SoftTypography variant="h3">
                  Choose Your Package
                </SoftTypography>
              </SoftBox>
              <Divider />
              <TimelineList title="">{renderTimelineItems}</TimelineList>
            </SoftBox>
            {renderIntroFlightDetails()}
          </DualView>
        ) : (
          renderIntroFlightDetails()
        )}
      </Card>
    </SoftBox>
  );
}
