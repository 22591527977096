import { useClubs } from "src/features/club/ClubProvider";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubMeetingRoomsCollection } from "src/features/club/collections";

function useClubMeetingRooms() {
  const [meetingRoomsLoaded, setMeetingRoomsLoaded] = useState(false);
  const { selectedClubId, selectedLocationId } = useClubs();
  const [meetingRooms, setMeetingRooms] = useState([]);

  const [meetingRoomsSnapshot] = useCollection(
    query(
      getClubMeetingRoomsCollection(selectedClubId, selectedLocationId),
      where("deleted", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (!meetingRoomsSnapshot) return;

    function compareRooms(a, b) {
      if (a.title < b.title) {
        return -1;
      }

      if (a.title > b.title) {
        return 1;
      }

      return 0;
    }

    const docs =
      meetingRoomsSnapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) || [];

    setMeetingRoomsLoaded(true);
    setMeetingRooms(docs.sort(compareRooms));
  }, [meetingRoomsSnapshot]);

  return {
    meetingRooms,
    meetingRoomsLoaded,
  };
}

export default useClubMeetingRooms;
