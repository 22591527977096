import { Modal } from "@mui/material";

interface IModalWrapper {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose: () => void;
}
function ModalWrapper({ children, isOpen, onClose }: IModalWrapper) {
  if (!isOpen) return null;
  return (
    <Modal
      open={!!isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <>{children}</>
    </Modal>
  );
}
export default ModalWrapper;
