// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React icons
import SpaceShip from "src/components/Icons/SpaceShip";
import Document from "src/components/Icons/Document";
import Cube from "src/components/Icons/Cube";
import CustomerSupport from "src/components/Icons/CustomerSupport";
import Settings from "src/components/Icons/Settings";
import CreditCard from "src/components/Icons/CreditCard";

function Sidenav() {
  const sidenavItems = [
    {
      icon: <SpaceShip />,
      label: "profile",
      href: "profile",
    },
    {
      icon: <Document />,
      label: "basic info",
      href: "basic-info",
    },
    {
      icon: <Cube />,
      label: "change password",
      href: "change-password",
    },
    {
      icon: <CustomerSupport />,
      label: "notifications",
      href: "notifications",
    },
    {
      icon: <Settings />,
      label: "sessions",
      href: "sessions",
    },
    {
      icon: <CreditCard />,
      label: "delete account",
      href: "delete-account",
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "135px",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{
          listStyle: "none",
        }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default Sidenav;
