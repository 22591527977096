import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  startOfWeek,
  endOfWeek,
  differenceInWeeks,
  differenceInDays,
  differenceInMonths,
  isWithinInterval,
  startOfDay,
  addHours,
  addDays,
  addWeeks,
  startOfMonth,
  addMonths,
  differenceInHours,
  startOfHour,
  endOfHour,
  startOfToday,
  isFuture,
} from "date-fns";
import { query, orderBy, where } from "firebase/firestore";

import { getClubBookingsCollection } from "src/features/club/collections";
import { getAircraftCollection } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

import SoftBox from "src/components/SoftBox";
import SoftBadgeDot from "src/components/SoftBadgeDot";
import DefaultLineChart from "src/components/Charts/LineCharts/DefaultLineChart";
import { stringToPastelColor } from "src/features/utils";

function AircraftFlightHoursChart({ dateRange }) {
  const { selectedClubId, selectedLocationId } = useClubs();

  const [datasets, setDatasets] = useState([]);
  const [labels, setLabels] = useState([]);

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", false),
    where("completedAt", ">=", dateRange[0] ? dateRange[0] : new Date()),
    orderBy("completedAt", "asc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const locationAircraftCollectionRef = query(
    getAircraftCollection(selectedClubId),
    where("locations", "array-contains", selectedLocationId),
    where("disabled", "==", false),
    where("deleted", "==", false)
  );
  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(locationAircraftCollectionRef, true);

  useEffect(() => {
    const weekLabels = [];

    if (differenceInDays(dateRange[1], dateRange[0]) === 0) {
      // By Hour
      let i = 0;
      while (i < 24) {
        weekLabels.push(
          `${format(addHours(startOfDay(dateRange[0]), i), "HH aaa")}`
        );
        i += 1;
      }
    } else if (differenceInDays(dateRange[1], dateRange[0]) <= 30) {
      // By Day
      let i = 0;
      while (i <= differenceInDays(dateRange[1], dateRange[0])) {
        weekLabels.push(
          `${format(startOfDay(addDays(dateRange[0], i)), "MMM d")}`
        );
        i += 1;
      }
    } else if (differenceInWeeks(dateRange[1], dateRange[0]) <= 12) {
      // By Week
      let i = 0;
      while (i <= differenceInWeeks(dateRange[1], dateRange[0])) {
        weekLabels.push(
          `${format(startOfWeek(addWeeks(dateRange[0], i)), "MMM d")}-${format(
            endOfWeek(addWeeks(dateRange[0], i)),
            "d"
          )}`
        );
        i += 1;
      }
    } else if (differenceInWeeks(dateRange[1], dateRange[0]) > 12) {
      // By Month
      let i = 0;
      while (i <= differenceInMonths(dateRange[1], dateRange[0])) {
        weekLabels.push(
          `${format(startOfMonth(addMonths(dateRange[0], i)), "MMM")}`
        );
        i += 1;
      }
    }

    setLabels(weekLabels);
  }, [dateRange]);

  useEffect(() => {
    if (allBookings && bookingsLoaded && allAircraft && aircraftLoaded) {
      const aircraftBookingHours = {};

      allAircraft.forEach((aircraft) => {
        const data = [];
        if (differenceInDays(dateRange[1], dateRange[0]) === 0) {
          // By Hour
          let i = 0;
          const numOfHours = isFuture(dateRange[1])
            ? differenceInHours(new Date(), startOfToday())
            : 23;
          while (i <= numOfHours) {
            data.push(0);
            i += 1;
          }
        } else if (differenceInDays(dateRange[1], dateRange[0]) <= 30) {
          // By Day
          let i = 0;
          while (
            i <=
            differenceInDays(
              isFuture(dateRange[1]) ? new Date() : dateRange[1],
              dateRange[0]
            )
          ) {
            data.push(0);
            i += 1;
          }
        } else if (differenceInWeeks(dateRange[1], dateRange[0]) <= 12) {
          // By Week
          let i = 0;
          while (
            i <=
            differenceInWeeks(
              isFuture(dateRange[1]) ? new Date() : dateRange[1],
              dateRange[0]
            )
          ) {
            data.push(0);
            i += 1;
          }
        } else if (differenceInWeeks(dateRange[1], dateRange[0]) > 12) {
          // By Month
          let i = 0;
          while (
            i <=
            differenceInMonths(
              isFuture(dateRange[1]) ? new Date() : dateRange[1],
              dateRange[0]
            )
          ) {
            data.push(0);
            i += 1;
          }
        }

        aircraftBookingHours[aircraft.id] = {
          label: `${
            aircraft?.aircraft_description?.tail_number ?? aircraft?.tailNumber
          } - ${aircraft.displayName}`,
          data,
          color: stringToPastelColor(`${aircraft.displayName}-${aircraft.id}`),
        };
      });

      allBookings
        .filter(
          (booking) =>
            ![
              "maintenance",
              "unavailable",
              "requestOnly",
              "meetingRoom",
            ].includes(booking.extendedProps?.type?.value)
        )
        .map((booking) => {
          const bookingCheckInDate = booking.completedAt.toDate();
          if (
            isWithinInterval(bookingCheckInDate, {
              start: dateRange[0],
              end: dateRange[1],
            })
          ) {
            const bookingAircraftId = booking.extendedProps.aircraft.value;
            if (
              bookingAircraftId &&
              aircraftBookingHours[bookingAircraftId]?.data
            ) {
              if (differenceInDays(dateRange[1], dateRange[0]) === 0) {
                // By Hour
                aircraftBookingHours[bookingAircraftId].data[
                  Math.round(
                    differenceInHours(
                      endOfHour(bookingCheckInDate),
                      startOfHour(dateRange[0])
                    ) // TODO
                  )
                ] += booking?.flightData?.totalHobbsHours || 0;
              } else if (differenceInDays(dateRange[1], dateRange[0]) <= 30) {
                // By Day
                aircraftBookingHours[bookingAircraftId].data[
                  Math.round(
                    differenceInDays(
                      startOfDay(bookingCheckInDate),
                      startOfDay(dateRange[0])
                    ) // TODO
                  )
                ] += booking?.flightData?.totalHobbsHours || 0;
              } else if (differenceInWeeks(dateRange[1], dateRange[0]) <= 12) {
                // By Week
                aircraftBookingHours[bookingAircraftId].data[
                  Math.round(
                    differenceInWeeks(
                      startOfWeek(bookingCheckInDate),
                      startOfWeek(dateRange[0])
                    ) // TODO
                  )
                ] += booking?.flightData?.totalHobbsHours || 0;
              } else if (differenceInWeeks(dateRange[1], dateRange[0]) > 12) {
                aircraftBookingHours[bookingAircraftId].data[
                  Math.round(
                    differenceInMonths(
                      startOfMonth(bookingCheckInDate),
                      startOfMonth(dateRange[0])
                    ) // TODO
                  )
                ] += booking?.flightData?.totalHobbsHours || 0;
              }
            }
          }
        });

      setDatasets(
        Object.keys(aircraftBookingHours).map(
          (aircraftId) => aircraftBookingHours[aircraftId]
        )
      );
    }
  }, [allBookings, bookingsLoaded, allAircraft, aircraftLoaded, dateRange]);

  return (
    <DefaultLineChart
      title="Aircraft Billable Flight Hours"
      description={
        <SoftBox display="flex" ml={-1} flexWrap="wrap">
          {datasets.map((dataset) => (
            <SoftBadgeDot
              key={dataset.label}
              color={dataset.color}
              size="sm"
              badgeContent={dataset.label}
            />
          ))}
        </SoftBox>
      }
      chart={{
        labels,
        datasets,
      }}
    />
  );
}

AircraftFlightHoursChart.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

export default AircraftFlightHoursChart;
