import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";

import { Grid, AppBar, Tabs, Tab, Modal } from "@mui/material";
import TabPanel from "src/components/TabPanel";

import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftSelect from "src/components/SoftSelect";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";

import Cube from "src/components/Icons/Cube";
import Document from "src/components/Icons/Document";
import ModalContainer from "src/components/ModalContainer";
import { CheckBox } from "@mui/icons-material";

export default function LineItemModal({ handleCloseLineItemModal }) {
  const [open, setOpen] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [description, setDescription] = useState(null);
  const [qty, setQty] = useState(1);
  const [amount, setAmount] = useState(null);
  const [transactionType, setTransactionType] = useState(null);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    handleCloseLineItemModal();
  };

  const onSubmit = () => {
    switch (tabValue) {
      case 0:
        handleCloseLineItemModal("custom", {
          amount,
          qty,
          description,
          transactionType,
        });
        setOpen(false);
        break;

      case 1:
        handleCloseLineItemModal("productService", { amount, transactionType });
        setAmount(null);
        setTransactionType(null);
        setOpen(false);
        break;

      case 2:
        handleCloseLineItemModal("invoice", { amount, transactionType });
        setOpen(false);
        break;

      default:
        break;
    }
    setAmount(null);
    setTransactionType(null);
    setQty(1);
    setDescription(null);
  };

  return (
    <>
      <SoftButton
        variant="text"
        color="primary"
        size="small"
        onClick={() => handleOpen()}
      >
        + Add Item
      </SoftButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <ModalContainer handleClose={handleClose}>
          <SoftBox>
            <SoftBox mt={2} display="flex">
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  sx={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Tab label="Custom" icon={<Document />} />
                  <Tab label="Products & Services" icon={<Cube />} />
                </Tabs>
              </AppBar>
            </SoftBox>
            <SoftBox mt={2}>
              <TabPanel value={tabValue} index={0}>
                <SoftBox display="flex" justifyContent="start" mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Type:
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftBox ml={1}>
                        <SoftSelect
                          options={[
                            {
                              value: "accountDeposit",
                              label: "Account Deposit",
                            },
                            { value: "debit", label: "Charge" },
                            { value: "credit", label: "Credit" },
                          ]}
                          onChange={(value) => setTransactionType(value)}
                          value={transactionType}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {transactionType?.value === "credit" ? (
                        <SoftBox ml={1}>
                          <CheckBox /> Add credit to user account?
                        </SoftBox>
                      ) : null}
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox display="flex" justifyContent="start" mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Amount:
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftBox ml={1}>
                        <SoftCurrencyInput
                          currencySymbol="$"
                          placeholder="0.00"
                          outputFormat="number"
                          onBlur={(value) => {
                            setAmount(value.target.value);
                          }}
                          value={amount}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Qty:
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <SoftBox ml={1}>
                        <SoftCurrencyInput
                          currencySymbol=""
                          placeholder="0"
                          decimalPlaces={1}
                          decimalPlacesShownOnBlur={1}
                          decimalPlacesShownOnFocus={1}
                          outputFormat="number"
                          onBlur={(value) => setQty(value.target.value)}
                          value={qty}
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox display="flex" justifyContent="start" mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Description:
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <SoftBox ml={1}>
                        <SoftInput
                          type="text"
                          placeholder="Eg. Ground School Fee"
                          onChange={(value) =>
                            setDescription(value.target.value)
                          }
                          value={description}
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <SoftBox display="flex" justifyContent="center" mt={3}>
                  <SoftBox>
                    <SoftTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      <i>Coming Soon!</i>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </TabPanel>
            </SoftBox>
            <SoftBox
              mt={3}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <SoftBox />
              <SoftButton color="primary" onClick={onSubmit}>
                Save
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </ModalContainer>
      </Modal>
    </>
  );
}

LineItemModal.propTypes = {
  handleCloseLineItemModal: PropTypes.func,
};
