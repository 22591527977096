import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  logInWithEmailAndPassword,
  loginWithCustomToken,
  setSessionPersistence,
  setLocalStoragePersistence,
  finaliseUserInfoAdding,
} from "src/features/firebase/auth/utils";

// react-router-dom components
import { Link, useSearchParams } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

// Authentication layout components
import CoverLayout from "src/pages/auth/Layout";

// Images
import loginBg2 from "src/assets/images/login-bg2.jpg";
import { useUser } from "src/features/user/UserProvider";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import { Divider } from "@mui/material";
import MicrosoftSSO from "src/assets/images/sso/microsoft-logo.svg";
import GoogleSSO from "src/assets/images/sso/google-logo.svg";
import useRfidScanner from "src/hooks/useRfidScanner";

const provider = new OAuthProvider("microsoft.com");
const providerGoogle = new GoogleAuthProvider();

const dividerStyle = {
  margin: "2rem 0",
};

function SignIn() {
  const [queryParameters] = useSearchParams();
  const functions = getFunctions();
  const referredLocationId = queryParameters.get("lid");
  const [rememberMe, setRememberMe] = useState(true);
  const [firebaseError, setFirebaseError] = useState("");
  const [firebaseLoggingIn, setFirebaseLoggingIn] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [savedUser, firebaseLoading] = useAuthState(auth);
  const { setUserId } = useUser();

  const onRfidScanned = async (rfid) => {
    const res = await axios.post(`${import.meta.env.VITE_API_URL}/auth/rfid`, {
      rfid,
    });
    const token = res.data;

    await loginWithCustomToken(token);
  };

  const { setEnabled } = useRfidScanner(onRfidScanned);

  useEffect(() => {
    setEnabled(true);
    return () => {
      setEnabled(false);
    };
  }, []);

  useEffect(() => {
    if (savedUser) {
      setUserId(savedUser.uid);
    }
  }, [savedUser]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const signInWithMicrosoft = async () => {
    const authMicrosoft = getAuth();
    signInWithPopup(authMicrosoft, provider)
      .then((result) => {
        setFirebaseLoggingIn(true);
        const credential = OAuthProvider.credentialFromResult(result);
        const { accessToken } = credential;
        axios
          .get("https://graph.microsoft.com/v1.0/me", {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(async (res) => {
            const addSsoUser = httpsCallable(functions, "addSsoUser");
            addSsoUser({
              firstName: res.data.givenName,
              lastName: res.data.surname,
              displayName: res.data.displayName,
              email: res.data.userPrincipalName,
              uid: authMicrosoft.currentUser.uid,
            });
            await finaliseUserInfoAdding(
              authMicrosoft.currentUser.uid,
              referredLocationId
            );
          });
      })
      .catch((error) => {
        setFirebaseLoggingIn(false);
        console.error("ERROR: ", error);
      });
  };

  const signInWithGoogle = async () => {
    const authGoogle = getAuth();
    signInWithPopup(authGoogle, providerGoogle)
      .then(async (result) => {
        setFirebaseLoggingIn(true);
        const { user } = result;
        user.firstName = result._tokenResponse.firstName;
        user.lastName = result._tokenResponse.lastName;
        const addSsoUser = httpsCallable(functions, "addSsoUser");
        await addSsoUser({
          firstName: user.firstName,
          lastName: user.lastName,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
        });
        await finaliseUserInfoAdding(user.uid, referredLocationId);
      })
      .catch((error) => {
        setFirebaseLoggingIn(false);
        console.error("ERROR: ", error);
      });
  };

  const onSubmit = async (data) => {
    try {
      if (rememberMe) {
        await setLocalStoragePersistence();
      } else {
        await setSessionPersistence();
      }

      setFirebaseLoggingIn(true);
      const { user } = await logInWithEmailAndPassword(
        data.email,
        data.password
      );
      setUserId(user.uid);
    } catch (err) {
      switch (err.code) {
        case "auth/user-not-found":
          setFirebaseError("User not found!");
          break;
        case "auth/wrong-password":
          setFirebaseError("Invalid password!");
          break;
        default:
      }
    } finally {
      setFirebaseLoggingIn(false);
    }
  };

  if (firebaseLoading || savedUser) {
    return <AuthLoadingPage />;
  }

  return (
    <CoverLayout
      title="Welcome back"
      top={7}
      description="Enter your email and password to sign in"
      image={loginBg2}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            id="email"
            name="email"
            autoComplete="username"
            placeholder="Email"
            {...register("email", {
              required: true,
            })}
          />
          {errors?.email?.type === "required" && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              Email is required!
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            id="current-password"
            autoComplete="current-password"
            placeholder="Password"
            {...register("password", {
              required: true,
            })}
          />
          {errors?.password?.type === "required" && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              Password is required!
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        {firebaseError && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {firebaseError}
          </SoftTypography>
        )}
        <SoftBox mt={4} mb={1}>
          <SoftButton
            type="submit"
            variant="gradient"
            color="info"
            fullWidth
            disabled={firebaseLoggingIn}
          >
            sign in
          </SoftButton>
        </SoftBox>
        <Divider style={dividerStyle}>
          <SoftTypography
            variant="caption"
            style={{
              position: "relative",
              top: "-19px",
              backgroundColor: "white",
              padding: "0 10px",
            }}
          >
            or using
          </SoftTypography>
        </Divider>
        <SoftBox mt={2} mb={1}>
          <SoftButton
            id="google-auth-button"
            type="button"
            variant="outlined"
            onClick={signInWithGoogle}
            style={{ borderColor: "#dedede", padding: "0rem 1.5rem" }}
            fullWidth
          >
            <SoftBox
              component="img"
              src={GoogleSSO}
              alt="Sign in with Google"
              width="20px"
              mr={2}
            />
            <SoftTypography variant="h6" fontWeight="regular" m={0}>
              Google
            </SoftTypography>
          </SoftButton>
        </SoftBox>
        <SoftBox mt={2} mb={1}>
          <SoftButton
            id="microsoft-auth-button"
            type="button"
            variant="outlined"
            onClick={signInWithMicrosoft}
            style={{ borderColor: "#dedede", padding: "0rem 1.5rem" }}
            fullWidth
          >
            <SoftBox
              component="img"
              src={MicrosoftSSO}
              alt="Sign in with Microsoft"
              width="20px"
              mr={2}
            />
            <SoftTypography variant="h6" fontWeight="regular" m={0}>
              Microsoft
            </SoftTypography>
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/auth/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Forgot password?{" "}
            <SoftTypography
              component={Link}
              to="/auth/reset"
              variant="button"
              color="warning"
              fontWeight="medium"
              textGradient
            >
              Reset
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </form>
    </CoverLayout>
  );
}

export default SignIn;
