import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import parse from "html-react-parser";

function EventInfo({ event }) {
  return (
    <SoftBox px={5}>
      {event && event.startDate && (
        <>
          <SoftBox mb={1}>
            <SoftTypography variant="h3" color="primary" fontWeight="bold">
              {event.title}
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1}>
            <SoftTypography variant="h5" color="secondary" fontWeight="medium">
              {format(event.startDate.toDate(), "MMMM dd, yyyy")}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={3} mb={1} ml={0.5}>
            <SoftTypography variant="caption" fontWeight="bold">
              Description:
            </SoftTypography>
          </SoftBox>
          <SoftBox m={0} pl={4} mb={2}>
            <SoftTypography variant="body2" color="text" verticalAlign="middle">
              {parse(event.description)}
            </SoftTypography>
          </SoftBox>
        </>
      )}
    </SoftBox>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventInfo;
