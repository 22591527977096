// @mui material components
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

export default styled(LinearProgress)(({ theme, ownerState }) => {
  const { palette } = theme;
  const { color, value, valueBuffer } = ownerState;

  const { text, grey } = palette;

  // background values
  let backgroundValue;
  let backgroundValueBuffer;
  let backgroundValueBuffer2;

  if (value >= 90) {
    backgroundValue = palette.error.main;
    backgroundValueBuffer = palette.dark.main;
    backgroundValueBuffer2 = palette.dark.light;
  } else if (value >= 80) {
    backgroundValue = palette.warning.main;
    backgroundValueBuffer = palette.error.main;
    backgroundValueBuffer2 = palette.error.light;
  } else {
    backgroundValue = palette[color] ? palette[color].main : palette.success.light;
    backgroundValueBuffer = palette.warning.main;
    backgroundValueBuffer2 = palette.warning.light;
  }

  return {
    '& .MuiLinearProgress-dashed': {
      backgroundImage: 'none',
      backgroundColor: grey[300],
      animation: 'none',
    },
    '& .MuiLinearProgress-bar': {
      background: backgroundValue,
      width: `${value}%`,
      color: text.main,
    },
    '& .MuiLinearProgress-bar2Buffer': {
      background: `repeating-linear-gradient(
        -45deg,
        ${backgroundValueBuffer2},
        ${backgroundValueBuffer2} 4px,
        ${backgroundValueBuffer} 4px,
        ${backgroundValueBuffer} 8px
      )`,
      border: `1px solid ${backgroundValueBuffer}`,
      opacity: 0.4,
      width: `${valueBuffer}%`,
      color: text.main,
    },
  };
});
