// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";
import SoftBox from "src/components/SoftBox";
import SoftProgressBuffer from "src/components/SoftProgressBuffer";

function MaintenanceRemainingCell({ reminder }) {
  return (
    <SoftBox w="100%">
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
        >
          {Math.max(Math.round(reminder.remaining.percentRemaining), 0)}%
        </SoftTypography>
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="text"
          textTransform="capitalize"
        >
          {Math.max(Math.round(reminder.remaining.timeRemaining), 0)}{" "}
          {reminder.type.value === "time" ? "days" : "hours"}
        </SoftTypography>
      </SoftBox>
      <SoftProgressBuffer
        variant="buffer"
        color="success"
        value={Math.min(reminder.remaining.percentComplete, 100)}
        valueBuffer={Math.min(reminder.remaining.percentComplete, 100)}
      />
    </SoftBox>
  );
}

// Typechecking props for the DefaultCell
MaintenanceRemainingCell.propTypes = {
  reminder: PropTypes.object.isRequired,
};

export default MaintenanceRemainingCell;
