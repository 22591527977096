/* eslint-disable no-undef */
import { createContext, useContext, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";
import { collection, query, orderBy } from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { getAppVersionCollection } from "src/features/app-version/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { formatDistanceToNow } from "date-fns";

import SoftSnackbar from "src/components/SoftSnackbar";
import SoftButton from "src/components/SoftButton";

const NotificationsContext = createContext(null);

NotificationsContext.displayName = "NotificationsContext";

function NotificationsProvider({ children }) {
  const { userId } = useUser();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [bookingNotificationAlert, setBookingNotificationAlert] =
    useState(false);
  const [appVersionNotificationAlert, setAppVersionNotificationAlert] =
    useState(false);

  const openBookingNotificationAlert = () => setBookingNotificationAlert(true);
  const closeBookingNotificationAlert = () =>
    setBookingNotificationAlert(false);

  const closeAppVersionNotificationAlert = () =>
    setAppVersionNotificationAlert(false);
  const handleGetNewAppVersionClick = () => {
    setAppVersionNotificationAlert(false);
    window.location.reload();
  };

  const notificationsRef =
    userId &&
    query(
      collection(getUsersCollection(), userId, "notifications"),
      orderBy("date", "desc")
    );
  const { data: notifications, isDataLoaded: notificationsLoaded } =
    useRealtimeCollectionData(notificationsRef, true);

  useEffect(() => {
    if (!notificationsLoaded || !userId) return;

    const unreadNotifications = notifications.filter(
      (notification) => !notification.read
    );

    if (
      unreadNotificationsCount !== 0 &&
      unreadNotifications.length > unreadNotificationsCount
    ) {
      if (
        notifications[0]?.notificationType === "booking" &&
        !notifications[0]?.read
      ) {
        openBookingNotificationAlert();
      }
    }

    setUnreadNotificationsCount(unreadNotifications.length);
  }, [userId, notifications]);

  const appVersionRef = getAppVersionCollection();
  const { data: appVersionData, isDataLoaded: appVersionDataLoaded } =
    useRealtimeCollectionData(appVersionRef, true);

  useEffect(() => {
    if (!appVersionDataLoaded) return;

    const latestProductionVersion = appVersionData.find(
      (versionType) => versionType.id === "production"
    );

    if (
      !latestProductionVersion ||
      latestProductionVersion.version === APP_VERSION
    )
      return;

    setAppVersionNotificationAlert(false);
  }, [appVersionData]);

  const value = {
    notifications,
    notificationsLoaded,
    unreadNotificationsCount,
  };

  if (!notificationsLoaded && userId) {
    return <AuthLoadingPage text="Please wait while we load user data." />;
  }

  const renderBookingNotification = () => {
    const notification = notifications[0];
    if (notification === false) return () => {};

    return (
      <SoftSnackbar
        color="info"
        icon="event_available"
        title={notification?.title}
        content={notification?.description}
        dateTime={
          notification?.date
            ? formatDistanceToNow(notifications[0].date?.toDate(), {
                addSuffix: true,
              })
            : null
        }
        open={bookingNotificationAlert}
        onClose={closeBookingNotificationAlert}
        close={closeBookingNotificationAlert}
      />
    );
  };

  const renderAppVersionNotification = (
    <SoftSnackbar
      color="info"
      icon="event_available"
      title="New App Version Available"
      duration={0}
      slideUp
      content={
        <SoftButton
          variant="gradient"
          color="white"
          fullWidth
          onClick={handleGetNewAppVersionClick}
        >
          Get Latest Version
        </SoftButton>
      }
      dateTime=""
      open={appVersionNotificationAlert}
      onClose={closeAppVersionNotificationAlert}
      close={closeAppVersionNotificationAlert}
    />
  );

  return (
    <>
      <NotificationsContext.Provider value={value}>
        {children}
      </NotificationsContext.Provider>
      {!!notifications[0] && renderBookingNotification()}
      {renderAppVersionNotification}
    </>
  );
}

function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications should be used inside the NotificationsProvider."
    );
  }

  return context;
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationsProvider, useNotifications };
