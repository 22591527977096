import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";

// Settings page components
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { changePassword } from "src/features/firebase/auth/utils";

import { useUser } from "src/features/user/UserProvider";

import * as toastr from "toastr";

const schema = yup
  .object({
    oldPassword: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password will only contain Latin letters."),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required"),
  })
  .required();

function ChangePassword() {
  const { user } = useUser();
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <SoftTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
      </SoftBox>
    );
  });

  const updateUsersPassword = async (data) => {
    try {
      setFirebaseInProgress(true);
      await changePassword(user.email, data.oldPassword, data.password);
      toastr.success("Password Updated", "Success");
      reset();
    } catch (err) {
      console.error(err.code);
      switch (err.code) {
        case "auth/invalid-password":
          setError("password", {
            type: "custom",
            message: "Password must be at least 6 characters long",
          });
          break;

        case "auth/too-many-requests":
          setError("oldPassword", {
            type: "custom",
            message: "Too many requests, please try again later",
          });
          break;

        case "auth/wrong-password":
          setError("oldPassword", {
            type: "custom",
            message: "You entered an incorrect password",
          });
          break;

        default:
          setError("oldPassword", {
            type: "custom",
            message: "An error occurred, please try again",
          });
      }
    } finally {
      setFirebaseInProgress(false);
    }
  };

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">Change Password</SoftTypography>
      </SoftBox>
      <SoftBox
        component="form"
        role="form"
        onSubmit={handleSubmit(updateUsersPassword)}
        pb={3}
        px={3}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftInput
              placeholder="Current Password"
              {...register("oldPassword", {
                required: true,
                type: "password",
                autoComplete: "",
              })}
            />
            {errors?.oldPassword?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.oldPassword.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <SoftInput
              placeholder="New Password"
              {...register("password", {
                required: true,
                type: "password",
                autoComplete: "",
              })}
            />
            {errors?.password?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.password.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <SoftInput
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: true,
                type: "password",
                autoComplete: "",
              })}
            />
            {errors?.confirmPassword?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.confirmPassword.message}
              </SoftTypography>
            )}
          </Grid>
        </Grid>
        <SoftBox mt={6} mb={1}>
          <SoftTypography variant="h5">Password requirements</SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox
            component="ul"
            m={0}
            pl={3.25}
            mb={{
              xs: 8,
              sm: 0,
            }}
          >
            {renderPasswordRequirements}
          </SoftBox>
          <SoftBox ml="auto">
            <SoftButton
              disabled={firebaseInProgress}
              type="submit"
              color="primary"
              variant="contained"
              size="small"
            >
              update password
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
