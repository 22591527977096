import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import PropTypes from "prop-types";

function InformationBite({ label, value, capitalize }) {
  return (
    <SoftBox>
      <SoftTypography variant="caption" margin={0}>
        {label}
      </SoftTypography>
      <SoftTypography
        bg="secondary"
        textTransform={capitalize ? "capitalize" : null}
        fontWeight="bold"
        fontSize="medium"
      >
        {value}
      </SoftTypography>
    </SoftBox>
  );
}

InformationBite.propType = {
  label: PropTypes.string,
  value: PropTypes.string,
  capitalize: PropTypes.bool,
};

export default InformationBite;
