import {
  CollectionReference,
  DocumentData,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import { IDocument, IDocumentType } from "src/interfaces";
import { isBefore } from "date-fns";

interface IUseDocuments {
  documentsCollection: CollectionReference<DocumentData>;
  documentTypes: Array<IDocumentType>;
}
export const useDocuments = ({
  documentsCollection,
  documentTypes,
}: IUseDocuments) => {
  const [documents, setDocuments] = useState<Array<IDocument>>([]);
  const { selectedClubId } = useClubs();
  const [documentsSnapshot, loadingDocuments] = useCollection(
    query(documentsCollection, where("deleted", "==", false)),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingDocuments) return;

    const documentsList: Array<IDocument> = [];
    if (documentsSnapshot) {
      documentsSnapshot.docs.map((documentRef) => {
        const document = documentRef.data();
        if (document.clubId && document.clubId !== selectedClubId) return;
        const documentTypeLabel =
          documentTypes?.find((el) => el.value === document.documentType)
            ?.label || document.documentType;
        documentsList.push({
          id: documentRef.id,
          ...document,
          documentTypeLabel,
        } as IDocument);
      });
    }
    setDocuments(
      documentsList.sort((a, b) =>
        isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
      )
    );
  }, [documentsSnapshot, documentTypes]);
  return {
    documents,
    loadingDocuments,
  };
};
