import {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import SplitPaneContext from "./SplitContext";
import SoftBox from "src/components/SoftBox";

function SplitPane({ children, rightMinWidth, leftPaneMinWidth, ...props }) {
  const [clientHeight, setClientHeight] = useState(null);
  const [clientWidth, setClientWidth] = useState();
  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);

  const onMouseHoldDown = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;

    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;

    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }

    setClientHeight(clientHeight + e.clientY - yDividerPos.current);
    setClientWidth(clientWidth + e.clientX - xDividerPos.current);

    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp);
      document.removeEventListener("mousemove", onMouseHoldMove);
    };
  }, []);

  return (
    <div {...props}>
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          showPanel: !!children[1],
          clientWidth,
          rightMinWidth,
          leftPaneMinWidth,
          setClientWidth,
          onMouseHoldDown,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
}

export function Divider(props) {
  const { onMouseHoldDown } = useContext(SplitPaneContext);
  return <div {...props} onMouseDown={onMouseHoldDown} />;
}

export function SplitPaneLeft(props) {
  const topRef = createRef();
  const {
    clientWidth,
    setClientWidth,
    rightMinWidth,
    showPanel,
    leftPaneMinWidth,
  } = useContext(SplitPaneContext);
  const { children, ...rest } = props;

  useLayoutEffect(() => {
    if (!clientWidth) {
      setClientWidth(
        leftPaneMinWidth || topRef.current.clientWidth - rightMinWidth
      );
      return;
    }

    if (showPanel) {
      if (rightMinWidth) {
        topRef.current.style.minWidth = `min(${clientWidth}px, calc(100% - ${rightMinWidth}px))`;
        topRef.current.style.maxWidth = `min(${clientWidth}px, calc(100% - ${rightMinWidth}px))`;
      } else {
        topRef.current.style.minWidth = `${clientWidth}px`;
        topRef.current.style.maxWidth = `${clientWidth}px`;
      }
    } else {
      topRef.current.style.minWidth = `100%`;
      topRef.current.style.maxWidth = `100%`;
    }
  }, [clientWidth, showPanel]);

  return (
    <SoftBox {...rest} className="split-pane-left" ref={topRef}>
      {children}
    </SoftBox>
  );
}

export function SplitPaneRight(props) {
  const { children, ...rest } = props;
  return (
    <SoftBox {...rest} className="split-pane-right">
      {children}
    </SoftBox>
  );
}

export default SplitPane;
