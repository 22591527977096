import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { getUserPermissionsCollection } from "src/features/user/collections";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubLocationsCollection } from "src/features/club/collections";
import SoftPhoneNumberInput from "src/components/SoftPhoneNumberInput";

const schema = yup
  .object({
    icao: yup.string().required("An ICAO is required."),
    name: yup.string().required("A location name is required."),
    website: yup.string().url("Please enter a valid URL."),
    number: yup.string(),
    email: yup.string().email("Please enter a valid email address."),
    address: yup.object({
      addressLine1: yup.string().required("An address is required."),
      addressLine2: yup.string(),
      city: yup.string().required("A city is required."),
      state: yup.string().required("A state is required."),
      zip: yup.string().required("A zip code is required."),
    }),
  })
  .required();

export function AddLocationModal({ handleClose, location }) {
  const { userId } = useUser();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: location,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (location)
      await updateData(
        {
          entity: getClubLocationsCollection(selectedClubId),
          pathSegmentsArr: [location.id],
        },
        data
      );
    else {
      const addedLocation = await addData(
        getClubLocationsCollection(selectedClubId),
        data
      );

      await updateData(
        {
          entity: getUserPermissionsCollection(userId),
          pathSegmentsArr: [addedLocation.id],
        },
        {
          clubId: selectedClubId,
          locationId: selectedLocationId,
          addedAt: new Date(),
          deleted: false,
          hide: false,
          invited: true,
          isSuperAdmin: true,
        }
      );
    }
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {location ? "Edit Location" : "Add Location"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    ICAO
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("icao", {
                    required: true,
                  })}
                />
                {errors?.icao?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.icao.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={10}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Location Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors?.name?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.name.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Contact Number
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="number"
                  render={({ field: { onChange, value, ref: inputRef } }) => (
                    <SoftPhoneNumberInput
                      value={value}
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      onChange={onChange}
                      inputRef={inputRef}
                    />
                  )}
                />
                {/* <SoftInput
                  {...register("number", {
                    required: true,
                  })}
                /> */}
                {errors?.number?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.number.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors?.email?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.email.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Website
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  {...register("website", {
                    required: true,
                  })}
                />
                {errors?.website?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.website.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox>
                  <SoftBox>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Address Line 1
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("address.addressLine1", {
                        required: true,
                      })}
                    />
                    {errors?.address?.addressLine1?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.address.addressLine1.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox>
                  <SoftBox>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Address Line 2
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput {...register("address.addressLine2")} />
                    {errors?.address?.addressLine2?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.address.addressLine2.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox>
                  <SoftBox>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        City
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("address.city", {
                        required: true,
                      })}
                    />
                    {errors?.address?.city?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.address.city.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={2}>
                <SoftBox>
                  <SoftBox>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        State
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("address.state", {
                        required: true,
                      })}
                    />
                    {errors?.address?.state?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.address.state.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox>
                  <SoftBox>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Zip
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("address.zip", {
                        required: true,
                      })}
                    />
                    {errors?.address?.zip?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.address.zip.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {location ? "Save" : "Add Location"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddLocationModal.propTypes = {
  handleClose: PropTypes.func,
  location: PropTypes.object,
};
