import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubAccountsCollection } from "src/features/club/collections";
import { Checkbox, Divider } from "@mui/material";

const schema = yup
  .object({
    name: yup.string().required("Provide a name for the new account."),
  })
  .required();

export function AddAccountModal({ handleClose, account, qbAccounts }) {
  const { selectedClubId, selectedClub } = useClubs();
  const { addData, updateData } = entityCrudUtils();
  const qboEnabled = selectedClub?.quickbooks?.enabled;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: account?.name,
      minDeposit: account?.minDeposit,
      discountType: account?.discount?.type,
      discountAmount: account?.discount?.value,
      qboId: account?.qboId,
      allowNegativeBalance: account?.allowNegativeBalance || false,
    },
  });

  const onSubmit = async (data) => {
    const accountObj = {
      name: data.name,
      minDeposit: data.minDeposit,
      discount: { type: data.discountType, value: data.discountAmount },
      qboId: data.qboId,
      allowNegativeBalance: data?.allowNegativeBalance,
      deleted: false,
    };
    if (account)
      await updateData(
        {
          entity: getClubAccountsCollection(selectedClubId),
          pathSegmentsArr: [account.id],
        },
        accountObj
      );
    else {
      await addData(getClubAccountsCollection(selectedClubId), accountObj);
    }

    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add New Account
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. VA Loan"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors?.name?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.name.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Min. Deposit Amount
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="minDeposit"
                  render={({ field: { onChange, ref, value } }) => (
                    <SoftCurrencyInput
                      currencySymbol="$"
                      placeholder="0.00"
                      outputFormat="number"
                      onBlur={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.minDeposit?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.minDeposit.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Discount Type
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" flexDirection="row" alignItems="center">
                  <SoftBox sx={{ display: "block", width: "100%" }}>
                    <Controller
                      control={control}
                      name="discountType"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          ref={ref}
                          options={[
                            { label: "Percentage", value: "percentage" },
                            { label: "Amount", value: "amount" },
                          ]}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </SoftBox>
                </SoftBox>
                {errors?.discountType?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.discountType.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Discount Amount
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="discountAmount"
                  render={({ field: { onChange, ref, value } }) => (
                    <SoftCurrencyInput
                      currencySymbol=""
                      placeholder="0"
                      outputFormat="number"
                      onBlur={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.discountAmount?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.discountAmount.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox
                  mb={1}
                  ml={0.5}
                  mr={1}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Allow negative balance?
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="allowNegativeBalance"
                  render={({ field: { onChange, ref, value } }) => (
                    <Checkbox
                      inputRef={ref}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {qboEnabled && (
              <>
                <Divider />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Quickbooks Account
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <SoftBox sx={{ display: "block", width: "100%" }}>
                        <Controller
                          control={control}
                          name="qboId"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              ref={ref}
                              options={qbAccounts?.map((account) => ({
                                label: account.Name,
                                value: account.Id,
                              }))}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </SoftBox>
                    </SoftBox>
                    {errors?.qboId?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.qboId.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add Rate
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddAccountModal.propTypes = {
  handleClose: PropTypes.func,
  account: PropTypes.object,
  qbAccounts: PropTypes.array,
};
