import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
  differenceInSeconds,
  subSeconds,
} from "date-fns";
import { query, where, getDocs } from "firebase/firestore";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { toTitleCase } from "src/features/utils";

const columns = [
  {
    Header: "User",
    accessor: "member.displayName",
    Cell: ({ row: { original } }) => (
      <DefaultCell
        value={
          original?.pilot?.displayName || original?.member?.displayName || "---"
        }
      />
    ),
  },
  {
    Header: "Invoice Date",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <DefaultCell
        value={value?.toDate?.() ? format(value?.toDate?.(), "PPp") : "N/A"}
      />
    ),
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({ value }) => <DefaultCell value={toTitleCase(value)} />,
  },
  {
    Header: "Gross Revenue",
    accessor: "totalCost",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Stripe Fee",
    accessor: "fees",
    id: "totalStripeFees",
    Cell: ({ value }) => (
      <DefaultCell value={formatCurrency(value?.totalStripeFees || 0)} />
    ),
  },
  {
    Header: "PreFlight Fee",
    id: "preflightFees",
    accessor: "fees",
    Cell: ({ value }) => (
      <DefaultCell value={formatCurrency(value?.preflightFees || 0)} />
    ),
  },
];

function FeesReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    clubUsers,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);
  const [dateRangePrevious, setDateRangePrevious] = useState([
    startOfMonth(subMonths(new Date(), 2)),
    endOfMonth(subMonths(new Date(), 2)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  useEffect(() => {
    setDateRangePrevious([
      startOfDay(
        subSeconds(
          dateRange[0],
          differenceInSeconds(dateRange[1], dateRange[0])
        )
      ),
      dateRange[0],
    ]);
  }, [dateRange]);

  const getTransactionData = async () => {
    const transactionDocs = await getDocs(
      query(
        getClubTransactionsCollection(selectedClubId),
        where("location.id", "==", selectedLocationId)
      )
    );

    const rowData = transactionDocs.docs
      .filter(
        (transaction) =>
          transaction?.data()?.createdAt &&
          isWithinInterval(transaction.data().createdAt.toDate(), {
            start: dateRange[0],
            end: dateRange[1],
          }) &&
          transaction.data().type !== "paymentRequest" &&
          transaction.data().type !== "deposit"
      )
      .map((transactionData) => transactionData.data());

    rowData.sort((a, b) => b.createdAt - a.createdAt);

    const csvDataFromRows = rowData.map((row) => ({
      Name: row.pilot?.displayName || row.member?.displayName,
      Date: row?.createdAt?.toDate?.()
        ? format(row.createdAt.toDate(), "yyyy/M/d H:mm")
        : "N/A",
      Type: toTitleCase(row.type || "---"),
      "Gross Revenue": formatCurrency(row.totalCost),
      "Stripe Fee": formatCurrency(row.fees?.totalStripeFees || 0),
      "PreFlight Fee": formatCurrency(row.fees?.preflightFees || 0),
    }));

    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (!clubUsers) return;
    getTransactionData();
  }, [dateRange, dateRangePrevious, selectedClubId, selectedLocationId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-check_ins-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{
                  text: "Number of Transactions",
                  fontWeight: "medium",
                }}
                count={rows.length}
                icon={{
                  color: "info",
                  component: "person",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Gross Revenue",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce((acc, row) => acc + row.totalCost, 0)
                )}
                icon={{
                  color: "info",
                  component: "account_balance",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Stripe Fees",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce(
                    (acc, row) => acc + (row.fees?.totalStripeFees || 0),
                    0
                  )
                )}
                icon={{
                  color: "info",
                  component: "currency_exchange",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total PreFlight Fees",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce(
                    (acc, row) => acc + (row.fees?.preflightFees || 0),
                    0
                  )
                )}
                icon={{
                  color: "info",
                  component: "currency_exchange",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
            selectedRowId
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FeesReport;
