import TimelineItem from "src/components/Timeline/TimelineItem";

import { formatRelative } from "date-fns";

export function AuditTrailCard({ action, date, user, title, diff }) {
  const cardData = {
    date: `${formatRelative(date.toDate(), new Date())} by ${user}`,
    title,
  };

  switch (action) {
    case "COMPLETED":
      cardData.color = "success";
      cardData.icon = "check";
      break;
    case "DISPATCHED":
      cardData.color = "info";
      cardData.icon = "flight_takeoff";
      break;
    case "CREATED":
      cardData.color = "success";
      cardData.icon = "add";
      break;
    case "UPDATED":
      cardData.color = "warning";
      cardData.icon = "refresh";
      break;
    case "CANCELLED":
    case "DELETED":
      cardData.color = "error";
      cardData.icon = "delete";
      break;
    default:
      cardData.color = "dark";
      cardData.icon = "circle";
      break;
  }

  return (
    <TimelineItem
      color={cardData.color}
      icon={cardData.icon}
      title={cardData.title}
      dateTime={cardData.date}
      description={cardData.description}
      listItems={diff}
    />
  );
}
