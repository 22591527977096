import { motion, useIsPresent } from "framer-motion";

const AnimatedItem = ({ children }: { children: React.ReactNode }) => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : ("absolute" as any),
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };
  return (
    <motion.div {...animations} layout>
      {children}
    </motion.div>
  );
};
export default AnimatedItem;
