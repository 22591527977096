import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import { query, orderBy, where } from "firebase/firestore";

import { getClubBookingsCollection } from "src/features/club/collections";
import ComplexReportsDoughnutChart from "src/components/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getInstructorsCollection } from "src/features/instructor/collections";

import { getProfileImageURL, stringToPastelColor } from "src/features/utils";

function InstructorBillableHoursChart({ dateRange }) {
  const { selectedClubId, selectedLocationId, globalUsers } = useClubs();
  const [instructors, setInstructors] = useState([]);

  const [datasets, setDatasets] = useState({
    labels: [],
    datasets: { data: [] },
  });

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", false),
    where("completedAt", ">=", dateRange[0] ? dateRange[0] : new Date()),
    orderBy("completedAt", "asc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const { isDataLoaded: instructorsLoaded, data: clubInstructors } =
    useRealtimeCollectionData(
      getInstructorsCollection(selectedClubId, selectedLocationId),
      true
    );

  const fetchInstructorData = async (instructorsList) => {
    const instructorsData = await Promise.all(
      instructorsList.map(async (instructorPrefs) => {
        const instructor = globalUsers.get(instructorPrefs.id);
        return {
          ...instructor,
          id: instructor.id,
          preferences: instructorPrefs,
        };
      })
    );
    setInstructors(instructorsData);
  };

  useEffect(() => {
    if (clubInstructors?.length > 0) {
      fetchInstructorData(clubInstructors);
    }
  }, [clubInstructors, instructorsLoaded]);

  useEffect(() => {
    if (allBookings && bookingsLoaded && instructors?.length > 0) {
      const instructorsBookingHours = {};
      const chartData = {
        images: [],
        labels: [],
        datasets: {
          label: "Billed Hours",
          backgroundColors: [],
          data: [],
        },
      };

      allBookings
        .filter(
          (booking) =>
            ![
              "maintenance",
              "unavailable",
              "requestOnly",
              "meetingRoom",
            ].includes(booking.extendedProps?.type?.value)
        )
        .map((booking) => {
          if (
            (!booking?.flightData?.instructionHours &&
              !booking?.flightData?.instructionHoursGround) ||
            booking.completedAt.toDate() > dateRange[1]
          ) {
            return;
          }

          const bookingCheckInDate = booking.completedAt.toDate();
          const bookingInstructorId = booking?.extendedProps?.instructor?.value;
          if (bookingCheckInDate) {
            if (
              bookingInstructorId &&
              instructorsBookingHours[bookingInstructorId]
            ) {
              instructorsBookingHours[bookingInstructorId] +=
                (booking.flightData.instructionHours || 0) +
                (booking.flightData.instructionHoursGround || 0);
            } else if (bookingInstructorId) {
              instructorsBookingHours[bookingInstructorId] =
                (booking.flightData.instructionHours || 0) +
                (booking.flightData.instructionHoursGround || 0);
            }
          }
        });

      const orderedInstructorData = [];
      instructors.forEach((instructor) => {
        if (instructorsBookingHours[instructor.id]) {
          orderedInstructorData.push({
            id: instructor.id,
            email: instructor.email,
            displayName: instructor.displayName,
            photoURL: getProfileImageURL({ instructor }),
            hours: instructorsBookingHours[instructor.id],
          });
        } else {
          orderedInstructorData.push({
            id: instructor.id,
            email: instructor.email,
            displayName: instructor.displayName,
            photoURL: getProfileImageURL({ instructor }),
            hours: 0,
          });
        }
      });
      orderedInstructorData.sort((a, b) => b.hours - a.hours);

      orderedInstructorData.map((data) => {
        chartData.images.push(getProfileImageURL({ data }));
        chartData.labels.push(data.displayName);
        chartData.datasets.backgroundColors.push(
          stringToPastelColor(`${data.displayName}-${data.id}`)
        );
        chartData.datasets.data.push(
          Math.round(instructorsBookingHours[data.id] * 10) / 10 || 0
        );
      });
      setDatasets(chartData);
    }
  }, [allBookings, bookingsLoaded, instructors, dateRange]);

  return (
    <ComplexReportsDoughnutChart
      title="Instructors Billable Hours"
      chart={datasets}
      tooltip="Top 5 instructors by billable hours. These do not include refunds or cancellations."
      action={{
        type: "internal",
        route: "/reports/instructors/summary",
        color: "info",
        label: "see all instructors",
      }}
    />
  );
}

InstructorBillableHoursChart.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

export default InstructorBillableHoursChart;
