import { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function StageMenu({ onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    onEdit();
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
    setAnchorEl(null);
  };

  return (
    <>
      <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </WithPermissions>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem sx={{ p: "0", minWidth: 0 }}>
          <IconButton onClick={handleEdit} size="small">
            <Icon>edit</Icon>
          </IconButton>
        </MenuItem>
        <MenuItem sx={{ p: "0", minWidth: 0 }}>
          <IconButton onClick={handleDelete} size="small">
            <Icon>delete</Icon>
          </IconButton>
        </MenuItem>
      </Menu>
    </>
  );
}

StageMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default StageMenu;
