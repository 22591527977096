import SoftTypography from "src/components/SoftTypography";

interface IAircraftTypography {
  children: React.ReactNode;
  fontWeight?: string;
}
export function SectionTitle({
  children,
  fontWeight = "700",
}: IAircraftTypography) {
  return (
    <SoftTypography variant="h6" fontSize="14px" sx={{ fontWeight }}>
      {children}
    </SoftTypography>
  );
}

export function SectionText({
  children,
  fontWeight = "400",
}: IAircraftTypography) {
  return (
    <SoftTypography variant="caption" fontSize="14px" sx={{ fontWeight }}>
      {children}
    </SoftTypography>
  );
}
