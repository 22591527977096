import { Info } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import { SoftCollapsibleSelect } from "src/components/SoftCollapsibleSelect/SoftCollapsibleSelect";
import SoftTypography from "src/components/SoftTypography";
import { IPermission } from "src/interfaces/roles/permissions/permission.interface";
import { systemPermissionsType } from "src/interfaces/roles/role.interface";

interface IUserRoleEntry {
  permissions: systemPermissionsType[];
  permission: IPermission;
  onChange?: (isChecked: boolean, key: systemPermissionsType) => void;
}
export const UserRoleEntry: React.FC<IUserRoleEntry> = ({
  permissions,
  permission,
  onChange,
}) => {
  return (
    <Stack direction="column" gap="12px">
      <SoftCollapsibleSelect
        title={
          <>
            {permission.label}{" "}
            {permission.tooltipGroup && (
              <Tooltip title={permission.tooltipGroup} placement="right">
                <Info color="action" />
              </Tooltip>
            )}
          </>
        }
        selectedItemsByDefault={permissions}
        items={Object.entries(permission.permissions).map(([key, values]) => ({
          id: key,
          label: (
            <>
              <SoftTypography variant="button">{values?.label}</SoftTypography>
              {values?.tooltip && (
                <Tooltip title={values.tooltip}>
                  <Info color="action" sx={{ marginLeft: "4px" }} />
                </Tooltip>
              )}{" "}
            </>
          ),
        }))}
        onChange={(e, options) => {
          const isChecked =
            options.type === "added" || options.type === "selectedAll";
          options.ids.forEach((id) => onChange?.(isChecked, id));
        }}
      />
    </Stack>
  );
};
