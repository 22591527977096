// prop-types is a library for typechecking of props
import 'react-phone-number-input/style.css';
import './phoneInput.scss';
import PhoneInput from 'react-phone-number-input';

function SoftPhoneNumberInput({ ...rest }) {
  return <PhoneInput {...rest} />;
}

export default SoftPhoneNumberInput;
