import { Suspense, useEffect, useState } from "react";
import { CONFIG } from "./modalConfig";
import { modalTypesObject, openModal$ } from "./modalConfiguration";
import SoftLoader from "src/components/SoftLoader/SoftLoader";

const ModalManager = () => {
  const [modalsState, setModalsState] = useState(modalTypesObject);
  useEffect(() => {
    const sub = openModal$.subscribe((modal) => {
      setModalsState((current) => ({
        ...current,
        [modal.modalName]: {
          isOpen: modal?.isOpen ?? true,
          props: modal.modalProps,
        },
      }));
    });
    return () => sub.unsubscribe();
  }, []);

  return (
    <>
      {CONFIG.map(
        (modalConfig) =>
          modalsState[modalConfig.key].isOpen && (
            <Suspense key={modalConfig.key} fallback={<SoftLoader isLoading />}>
              <modalConfig.component
                isOpen={modalsState[modalConfig.key].isOpen}
                {...modalsState[modalConfig.key].props}
                onClose={() =>
                  setModalsState((current) => ({
                    ...current,
                    [modalConfig.key]: { isOpen: false, props: {} },
                  }))
                }
              />
            </Suspense>
          )
      )}
    </>
  );
};

export default ModalManager;
