import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissionsType } from "src/interfaces/roles/role.interface";

interface IWithPermissions {
  children: React.ReactNode;
  permissions: systemPermissionsType[] | systemPermissionsType;
}
export const WithPermissions = ({
  permissions,
  children,
}: IWithPermissions) => {
  const { hasAccess } = usePermissions();
  if (hasAccess(permissions)) return children;
  return null;
};
