import { useEffect, useState } from "react";
import { useWeather } from "src/features/weather/WeatherProvider";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { styled } from "@mui/material/styles";
import { SvgIcon } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

// Weather Icons
import Cloud from "src/assets/images/weather/001-cloud.svg?react";
import Cloud1 from "src/assets/images/weather/002-cloud-1.svg?react";
import Rainy from "src/assets/images/weather/003-rainy.svg?react";
import Rainy1 from "src/assets/images/weather/004-rainy-1.svg?react";
import Hail from "src/assets/images/weather/005-hail.svg?react";
import Snowy from "src/assets/images/weather/006-snowy.svg?react";
import Storm from "src/assets/images/weather/008-storm.svg?react";
import Cloudy from "src/assets/images/weather/011-cloudy.svg?react";
import Snowy1 from "src/assets/images/weather/012-snowy-1.svg?react";
import Storm2 from "src/assets/images/weather/013-storm-2.svg?react";
import Fog from "src/assets/images/weather/017-foog.svg?react";
import Night1 from "src/assets/images/weather/019-night-1.svg?react";
import Storm3 from "src/assets/images/weather/020-storm-3.svg?react";
import Night2 from "src/assets/images/weather/021-night-2.svg?react";
import Night3 from "src/assets/images/weather/022-night-3.svg?react";
import Cloudy1 from "src/assets/images/weather/034-cloudy-1.svg?react";
import Snowy2 from "src/assets/images/weather/035-snowy-2.svg?react";
import Storm4 from "src/assets/images/weather/036-storm-4.svg?react";
import Cloudy3 from "src/assets/images/weather/038-cloudy-3.svg?react";
import Sun from "src/assets/images/weather/039-sun.svg?react";
import { isWithinInterval, startOfDay } from "date-fns";

const WeatherCode = new Map<number, { day: any; night: any }>([
  [0, { day: Sun, night: Night3 }],
  [1, { day: Cloudy3, night: Cloud1 }],
  [2, { day: Cloudy3, night: Cloud1 }],
  [3, { day: Cloud, night: Cloud }],
  [45, { day: Fog, night: Fog }],
  [48, { day: Fog, night: Fog }],
  [51, { day: Cloudy, night: Cloudy }],
  [53, { day: Cloudy1, night: Night2 }],
  [55, { day: Cloudy1, night: Night2 }],
  [56, { day: Rainy, night: Rainy }],
  [57, { day: Rainy1, night: Rainy1 }],
  [61, { day: Rainy, night: Rainy }],
  [63, { day: Rainy, night: Rainy }],
  [65, { day: Rainy, night: Rainy }],
  [66, { day: Rainy1, night: Rainy1 }],
  [67, { day: Hail, night: Hail }],
  [71, { day: Snowy2, night: Night1 }],
  [73, { day: Snowy, night: Snowy }],
  [75, { day: Snowy, night: Snowy }],
  [77, { day: Snowy, night: Snowy }],
  [80, { day: Rainy, night: Rainy }],
  [81, { day: Rainy1, night: Rainy1 }],
  [82, { day: Rainy1, night: Rainy1 }],
  [85, { day: Snowy1, night: Snowy1 }],
  [85, { day: Snowy1, night: Snowy1 }],
  [95, { day: Storm4, night: Storm3 }],
  [96, { day: Storm2, night: Storm2 }],
  [99, { day: Storm, night: Storm }],
]);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f8f8f8",
    color: "#f8f8f8",
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #b8b8b8",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#b8b8b8",
  },
}));

function WeatherBarLabel({ label, date }: { label: string; date: Date }) {
  const [isDay, setIsDay] = useState(false);
  const {
    weatherForecast,
    dailyData,
  }: { weatherForecast: any; dailyData: any } = useWeather();
  const dailyDataMap = dailyData?.get(startOfDay(date).toISOString());
  const hourData = weatherForecast?.get(date.toISOString());

  useEffect(() => {
    if (dailyDataMap?.sunrise && dailyDataMap?.sunset && date) {
      setIsDay(
        isWithinInterval(date, {
          start: dailyDataMap.sunrise,
          end: dailyDataMap.sunset,
        })
      );
    }
  }, [dailyDataMap, date]);

  const tooltipContent = (
    <div>
      <div>
        <SoftTypography variant="caption">
          Wind:{" "}
          {hourData?.windSpeed10m < 1 ? (
            <>Calm</>
          ) : (
            <>
              {(Math.round(hourData?.windDirection10m / 10) * 10)
                .toString()
                .padStart(3, "0")}
              ° @ {Math.round(hourData?.windSpeed10m)}kts
              {hourData?.windGusts10m - hourData?.windSpeed10m > 2 && (
                <> G{Math.round(hourData?.windGusts10m)}kts</>
              )}
            </>
          )}
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Visibility: {Math.round(getMiles(hourData?.visibility))}mi
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Cloud Cover: {Math.round(hourData?.cloudCover)}%
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Temps: {Math.round(hourData?.temperature2m)}°C/
          {Math.round(hourData?.dewPoint2m)}°C
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Humidity: {Math.round(hourData?.humidity2m)}%
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Precipitation: {Math.round(hourData?.precipitationProbability)}%{" "}
          {Math.round(hourData?.precipitation * 0.0393701 * 100) / 100 > 0 && (
            <>
              {(
                Math.round(hourData?.precipitation * 0.0393701 * 100) / 100
              ).toFixed(2)}
              in
            </>
          )}
        </SoftTypography>
      </div>
      <div>
        <SoftTypography variant="caption">
          Pressure:{" "}
          {(Math.round(hourData?.pressure * 0.02953 * 100) / 100).toFixed(2)}
          inHg
        </SoftTypography>
      </div>
    </div>
  );

  return (
    <SoftBox>
      <SoftBox>
        <SoftTypography variant="caption">{label}</SoftTypography>
      </SoftBox>
      <SoftBox>
        <HtmlTooltip title={tooltipContent}>
          <SoftBox>
            <SvgIcon
              sx={{ height: "25px", width: "25px" }}
              inheritViewBox
              component={
                isDay
                  ? WeatherCode.get(hourData?.weatherCode)?.day
                  : WeatherCode.get(hourData?.weatherCode)?.night
              }
            />
          </SoftBox>
        </HtmlTooltip>
      </SoftBox>
    </SoftBox>
  );
}

function getMiles(meters: number) {
  return meters * 0.000621371192;
}

export default WeatherBarLabel;
