// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Settings page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import Sidenav from "src/pages/user/account/settings/components/Sidenav";
import Header from "src/pages/user/account/settings/components/Header";
import BasicInfo from "src/pages/user/account/settings/components/BasicInfo";
import Notifications from "src/pages/user/account/settings/components/Notifications";
import DeleteAccount from "src/pages/user/account/settings/components/DeleteAccount";
import ChangePassword from "src/pages/user/account/settings/components/ChangePassword";

function Settings() {
  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid> */}
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                {/* <Grid item xs={12}>
                  <Sessions />
                </Grid> */}
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default Settings;
