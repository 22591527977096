import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import { getDocs } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  getClubsCollection,
  getClubLocationsCollection,
} from "src/features/club/collections";

import Swal from "sweetalert2";
import { getFunctions, httpsCallable } from "firebase/functions";

function ManageClubs() {
  const [clubs, setClubs] = useState([]);
  const navigate = useNavigate();

  const [clubsSnapshot, loadingClubs] = useCollection(getClubsCollection(), {
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const deleteClub = (clubId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          const functions = getFunctions();
          const deleteClubFunction = httpsCallable(functions, "deleteClub");
          deleteClubFunction({
            clubId,
          }).then((deleteResult) => {
            if (deleteResult.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const columns = [
    {
      Header: "name",
      accessor: "name",
      width: "20%",
    },
    {
      Header: "email",
      accessor: "email",
      width: "20%",
    },
    {
      Header: "website",
      accessor: "website",
      width: "20%",
    },
    {
      Header: "number",
      accessor: "number",
      width: "8%",
    },
    {
      Header: "locations",
      accessor: "locations",
    },
    {
      Header: "actions",
      accessor: "actions",
    },
  ];

  const addClubLocations = async (club) => {
    const locationIcaos = [];
    const locations = await getDocs(getClubLocationsCollection(club.id));
    locations.docs.forEach((locationDoc) => {
      locationIcaos.push(locationDoc.data().icao);
    });
    return {
      ...club,
      locations: locationIcaos.toString(),
      actions: (
        <SoftButton
          variant="outlined"
          color="error"
          onClick={() => deleteClub(club.id)}
        >
          Delete
        </SoftButton>
      ),
    };
  };

  const setupTableData = async (clubsList) => {
    const addClubLocationsPromises = [];
    clubsList.forEach((club) => {
      addClubLocationsPromises.push(addClubLocations(club));
    });
    const clubsWithLocations = await Promise.all(addClubLocationsPromises);
    setClubs(clubsWithLocations);
  };

  useEffect(() => {
    if (loadingClubs) return;

    const clubsList = [];
    if (clubsSnapshot) {
      clubsSnapshot.docs.map((document) => {
        clubsList.push({ id: document.id, ...document.data() });
      });
    }
    setupTableData(clubsList);
  }, [clubsSnapshot]);

  const handleRowSelection = (row) => {
    navigate(`/manage-clubs/club-details/${row.id}`);
  };

  const addNewClub = () => {
    navigate("/manage-clubs/add-new-club");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h4" fontWeight="medium">
                Registered Clubs
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body2" color="text">
                  Each one of these clubs is special, and the reason PreFlight
                  can exist...
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="gradient"
                  color="info"
                  onClick={addNewClub}
                >
                  Add Club
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Card>
          {clubs.length === 0 && (
            <SoftBox p={2} mx={3} display="flex" justifyContent="center">
              <SoftTypography variant="caption" color="text" textAlign="center">
                <i>Loading Clubs..</i>
              </SoftTypography>
            </SoftBox>
          )}
          {clubs.length > 0 && (
            <DataTable
              table={{ columns, rows: clubs }}
              onRowSelect={handleRowSelection}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ManageClubs;
