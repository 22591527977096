import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import SoftDatePicker from "src/components/SoftDatePicker";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getAircraftCollection,
  getAircraftReminder,
} from "src/features/aircraft/collections";

const schema = yup
  .object({
    name: yup.string().required("You must add a name for this reminder."),
    type: yup.object().required("You must select a type for the reminder."),
    meterBasedType: yup.object().when("type", {
      is: (val) => val.value === "meter" || val.value === "meterAndTime",
      then: yup.object().required("This field is required"),
    }),

    meterBasedLastCompleted: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("type", {
        is: (val) => val.value === "meter" || val.value === "meterAndTime",
        then: (elementSchema) =>
          elementSchema
            .required("This field is required")
            .typeError("This must be a number"),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    timeBasedLastCompletedOn: yup.date().when("type", {
      is: (val) => val.value === "time" || val.value === "meterAndTime",
      then: yup
        .date()
        .required("This field is required")
        .typeError("This must be a date"),
    }),
  })
  .required();

const meterTypes = [
  { value: "tach1", label: "Tach" },
  { value: "hobbs", label: "Hobbs" },
  // { value: 'ttaf', label: 'TTAF' },
];

const timeBasedTimeOptions = [
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
];

const meterBasedTimeOptions = [{ value: "hour", label: "Hours" }];
function MeterBasedReminderForm({
  control,
  errors,
  watch,
  register,
  selectedAircraft,
}) {
  const type = "meterBased";
  const meterType = watch(`${type}Type`);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Track From
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={`${type}Type`}
          render={({ field: { onChange, value, ref } }) => (
            <SoftSelect
              options={meterTypes}
              onChange={onChange}
              inputRef={ref}
              value={value}
              defaultValue={meterTypes[0]}
              isOptionDisabled={() => true}
            />
          )}
        />
        {errors?.[`${type}Type`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}Type`].message}
          </SoftTypography>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Completed At
          </SoftTypography>
        </SoftBox>
        <SoftInput
          placeholder="0.0"
          {...register(`${type}LastCompleted`, {
            type: "number",
            required: true,
          })}
        />
        {errors?.[`${type}LastCompleted`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}LastCompleted`].message}
          </SoftTypography>
        )}
        <SoftBox>
          <SoftTypography fontSize={12} color="text.secondary">
            {selectedAircraft?.value?.[`${meterType?.value}`] &&
              `Current ${meterType?.label}: ${
                selectedAircraft?.value?.[`${meterType?.value}`]
              }`}
          </SoftTypography>
        </SoftBox>
      </Grid>
    </>
  );
}
function TimeBasedReminderForm({ control, errors }) {
  const type = "timeBased";

  return (
    <Grid item xs={12} sm={6}>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Completed On
        </SoftTypography>
      </SoftBox>
      <Controller
        control={control}
        name={`${type}LastCompletedOn`}
        render={({ field: { onChange, onBlur, value } }) => (
          <SoftDatePicker
            dateFormat="Pp"
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
        )}
      />
      {errors?.[`${type}LastCompletedOn`]?.message && (
        <SoftTypography marginTop={1} fontSize={12} color="error">
          {errors[`${type}LastCompletedOn`].message}
        </SoftTypography>
      )}
    </Grid>
  );
}

export function ReminderCompletedModal({ handleClose, existingData }) {
  const { selectedClubId } = useClubs();
  const { updateDataFromRef } = entityCrudUtils();
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reminderTypeOptions] = useState([
    { value: "meter", label: "Meter Based" },
    { value: "time", label: "Time Based" },
    { value: "meterAndTime", label: "Meter and Time Based" },
  ]);

  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(getAircraftCollection(selectedClubId), false);

  useEffect(() => {
    if (aircraftLoaded) {
      const aircraftOptionsValues = allAircraft.map((aircraft) => ({
        value: aircraft,
        label: `${
          aircraft?.aircraft_description?.tail_number ?? aircraft?.tailNumber
        } - ${aircraft.displayName}`,
      }));
      setAircraftOptions(aircraftOptionsValues);
    }
  }, [aircraftLoaded, allAircraft]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    unregister,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: reminderTypeOptions[0],
      meterBasedRemindEvery: 0,
      meterBasedRemindEveryUnit: meterBasedTimeOptions[0],
      timeBasedRemindEveryUnit: timeBasedTimeOptions[0],
      timeBasedEndOfMonth: false,
      meterBasedType: meterTypes[0],
      ...existingData,
      timeBasedLastCompletedOn: new Date(),
      nonGrounded: existingData?.nonGrounded || false,
      notes: existingData?.notes || "",
    },
  });

  const selectedAircraft = watch("aircraft");

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const reminder = {
      ...existingData,
      ...data,
      updatedAt: new Date(),
    };

    await updateDataFromRef(
      getAircraftReminder(
        selectedClubId,
        reminder.aircraft.id,
        existingData.id
      ),
      reminder
    );

    handleClose({
      ...reminder,
      aircraft: reminder.aircraft,
    });
    setIsSubmitting(false);
  };

  return (
    <ModalContainer overflow="visible" handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Complete Reminder
            {existingData?.aircraft?.tailNumber &&
              ` for N${existingData?.aircraft?.tailNumber}`}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              {!existingData && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Aircraft
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="aircraft"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={aircraftOptions}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        defaultValue={aircraftOptions[0]}
                      />
                    )}
                  />
                  {errors?.aircraft?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.aircraft.message}
                    </SoftTypography>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Reminder Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. 100hr Inspection"
                  disabled
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors?.name?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.name.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Reminder type
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      isOptionDisabled={() => true}
                      options={reminderTypeOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                      defaultValue={reminderTypeOptions[0]}
                    />
                  )}
                />
                {errors?.type?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.type.message}
                  </SoftTypography>
                )}
              </Grid>

              {watch("type")?.value === "meter" && (
                <MeterBasedReminderForm
                  control={control}
                  errors={errors}
                  register={register}
                  watch={watch}
                  unregister={unregister}
                  selectedAircraft={selectedAircraft}
                  showReminderIntervals
                />
              )}

              {watch("type")?.value === "time" && (
                <TimeBasedReminderForm
                  control={control}
                  errors={errors}
                  watch={watch}
                  register={register}
                  selectedAircraft={selectedAircraft}
                  unregister={unregister}
                />
              )}

              {watch("type")?.value === "meterAndTime" && (
                <>
                  <MeterBasedReminderForm
                    control={control}
                    errors={errors}
                    register={register}
                    watch={watch}
                    unregister={unregister}
                    selectedAircraft={selectedAircraft}
                    showReminderIntervals={false}
                  />
                  <TimeBasedReminderForm
                    control={control}
                    errors={errors}
                    watch={watch}
                    register={register}
                    selectedAircraft={selectedAircraft}
                    unregister={unregister}
                  />
                </>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    color="success"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? `Updating...` : `Mark as Completed`}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

MeterBasedReminderForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.array,
  watch: PropTypes.func,
  register: PropTypes.func,
  selectedAircraft: PropTypes.object,
};

TimeBasedReminderForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.array,
};

ReminderCompletedModal.propTypes = {
  handleClose: PropTypes.func,
  existingData: PropTypes.object,
};
