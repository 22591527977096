// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "src/context";

// Custom styles for the SidenavCollapse
import { collapseText } from "src/containers/Sidenav/styles/sidenavCollapse";

import { useUser } from "src/features/user/UserProvider";
import { useNavigate } from "react-router-dom";
import { UserAvatar } from "src/components/UserAvatar";

function SidenavUserProfile() {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const { user } = useUser();
  const navigate = useNavigate();

  const handleUserProfileClick = () => {
    navigate("/settings/account-settings");
  };

  return (
    <Grid
      container
      wrap="nowrap"
      spacing={0}
      pl={2}
      alignItems="center"
      style={{ cursor: "pointer" }}
      onClick={handleUserProfileClick}
    >
      <Grid item>
        <UserAvatar user={user} />
      </Grid>
      <Grid item>
        <SoftBox
          height="100%"
          mt={0.5}
          lineHeight={1}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
            })
          }
        >
          <SoftTypography variant="h5" fontWeight="medium">
            {user?.displayName}
          </SoftTypography>
          {/* <SoftTypography variant="caption" color="text">
            Edit Profile
          </SoftTypography> */}
        </SoftBox>
      </Grid>
    </Grid>
  );
}

export default SidenavUserProfile;
