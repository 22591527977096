import UpcomingEventsListItem from "./UpcomingEventsListItem";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";

function UpcomingEventsList({ events }) {
  return (
    <SoftBox>
      {events?.length > 0 ? (
        <>
          {events.map((event, index) => {
            function getItem() {
              return (
                <UpcomingEventsListItem
                  key={event.id}
                  icon={event.icon}
                  title={event.title}
                  description={event.description}
                  color={event.color ?? "info"}
                />
              );
            }

            return index === 0 ? (
              getItem()
            ) : (
              <SoftBox key={`${event.id}-first-item`} mt={3.5}>
                {getItem()}
              </SoftBox>
            );
          })}
        </>
      ) : (
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          px={2}
          pb={2}
        >
          <SoftTypography variant="body2" color="secondary">
            No upcoming events, monitoring ground...
          </SoftTypography>
        </SoftBox>
      )}
    </SoftBox>
  );
}

export default UpcomingEventsList;
