import { useMemo, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import SoftBox from "src/components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import { CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { AirplanemodeActive, Info, Settings } from "@mui/icons-material";
import InformationBite from "src/components/InformationBite";

// import axios from 'axios';

export function AircraftLookup({ onNext, onClose, aircrafts }) {
  const [tailNumber, setTailNumber] = useState("");
  const [aircraftLookupError, setAircraftLookupError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [aircraftData, setAircraftData] = useState(null);
  const tailNumbers = useMemo(() => {
    return (aircrafts ?? []).map((aircraft) => aircraft.tailNumber);
  }, [aircrafts]);

  const fetchAircraftDetails = async (ntailNumber) => {
    // const res = await axios.post(
    //   'https://us-central1-preflight-aircraft-db.cloudfunctions.net/nNumberLookup',
    //   { data: { tailNumber: ntailNumber } }
    // );
    // const aircraft = res.data;
    // console.log('Aircraft Result:', aircraft);
    // return aircraft;

    const functions = getFunctions();
    const nNumberLookup = httpsCallable(functions, "nNumberLookup");
    const aircraft = await nNumberLookup({
      tailNumber: ntailNumber,
    });
    return aircraft;
  };

  const handleFetchAircraftDetails = async () => {
    const tailNumberParsed = tailNumber.replace(/[Nn]/, "");
    if (tailNumbers.includes(tailNumberParsed)) {
      setAircraftData(null);
      setAircraftLookupError("Aircraft already added");
      return;
    }

    setAircraftLookupError(null);
    setLoading(true);
    const aircraft = await fetchAircraftDetails(tailNumber);

    if (aircraft?.data?.tailNumber) {
      setAircraftData(aircraft.data);
    } else {
      setAircraftData(null);
      setAircraftLookupError("Aircraft not found. Please try again.");
    }
    setLoading(false);
  };

  return (
    <SoftBox>
      {!loading && (
        <>
          <Grid
            container
            columns={{ xs: 12 }}
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid item xs={7} sm={10}>
              <SoftBox>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Enter the aircraft tail number
                </SoftTypography>
              </SoftBox>
              <SoftInput
                placeholder="Tail Number"
                onChange={(evt) => setTailNumber(evt.target.value)}
                value={tailNumber}
              />
            </Grid>
            <Grid item ml={1}>
              <SoftButton
                disabled={!tailNumber || tailNumber === ""}
                color="primary"
                onClick={handleFetchAircraftDetails}
              >
                Search
              </SoftButton>
            </Grid>
          </Grid>
          {aircraftLookupError && (
            <SoftTypography color="error" variant="caption">
              {aircraftLookupError}
            </SoftTypography>
          )}
        </>
      )}
      <Grid
        container
        columns={{ xs: 12 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {loading && <CircularProgress />}
      </Grid>

      {aircraftData && (
        <>
          <Grid container>
            <Grid item xs={12} marginY={2}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 12 }}>
            <Grid item xs={12} display="flex" alignItems="center" mb={1}>
              <AirplanemodeActive />
              <SoftTypography ml={1}>Basic Information</SoftTypography>
            </Grid>

            <Grid item xs={4}>
              <InformationBite
                label="Manufacturer"
                value={aircraftData?.details?.mfr}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationBite
                label="Model"
                value={aircraftData?.details?.model}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationBite
                label="Year"
                value={aircraftData?.details?.yearMfr}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBite
                label="Type"
                value={aircraftData?.details?.typeAircraft}
              />
            </Grid>
            <Grid item xs={12} marginY={2}>
              <Divider />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" mb={1}>
              <Settings />
              <SoftTypography ml={1}>Engine Information</SoftTypography>
            </Grid>
            <Grid item xs={4}>
              <InformationBite
                label="Engine Manufacturer"
                value={aircraftData?.details?.engine?.mfr}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBite
                label="Engine Model"
                value={aircraftData?.details?.engine?.model}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBite
                label="Engine Type"
                value={aircraftData?.details?.engine?.type}
              />
            </Grid>

            <Grid item xs={12} marginY={2}>
              <Divider />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" mb={1}>
              <Info />
              <SoftTypography ml={1}>Other Information</SoftTypography>
            </Grid>

            <Grid item xs={6}>
              <InformationBite
                label="Mode S Transponder Code (Hex) "
                value={aircraftData?.details?.modeSCode}
              />
            </Grid>

            <Grid item xs={6}>
              <InformationBite
                label="Owner Name"
                value={aircraftData.details.name}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        container
        columns={{ xs: 12 }}
        display="flex"
        justifyContent="flex-end"
      >
        <Grid item xs={12} marginY={2}>
          <Divider />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <SoftButton color="secondary" onClick={onClose}>
            Cancel
          </SoftButton>
          <SoftButton
            color="primary"
            sx={{
              marginLeft: 2,
            }}
            disabled={!aircraftData}
            onClick={() => onNext(aircraftData)}
          >
            Next
          </SoftButton>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
