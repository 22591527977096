import { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useClubs } from "src/features/club/ClubProvider";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Icon } from "@mui/material";

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, title, options, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef();

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label={title}
          name={title}
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Switch</Button>
      </DialogActions>
    </Dialog>
  );
}

function SidenavClubSelect() {
  const {
    userClubs,
    selectedClubId,
    setSelectedClubId,
    selectedLocationId,
    selectedClub,
    setSelectedLocationId,
    selectedLocation,
    clubLocations,
  } = useClubs();

  const [openSelectClub, setOpenSelectClub] = useState(false);
  const [openSelectLocation, setOpenSelectLocation] = useState(false);

  const handleChangeClub = (e) => {
    e.preventDefault();
    setOpenSelectClub(true);
  };
  const handleChangeLocation = (e) => {
    e.preventDefault();
    setOpenSelectLocation(true);
  };

  const handleClubSelect = (club) => {
    setOpenSelectClub(false);

    if (club) {
      setSelectedClubId(club);
    }
  };

  const handleLocationSelect = (location) => {
    setOpenSelectLocation(false);

    if (location) {
      setSelectedLocationId(location);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      pl={2}
      mt={2}
      alignItems="center"
      style={{ cursor: "pointer", position: "sticky", top: 0 }}
    >
      <Grid item xs={12}>
        {userClubs && userClubs.length > 1 && (
          <SoftBox pr={2} className="ClubWrapper">
            <SoftBox
              className="SelectLabel"
              mb={1}
              ml={0.5}
              lineHeight={0}
              display="inline-block"
            >
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Club
              </SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                borderRadius: "0.5rem",
                border: "0.0625rem solid #d2d6da",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              onClick={handleChangeClub}
            >
              <SoftTypography
                variant="body2"
                fontWeight="regular"
                textTransform="capitalize"
                style={{ padding: "0.3rem 0.75rem", fontSize: "0.875rem" }}
              >
                {selectedClub?.name || "Back Office"}
              </SoftTypography>
              <Icon
                sx={{
                  padding: "0.3rem 0",
                  marginRight: "0.75rem",
                  fontSize: "0.875rem",
                }}
              >
                chevron_right
              </Icon>
            </SoftBox>
          </SoftBox>
        )}
        {clubLocations && clubLocations.length > 1 && (
          <SoftBox pr={2} className="LocationWrapper">
            <SoftBox
              className="SelectLabel"
              mb={1}
              ml={0.5}
              lineHeight={0}
              display="inline-block"
            >
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Location
              </SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                borderRadius: "0.5rem",
                border: "0.0625rem solid #d2d6da",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              onClick={handleChangeLocation}
            >
              <SoftTypography
                variant="body2"
                fontWeight="regular"
                textTransform="capitalize"
                style={{ padding: "0.3rem 0.75rem", fontSize: "0.875rem" }}
              >
                {selectedLocation?.icao}
              </SoftTypography>
              <Icon
                sx={{
                  padding: "0.3rem 0",
                  marginRight: "0.75rem",
                  fontSize: "0.875rem",
                }}
              >
                chevron_right
              </Icon>
            </SoftBox>
          </SoftBox>
        )}
      </Grid>
      <ConfirmationDialogRaw
        id="select-club"
        keepMounted
        open={openSelectClub}
        title="Select Club"
        options={userClubs.map((club) => ({
          value: club.id,
          label: club.data().name,
        }))}
        onClose={handleClubSelect}
        value={selectedClubId}
      />
      <ConfirmationDialogRaw
        id="select-location"
        keepMounted
        open={openSelectLocation}
        title="Select Location"
        options={clubLocations.map((location) => ({
          value: location.id,
          label: location.icao,
        }))}
        onClose={handleLocationSelect}
        value={selectedLocationId}
      />
    </Grid>
  );
}

export default SidenavClubSelect;
