import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
import SoftTypography from "src/components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { ThreeDots } from "react-loader-spinner";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc, updateDoc } from "firebase/firestore";

export function AwaitCardTerminalPayment({
  handleClose,
  handleRetry,
  transaction,
}) {
  const transactionRef =
    transaction?.club?.id &&
    doc(getClubTransactionsCollection(transaction.club.id), transaction.id);
  const { data: transactionData } = useRealtimeDocumentData(transactionRef);
  const [failure, setFailure] = useState(null);

  const retryPayment = async () => {
    const payments = transactionData.payments?.map((payment) => ({
      ...payment,
      last_payment_error: undefined,
    }));
    await updateDoc(transactionRef, { payments, reader: {} });
    handleRetry();
  };

  const cancelPayment = async () => {
    // TODO: cancel the payment on the reader
    const functions = getFunctions();
    const cancelReaderAction = httpsCallable(functions, "cancelReaderAction");
    cancelReaderAction({
      readerId: transactionData.reader.id,
    }).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    if (!transactionData) return;

    if (
      !transactionData.reader &&
      transactionData.payments?.[0]?.last_payment_error?.code ===
        "card_declined"
    ) {
      setFailure({
        message: transactionData.payments?.[0]?.last_payment_error?.message,
        status: transactionData.reader?.action?.status,
        code: transactionData.payments?.[0]?.last_payment_error?.code,
      });
      return;
    }

    if (transactionData.reader?.action?.status === "failed") {
      setFailure({
        message: transactionData.reader?.action?.failure_message,
        status: transactionData.reader?.action?.status,
        code: transactionData.reader?.action?.failure_code,
      });
    } else if (
      transactionData.reader?.action?.process_payment_intent?.payment_intent ===
        transactionData.payments?.[0]?.id &&
      transactionData.reader?.action?.status &&
      transactionData.reader?.action?.status !== "in_progress"
    ) {
      handleClose(transactionData.reader);
    }
  }, [transactionData]);

  if (
    !transactionData?.reader &&
    transactionData?.payments?.[0]?.last_payment_error?.code ===
      "card_declined" &&
    failure
  ) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox p={2}>
          <SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              position="relative"
              alignItems="center"
            >
              <SoftTypography variant="h5" fontWeight="bold">
                Processing Card Payment
              </SoftTypography>
            </SoftBox>
            <Divider />
            <SoftBox mt={1.625}>
              <SoftBox
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color="error"
                  fontSize={65}
                >
                  <CreditCardOffIcon />
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color="error"
                  textTransform="capitalize"
                >
                  {failure.status}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold">
                  {failure.message}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              mt={4}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <SoftButton
                variant="outlined"
                type="submit"
                onClick={handleClose}
                color="error"
              >
                Cancel Payment
              </SoftButton>
              {handleRetry && (
                <SoftButton
                  type="submit"
                  onClick={() => retryPayment()}
                  color="primary"
                >
                  Retry
                </SoftButton>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (
    transactionData?.reader?.action?.process_payment_intent?.payment_intent ===
      transactionData?.payments?.[0]?.id &&
    failure
  ) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox p={2}>
          <SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              position="relative"
              alignItems="center"
            >
              <SoftTypography variant="h5" fontWeight="bold">
                Processing Card Payment
              </SoftTypography>
            </SoftBox>
            <Divider />
            <SoftBox mt={1.625}>
              <SoftBox
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color="error"
                  fontSize={65}
                >
                  <CreditCardOffIcon />
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  color="error"
                  textTransform="capitalize"
                >
                  {failure.status}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold">
                  {failure.message}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              mt={4}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <SoftButton
                variant="outlined"
                type="submit"
                onClick={handleClose}
                color="error"
              >
                Cancel Payment
              </SoftButton>
              {handleRetry && (
                <SoftButton
                  type="submit"
                  onClick={() => retryPayment()}
                  color="primary"
                >
                  Retry
                </SoftButton>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox p={2}>
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Processing Card Payment
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <SoftBox
              p={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <div style={{ transform: "scale(0.75)" }}>
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#329baa"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
              <SoftTypography variant="h6" fontWeight="bold">
                Please continue on the card reader...
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={4}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton
              type="submit"
              onClick={() => cancelPayment()}
              color="error"
            >
              Cancel Payment
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AwaitCardTerminalPayment.propTypes = {
  handleClose: PropTypes.func,
  handleRetry: PropTypes.func,
  transaction: PropTypes.object,
};
