import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import { format } from "date-fns";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "@mui/material/Modal";
import SquawkInfo from "src/modals/SquawksModal";
import AddSquawkModal from "src/modals/AddSquawkModal";

import useSquawks from "src/hooks/useSquawks";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { Stack } from "@mui/material";

function SquawksList({ clubId, aircraft }) {
  const { light } = colors;
  const { borderWidth } = borders;
  const { hasAccess } = usePermissions();
  const [open, setOpen] = useState(false);
  const [openAddSquawk, setOpenAddSquawk] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenAddSquawk = () => setOpenAddSquawk(true);
  const handleCloseAddSquawk = () => setOpenAddSquawk(false);
  const [selectedSquawk, setSelectedSquawk] = useState([]);

  const { squawks, loadingSquawks } = useSquawks({
    clubId,
    aircraftId: aircraft.id,
  });

  const handleSquawksSelected = (row) => {
    handleOpen(true);
    setSelectedSquawk(row);
  };
  const renderSquawks = () => {
    if (squawks.length === 0)
      return (
        <SoftBox component="li" pt={1} pr={2}>
          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <SoftTypography variant="button" fontWeight="light">
              {loadingSquawks && <i>Loading Squawks...</i>}
              {!loadingSquawks && "No Open Squawks"}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );

    return squawks.map(
      ({ id, grounded, description, date, verified, status }, key) => (
        <SoftBox
          key={id}
          component="li"
          pt={1}
          pr={2}
          onClick={() => handleSquawksSelected(squawks[key])}
          sx={{ cursor: "pointer" }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftBox mr={2}>
                <SoftBox color={grounded ? "error" : "warning"}>
                  {grounded ? (
                    <CloseIcon fontSize="medium" />
                  ) : (
                    <PriorityHighIcon fontSize="medium" />
                  )}
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" flexDirection="column">
                <Stack direction="row" spacing={1} mb={1}>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    gutterBottom
                  >
                    {format(date.toDate(), "MM/dd/yyyy")}
                  </SoftTypography>
                  {status ? (
                    <SoftTypography variant="button" gutterBottom>
                      {`- ${status}`}
                    </SoftTypography>
                  ) : null}
                </Stack>
                <SoftTypography variant="caption" color="text">
                  {description}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftTypography p={2} variant="caption">
              {verified && "Verified"}
            </SoftTypography>
          </SoftBox>
          {key === squawks.length - 1 ? null : (
            <Divider
              sx={{
                mt: 2,
                mb: 1,
              }}
            />
          )}
        </SoftBox>
      )
    );
  };

  return (
    <SoftBox border={`${borderWidth[1]} solid ${light.main}`}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        bgColor="light"
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Squawks
        </SoftTypography>
        {hasAccess([systemPermissions.ADD_AIRCRAFT_SQUAWKS]) && (
          <SoftButton
            variant="text"
            color="primary"
            size="small"
            onClick={handleOpenAddSquawk}
          >
            <Icon>add</Icon>&nbsp; add new
          </SoftButton>
        )}
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          px={2}
          m={0}
          sx={{
            listStyle: "none",
          }}
        >
          {renderSquawks()}
        </SoftBox>
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SquawkInfo handleClose={handleClose} selectedSquawk={selectedSquawk} />
      </Modal>
      <Modal
        open={openAddSquawk}
        onClose={handleCloseAddSquawk}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddSquawkModal
          handleClose={handleCloseAddSquawk}
          aircraft={aircraft}
        />
      </Modal>
    </SoftBox>
  );
}

// Typechecking props for the Calendar
SquawksList.propTypes = {
  clubId: PropTypes.string,
  aircraft: PropTypes.object,
};

export default SquawksList;
