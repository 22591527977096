import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import { useCollection } from "react-firebase-hooks/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { getStatusCollection } from "src/features/status/collections";

import Swal from "sweetalert2";
import { getFunctions, httpsCallable } from "firebase/functions";

import { format } from "date-fns";

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [usersWithState, setUsersWithState] = useState([]);
  const navigate = useNavigate();

  const deleteUser = (userId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          const functions = getFunctions();
          const deleteUserFunction = httpsCallable(functions, "deleteUser");
          deleteUserFunction({
            userId,
          }).then((deleteResult) => {
            if (deleteResult.error) {
              console.error(deleteResult.error.message);
            }
          });
        }
      });
  };

  const [usersSnapshot, loadingUsers] = useCollection(getUsersCollection(), {
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const [statusSnapshot, loadingStatus] = useCollection(getStatusCollection(), {
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const columns = [
    {
      Header: "name",
      accessor: "displayName",
      width: "20%",
    },
    {
      Header: "email",
      accessor: "email",
      width: "20%",
    },
    {
      Header: "status",
      accessor: "status.state",
      width: "20%",
    },
    {
      Header: "date added",
      accessor: "createdAt",
    },
    {
      Header: "actions",
      accessor: "actions",
      width: "8%",
    },
  ];

  const addUserStatus = async (statuses) => {
    const usersList = users;
    statuses.forEach((status) => {
      const userIndex = usersList.findIndex((user) => user.id === status.id);
      if (userIndex >= 0) usersList[userIndex].status = status;
    });
    usersList.forEach((element) => {
      element.actions = (
        <SoftButton
          variant="outlined"
          color="error"
          onClick={() => deleteUser(element.id)}
        >
          Delete
        </SoftButton>
      );
    });
    setUsersWithState(usersList);
  };

  useEffect(() => {
    if (loadingStatus || loadingUsers) return;

    const statusList = [];
    if (statusSnapshot) {
      statusSnapshot.docs.map((document) => {
        statusList.push({ id: document.id, ...document.data() });
      });
    }
    addUserStatus(statusList);
  }, [users, statusSnapshot]);

  useEffect(() => {
    if (loadingUsers) return;

    const usersList = [];
    if (usersSnapshot) {
      usersSnapshot.docs.map((document) => {
        const userData = document.data();
        usersList.push({
          id: document.id,
          ...userData,
          createdAt: userData.createdAt
            ? format(userData?.createdAt?.toDate(), "MMM dd yyyy")
            : "",
        });
      });
    }
    setUsers(usersList);
  }, [usersSnapshot]);

  const handleRowSelection = (row) => {
    navigate(`/manage-users/user-details/${row.id}`);
  };

  const addNewClub = () => {
    navigate("/manage-clubs/add-new-club");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h4" fontWeight="medium">
                Registered Clubs
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body2" color="text">
                  Each one of these users is special, and the reason PreFlight
                  can exist...
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="gradient"
                  color="info"
                  onClick={addNewClub}
                >
                  Add User
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Card>
          {users.length === 0 && (
            <SoftBox p={2} mx={3} display="flex" justifyContent="center">
              <SoftTypography variant="caption" color="text" textAlign="center">
                <i>Loading Users..</i>
              </SoftTypography>
            </SoftBox>
          )}
          {users.length > 0 && (
            <DataTable
              table={{ columns, rows: usersWithState }}
              onRowSelect={handleRowSelection}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ManageUsers;
