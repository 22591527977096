import { IPermission } from "./permission.interface";

export enum instructorRoleCategory {
  INSTRUCTORS = "INSTRUCTORS",
}

export enum instructorPermissionTypes {
  EDIT_INSTRUCTOR = "EDIT_INSTRUCTOR",
  VIEW_INSTRUCTORS = "VIEW_INSTRUCTORS",
  BOOK_INSTRUCTOR = "BOOK_INSTRUCTOR",
  VIEW_INSTRUCTOR_DETAILS = "VIEW_INSTRUCTOR_DETAILS",
  DELETE_INSTRUCTOR = "DELETE_INSTRUCTOR",
  DISABLE_INSTRUCTOR = "DISABLE_INSTRUCTOR",
}

export const instructorPermissions: IPermission[] = [
  {
    group: instructorRoleCategory.INSTRUCTORS,
    label: "Instructors",
    tooltipGroup: "",
    permissions: {
      [instructorPermissionTypes.VIEW_INSTRUCTORS]: {
        label: "View All Instructors",
        tooltip:
          "Disabling this permission will hide the Instructors menu option from Resources",
      },
      [instructorPermissionTypes.VIEW_INSTRUCTOR_DETAILS]: {
        label: "View Instructor Details",
        tooltip:
          "Toggle if the user can view the details of an instructors from any page",
      },
      [instructorPermissionTypes.BOOK_INSTRUCTOR]: {
        label: "Book Instructors",
        tooltip: "",
      },
      [instructorPermissionTypes.EDIT_INSTRUCTOR]: {
        label: "Edit Instructor",
        tooltip: "",
      },
      [instructorPermissionTypes.DELETE_INSTRUCTOR]: {
        label: "Delete Instructor",
        tooltip: "",
      },
      [instructorPermissionTypes.DISABLE_INSTRUCTOR]: {
        label: "Disable Instructor",
        tooltip: "",
      },
    },
  },
];
