// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftAvatar from "src/components/SoftAvatar";

function CustomerCell({ image = "", name, color = "dark" }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mr={1}>
        <SoftAvatar bgColor={color} src={image} alt={name} size="xs" />
      </SoftBox>
      <SoftTypography
        variant="caption"
        fontWeight="medium"
        color="text"
        sx={{
          lineHeight: 0,
        }}
      >
        {name}
      </SoftTypography>
    </SoftBox>
  );
}

// Typechecking props for the CustomerCell
CustomerCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default CustomerCell;
