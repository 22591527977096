import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import Grade from "src/pages/lms/components/Grade";

import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Popover,
} from "@mui/material";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { useLms } from "src/features/lms/LmsProvider";
import { addDoc, getDocs, query, where } from "firebase/firestore";
import {
  getUserTaskGradingsCollection,
  getUserTaskListsCollection,
} from "src/features/user/collections";
import { useUser } from "src/features/user/UserProvider";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SoftTypography from "src/components/SoftTypography";
import { ExpandMore } from "@mui/icons-material";

const { grey, light } = colors;

function Row({ task, handleEditTask, editable, isLoading }) {
  const { hasAccess } = usePermissions();

  return (
    <>
      <TableRow>
        <TableCell>
          {isLoading ? (
            <CircularProgress size="1.5rem" color="secondary" />
          ) : (
            <Grade value={task.grade?.grade} />
          )}
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="regular">
            {task?.title}
          </SoftTypography>
        </TableCell>
        {editable && hasAccess([systemPermissions.LMS_GRADE_ANY_LESSON]) ? (
          <TableCell align="right" sx={{ pr: 3 }}>
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={(e) => handleEditTask(task, e.target)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
    </>
  );
}

Row.defaultProps = {
  handleEditTask: () => {},
};
Row.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default function TaskTable({ bookingId, studentId, instructorId }) {
  const { userId } = useUser();
  const { getSingleEnrolledCourse, getTasksFromEnrolledCourse } = useLms();
  const [tasks, setTasks] = useState([]);
  const [taskListId, setTaskListId] = useState("");
  const [selectedTask, setSelectedTask] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [formGrade, setFormGrade] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getTasks = async () => {
    const taskListDocs = await getDocs(
      query(
        getUserTaskListsCollection(studentId),
        where("bookingId", "==", bookingId),
        where("deleted", "==", false)
      )
    );
    if (taskListDocs.docs?.length) {
      const taskList = {
        ...taskListDocs.docs[0].data(),
        id: taskListDocs.docs[0].id,
      };
      const enrollmentIds = {};
      const gradesByTaskId = {};
      taskList.taskGrades?.forEach((grade) => {
        enrollmentIds[grade.enrolledCourseId] = grade.enrollmentId;
        gradesByTaskId[grade.taskId] = grade;
      });
      const taskListTasks = [];
      await Promise.all(
        Object.keys(enrollmentIds).map(async (courseId) => {
          const enrolledCourseData = await getSingleEnrolledCourse(
            studentId,
            enrollmentIds[courseId],
            courseId
          );
          taskListTasks.push(
            ...getTasksFromEnrolledCourse(enrolledCourseData, (task) => {
              return (
                gradesByTaskId[task.id]?.enrollmentId ===
                  enrollmentIds[courseId] &&
                gradesByTaskId[task.id]?.enrolledCourseId === courseId
              );
            })
          );
        })
      );
      setTaskListId(taskList.id);
      setTasks(taskListTasks);
    }
  };

  const submitNewGrade = async (gradeValue) => {
    const newGradeData = {
      taskId: selectedTask.id,
      grade: gradeValue,
      deleted: false,
      enrollmentId: selectedTask.enrollmentId,
      lessonId: selectedTask.lessonId,
      studentId,
      createdAt: new Date(),
    };
    const newGrade = await addDoc(
      getUserTaskGradingsCollection(studentId, selectedTask.enrollmentId),
      newGradeData
    );
    setTasks(
      tasks.map((task) =>
        task.id === selectedTask.id
          ? {
              ...selectedTask,
              grade: {
                ...newGradeData,
                id: newGrade.id,
              },
            }
          : task
      )
    );
    setFormGrade(null);
    setIsLoading(false);
  };

  const handleEditTask = (task, target) => {
    setSelectedTask(task);
    setFormGrade(task.grade?.grade || "");
    setAnchorEl(target);
  };

  const handleGradeChange = (event) => {
    setIsLoading(true);
    setAnchorEl(null);
    setFormGrade(event.target.value);
    submitNewGrade(event.target.value);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [tasks]);

  useEffect(() => {
    getTasks();
  }, []);

  if (!taskListId) {
    return null;
  }

  return (
    <Accordion elevation={0} sx={{ border: 1, borderColor: light.main }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          background: "#E9ecef",
        }}
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Tasks Covered
        </SoftTypography>
      </AccordionSummary>
      <AccordionDetails>
        <SoftBox borderRadius="md">
          <TableContainer
            sx={{
              maxHeight: "100%",
              boxShadow: "none",
              borderRadius: "0",
              marginTop: 1,
              overflowY: "scroll",
            }}
          >
            <Table
              stickyHeader
              aria-label="collapsible table"
              style={{ borderSpacing: "0 0.2rem" }}
            >
              <TableHead>
                {tasks.length ? (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>Grade</HeaderCellText>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderCellText>Task</HeaderCellText>
                    </HeaderCell>
                    {instructorId === userId ? (
                      <HeaderCell alignRight>
                        <HeaderCellText alignRight>Actions</HeaderCellText>
                      </HeaderCell>
                    ) : (
                      <HeaderCell />
                    )}
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>
                        <i>No Tasks</i>
                      </HeaderCellText>
                    </HeaderCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {tasks?.map((task) => (
                  <Row
                    task={task}
                    key={task.id}
                    handleEditTask={handleEditTask}
                    editable={instructorId === userId}
                    isLoading={isLoading && task.id === selectedTask.id}
                  />
                )) ?? (
                  <TableRow>
                    <TableCell sx={{ color: grey[500] }}>
                      <i>No Tasks Added</i>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Popover
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <SoftBox
                sx={{ background: "white", borderRadius: 1, paddingX: 0.5 }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    value={formGrade}
                    onChange={handleGradeChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          size="small"
                          sx={{ padding: 0.5 }}
                          color="success"
                        />
                      }
                      label="1"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio size="small" sx={{ padding: 0.5 }} />}
                      label="2"
                      labelPlacement="top"
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio size="small" sx={{ padding: 0.5 }} />}
                      label="3"
                      labelPlacement="top"
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio size="small" sx={{ padding: 0.5 }} />}
                      label="4"
                      labelPlacement="top"
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value="5"
                      control={
                        <Radio
                          size="small"
                          sx={{ padding: 0.5 }}
                          color="error"
                        />
                      }
                      label="5"
                      labelPlacement="top"
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value="I"
                      control={
                        <Radio
                          size="small"
                          sx={{ padding: 0.5 }}
                          color="warning"
                        />
                      }
                      label="I"
                      labelPlacement="top"
                      sx={{ marginLeft: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
              </SoftBox>
            </Popover>
          </TableContainer>
        </SoftBox>
      </AccordionDetails>
    </Accordion>
  );
}

TaskTable.defaultProps = {
  editable: false,
  refreshLesson: () => {},
};

TaskTable.propTypes = {
  bookingId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  instructorId: PropTypes.string.isRequired,
};
