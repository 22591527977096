// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubsCollection } from "src/features/club/collections";

import { doc, updateDoc } from "firebase/firestore";
import Switch from "@mui/material/Switch";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

function PreHeatFees() {
  const { selectedClub, selectedClubId } = useClubs();
  const { preferences } = selectedClub;

  const updatePreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        preferences,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const togglePreHeatFee = (event) => {
    updatePreference("preHeatFee", {
      ...preferences.preHeatFee,
      enabled: event.target.checked,
      amount: preferences?.preHeatFee?.amount ?? 0,
    });
  };

  const handlePreHeatFeeRateChange = (event) => {
    let rate = Number(event.target.value);
    if (!rate || Number.isNaN(rate)) rate = 0;

    updatePreference("preHeatFee", {
      ...preferences.preHeatFee,
      rate,
    });
  };

  return (
    <Card
      id="fuel-reimbursement"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Pre-Heat Fees</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Settings for pre-heat reimbursements on bookings
        </SoftTypography>
      </SoftBox>

      <SoftBox p={3} pb={1} lineHeight={1}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <SoftBox lineHeight={1.4}>
            <SoftTypography
              display="block"
              variant="button"
              fontWeight="medium"
            >
              Enable Pre Heat Fee Reimbursement
            </SoftTypography>
            <SoftTypography variant="caption" color="text" fontWeight="regular">
              If enabled, pilots can add a fixed amount reimbursement for engine
              pre-heating when checking in aircraft
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={2} mr={1}>
            <Switch
              checked={preferences?.preHeatFee?.enabled ?? false}
              onChange={togglePreHeatFee}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {preferences?.preHeatFee?.enabled && (
        <SoftBox pl={3} pr={3} pb={3} lineHeight={1}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            lineHeight={1}
          >
            <SoftBox lineHeight={1.4}>
              <SoftTypography
                display="block"
                variant="button"
                fontWeight="medium"
              >
                Amount
              </SoftTypography>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="regular"
              >
                Enter an amount to be reimbursed
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              lineHeight={1}
            >
              <SoftBox ml={1} width={80}>
                <SoftCurrencyInput
                  currencySymbol="$"
                  placeholder="0"
                  decimalPlaces={2}
                  outputFormat="number"
                  onBlur={handlePreHeatFeeRateChange}
                  value={preferences?.preHeatFee?.rate ?? 0}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      )}
    </Card>
  );
}

export default PreHeatFees;
