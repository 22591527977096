import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import { query, where } from "firebase/firestore";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import SoftTypography from "src/components/SoftTypography";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubBookingsCollection } from "src/features/club/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getHobbsOrTacks,
  getInstructorOrPilot2,
  getPilot,
} from "src/features/utils/booking.utils";
import { IAircraft } from "src/interfaces";
import { IBooking } from "src/interfaces/booking.interface";

interface IAircraftLogs {
  aircraft: IAircraft;
}
function AircraftLogs({ aircraft }: IAircraftLogs) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("extendedProps.aircraft.value", "==", aircraft.id)
  );
  const { data: aircraftLogs, isDataLoaded: logsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true) as {
      data: IBooking[];
      isDataLoaded: boolean;
    };

  return (
    <Stack direction="column" gap="4px" flex={1}>
      <SoftLoader isLoading={!logsLoaded}>
        <Stack direction="row" justifyContent="space-between">
          <SoftTypography sx={{ fontWeight: 700, fontSize: 14 }}>
            {!aircraftLogs.length && "No Logs"}
          </SoftTypography>
        </Stack>
        {aircraftLogs.map((aircraftLog) => {
          const secondPerson = getInstructorOrPilot2(aircraftLog);
          const hobsOrTacks = getHobbsOrTacks(aircraftLog);
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              key={aircraftLog.id}
              sx={{
                padding: "10px 0",
                "&:hover": { background: "#FAFAFA", cursor: "pointer" },
              }}
              gap="22px"
            >
              <Stack direction="row" gap="18px" justifyContent="space-between">
                <SoftTypography sx={{ fontWeight: 700, fontSize: 14 }}>
                  {format(aircraftLog.createdAt.toDate(), "MM/dd/yyyy, HH:hh")}
                </SoftTypography>
                <Stack direction="column">
                  <SoftTypography sx={{ fontWeight: 500, fontSize: 13 }}>
                    Pilot: {getPilot(aircraftLog).label}
                  </SoftTypography>
                  {!!secondPerson.label && (
                    <SoftTypography sx={{ fontWeight: 500, fontSize: 13 }}>
                      {secondPerson.displayLabel}
                      {secondPerson.label}
                    </SoftTypography>
                  )}
                </Stack>
                {hobsOrTacks.hasData && (
                  <Stack direction="column">
                    <SoftTypography sx={{ fontWeight: 700, fontSize: 13 }}>
                      {hobsOrTacks.displayName} Out: {hobsOrTacks.out}
                    </SoftTypography>
                    <SoftTypography sx={{ fontWeight: 700, fontSize: 13 }}>
                      {hobsOrTacks.displayName} In: {hobsOrTacks.in}
                    </SoftTypography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          );
        })}
      </SoftLoader>
    </Stack>
  );
}
export default AircraftLogs;
