import { IBooking } from "src/interfaces/booking.interface";
import get from "lodash/get";

export const getPilot = (booking: IBooking) => {
  return {
    label: get(booking, "extendedProps.pilot.label", "No Pilot"),
    value: get(booking, "extendedProps.pilot.value", ""),
  };
};

export const getInstructorOrPilot2 = (booking: IBooking) => {
  const instructor = get(booking, "extendedProps.instructor");
  const pilot2 = booking.pilot2;
  const label = instructor?.label || pilot2?.label || "";
  const value = instructor?.value || pilot2?.value || "";
  return {
    label,
    value,
    displayLabel: instructor?.label
      ? "Instructor"
      : pilot2?.label
      ? "Pilot 2"
      : "",
  };
};

export const getHobbsOrTacks = (booking: IBooking) => {
  const { flightData } = booking;
  const isHobbs = flightData?.endHobbs && flightData?.startHobbs;
  const outData = flightData?.endHobbs || flightData?.endTach1;
  const inData = flightData?.startHobbs || flightData?.startTach1;
  return {
    out: outData,
    in: inData,
    hasData: !!outData && !!inData,
    displayName: isHobbs ? "Hobbs" : "Tach"
  };
};
