interface IUseStorage {
  engine?: "localStorage" | "sessionStorage";
}
export const useStorage = ({ engine = "localStorage" }: IUseStorage = {}) => {
  const storage = engine === "localStorage" ? localStorage : sessionStorage;
  const parsedItem = (obj: string | null) => (obj ? JSON.parse(obj) : null);

  const getStorageItem = <T>(key: string) => {
    const item = storage.getItem(key);
    return parsedItem(item) as T;
  };

  const saveStorageItem = (key: string, obj: any) => storage.setItem(key, JSON.stringify(obj));

  return {
    getStorageItem,
    saveStorageItem,
  };
};
