// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "src/containers/DefaultNavbar";
import PageLayout from "src/containers/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "src/routes/page.routes";

// Images
import loginBg2 from "src/assets/images/login-bg2.jpg";

function Terms() {
  return (
    <PageLayout>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          route: "/auth/sign-in",
          label: "Go To App",
        }}
        transparent
        light
      />
      <SoftBox
        position="relative"
        width="100vw"
        height="100vh"
        sx={{
          overflow: "hidden",
        }}
      >
        <SoftBox
          component="img"
          src={loginBg2}
          alt="background-image"
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <SoftBox
          position="absolute"
          width="100%"
          height="100%"
          bgColor="dark"
          variant="gradient"
          opacity={0.6}
        />
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <Grid item xs={11}>
            <Card>
              <SoftBox textAlign="center" p={6}>
                <SoftBox mb={3} px={1}>
                  <SoftTypography variant="h2" fontWeight="bold">
                    Terms Of Service
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    variant="h5"
                    fontWeight="regular"
                    color="secondary"
                  >
                    Last updated: 01/01/2021
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="secondary"
                  >
                    Please read these terms of service carefully before using
                    Our Service.
                  </SoftTypography>

                  <SoftTypography
                    variant="h5"
                    fontWeight="regular"
                    color="secondary"
                  >
                    Your access to and use of the Service is conditioned on your
                    acceptance of and compliance with these Terms. These Terms
                    apply to all visitors, users and others who access or use
                    the Service.
                  </SoftTypography>
                  <SoftTypography
                    variant="h5"
                    fontWeight="regular"
                    color="secondary"
                  >
                    Payment processing services for Instructors, Schools, and
                    Clubs on PreFlight are provided by Stripe and are subject to
                    the Stripe Connected Account Agreement, which includes the
                    Stripe Terms of Service (collectively, the “Stripe Services
                    Agreement”). By agreeing to these terms or continuing to
                    operate as an Instructor, School or Club on PreFlight, you
                    agree to be bound by the Stripe Services Agreement, as the
                    same may be modified by Stripe from time to time. As a
                    condition of PreFLight enabling payment processing services
                    through Stripe, you agree to provide PreFlight accurate and
                    complete information about you and yourself or your
                    business, and you authorize PreFlight to share it and
                    transaction information related to your use of the payment
                    processing services provided by Stripe.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

export default Terms;
