import { PropTypes } from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import { UserAvatar } from "src/components/UserAvatar";
import SoftTypography from "src/components/SoftTypography";

function UserInfo({ user, userPermissions }) {
  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item ml={2} my={2}>
          <UserAvatar user={user} />
        </Grid>
        <Grid item>
          <SoftBox height="100%" mt={0.5} lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium">
              {user?.displayName}
            </SoftTypography>
            <SoftTypography
              variant="button"
              color="text"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {userPermissions?.userType}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid
          item
          sx={{
            ml: "auto",
          }}
        >
          <SoftBox display="flex" pt={1} pr={2}>
            <SoftTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              ID: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{user?.uid ?? "None Provided"}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" pt={1} pr={2}>
            <SoftTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Email: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{user?.email}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" py={1} pr={2}>
            <SoftTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Phone: &nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{user?.phoneNumber ?? "None Provided"}
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
    </Card>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  userPermissions: PropTypes.object.isRequired,
};

export default UserInfo;
