import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useUser } from "src/features/user/UserProvider";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import SoftInput from "src/components/SoftInput";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import * as toastr from "toastr";
import { doc, updateDoc } from "firebase/firestore";
import { getUsersCollection } from "src/features/user/collections";
import { CircularProgress } from "@mui/material";
import { UserAvatar } from "src/components/UserAvatar";

function PhotoSelector({ onSelected }) {
  return (
    <SoftBox p={2}>
      <SoftBox mb={1} ml={0.5}>
        <SoftTypography component="label" variant="caption" fontWeight="bold">
          Change photo
        </SoftTypography>
      </SoftBox>
      <SoftInput
        type="file"
        inputProps={{ accept: "image/*" }}
        onChange={onSelected}
      />
    </SoftBox>
  );
}

function UploadingIndicator() {
  return (
    <SoftBox p={2}>
      <Grid container spacing={1}>
        <Grid item>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Uploading
          </SoftTypography>
        </Grid>
        <Grid item>
          <CircularProgress size="1rem" />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

function Header() {
  const [visible, setVisible] = useState(true);
  const [uploading, setUploading] = useState(false);
  const { user, userId } = useUser();
  const { userPermissions } = useUserPermissions();

  const handleSetVisible = () => setVisible(!visible);

  const onChangePicture = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      try {
        setUploading(true);
        const storage = getStorage();

        const fileType = selectedFile.name.split(".")[1];
        const fileName = `users/${userId}/${uuidv4()}.${fileType}`;
        const storageRef = ref(storage, fileName);

        let url;
        try {
          await uploadBytes(storageRef, selectedFile);
          url = await getDownloadURL(storageRef);
        } catch (err) {
          console.error("Unable to upload new user profile picture", err);
          toastr.error("Unable to upload", "Error");
          return;
        }

        try {
          if (user.photoURL) {
            // Delete old photo
            const existingFileRef = ref(storage, user.photoURL);
            await deleteObject(existingFileRef);
          }
        } catch (err) {
          console.error("Unable to delete old user profile picture", err);
        } finally {
          const userRef = doc(getUsersCollection(), userId);
          await updateDoc(userRef, { photoURL: url });

          toastr.success("Profile picture updated", "Success");
        }
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <UserAvatar user={user} />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {user.displayName}
              </SoftTypography>
              <SoftTypography
                variant="button"
                color="text"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {userPermissions.userType}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              ml: "auto",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent={{
                md: "flex-end",
              }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="regular">
                Show online status
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      {uploading ? (
        <UploadingIndicator />
      ) : (
        <PhotoSelector onSelected={onChangePicture} />
      )}
    </Card>
  );
}

export default Header;
