import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Box)(() => {
  return {
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
  };
});
