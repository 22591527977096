import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { getFunctions, httpsCallable } from "firebase/functions";

const schema = yup
  .object({
    location: yup.object().required("Select a location."),
    code: yup.string().required("Enter the code displayed on the card reader."),
    label: yup.string().required("Provide a name for the reader."),
  })
  .required();

export function AddPaymentTerminalModal({ handleClose }) {
  const { clubLocations } = useClubs();
  const [clubLocationOptions, setClubLocationOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { stripeLocationId } = data.location;

    const functions = getFunctions();
    const addPaymentTerminal = httpsCallable(functions, "addPaymentTerminal");
    const terminalAdded = await addPaymentTerminal({
      stripeLocationId,
      registrationCode: data.code,
      label: data.label,
    });

    if (terminalAdded?.data?.type === "StripeInvalidRequestError") {
      console.error(terminalAdded.data);
      setError("code", {
        message: terminalAdded.data?.raw?.message.includes("expired")
          ? "Registration code has expired!"
          : "Invalid code. Please try again.",
        type: "manual",
        shouldFocus: true,
      });
      return;
    }

    handleClose(terminalAdded);
  };

  useEffect(() => {
    setClubLocationOptions(
      clubLocations
        .map((location) => ({
          label: `${location.icao} - ${location.name}`,
          value: location.id,
          ...location,
        }))
        .filter((location) => location.stripeLocationId)
    );
  }, [clubLocations]);

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add New Terminal
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Location
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="location"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={clubLocationOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.location?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.location.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Terminal Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Front Desk"
                  {...register("label", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Terminal Code
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. swim-awesome-green"
                  {...register("code", {
                    required: true,
                  })}
                />
                {errors?.code?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.code.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add Terminal
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddPaymentTerminalModal.propTypes = {
  handleClose: PropTypes.func,
};
