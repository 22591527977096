import SignIn from "src/pages/auth/SignIn";
import SignUp from "src/pages/auth/SignUp";
import ClubSelect from "src/pages/auth/ClubSelect";
import PasswordReset from "src/pages/auth/PasswordReset";
import SetNewPassword from "src/pages/auth/SetNewPassword";
import AcceptInvite from "src/pages/auth/AcceptInvite";
import Terms from "src/pages/terms";
import { IntroFlights } from "src/pages/intro-flights";
import IntroFlightsPayment from "src/pages/intro-flights/checkout";
import { IntroFlightConfirmation } from "src/pages/intro-flights/confirmation";

const authRoutes = [
  {
    name: "Sign In",
    key: "sign-in",
    route: "/auth/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: "/auth/sign-up",
    component: <SignUp />,
  },
  {
    name: "Club/School Select",
    key: "club-select",
    route: "/auth/club-select",
    component: <ClubSelect />,
  },
  {
    name: "Password Reset",
    key: "pw-reset",
    route: "/auth/reset",
    component: <PasswordReset />,
  },
  {
    name: "Create New Password",
    key: "pw-set",
    route: "/auth/setPassword",
    component: <SetNewPassword />,
  },
  {
    name: "Accept Invite",
    key: "accept-invite",
    route: "/auth/accept-invite/:inviteId",
    component: <AcceptInvite authenticated={false} />,
  },
  {
    name: "Intro Flights",
    key: "intro-flight",
    route: "/intro-flight/:clubId/:locationId",
    component: <IntroFlights />,
  },
  {
    name: "Direct Intro Flights",
    key: "intro-flight",
    route: "/intro-flight/:clubId/:locationId/:introFlightId",
    component: <IntroFlights />,
  },
  {
    name: "Direct Intro Flights Payment",
    key: "intro-flight-pay",
    route: "/intro-flight/:clubId/:locationId/:introFlightId/payment",
    component: <IntroFlightsPayment />,
  },
  {
    name: "Intro Flights Payment",
    key: "intro-flight-pay",
    route: "/intro-flight/:clubId/:locationId/payment",
    component: <IntroFlightsPayment />,
  },
  {
    name: "Intro Flights Confirmation",
    key: "intro-flight-confirm",
    route: "/intro-flight/:clubId/confirmation/:clubBillingEntryId",
    component: <IntroFlightConfirmation />,
  },
  {
    name: "Terms of Service",
    key: "terms",
    route: "/terms",
    component: <Terms />,
  },
];

export default authRoutes;
