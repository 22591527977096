import { useState } from "react";

/**
 * hook to be used as a wrapper around a promise so that isLoading, data and error objects are handled
 * automatically
 * @param param0
 * @returns
 */
export const usePromiseLoading = <
  T extends (...args: Parameters<T>) => ReturnType<T>
>({
  fn,
  throwError = true,
}: {
  fn: T;
  throwError?: boolean;
}): {
  caller: T;
  isLoading: boolean;
  error: any;
  data: Awaited<ReturnType<T>> | null;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<Awaited<ReturnType<T>> | null>(null);
  const caller = async (...args: Parameters<T>) => {
    try {
      setIsLoading(true);
      setError(null);
      const result = await fn(...args);
      setData(result as any);
      return result;
    } catch (err: any) {
      setError(err);
      if (throwError) throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { caller: caller as T, isLoading, error, data };
};
