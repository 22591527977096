import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";

function BankAccount({ register, formErrors, formControl }) {
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            How do you want to be paid?
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Provide the details below for receiving payouts. You can update this
          anytime in club settings should these details change!
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={8}>
            <Grid container rowSpacing={0} columnSpacing={3}>
              <Grid item xs={8}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Account Holder Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("account.name", {
                        required: true,
                      })}
                    />
                    {formErrors?.account?.name?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.account.name.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Account Type
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={formControl}
                      name="account.type"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          placeholder="Account Type"
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                          options={[
                            { value: "company", label: "Business" },
                            { value: "individual", label: "Personal" },
                          ]}
                        />
                      )}
                    />
                    {formErrors?.account?.type?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.account.type.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Account Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput {...register("account.number")} />
                    {formErrors?.account?.number?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.account.number.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={2}>
                  <SoftBox mb={2} lineHeight={1.25}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Routing Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      {...register("account.routing", {
                        required: true,
                      })}
                    />
                    {formErrors?.account?.routing?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {formErrors.account.routing.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

BankAccount.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  formControl: PropTypes.any,
};

export default BankAccount;
