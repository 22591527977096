// @mui material components
import Icon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";
import { IOwnerState } from "./softInput.config";

export default styled(Icon)<{ ownerState: Partial<IOwnerState> }>(
  ({ theme, ownerState }) => {
    const { typography } = theme as any;
    const { size } = ownerState;

    const { fontWeightBold, size: fontSize } = typography;

    return {
      fontWeight: fontWeightBold,
      fontSize: size === "small" && `${fontSize.md} !important`,
    } as any;
  }
);
