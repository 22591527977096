// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

const { borderWidth } = borders;
const { light } = colors;

const tableRow = {
  styleOverrides: {
    root: {
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
      borderRadius: 0,
      boxShadow: "none"
    },
  },
};

export default tableRow;