import PropTypes from "prop-types";
import SoftAvatar from "src/components/SoftAvatar";
import { getProfileImageURL } from "src/features/utils";

function UserAvatar({ user, size = "xl" }) {
  return (
    <SoftAvatar
      src={getProfileImageURL({ user })}
      alt="profile-image"
      variant="rounded"
      size={size}
      shadow="sm"
    />
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
};

export { UserAvatar, getProfileImageURL };
