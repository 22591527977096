import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import { format } from "date-fns";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const getTableColumns = (onAction) => [
  {
    Header: "start",
    accessor: "start",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? format(value, "Pp") : "-"}`} />
    ),
  },
  {
    Header: "end",
    accessor: "end",
    Cell: ({ value }) => (
      <DefaultCell value={`${value ? format(value, "Pp") : "-"}`} />
    ),
  },
  {
    Header: "action",
    accessor: "action",
    width: "14%",
    align: "center",
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftBox display="flex" flexDirection="row">
          {/*    <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={() => onAction(TableActions.DELETE, original)}
          >
            <Icon>delete</Icon>
          </IconButton> */}
          <IconButton
            aria-label="edit"
            sx={iconButtonStyles}
            onClick={() => onAction(TableActions.EDIT, original)}
          >
            <Icon>edit</Icon>
          </IconButton>
        </SoftBox>
      </SoftBox>
    ),
  },
];
