import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Settings page components
import FormField from "src/pages/user/account/components/FormField";

// Data
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftButton from "src/components/SoftButton";
import { doc, setDoc } from "firebase/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubsCollection } from "src/features/club/collections";

const schema = yup
  .object({
    name: yup.string().required("This is required."),
    email: yup.string().email().required(),
    number: yup.number().required(),
    website: yup.string().url(),
  })
  .required();

function ClubInfo() {
  const { selectedClub, selectedClubId } = useClubs();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedClub.name,
      email: selectedClub.email,
      number: selectedClub.number,
      website: selectedClub.website,
    },
  });

  const onSubmit = async (data) => {
    try {
      await setDoc(doc(getClubsCollection(), selectedClubId), data, {
        merge: true,
      });
      toastr.success("Your club info has been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your info.", "Error");
    }
  };

  const website = watch("website");
  const detailWebsiteError = () => {
    if (!website.startsWith("http"))
      return "- Url must start with http or https followed by ://";
    return "";
  };

  return (
    <Card
      id="club-info"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Club/School Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormField
                  {...field}
                  label="name"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="email"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.email}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="phone number"
                  placeholder="+1 (XXX) XXX-XXXX"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.number}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="website"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="website"
                  placeholder="https://www.example.com"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.website}
                />
              )}
            />
            {errors?.website?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.website.message} {detailWebsiteError()}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={!isDirty}
            >
              Save
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default ClubInfo;
