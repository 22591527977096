import { openModal$ } from "../modalConfiguration";

export interface OpenConfirmModal {
  title?: string | React.ReactNode;
  body?: React.ReactNode;
}

export const openConfirmModal = async ({ title, body }: OpenConfirmModal) => {
  return new Promise((resolve) => {
    openModal$.next({
      modalName: "CONFIRM_BOOKING_MODAL",
      modalProps: {
        title,
        body,
        onAccept: () => resolve(true),
        onCancel: () => resolve(false),
      },
    });
  });
};
