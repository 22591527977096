import { Build, Code, FolderOpen, Handyman, MenuBook } from "@mui/icons-material";
import FlightIcon from "@mui/icons-material/Flight";
import { ListItem, ListItemIcon, Stack } from "@mui/material";
import { collapseIconBox } from "src/containers/Sidenav/styles/sidenavCollapse";
import { useSoftUIController } from "src/context";
import { tabsEnum } from "../AircraftModal.config";

const TABS = [
  {
    icon: <FlightIcon style={{ transform: "rotate(45deg)" }} />,
    type: tabsEnum.AIRCRAFT,
    title: "Description"
  },
  {
    icon: <MenuBook />,
    type: tabsEnum.BOOK,
    title: "Logs"
  },
  {
    icon: <Build />,
    type: tabsEnum.TOOLS,
    title: "Maintenance"
  },
  {
    icon: <FolderOpen />,
    type: tabsEnum.FOLDER,
    title: "Documents"
  },
  {
    icon: <Handyman />,
    type: tabsEnum.EDIT,
    title: "Edit"
  },
  {
    icon: <Code />,
    type: tabsEnum.SEARCH,
    title: "Previous search"
  },
];

interface IToolbar {
  selectedTabs: Array<tabsEnum>;
  onTabSelected: (tab: tabsEnum) => void;
}
function Toolbar({ selectedTabs, onTabSelected }: IToolbar) {
  const [controller] = useSoftUIController() as any;
  const { transparentSidenav, sidenavColor } = controller;

  return (
    <Stack direction="column" p="0 20px" height="100%">
      <ListItem
        component="li"
        sx={{ flexDirection: "column", gap: "20px", height: "100%" }}
      >
        {TABS.map((tab) => (
          <ListItemIcon
            className="clickable"
            onClick={() => onTabSelected(tab.type)}
            key={tab.type}
            title={tab.title}
            sx={(theme) => ({
              ...collapseIconBox(
                theme,
                {
                  active: selectedTabs.includes(tab.type),
                  transparentSidenav,
                  sidenavColor,
                },
                { defaultSize: 36, customBackground: () => "#F5F5F6" }
              ),
              marginTop: tab.type === tabsEnum.SEARCH ? "auto" : "unset",
            })}
          >
            {tab.icon}
          </ListItemIcon>
        ))}
      </ListItem>
    </Stack>
  );
}

export default Toolbar;
