// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Custom styles for Calendar
import CalendarRoot from "../CalendarRoot";

import { getClassName, prepareEventForCalendar } from "../uiHelper";

function ListCalendar({ header = { title: "", date: "" }, events, ...rest }) {
  const preparedEvents = events
    ? events.flatMap((event) => {
        const preparedEvent = prepareEventForCalendar(event);
        preparedEvent.className = getClassName(event, "list");
        return preparedEvent;
      })
    : [];

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox pt={2} px={2} lineHeight={1}>
        {header.title ? (
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {header.title}
          </SoftTypography>
        ) : null}
        {header.date ? (
          <SoftTypography
            component="p"
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {header.date}
          </SoftTypography>
        ) : null}
      </SoftBox>
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          plugins={[
            listPlugin,
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
          ]}
          schedulerLicenseKey={import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY}
          events={preparedEvents}
          height="100%"
        />
      </CalendarRoot>
    </Card>
  );
}

// Typechecking props for the Calendar
ListCalendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default ListCalendar;
