import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { useClubs } from "src/features/club/ClubProvider";
import SoftInput from "src/components/SoftInput";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getMeetingRoomsCollection } from "src/features/meeting-room/collections";

import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "visible",
  boxShadow: 24,
  p: 4,
};

export function AddEditMeetingRoomModal({ selectedMeetingRoom, handleClose }) {
  const { addData, updateData } = entityCrudUtils();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();

  const doesRoomAlreadyExist = (value) => {
    if (selectedMeetingRoom && selectedMeetingRoom.title === value) return true;

    return !meetingRooms.map((x) => x.title).includes(value);
  };

  const schema = yup
    .object({
      title: yup
        .string()
        .required("A room name is required.")
        .test("roomExists", "This room already exists.", doesRoomAlreadyExist),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      title: selectedMeetingRoom ? selectedMeetingRoom.title : "",
    },
    resolver: yupResolver(schema),
  });

  const handleAddMeetingRoom = async (data) => {
    const newMeetingRoom = {
      title: data.title,
      createdAt: new Date(),
    };

    await addData(
      getMeetingRoomsCollection(selectedClubId, selectedLocationId),
      newMeetingRoom
    );
  };

  const handleUpdateMeetingRoom = async (data) => {
    if (data.title === selectedMeetingRoom.title) return;

    const updatedMeetingRoom = {
      ...selectedMeetingRoom,
      title: data.title,
    };

    await updateData(
      {
        entity: getMeetingRoomsCollection(selectedClubId, selectedLocationId),
        pathSegmentsArr: [selectedMeetingRoom.id],
      },
      updatedMeetingRoom
    );
  };

  const onHandleSubmit = async (data) => {
    if (selectedMeetingRoom) {
      await handleUpdateMeetingRoom(data);
    } else {
      await handleAddMeetingRoom(data);
    }

    handleClose();
  };

  return (
    <Card sx={style}>
      <SoftBox p={2}>
        <SoftTypography variant="h5" fontWeight="bold">
          {selectedMeetingRoom ? "Edit" : "Add"} Meeting Room
        </SoftTypography>
        <SoftBox
          mt={2.625}
          justifyContent="center"
          display="flex"
          component="form"
          role="form"
          onSubmit={handleSubmit(onHandleSubmit)}
        >
          <SoftBox pr={2}>
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value, ref } }) => (
                <SoftInput
                  inputRef={ref}
                  value={value}
                  placeholder="Enter meeting room name"
                  rows={1}
                  onChange={onChange}
                  width="100%"
                />
              )}
            />
            {errors?.title?.message && (
              <SoftTypography ml={8} marginTop={1} fontSize={12} color="error">
                {errors.title.message}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox pr={2}>
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="medium"
            >
              {selectedMeetingRoom ? "Update" : "Add"}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddEditMeetingRoomModal.propTypes = {
  handleClose: PropTypes.func,
};
