import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export default function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    const route = location.pathname.split("/").slice(1);
    const title = route[route.length - 1].replace("-", " ");
    ReactGA.set({ page: location.pathname });
    ReactGA.send({
      hitType: "pageview",
      page_location: location.pathname,
      page_title: title,
    });
  }, [location]);

  return <div />;
}
