import { useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftBadge from "src/components/SoftBadge";

import { useReactToPrint } from "react-to-print";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import PrintInvoice from "src/components/Printing/Invoice/PrintInvoice";
import ModalContainer from "src/components/ModalContainer";

function InvoiceItem({
  date,
  id,
  totalAmount,
  _data,
  noGutter = false,
  invoice,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const printableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const containsValue = (arr, key, value) => {
    return arr?.some((obj) =>
      JSON.stringify(obj).includes(`"${key}":"${value}"`)
    );
  };

  const getStatus = () => {
    let status;
    const amountPaid =
      Math.round(
        (invoice?.payments?.reduce((acc, payment) => {
          if (payment?.status === "succeeded") {
            return acc + (payment?.amount ?? 0) / 100;
          } else {
            return acc;
          }
        }, 0) ?? 0) * 100
      ) / 100;

    const totalCost = Math.round(invoice.totalCost * 100) / 100 || 0;

    if (totalCost <= 0) {
      status = (
        <SoftBadge
          variant="contained"
          color="success"
          size="xs"
          badgeContent="Paid"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (
      amountPaid >= totalCost &&
      (!invoice.refunded || invoice.refunded === 0)
    ) {
      status = (
        <SoftBadge
          variant="contained"
          color="success"
          size="xs"
          badgeContent="Paid"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (
      amountPaid < totalCost &&
      amountPaid > 0 &&
      (!invoice.refunded || invoice.refunded === 0)
    ) {
      status = (
        <SoftBadge
          variant="contained"
          color="warning"
          size="xs"
          badgeContent="Partially Paid"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (invoice.refunded && invoice.refunded >= totalCost) {
      status = (
        <SoftBadge
          variant="contained"
          color="dark"
          size="xs"
          badgeContent="Refunded"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (invoice.refunded && invoice.refunded < totalCost) {
      status = (
        <SoftBadge
          variant="contained"
          color="warning"
          size="xs"
          badgeContent="Partially Refunded"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (
      invoice.payments?.some((paymt) => paymt.status === "processing")
    ) {
      status = (
        <SoftBadge
          variant="contained"
          color="warning"
          size="xs"
          badgeContent="Processing Payment"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else if (
      invoice.payments?.some((paymt) => paymt.status !== "succeeded") &&
      containsValue(
        invoice.payments?.[invoice.payments.length - 1]?.charges?.data,
        "status",
        "failed"
      )
    ) {
      status = (
        <SoftBadge
          variant="contained"
          color="error"
          size="xs"
          badgeContent="Failed"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    } else {
      status = (
        <SoftBadge
          variant="contained"
          color="error"
          size="xs"
          badgeContent="Unpaid"
          container
          sx={{
            marginRight: 1,
          }}
        />
      );
    }

    return status;
  };

  return (
    <SoftBox
      component="li"
      key={id}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SoftBox
        lineHeight={1.125}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => navigate(`/billing/invoices/${invoice.id}`)}
      >
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {date}
        </SoftTypography>
        {/* <SoftTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </SoftTypography> */}
      </SoftBox>
      <SoftBox display="flex" alignItems="center">
        {getStatus()}
        <SoftTypography variant="button" fontWeight="regular" color="text">
          <CurrencyFormat
            value={totalAmount}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            prefix="$"
          />
        </SoftTypography>
        <SoftBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          <Icon fontSize="small">picture_as_pdf</Icon>
          <SoftTypography variant="button" fontWeight="bold">
            &nbsp;PDF
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          <Icon fontSize="small">print</Icon>
          <SoftBox style={{ display: "none" }}>
            <SoftBox ref={printableRef}>
              <PrintInvoice invoiceId={invoice.id} showPageControls={false} />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <ModalContainer padding={0} handleClose={handleClose}>
          <SoftBox>
            <PrintInvoice invoiceId={invoice.id} showPageControls={false} />
          </SoftBox>
        </ModalContainer>
      </Modal>
    </SoftBox>
  );
}

// Typechecking props for the Invoice
InvoiceItem.propTypes = {
  date: PropTypes.string.isRequired,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  totalAmount: PropTypes.number.isRequired,
  noGutter: PropTypes.bool,
};

export default InvoiceItem;
