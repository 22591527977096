import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftInput
import SoftInputRoot from "src/components/SoftInput/SoftInputRoot";
import SoftInputWithIconRoot from "src/components/SoftInput/SoftInputWithIconRoot";
import SoftInputIconBoxRoot from "src/components/SoftInput/SoftInputIconBoxRoot";
import SoftInputIconRoot from "src/components/SoftInput/SoftInputIconRoot";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "src/context";
import { InputBaseProps } from "@mui/material";
import { IOwnerState } from "./softInput.config";

type softInputType = Omit<InputBaseProps, "size"> & {
  height?: string;
  icon?: {
    component: React.ReactNode | boolean;
    direction: "none" | "left" | "right";
  };
  bold?: boolean;
  success?: boolean;
  ref?: any;
  size?: "small" | "medium" | "large";
} & Partial<IOwnerState>;

const SoftInput = forwardRef<HTMLDivElement, softInputType>(
  (
    {
      height,
      size = "medium" as const,
      icon = {
        component: false,
        direction: "none",
      },
      error = false,
      bold = false,
      success = false,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    let template;
    const [controller] = useSoftUIController() as any;
    const { direction } = controller;
    const iconDirection = icon?.direction;

    if (icon?.component && icon?.direction === "left") {
      template = (
        <SoftInputWithIconRoot
          ref={ref}
          ownerState={{
            error,
            success,
            bold,
            disabled,
          }}
        >
          <SoftInputIconBoxRoot
            ownerState={{
              height,
              size,
              bold,
            }}
          >
            <SoftInputIconRoot
              fontSize="small"
              ownerState={{
                height,
                size,
                bold,
              }}
            >
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
          <SoftInputRoot
            {...rest}
            ownerState={{
              height,
              size,
              error,
              bold,
              success,
              iconDirection,
              direction,
              disabled,
            }}
          />
        </SoftInputWithIconRoot>
      );
    } else if (icon?.component && icon?.direction === "right") {
      template = (
        <SoftInputWithIconRoot
          ref={ref}
          ownerState={{
            error,
            bold,
            success,
            disabled,
          }}
        >
          <SoftInputRoot
            {...rest}
            ownerState={{
              height,
              size,
              error,
              success,
              bold,
              iconDirection,
              direction,
              disabled,
            }}
          />
          <SoftInputIconBoxRoot
            ownerState={{
              height,
              size,
            }}
          >
            <SoftInputIconRoot
              fontSize="small"
              ownerState={{
                height,
                size,
                bold,
              }}
            >
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
        </SoftInputWithIconRoot>
      );
    } else {
      template = (
        <SoftInputRoot
          {...rest}
          ref={ref}
          ownerState={{
            height,
            size,
            error,
            bold,
            success,
            disabled,
          }}
        />
      );
    }

    return template;
  }
);

// Typechecking props for the SoftInput
const sizePropType = PropTypes.oneOf([
  "small",
  "medium",
  "large",
]) as PropTypes.Validator<"small" | "medium" | "large">;
const booleanPropType = PropTypes.bool as PropTypes.Validator<boolean>;

SoftInput.propTypes = {
  size: sizePropType as any,
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }) as any,
  error: booleanPropType,
  bold: booleanPropType,
  success: booleanPropType,
  disabled: booleanPropType,
};

export default SoftInput;
