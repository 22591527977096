import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import Switch from "@mui/material/Switch";
import CurrencyFormat from "react-currency-format";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
};

export const getTableColumns = (onAction) => [
  {
    Header: "Membership Name",
    accessor: "label",
  },
  {
    Header: "Cost",
    width: "10%",
    Cell: ({ row: { original } }) => {
      const { cost } = original;
      if (!cost) return null;
      return (
        <CurrencyFormat
          value={cost.amount}
          displayType="text"
          thousandSeparator
          prefix="$"
        />
      );
    },
  },
  {
    Header: "Billed",
    width: "10%",
    Cell: ({ row: { original } }) => {
      const { cost } = original;
      if (!cost) return null;
      return (
        <SoftTypography variant="p" textTransform="capitalize">
          {cost.billed}
        </SoftTypography>
      );
    },
  },
  {
    Header: "Active",
    width: "10%",
    Cell: ({ row: { original } }) => {
      const { active } = original;
      return (
        <Switch
          onChange={() => onAction(TableActions.TOGGLE, original)}
          checked={active}
        />
      );
    },
  },
  {
    Header: "Actions",
    width: "10%",
    Cell: ({ row: { original } }) => (
      <SoftBox sx={{ display: "flex" }}>
        <IconButton
          aria-label="delete"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.DELETE, original)}
        >
          <Icon>delete</Icon>
        </IconButton>
        <IconButton
          aria-label="edit"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.EDIT, original)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </SoftBox>
    ),
  },
];
