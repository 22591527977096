export const standardReservationTypes = [
  {
    color: "#329baa",
    colorCompleted: "#6c757d90",
    colorSecondary: "#329aaaa9",
    colorText: "#FFFFFF",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: true,
        nonRev: false,
        fuelReimbursement: true,
      },
      instructor: {
        enabled: true,
        split: 0,
        nonRev: false,
      },
      member1: {
        enabled: true,
        split: 100,
      },
      member2: {
        enabled: false,
      },
    },
    label: "Reservation",
    id: "reservation",
    value: "reservation",
  },
  {
    color: "#f44336",
    colorCompleted: "#6c757d90",
    colorSecondary: "#f44336a9",
    colorText: "#FFFFFF",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: true,
        nonRev: false,
        fuelReimbursement: true,
      },
      instructor: {
        enabled: true,
        split: 0,
        nonRev: false,
      },
      member1: {
        enabled: true,
        split: 100,
      },
      member2: {
        enabled: false,
      },
      checkRide: {
        enabled: true,
      },
    },
    label: "Check Ride",
    id: "checkRide",
    value: "checkRide",
  },
  {
    color: "#2196f3",
    colorCompleted: "#6c757d90",
    colorSecondary: "#268adcb0",
    colorText: "#FFFFFF",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: false,
      },
      instructor: {
        enabled: true,
      },
      member1: {
        enabled: true,
        split: 100,
      },
      member2: {
        enabled: false,
      },
      meetingRoom: {
        enabled: true,
      },
    },
    label: "Meeting Room",
    id: "meetingRoom",
    value: "meetingRoom",
  },
  {
    color: "#fbcf33",
    colorCompleted: "#6c757d90",
    colorSecondary: "#FCDD6E",
    colorText: "#3b3b3b",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: true,
        nonRev: true,
        fuelReimbursement: true,
      },
      instructor: {
        enabled: false,
      },
      member1: {
        enabled: true,
        nonRev: true,
      },
      member2: {
        enabled: false,
      },
    },
    hideDelete: true,
    label: "Maintenance",
    id: "maintenance",
    value: "maintenance",
  },
  {
    color: "#FFFFFF",
    colorCompleted: "#6c757d90",
    colorSecondary: "#FFFFFF",
    colorText: "#3b3b3b",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: false,
      },
      instructor: {
        enabled: true,
        nonRev: true,
      },
      member1: {
        enabled: false,
      },
      member2: {
        enabled: false,
      },
    },
    hideDelete: true,
    isSystemType: true,
    label: "Request Only",
    id: "requestOnly",
    value: "requestOnly",
  },
  {
    color: "#FF0000",
    colorCompleted: "#6c757d90",
    colorSecondary: "#FF0000",
    colorText: "#FFFFFF",
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: true,
        nonRev: true,
        fuelReimbursement: false,
      },
      instructor: {
        enabled: true,
        nonRev: true,
      },
      member1: {
        enabled: true,
      },
      member2: {
        enabled: false,
      },
    },
    hideDelete: true,
    label: "Intro Flight",
    id: "introFlight",
    value: "introFlight",
  },
  {
    deleted: false,
    enabledOptions: {
      aircraft: {
        enabled: false,
      },
      instructor: {
        enabled: true,
        nonRev: true,
      },
      member1: {
        enabled: false,
      },
      member2: {
        enabled: false,
      },
    },
    hideDelete: true,
    isSystemType: true,
    label: "Time Off",
    id: "unavailable",
    value: "unavailable",
  },
];
