import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "PreFlightContext";

// Soft UI Dashboard PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return {
        ...state,
        miniSidenav: action.value,
      };
    }
    case "SIDENAV_COLOR": {
      return {
        ...state,
        sidenavColor: action.value,
      };
    }
    case "FIXED_NAVBAR": {
      return {
        ...state,
        fixedNavbar: action.value,
      };
    }
    case "OPEN_CONFIGURATOR": {
      return {
        ...state,
        openConfigurator: action.value,
      };
    }
    case "LAYOUT": {
      return {
        ...state,
        layout: action.value,
      };
    }
    case "ROUTER": {
      return {
        ...state,
        router: action.value,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    router: "auth",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error(
      "useSoftUIController should be used inside the SoftUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({
    type: "MINI_SIDENAV",
    value,
  });
const setSidenavColor = (dispatch, value) =>
  dispatch({
    type: "SIDENAV_COLOR",
    value,
  });
const setFixedNavbar = (dispatch, value) =>
  dispatch({
    type: "FIXED_NAVBAR",
    value,
  });
const setOpenConfigurator = (dispatch, value) =>
  dispatch({
    type: "OPEN_CONFIGURATOR",
    value,
  });
const setLayout = (dispatch, value) =>
  dispatch({
    type: "LAYOUT",
    value,
  });
const setRouter = (dispatch, value) =>
  dispatch({
    type: "ROUTER",
    value,
  });

export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setSidenavColor,
  setFixedNavbar,
  setOpenConfigurator,
  setLayout,
  setRouter,
};
