import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

import FlightIcon from "@mui/icons-material/Flight";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

import SoftInput from "src/components/SoftInput";
import { useClubs } from "src/features/club/ClubProvider";
import SoftSelect from "src/components/SoftSelect";

function AircraftDataForm({
  aircraft,
  totalHobbs,
  totalTach,
  onHobbsChanged,
  onTachChanged,
}) {
  const [overrideHobbsStart, setOverrideHobbsStart] = useState(false);
  const [overrideTachStart, setOverrideTachStart] = useState(false);
  const [hobbsStart, setHobbsStart] = useState(aircraft.hobbs);
  const [hobbsEnd, setHobbsEnd] = useState();
  const [tachStart, setTachStart] = useState(aircraft.tach1);
  const [tachEnd, setTachEnd] = useState();
  const { selectedClub, hobbsMultipliers } = useClubs();
  const [selectedMultiplier, setSelectedMultiplier] = useState();

  useEffect(() => {
    if ((!totalTach || totalTach <= 0) && aircraft.useHobbsMultiplier) {
      onHobbsEndChanged(0);
    } else if (
      totalTach &&
      totalTach > 0 &&
      aircraft.useHobbsMultiplier &&
      selectedMultiplier
    ) {
      onHobbsEndChanged(aircraft.hobbs + totalTach * selectedMultiplier);
    }
  }, [totalTach]);

  useEffect(() => {
    if (totalTach && totalTach > 0 && selectedMultiplier) {
      onHobbsEndChanged(aircraft.hobbs + totalTach * selectedMultiplier);
    }
  }, [selectedMultiplier]);

  const onHobbsStartChanged = (value) => {
    setHobbsStart(value);
    onHobbsChanged(value, hobbsEnd);
  };

  const onTachStartChanged = (value) => {
    setTachStart(value);
    onTachChanged(value, tachEnd);
  };

  const onHobbsEndChanged = (value) => {
    setHobbsEnd(value);
    onHobbsChanged(hobbsStart, value);
  };

  const onTachEndChanged = (value) => {
    setTachEnd(value);
    onTachChanged(tachStart, value);
  };

  const toggleOverrideHobbsStart = () => {
    setOverrideHobbsStart(!overrideHobbsStart);
  };

  const toggleOverrideTachStart = () => {
    setOverrideTachStart(!overrideTachStart);
  };

  const renderTotalHobbs = () => {
    if (totalHobbs && totalHobbs > 0) {
      return (
        <SoftTypography variant="caption" color="text" pl={2} fontSize="0.9rem">
          Total: <b>{totalHobbs}</b>
        </SoftTypography>
      );
    }
    return null;
  };

  const renderTotalTach = () => {
    if (totalTach && totalTach > 0) {
      return (
        <SoftTypography variant="caption" color="text" pl={2} fontSize="0.9rem">
          Total: <b>{totalTach}</b>
        </SoftTypography>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <FlightIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="dark"
              fontSize="large"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Aircraft
            </SoftTypography>
            <SoftTypography
              variant="caption"
              color="text"
              fontSize="1.3rem"
              fontWeight="bold"
            >
              {aircraft?.aircraft_description?.tail_number ??
                aircraft?.tailNumber}
            </SoftTypography>
            <SoftTypography variant="caption" fontSize="0.8rem" color="text">
              {aircraft.displayName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8} mt={{ xs: 3, sm: 0 }}>
        <SoftBox
          display="flex"
          alignItems="center"
          py={2}
          borderRadius="md"
          bgColor="light"
        >
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent="center"
            direction="column"
          >
            {!aircraft.useHobbsMultiplier ? (
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent="center"
                >
                  <>
                    <Grid item xs={5} sm={5}>
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        pr={3}
                        height="100%"
                      >
                        <SoftBox display="flex" flexDirection="column">
                          <SoftTypography
                            variant="button"
                            color="dark"
                            fontWeight="medium"
                          >
                            Hobbs Out:
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={7} sm={7}>
                      <SoftBox display="flex" alignItems="center">
                        {overrideHobbsStart ? (
                          <SoftBox display="flex" flexDirection="column">
                            <SoftInput
                              type="number"
                              bold
                              error={totalHobbs < 0}
                              size="small"
                              value={hobbsStart}
                              onWheel={(event) => event.target.blur()}
                              sx={{ maxWidth: "7rem" }}
                              onChange={(event) =>
                                onHobbsStartChanged(event.target.value)
                              }
                            />
                          </SoftBox>
                        ) : (
                          <SoftBox display="flex" pl={1} flexDirection="row">
                            <SoftTypography variant="h6" color="dark">
                              {aircraft.hobbs}
                            </SoftTypography>
                            <Tooltip
                              title="Warning, this will override the current hobbs start value"
                              placement="bottom"
                            >
                              <SoftButton
                                variant="none"
                                color="secondary"
                                size="small"
                                iconOnly
                                circular
                                onClick={toggleOverrideHobbsStart}
                              >
                                <EditIcon />
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="100%"
                        pr={3}
                      >
                        <SoftBox display="flex" flexDirection="column">
                          <SoftTypography
                            variant="button"
                            color="dark"
                            fontWeight="medium"
                          >
                            Hobbs In:
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={7} sm={7}>
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" flexDirection="column">
                          <SoftInput
                            type="number"
                            bold
                            error={
                              totalHobbs < 0 ||
                              (selectedClub.preferences?.limitMaxHobbsTach &&
                                totalHobbs >
                                  selectedClub.preferences?.maxHobbsTach)
                            }
                            size="small"
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={(event) =>
                              onHobbsEndChanged(event.target.value)
                            }
                          />
                        </SoftBox>
                        {renderTotalHobbs()}
                      </SoftBox>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            ) : null}
            {!aircraft.simulator && !aircraft.tachFollowsHobbs && (
              <Grid item xs={12} sm={6} mt={2}>
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent="center"
                >
                  <Grid item xs={5} sm={5}>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      pr={3}
                      height="100%"
                    >
                      <SoftBox display="flex" flexDirection="column">
                        <SoftTypography
                          variant="button"
                          color="dark"
                          fontWeight="medium"
                        >
                          Tach Out:
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <SoftBox display="flex" alignItems="center">
                      {overrideTachStart ? (
                        <SoftBox display="flex" flexDirection="column">
                          <SoftInput
                            type="number"
                            bold
                            error={totalTach < 0}
                            size="small"
                            value={tachStart}
                            onWheel={(event) => event.target.blur()}
                            sx={{ maxWidth: "7rem" }}
                            onChange={(event) =>
                              onTachStartChanged(event.target.value)
                            }
                          />
                        </SoftBox>
                      ) : (
                        <SoftBox display="flex" pl={1} flexDirection="row">
                          <SoftTypography variant="h6" color="dark">
                            {aircraft.tach1}
                          </SoftTypography>
                          <Tooltip
                            title="Warning, this will override the current tach start value"
                            placement="bottom"
                          >
                            <SoftButton
                              variant="none"
                              color="secondary"
                              size="small"
                              iconOnly
                              circular
                              onClick={toggleOverrideTachStart}
                            >
                              <EditIcon />
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      pr={3}
                      height="100%"
                    >
                      <SoftBox display="flex" flexDirection="column">
                        <SoftTypography
                          variant="button"
                          color="dark"
                          fontWeight="medium"
                        >
                          Tach In:
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" flexDirection="column">
                        <SoftInput
                          type="number"
                          bold
                          size="small"
                          error={
                            totalTach < 0 ||
                            (selectedClub.preferences?.limitMaxHobbsTach &&
                              totalTach >
                                selectedClub.preferences?.maxHobbsTach)
                          }
                          onWheel={(event) => event.target.blur()}
                          sx={{ maxWidth: "7rem" }}
                          onChange={(event) =>
                            onTachEndChanged(event.target.value)
                          }
                        />
                      </SoftBox>
                      {renderTotalTach()}
                    </SoftBox>
                  </Grid>
                  {aircraft.useHobbsMultiplier ? (
                    <>
                      <Grid mt={2} item xs={5} sm={5}>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          pr={3}
                          height="100%"
                        >
                          <SoftBox display="flex" flexDirection="column">
                            <SoftTypography
                              variant="button"
                              color="dark"
                              fontWeight="medium"
                            >
                              Flight Type:
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                      <Grid mt={2} item xs={7} sm={7}>
                        <SoftBox
                          sx={{
                            backgroundColor:
                              totalTach > 0 ? "#FFF" : "secondary",
                            borderRadius: "8px",
                          }}
                          mr={3}
                        >
                          <SoftSelect
                            options={hobbsMultipliers.map((entry) => ({
                              value: entry.multiplier,
                              label: entry.label,
                            }))}
                            isDisabled={!totalTach}
                            onChange={(option) => {
                              setSelectedMultiplier(option.value);
                            }}
                            size="small"
                          />
                        </SoftBox>
                      </Grid>
                      {totalHobbs > 0 ? (
                        <>
                          <Grid item xs={5} sm={5}>
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              pr={3}
                              height="100%"
                            >
                              <SoftBox display="flex" flexDirection="column">
                                <SoftTypography
                                  variant="button"
                                  color="dark"
                                  fontWeight="medium"
                                >
                                  Hobbs Time:
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={7} sm={7}>
                            <SoftBox mr={3}>
                              <SoftTypography
                                variant="caption"
                                color="text"
                                pl={2}
                                fontSize="0.9rem"
                              >
                                <b>{totalHobbs}</b>
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
AircraftDataForm.propTypes = {
  aircraft: PropTypes.object,
  totalHobbs: PropTypes.number,
  totalTach: PropTypes.number,
  onHobbsChanged: PropTypes.func,
  onTachChanged: PropTypes.func,
};

export default AircraftDataForm;
