import { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import * as toastr from "toastr";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";

// Authentication layout components
import CoverLayout from "../Layout";

// Images
import loginBg2 from "src/assets/images/login-bg2.jpg";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAuth,
  verifyPasswordResetCode,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";

const schema = yup
  .object({
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function SetNewPassword() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [resetEmail, setResetEmail] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [queryParameters] = useSearchParams();
  const code = queryParameters.get("oobCode");

  useEffect(() => {
    verifyPasswordResetCode(auth, code)
      .then((email) => {
        // Display a "new password" form with the user's email address
        setUserEmail(email);
      })
      .catch((error) => {
        // Invalid code
        console.error(error);
        setErrorMessage(error.message);
      });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    console.error("errorMessage", errorMessage);
  }, [errorMessage]);

  const onSubmit = (e) => {
    confirmPasswordReset(auth, code, e.password)
      .then(() => {
        toastr.success("Your password has been reset.", "Success");
        navigate("/auth/login");
      })
      .catch((error) => {
        toastr.error("There was an error setting your new password.", "Error");
        console.error(error);
      });
  };
  const onSubmitRequest = () => {
    if (!resetEmail) {
      toastr.error("Please enter your email address.", "Error");
      return;
    }
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        toastr.success(
          "Your request for a password reset was sent.",
          "Success"
        );
        navigate("/auth/login");
      })
      .catch((error) => {
        toastr.error(
          "There was an error sending your password reset.",
          "Error"
        );
        console.error(error);
      });
  };

  if (userEmail && !errorMessage) {
    return (
      <CoverLayout
        title="Reset Password"
        description={`Enter a new password for your ${userEmail} account.`}
        image={loginBg2}
        top={30}
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="password"
              placeholder="Enter new password"
              {...register("password", {
                required: true,
              })}
            />
            {errors?.password?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.password.message}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Confirm Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="password"
              placeholder="Confirm your new password"
              {...register("confirmPassword", {
                required: true,
              })}
            />
            {errors?.confirmPassword?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.confirmPassword.message}
              </SoftTypography>
            )}
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="info" fullWidth type="submit">
              reset
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Remembered your details?&nbsp;
              <SoftTypography
                component={Link}
                to="/auth/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    );
  }
  if (errorMessage) {
    return (
      <CoverLayout
        title="Error Reseting Password"
        description="Your reset token may have expired, please request a new one."
        image={loginBg2}
        top={30}
      >
        <SoftBox>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="email"
              placeholder="Enter your e-mail"
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={onSubmitRequest}
            >
              reset
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Remembered your details?&nbsp;
              <SoftTypography
                component={Link}
                to="/auth/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    );
  }

  return (
    <AuthLoadingPage text="Please wait while we check you reset password code." />
  );
}

export default SetNewPassword;
