import TailNumberCell from "./TailNumberCell";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import PlaneIcon from "src/assets/images/plane-icon.png";
import Switch from "@mui/material/Switch";
import { systemPermissions } from "src/interfaces/roles/role.interface";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
  TRACK: "TRACK",
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
}));

export const getTableColumnsAdmin = (onAction, hasAccess) => [
  // {
  //   id: 'photo',
  //   width: '20%',
  //   Cell: ({ row: { original } }) => {
  //     const { photo } = original;
  //     return <ProductCell image={photo ?? PlaneIcon} checked={false} />;
  //   },
  // },
  {
    Header: "tail #",
    accessor: "tailNumber",
    width: "31%",
    Cell: ({ row: { original } }) => {
      const { photo } = original;
      return (
        <TailNumberCell
          image={photo ?? PlaneIcon}
          name={original.tailNumber}
          checked={false}
        />
      );
    },
  },
  {
    Header: "aircraft name",
    accessor: "displayName",
  },
  {
    Header: "Enabled",
    accessor: "status",
    width: "15%",
    align: "center",
    Cell: ({ row: { original } }) => {
      const { disabled } = original;
      return (
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <Switch
            disabled={!hasAccess([systemPermissions.DISABLE_AIRCRAFT])}
            onChange={() => onAction(TableActions.TOGGLE, original)}
            checked={!disabled}
          />
        </SoftBox>
      );
    },
  },
  {
    Header: () => (
      <>
        Track
        <LightTooltip
          title={
            <>
              <SoftTypography variant="h6" color="info">
                What Is Tracking?
              </SoftTypography>
              <SoftTypography variant="caption">
                When tracking is enabled, you will be notified (configured in
                club settings) when an aircraft dispatches without a booking or
                arrives back early.
              </SoftTypography>
              <br />
              <br />
              <SoftTypography variant="caption">
                <b>Note: </b>
                <i>
                  This is an experimental feature using ADSB data from the
                  aircrafts transponder. There is currently no additional
                  charges but this is may change in the future.
                </i>
              </SoftTypography>
            </>
          }
          arrow
        >
          <Icon
            sx={{
              fontSize: "1rem!important",
              top: "0",
              position: "absolute",
              marginLeft: "5px",
            }}
          >
            info_outline
          </Icon>
        </LightTooltip>
      </>
    ),
    accessor: "flightAwareAlertId",
    width: "15%",
    align: "center",
    Cell: ({ row: { original } }) => {
      const { flightAwareAlertId } = original;
      return (
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <Switch
            disabled={!hasAccess([systemPermissions.TRACK_AIRCRAFT])}
            onChange={() => {
              onAction(TableActions.TRACK, original);
            }}
            checked={!!flightAwareAlertId}
          />
        </SoftBox>
      );
    },
  },
  {
    Header: "action",
    accessor: "action",
    width: "14%",
    align: "center",
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftBox display="flex" flexDirection="row">
          {hasAccess([systemPermissions.DELETE_AIRCRAFT]) && (
            <IconButton
              aria-label="delete"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onAction(TableActions.DELETE, original);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
          {hasAccess([systemPermissions.EDIT_AIRCRAFT]) && (
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onAction(TableActions.EDIT, original);
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
          )}
        </SoftBox>
      </SoftBox>
    ),
  },
];

export const getTableColumns = () => [
  // {
  //   id: 'photo',
  //   width: '20%',
  //   Cell: ({ row: { original } }) => {
  //     const { photo } = original;
  //     return <ProductCell image={photo ?? PlaneIcon} checked={false} />;
  //   },
  // },
  {
    Header: "tail #",
    accessor: "tailNumber",
    width: "31%",
    Cell: ({ row: { original } }) => {
      const { photo } = original;
      return (
        <TailNumberCell
          image={photo ?? PlaneIcon}
          name={original.tailNumber}
          checked={false}
        />
      );
    },
  },
  {
    Header: "aircraft name",
    accessor: "displayName",
  },
];
