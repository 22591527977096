import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import { format } from "date-fns";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { useClubs } from "src/features/club/ClubProvider";
import { Icon } from "@mui/material";
import PrintInvoice from "src/components/Printing/Invoice/PrintInvoice";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

function Header({
  transaction,
  onRefundTransaction,
  onMarkAsPaid,
  onSendPaymentRequest,
  adjustInvoice,
  onPayWithALC,
}) {
  const { selectedClubId } = useClubs();
  const amountPaid =
    transaction?.payments?.reduce((acc, payment) => {
      if (payment?.status === "succeeded")
        return acc + (payment?.amount ?? 0) / 100;
      else return acc;
    }, 0) ?? 0;

  const totalCost = Math.round(transaction.totalCost * 100) / 100 || 0;

  const isUnpaid =
    !transaction.paid && amountPaid < totalCost - (transaction.fuelCost ?? 0);

  const payWithALC = () => {
    onPayWithALC(transaction);
  };

  const printableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          {/* <SoftBox component="img" src={logoCT} width="25%" p={1} mb={1} /> */}
          <SoftTypography variant="h4" fontWeight="medium">
            {transaction.club.name}
          </SoftTypography>
          {transaction?.club?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {transaction.club.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={3}>
          <SoftBox
            display="flex"
            alignItems="end"
            justifyContent="end"
            lineHeight={1}
            ml={3}
            sx={{
              cursor: "pointer",
            }}
            onClick={handlePrint}
          >
            <SoftButton variant="outlined">
              <Icon fontSize="small">print</Icon>
              <SoftTypography ml={1} variant="button" fontWeight="bold">
                Print Invoice
              </SoftTypography>
            </SoftButton>
            <SoftBox style={{ display: "none" }}>
              <SoftBox ref={printableRef}>
                <PrintInvoice
                  invoiceId={transaction.id}
                  showPageControls={false}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {transaction.type === "booking" ? (
            <>
              <SoftBox
                width="100%"
                textAlign={{ xs: "left", md: "right" }}
                mt={2}
              >
                {transaction?.pilot?.displayName && (
                  <SoftBox mt={1}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      Billed to: {transaction.pilot.displayName}
                    </SoftTypography>
                  </SoftBox>
                )}
                {transaction?.pilot?.email && (
                  <SoftBox mb={1}>
                    <SoftTypography variant="body2" color="secondary">
                      {transaction.pilot.email}
                    </SoftTypography>
                  </SoftBox>
                )}
                {transaction?.pilot?.address && (
                  <SoftBox mb={1}>
                    <SoftTypography variant="body2" color="secondary">
                      {transaction?.pilot?.address?.addressLine1}
                      <br />
                      {transaction?.pilot?.address?.addressLine2 && (
                        <>
                          {transaction?.pilot?.address?.addressLine2}
                          <br />
                        </>
                      )}
                      {transaction?.pilot?.address?.city},{" "}
                      {transaction?.pilot?.address?.state},{" "}
                      {transaction?.pilot?.address?.zip}
                    </SoftTypography>
                  </SoftBox>
                )}
              </SoftBox>
            </>
          ) : (
            <SoftBox
              width="100%"
              textAlign={{ xs: "left", md: "right" }}
              mt={2}
            >
              {transaction?.member?.displayName && (
                <SoftBox mt={1}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Billed to: {transaction.member.displayName}
                  </SoftTypography>
                </SoftBox>
              )}
              {transaction?.member?.email && (
                <SoftBox mb={1}>
                  <SoftTypography variant="body2" color="secondary">
                    {transaction.member.email}
                  </SoftTypography>
                </SoftBox>
              )}
              {transaction?.member?.address && (
                <SoftBox mb={1}>
                  <SoftTypography variant="body2" color="secondary">
                    {transaction?.member?.address?.address1}
                    <br />
                    {transaction?.member?.address?.address2 && (
                      <>
                        {transaction?.member?.address?.address2}
                        <br />
                      </>
                    )}
                    {transaction?.member?.address?.city}{" "}
                    {transaction?.member?.address?.state}
                    <br />
                    {transaction?.member?.address?.zip}
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Transaction Details
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                Transaction no.{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {transaction.id}
                </span>
              </SoftTypography>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {transaction.createdAt
                    ? format(transaction.createdAt.toDate(), "eee, MMM do yyyy")
                    : "N/A"}
                </span>
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              {onRefundTransaction &&
                transaction.payments?.reduce(
                  (acc, payment) =>
                    payment.status === "succeeded"
                      ? acc + (payment?.amount ?? 0)
                      : acc,
                  0
                ) > 0 &&
                transaction.type !== "deposit" &&
                transaction.type !== "paymentRequest" &&
                (transaction.refunded || 0) < transaction.totalCost && (
                  <WithPermissions
                    permissions={systemPermissions.REFUND_TO_ACCOUNT}
                  >
                    <SoftButton
                      variant="outlined"
                      color="error"
                      onClick={onRefundTransaction}
                      style={{ marginLeft: "10px" }}
                    >
                      Refund
                    </SoftButton>
                  </WithPermissions>
                )}
              <WithPermissions permissions={systemPermissions.MARK_AS_PAID}>
                {isUnpaid && onMarkAsPaid && (
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={onMarkAsPaid}
                    style={{ marginLeft: "10px" }}
                  >
                    Mark as Paid
                  </SoftButton>
                )}
              </WithPermissions>
              <WithPermissions permissions={systemPermissions.REQUEST_PAYMENT}>
                {isUnpaid && onSendPaymentRequest && (
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={onSendPaymentRequest}
                    style={{ marginLeft: "10px" }}
                  >
                    Request Payment
                  </SoftButton>
                )}
              </WithPermissions>
              {adjustInvoice && transaction.type === "booking" && (
                <WithPermissions
                  permissions={systemPermissions.PREFLIGHT_ADMIN}
                >
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={adjustInvoice}
                    style={{ marginLeft: "10px" }}
                  >
                    Adjust Invoice
                  </SoftButton>
                  {selectedClubId === "4niGBlwQo8xiUFFqQaM9" && (
                    <SoftButton
                      variant="gradient"
                      color="info"
                      onClick={payWithALC}
                      style={{ marginLeft: "10px" }}
                    >
                      Debit ALC
                    </SoftButton>
                  )}
                </WithPermissions>
              )}
              {adjustInvoice && transaction.type === "manual" && (
                <WithPermissions
                  permissions={systemPermissions.PREFLIGHT_ADMIN}
                >
                  {selectedClubId === "4niGBlwQo8xiUFFqQaM9" && (
                    <SoftButton
                      variant="gradient"
                      color="info"
                      onClick={payWithALC}
                      style={{ marginLeft: "10px" }}
                    >
                      Debit ALC
                    </SoftButton>
                  )}
                </WithPermissions>
              )}
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Header.propTypes = {
  transaction: PropTypes.object.isRequired,
  onRefundTransaction: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  adjustInvoice: PropTypes.func,
};

export default Header;
