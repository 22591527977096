import { useEffect, useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { DeleteOutline, Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Skeleton } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import {
  CourseCard,
  CourseCardHeader,
  CourseCardTitle,
  CourseCardFooter,
} from "src/pages/lms/components";

// Firebase helpers
import {
  getCourseLessonsCollection,
  getCourseStagesCollection,
} from "src/features/lms/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getCountFromServer, where, query } from "firebase/firestore";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function EditableCourseCard({
  onEditClick = () => {},
  onDeleteClick = () => {},
  course,
  onCardClick = () => {},
  clubId,
}) {
  const [lessonCount, setLessonCount] = useState(0);
  const { data: allStages, isDataLoaded: stagesLoaded } =
    useRealtimeCollectionData(
      getCourseStagesCollection(clubId, course.id),
      false
    );

  const fetchStageLessons = async (stages) => {
    const stageLessons = await Promise.all(
      stages.map(async (stageDoc) => {
        const lessonDocsArray = await getCountFromServer(
          query(
            getCourseLessonsCollection(clubId, course.id, stageDoc.id),
            where("deleted", "==", false)
          )
        );
        return lessonDocsArray.data().count;
      })
    );
    setLessonCount(stageLessons.reduce((a, b) => a + b, 0));
  };

  useEffect(() => {
    if (stagesLoaded && allStages) {
      fetchStageLessons(allStages);
    }
  }, [allStages]);

  return (
    <CourseCard onClick={onCardClick} courseId={course.id}>
      <CourseCardHeader createdAt={course.createdAt}>
        <SoftBox display="flex" gap="2px" alignItems="center">
          <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
            <IconButton
              sx={{ p: 0 }}
              size="small"
              onClick={(e) => onEditClick(e, course)}
            >
              <Edit fontSize="small" color="text" />
            </IconButton>
          </WithPermissions>
          <WithPermissions permissions={systemPermissions.DELETE_LMS_COURSES}>
            <IconButton
              sx={{ p: 0 }}
              size="small"
              onClick={(e) => onDeleteClick(e, course)}
            >
              <DeleteOutline fontSize="small" color="text" />
            </IconButton>
          </WithPermissions>
        </SoftBox>
      </CourseCardHeader>

      <CourseCardTitle>{course.title}</CourseCardTitle>

      {stagesLoaded ? (
        <CourseCardFooter
          totalStages={allStages.length}
          totalLessons={lessonCount}
        />
      ) : (
        <Skeleton width="100%" height="1rem" />
      )}
    </CourseCard>
  );
}

// Typechecking props for the EditableCourseCard
EditableCourseCard.propTypes = {
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onCardClick: PropTypes.func,
  course: PropTypes.object.isRequired,
  clubId: PropTypes.string.isRequired,
};

export default EditableCourseCard;
