import { IPermission } from "./permission.interface";

export enum billingRoleCategory {
  BILLING_BASIC = "BILLING_BASIC",
  BILLING_ADVANCED = "BILLING_ADVANCED",
}

export enum billingPermissionTypes {
  VIEW_OWN_BALANCE = "VIEW_OWN_BALANCE",
  ADD_FUNDS_TO_ACCOUNT = "ADD_FUNDS_TO_ACCOUNT",
  VIEW_INVOICES = "VIEW_INVOICES",
  ACCESS_MEMBERS_ACCOUNTS = "ACCESS_MEMBERS_ACCOUNTS",
  ADD_INVOICES = "ADD_INVOICES",
  DEPOSIT_FUNDS = "DEPOSIT_FUNDS",
  REQUEST_PAYMENT = "REQUEST_PAYMENT",
  NON_REV_BOOKING = "NON_REV_BOOKING",
  MARK_AS_PAID = "MARK_AS_PAID",
  REFUND_TO_ACCOUNT = "REFUND_TO_ACCOUNT",
  VIEW_PAYMENT_DETAILS = "VIEW_PAYMENT_DETAILS",
  ACCESS_POS = "ACCESS_POS",
}

export const billingPermissions: IPermission[] = [
  {
    group: billingRoleCategory.BILLING_BASIC,
    label: "Billing - Basic",
    tooltipGroup: "",
    permissions: {
      [billingPermissionTypes.VIEW_OWN_BALANCE]: {
        label: "View own Balance",
        tooltip:
          "This will show or hide the users balance on the left hand menu!",
      },
      [billingPermissionTypes.ADD_FUNDS_TO_ACCOUNT]: {
        label: "Add Funds to Own Account",
        tooltip: "This will show or hide the Add Funds option on the left menu",
      },
    },
  },
  {
    group: billingRoleCategory.BILLING_ADVANCED,
    label: "Billing - Advanced",
    tooltipGroup: "",
    permissions: {
      [billingPermissionTypes.VIEW_INVOICES]: {
        label: "View All Invoices",
        tooltip: "",
      },
      [billingPermissionTypes.ACCESS_MEMBERS_ACCOUNTS]: {
        label: "Access Members Accounts Tab",
        tooltip: "",
      },
      [billingPermissionTypes.ACCESS_POS]: {
        label: "Access POS for one off transactions",
        tooltip: "",
      },
      [billingPermissionTypes.ADD_INVOICES]: {
        label: "Add Invoices",
        tooltip: "Add invoices tooltip",
      },
      [billingPermissionTypes.MARK_AS_PAID]: {
        label: "Mark Invoices As Paid",
        tooltip: "",
      },
      [billingPermissionTypes.VIEW_PAYMENT_DETAILS]: {
        label: "View Card Payment Details (and refund)",
        tooltip: "",
      },
      [billingPermissionTypes.REFUND_TO_ACCOUNT]: {
        label: "Refund Invoices To Account",
        tooltip: "",
      },
      [billingPermissionTypes.DEPOSIT_FUNDS]: {
        label: "Deposit Funds To Members Account",
        tooltip: "Add funds to a users account balance",
      },
      [billingPermissionTypes.REQUEST_PAYMENT]: {
        label: "Request a payment from a Member",
        tooltip: "",
      },
      [billingPermissionTypes.NON_REV_BOOKING]: {
        label: "Can Non-Rev A Booking",
        tooltip: "Allows user to non-rev a booking for a $0 charge",
      },
    },
  },
];
