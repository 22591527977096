import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
  differenceInSeconds,
  subSeconds,
} from "date-fns";
import {
  query,
  orderBy,
  where,
  doc,
  getDoc,
  getDocs,
  limit,
} from "firebase/firestore";
import {
  getClubBookingsCollection,
  getClubMembershipsCollection,
} from "src/features/club/collections";
import { getBillingCollection } from "src/features/billing/collections";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { getMembershipsCollection } from "src/features/user/collections";

const columns = [
  {
    Header: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Account Balance",
    accessor: "balance",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value)} />,
  },
  {
    Header: "Total Revenue",
    accessor: "totalRevenue",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Total Paid",
    accessor: "totalPayments",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Last Flight",
    accessor: "lastFlight",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? format(value.toDate(), "eee MMM do yyyy") : "---"}
      />
    ),
  },
];

function UserFinanaceReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    selectedLocation,
    clubUsers,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);
  const [dateRangePrevious, setDateRangePrevious] = useState([
    startOfMonth(subMonths(new Date(), 2)),
    endOfMonth(subMonths(new Date(), 2)),
  ]);
  const [totalRevenuePreviousPercentage, setTotalRevenuePreviousPercentage] =
    useState(0);
  const [totalPaymentsPreviousPercentage, setTotalPaymentsPreviousPercentage] =
    useState(0);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  useEffect(() => {
    setDateRangePrevious([
      startOfDay(
        subSeconds(
          dateRange[0],
          differenceInSeconds(dateRange[1], dateRange[0])
        )
      ),
      dateRange[0],
    ]);
  }, [dateRange]);

  const addMemberData = async (members) => {
    const rowData = await Promise.all(
      members.map(async (member) => {
        const userMembershipSnap = await getDoc(
          doc(getMembershipsCollection(member.uid), selectedClubId)
        );
        const userAccountBalanceSnap = await getDoc(
          doc(getBillingCollection(), member.uid)
        );
        const membershipSnap = userMembershipSnap?.data()?.membershipPlan
          ? await getDoc(
              doc(
                getClubMembershipsCollection(selectedClubId),
                userMembershipSnap.data()?.membershipPlan
              )
            )
          : null;
        const latestBooking = await getDocs(
          query(
            getClubBookingsCollection(selectedClubId, selectedLocationId),
            where("extendedProps.pilot.value", "==", member.uid),
            // TODO: FILTER OUT DEPOSITS
            orderBy("completedAt", "desc"),
            limit(1)
          )
        );
        const userTransactions = await getDocs(
          query(
            getClubTransactionsCollection(selectedClubId),
            where("pilot.uid", "==", member.uid)
          )
        );
        const filteredTransactions = userTransactions.docs?.filter(
          (transaction) =>
            transaction?.data()?.createdAt &&
            isWithinInterval(transaction.data().createdAt.toDate(), {
              start: dateRange[0],
              end: dateRange[1],
            }) &&
            transaction?.data()?.type !== "deposit" &&
            transaction?.data()?.type !== "accountFunding" &&
            transaction?.data()?.type !== "paymentRequest"
        );
        const filteredTransactionsPrevious = userTransactions.docs?.filter(
          (transaction) =>
            transaction?.data()?.createdAt &&
            isWithinInterval(transaction.data().createdAt.toDate(), {
              start: dateRangePrevious[0],
              end: dateRangePrevious[1],
            }) &&
            transaction?.data()?.type !== "deposit" &&
            transaction?.data()?.type !== "accountFunding" &&
            transaction?.data()?.type !== "paymentRequest"
        );

        let totalRevenue = 0;
        let totalRevenuePrevious = 0;
        filteredTransactions.forEach((transaction) => {
          const { totalCost } = transaction.data();
          totalRevenue += totalCost;
        });

        let totalPayments = 0;
        let totalPaymentsPrevious = 0;
        filteredTransactions.forEach((transaction) => {
          const { payments } = transaction.data();
          totalPayments += payments?.reduce((acc, payment) => {
            if (payment.status === "succeeded")
              return acc + payment.amount / 100;
            else return acc;
          }, 0);
        });

        filteredTransactionsPrevious.forEach((transaction) => {
          const { totalCost } = transaction.data();
          totalRevenuePrevious += totalCost;
        });

        filteredTransactionsPrevious.forEach((transaction) => {
          const { payments } = transaction.data();
          totalPaymentsPrevious += payments?.reduce((acc, payment) => {
            if (payment.status === "succeeded")
              return acc + payment.amount / 100;
            else return acc;
          }, 0);
        });

        const fullMemberData = {
          ...member,
          balance: userAccountBalanceSnap.data()?.[selectedClubId] || 0,
          totalRevenue,
          totalRevenuePrevious,
          totalPayments,
          totalPaymentsPrevious,
          lastFlight: latestBooking?.docs[0]?.data()?.completedAt,
          membershipType: membershipSnap?.data()?.label,
        };
        return fullMemberData;
      })
    );

    rowData.sort((a, b) => {
      if (a.totalRevenue > b.totalRevenue) return -1;
      if (a.totalRevenue < b.totalRevenue) return 1;
      return 0;
    });

    const csvDataFromRows = rowData.map((row) => ({
      "First Name": row.firstName,
      "Last Name": row.lastName,
      Email: row.email,
      "User Type": row.userType,
      "Account Balance": formatCurrency(row.balance),
      "Total Revenue": formatCurrency(row.totalRevenue),
      "Total Paid": formatCurrency(row.totalPayments),
      "Last Flight": row.lastFlight
        ? format(row.lastFlight.toDate(), "eee MMM do yyyy")
        : "---",
    }));

    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (!clubUsers) return;
    addMemberData(clubUsers);
  }, [
    clubUsers,
    dateRange,
    dateRangePrevious,
    selectedClubId,
    selectedLocationId,
  ]);

  useEffect(() => {
    setTotalRevenuePreviousPercentage(
      Math.round(
        Math.min(
          (rows.reduce((acc, row) => acc + row.totalRevenue, 0) /
            rows.reduce((acc, row) => acc + row.totalRevenuePrevious, 0)) *
            100 -
            100,
          999
        ) || 0
      )
    );

    setTotalPaymentsPreviousPercentage(
      Math.round(
        Math.min(
          (rows.reduce((acc, row) => acc + (row?.totalPayments || 0), 0) /
            rows.reduce((acc, row) => acc + row.totalPaymentsPrevious, 0)) *
            100 -
            100,
          999
        ) || 0
      )
    );
  }, [rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-check_ins-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Members",
                  fontWeight: "medium",
                }}
                count={rows.length}
                icon={{
                  color: "info",
                  component: "person",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Balances",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce((acc, row) => acc + row.balance, 0)
                )}
                icon={{
                  color: "info",
                  component: "account_balance",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Revenue",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce((acc, row) => acc + row.totalRevenue, 0)
                )}
                percentage={{
                  color:
                    rows.reduce((acc, row) => acc + row.totalRevenue, 0) < 0
                      ? "error"
                      : "success",
                  text: `${
                    totalRevenuePreviousPercentage < 0 ? "" : "+"
                  }${totalRevenuePreviousPercentage}%`,
                }}
                icon={{
                  color: "info",
                  component: "currency_exchange",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{
                  text: "Total Paid",
                  fontWeight: "medium",
                }}
                count={formatCurrency(
                  rows.reduce((acc, row) => acc + (row?.totalPayments || 0), 0)
                )}
                percentage={{
                  color:
                    totalPaymentsPreviousPercentage < 0 ? "error" : "success",
                  text: `${
                    totalPaymentsPreviousPercentage < 0 ? "" : "+"
                  }${totalPaymentsPreviousPercentage}%`,
                }}
                icon={{
                  color: "info",
                  component: "credit_score",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserFinanaceReport;
