import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import InformationBite from "src/components/InformationBite";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

function CheckoutInfo({ selectedCheckout }) {
  return (
    <SoftBox sx={style}>
      <SoftTypography variant="h2">Checkout Information</SoftTypography>
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <Divider sx={{ margin: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <InformationBite
            label="Date"
            value={selectedCheckout?.date?.toDate().toDateString()}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default CheckoutInfo;
