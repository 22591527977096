// Countries flags
import US from "src/assets/images/icons/flags/US.png";
import DE from "src/assets/images/icons/flags/DE.png";
import GB from "src/assets/images/icons/flags/GB.png";
import BR from "src/assets/images/icons/flags/BR.png";

const salesTableData = [
  {
    country: [US, "united state"],
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
  },
  {
    country: [DE, "germany"],
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
  },
  {
    country: [GB, "great britain"],
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
  },
  {
    country: [BR, "brasil"],
    sales: 562,
    value: "$143,960",
    bounce: "32.14%",
  },
];

export default salesTableData;
