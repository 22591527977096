import { useMemo, useState } from "react";
import UniversalPopup from "../UniversalPopup/UniversalPopup";
import { useObservable } from "../../hooks/useObservable";
import {
  IAircraftModal,
  aircraftModalOpener,
  getAircraftModalTab,
  getAircraftCurrentTab,
  openAircraftModal$,
  tabsEnum,
  getAircraftTabTitle,
} from "./AircraftModal.config";
import { Stack } from "@mui/material";
import Toolbar from "./components/Toolbar";
import SoftTypography from "src/components/SoftTypography";
import { Close } from "@mui/icons-material";
import PlaneIcon from "src/assets/images/plane-icon.png";
import ImageLoader from "src/components/ImageLoader/ImageLoader";
import { useAircraftSearch } from "./hooks/useAircraftSearch";
import SearchAircraftHistory from "./components/SearchAircraftHistory";
import AircraftDescription from "./components/AircraftDescription";
import SoftBox from "src/components/SoftBox";
import AircraftMaintenance from "./components/AircraftMaintenance";
import AircraftDocuments from "./components/AircraftDocuments";
import AircraftLogs from "./components/AircraftLogs";
import AircraftEdit from "./components/AircraftEdit/AircraftEdit";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getAircraftById } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import { IAircraft } from "src/interfaces";

function AircraftModal() {
  const [modalProps, setModalProps] = useState<IAircraftModal>({
    isOpen: false,
    aircraft: null,
  });
  const [selectedTabs, setSelectedTabs] = useState([tabsEnum.AIRCRAFT]);
  const { selectedClubId } = useClubs();
  useObservable(openAircraftModal$, (data) => {
    setModalProps({
      isOpen: data?.isOpen ?? true,
      aircraft: data.aircraft,
    });
    setSelectedTabs((prev) => getAircraftModalTab(prev, data.tab));
  });
  const { saveAircraft, getAircrafts } = useAircraftSearch();
  const aircrafts = useMemo(() => {
    if (modalProps.aircraft) saveAircraft(modalProps.aircraft);
    return getAircrafts();
  }, [modalProps.aircraft]);

  const selectedAircraftId = useMemo(() => {
    return modalProps.aircraft?.id;
  }, [modalProps.aircraft]);

  const [aircraftData] = useDocumentData<IAircraft>(
    selectedClubId &&
      selectedAircraftId &&
      getAircraftById(selectedClubId, selectedAircraftId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const currentAircraft = useMemo(() => {
    return { ...modalProps.aircraft, ...(aircraftData ?? {}) } as IAircraft;
  }, [modalProps.aircraft, aircraftData]);

  const isSearchOpen = selectedTabs.includes(tabsEnum.SEARCH);
  if (!modalProps.aircraft) return null;

  return (
    <UniversalPopup
      open={!!modalProps.isOpen}
      direction="left"
      wrapperStyle={{
        position: "absolute",
        top: "13%",
        right: 25,
      }}
      containerStyles={{
        padding: 1,
        width: isSearchOpen ? "50vw" : "40vw",
        height: "84vh",
      }}
    >
      <Stack height="100%" direction="row" gap="12px">
        <Stack height="100%">
          <Toolbar
            selectedTabs={selectedTabs}
            onTabSelected={(tab) =>
              setSelectedTabs((prev) => getAircraftModalTab(prev, tab))
            }
          />
        </Stack>
        {isSearchOpen && (
          <SearchAircraftHistory
            aircrafts={aircrafts}
            selectedAircraftId={selectedAircraftId}
            onAircraftSelected={(aircraft) => {
              aircraftModalOpener({
                aircraft,
                tab: getAircraftCurrentTab(selectedTabs),
              });
            }}
          />
        )}
        <Stack
          direction="column"
          width="100%"
          height="100%"
          sx={{ overflowY: "auto" }}
        >
          <Stack direction="row" justifyContent="space-between">
            <SoftTypography variant="h4">
              {currentAircraft.displayName}
            </SoftTypography>
            <SoftBox
              className="clickable"
              style={{
                marginRight: 20,
                border: "1px solid black",
                borderRadius: "50%",
                height: 24,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                padding: 1,
              }}
              onClick={() => setModalProps({ isOpen: false, aircraft: null })}
            >
              <Close />
            </SoftBox>
          </Stack>
          <SoftTypography variant="h6">
            {selectedTabs.includes(tabsEnum.AIRCRAFT)
              ? currentAircraft.tailNumber
              : getAircraftTabTitle(selectedTabs)}
          </SoftTypography>
          <ImageLoader
            style={{
              height: 100,
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
              marginTop: 10,
            }}
            src={currentAircraft?.photo ?? PlaneIcon}
            alt={currentAircraft.displayName}
          />
          <br />
          {selectedTabs.includes(tabsEnum.AIRCRAFT) && (
            <AircraftDescription aircraft={currentAircraft} />
          )}
          {selectedTabs.includes(tabsEnum.TOOLS) && (
            <AircraftMaintenance aircraft={currentAircraft} />
          )}
          {selectedTabs.includes(tabsEnum.FOLDER) && (
            <AircraftDocuments aircraft={currentAircraft} />
          )}
          {selectedTabs.includes(tabsEnum.BOOK) && (
            <AircraftLogs aircraft={currentAircraft} />
          )}
          {selectedTabs.includes(tabsEnum.EDIT) && (
            <AircraftEdit aircraft={currentAircraft} />
          )}
        </Stack>
      </Stack>
    </UniversalPopup>
  );
}

export default AircraftModal;
