import PropTypes from "prop-types";
import { CancelOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import parse from "html-react-parser";

function CourseInfoPopover({ anchorEl, handleClosePopover, course }) {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card
        sx={{
          width: "400px",
          mt: "-0.5rem",
          mr: "-0.5rem",
          backgroundColor: "grey.200",
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          borderBottom={1}
          borderColor="grey.400"
        >
          <SoftTypography variant="h5">Course Description</SoftTypography>
          <IconButton
            onClick={handleClosePopover}
            aria-label="Close"
            sx={{ p: 0 }}
          >
            <CancelOutlined color="text" fontSize="small" />
          </IconButton>
        </SoftBox>
        <SoftBox
          p={2}
          borderBottom={1}
          borderColor="grey.300"
          sx={{ maxHeight: "60vh", overflowY: "scroll" }}
        >
          <SoftTypography
            variant="h6"
            fontSize="small"
            textTransform="uppercase"
          >
            Description
          </SoftTypography>
          <SoftTypography component="div" fontSize="small" mb={2}>
            {parse(course.description || "")}
          </SoftTypography>
          <SoftTypography
            variant="h6"
            fontSize="small"
            textTransform="uppercase"
          >
            Completion Standards
          </SoftTypography>
          <SoftTypography component="div" fontSize="small" mb={2}>
            {parse(course.completionStandards || "")}
          </SoftTypography>
          <SoftTypography
            variant="h6"
            fontSize="small"
            textTransform="uppercase"
          >
            Objective
          </SoftTypography>
          <SoftTypography component="div" fontSize="small" mb={2}>
            {parse(course.objective || "")}
          </SoftTypography>
        </SoftBox>
      </Card>
    </Popover>
  );
}

CourseInfoPopover.propTypes = {
  anchorEl: PropTypes.any,
  handleClosePopover: PropTypes.func,
  course: PropTypes.object,
};

export default CourseInfoPopover;
