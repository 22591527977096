import { add, differenceInDays, endOfMonth } from 'date-fns';

export const calculateReminderRemainingTime = (reminder) => {
  let timeRemaining;
  let percentRemaining;
  let percentComplete;
  let currentMeterReading;

  if (reminder?.meterBasedType?.value === 'tach1') {
    currentMeterReading = reminder.aircraft.tach1;
  } else if (reminder?.meterBasedType?.value === 'hobbs') {
    currentMeterReading = reminder.aircraft.hobbs;
  } else if (reminder?.meterBasedType?.value === 'tt') {
    currentMeterReading = reminder.aircraft.tt;
  }

  if (reminder.type.value === 'meter') {
    timeRemaining =
      Math.round(
        (reminder.meterBasedLastCompleted +
          reminder.meterBasedRemindEvery -
          (currentMeterReading ?? 0)) *
          100
      ) / 100;
    percentRemaining = Math.round((timeRemaining / reminder.meterBasedRemindEvery) * 100);
    percentComplete = 100 - percentRemaining;
  } else if (reminder.type.value === 'time') {
    const dateLastCompleted =
      Object.prototype.toString.call(reminder.timeBasedLastCompletedOn) === '[object Date]'
        ? reminder.timeBasedLastCompletedOn
        : reminder.timeBasedLastCompletedOn.toDate();
    let dateDue = add(
      Object.prototype.toString.call(reminder.timeBasedLastCompletedOn) === '[object Date]'
        ? reminder.timeBasedLastCompletedOn
        : reminder.timeBasedLastCompletedOn.toDate(),
      {
        [`${reminder.timeBasedRemindEveryUnit.value}`]: reminder.timeBasedRemindEvery,
      }
    );
    if (reminder?.timeBasedEndOfMonth) {
      dateDue = endOfMonth(dateDue);
    }
    timeRemaining = differenceInDays(dateDue, new Date());
    percentRemaining = Math.round(
      (timeRemaining / differenceInDays(dateDue, dateLastCompleted)) * 100
    );
    percentComplete = 100 - percentRemaining;
  } else {
    timeRemaining =
      Math.round(
        (reminder.meterBasedLastCompleted +
          reminder.meterBasedRemindEvery -
          (currentMeterReading ?? 0)) *
          100
      ) / 100;
    percentRemaining = (timeRemaining / reminder.meterBasedRemindEvery) * 100;
    percentComplete = 100 - percentRemaining;
  }
  // percentRemaining = Math.min(Math.floor(percentRemaining), 100);
  // percentRemaining = Math.max(percentRemaining, 0);
  //
  // percentComplete = Math.min(Math.floor(percentComplete), 100);
  // percentComplete = Math.max(percentComplete, 0);
  //
  // timeRemaining = Math.max(timeRemaining, 0);
  return { timeRemaining, percentRemaining, percentComplete };
};
