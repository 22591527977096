import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import PlaneIcon from "src/assets/images/plane-icon.png";
import SoftBox from "../SoftBox";

interface IImageLoader
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  defaultImage?: string;
  containerStyles?: React.CSSProperties;
  loaderVariant?: "default" | "small";
}
function ImageLoader({
  defaultImage = PlaneIcon,
  containerStyles = {},
  loaderVariant = "default",
  ...rest
}: IImageLoader) {
  const [imageUrl, setImageUrl] = useState(rest.src || defaultImage);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const onErrorHandler = () => {
    setImageUrl(defaultImage);
    setIsImageLoading(false);
  };

  useEffect(() => {
    setImageUrl(rest.src || defaultImage);
    setIsImageLoading(true);
  }, [rest.src]);

  return (
    <SoftBox
      style={{
        ...rest.style,
        ...containerStyles,
        position: "relative",
      }}
    >
      <img
        {...rest}
        src={imageUrl}
        loading="lazy"
        onError={onErrorHandler}
        onLoad={() => setIsImageLoading(false)}
      />
      {isImageLoading && (
        <CircularProgress
          sx={{
            top: "40%",
            left: "50%",
            position: "absolute",
            ...(loaderVariant === "small"
              ? { width: "20px !important", height: "20px !important" }
              : {}),
          }}
        />
      )}
    </SoftBox>
  );
}
export default ImageLoader;
