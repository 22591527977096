import { Timestamp } from "firebase/firestore";

export interface IDocumentType {
  value: string;
  label: string;
}

export interface IDocument {
  id: string;
  createdAt: Timestamp;
  fileType: string;
  deleted: boolean;
  storagePath: string;
  documentType: string;
  url: string;
  clubId: string;
  fileName: string;
  documentTypeLabel: string;
}

export const documentTypeUploadModal: IDocumentType[] = [
  { label: "POH", value: "poh" },
  { label: "W&B", value: "weightAndBalance" },
  { label: "Other", value: "" },
];

export enum medicalClasses {
  firstClass = "firstClass",
  secondClass = "secondClass",
  thirdClass = "thirdClass",
  basicMed = "basicMed",
}
export interface IUserDocumentUpload {
  createdAt: Date;
  documentType: string;
  url: string;
  storagePath: string;
  fileType: string;
  fileName: string;
  fileSize: number;
  id: string;
  expireDate?: Date | Timestamp;
  issueDate?: Date | Timestamp;
  medicalData?: {
    medicalClass: medicalClasses;
    firstExpiration: Date | Timestamp;
    secondExpiration: Date | Timestamp;
    thirdExpiration: Date | Timestamp;
  }
  path: string;
}
