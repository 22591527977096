import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { query, orderBy } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getCourseLessonsCollection } from "src/features/lms/collections";
import PropTypes from "prop-types";
import { getNextLessonUrl } from "../../utils";
import { useMemo } from "react";

export default function LessonHeader({ lesson }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedClubId } = useClubs();
  const { lessonId } = useParams();

  const { data: lessons } = useRealtimeCollectionData(
    query(
      getCourseLessonsCollection(
        selectedClubId,
        lesson.courseId,
        lesson.stageId
      ),
      orderBy("index", "asc")
    )
  );

  const nextLesson = useMemo(
    () => lessons?.find((l) => l.index === lesson?.index + 1),
    [lessons, lesson]
  );

  const handleClickNextLesson = () => {
    navigate(getNextLessonUrl(pathname, lessonId, nextLesson?.id));
  };

  return (
    <SoftBox display="flex" justifyContent="space-between">
      <SoftBox display="flex" alignItems="flex-start">
        <IconButton
          onClick={() => navigate(-1)}
          aria-label="Back"
          sx={{ p: 0 }}
        >
          <ChevronLeftIcon fontSize="large" color="text" />
        </IconButton>
        <SoftBox
          width="0.6rem"
          height="100%"
          sx={{
            backgroundColor: "primary.background",
            mr: "0.8rem",
            ml: "0.2rem",
          }}
        />
        <SoftBox>
          <SoftTypography
            color="secondary"
            textTransform="uppercase"
            fontSize="small"
            fontWeight="medium"
            pt="0.55rem"
          >
            Lesson {lesson.index + 1}
          </SoftTypography>
          <SoftTypography variant="h3" color="text" textTransform="capitalize">
            {lesson.title || "Loading..."}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {nextLesson && (
        <SoftBox display="flex" alignItems="flex-start">
          <SoftTypography
            color="secondary"
            textTransform="uppercase"
            fontSize="small"
            fontWeight="medium"
            pt="0.55rem"
            mr="0.2rem"
            ml="0.5rem"
            minWidth="85.2px"
          >
            Next Lesson
          </SoftTypography>
          <IconButton
            onClick={handleClickNextLesson}
            aria-label="Next"
            sx={{ p: 0 }}
          >
            <ChevronRightIcon fontSize="large" color="text" />
          </IconButton>
        </SoftBox>
      )}
    </SoftBox>
  );
}

LessonHeader.propTypes = {
  lesson: PropTypes.object.isRequired,
};
