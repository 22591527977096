import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const getTableColumns = (onAction) => [
  {
    Header: "Custom Document Types",
    accessor: "name",
  },
  {
    Header: "Actions",
    width: "10%",
    Cell: ({ row: { original } }) => (
      <SoftBox sx={{ display: "flex" }}>
        <IconButton
          aria-label="delete"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.DELETE, original)}
        >
          <Icon>delete</Icon>
        </IconButton>
        <IconButton
          aria-label="edit"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.EDIT, original)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </SoftBox>
    ),
  },
];
export const getTableStandardColumns = () => [
  {
    Header: "Standard Document Types",
    accessor: "name",
  },
  {
    Header: "",
    id: "blank",
    width: "10%",
    Cell: () => <SoftBox sx={{ display: "flex" }} />,
  },
];
