import SingleNote from "../SingleNote";
import { INote } from "../interface";
import TabPanel from "src/components/TabPanel";
import { Modal } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import { useEffect, useMemo, useState } from "react";
import { IUseClub } from "src/interfaces";
import { useClubs } from "src/features/club/ClubProvider";

import BookingInfoModal from "src/modals/BookingInfoModal";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";
import SoftTypography from "src/components/SoftTypography";

interface INotesListProps {
  bookingNotes: INote[];
  userNotes: INote[];
  selectedTab: number;
  refreshNotes?: () => void;
}
export default function NotesList({
  bookingNotes,
  userNotes,
  selectedTab,
  refreshNotes = () => {},
}: INotesListProps) {
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    locationUsers,
    locationAircraft,
    locationSqauwks,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs() as IUseClub;
  const { meetingRooms } = useClubMeetingRooms();
  const [resourceData, setResourceData] = useState<any[]>([]);
  const [pilotData, setPilotData] = useState<any[]>([]);
  const [selectedBookingId, setSelectedBookingId] = useState<string>("");
  const [openBookingInfoModal, setOpenBookingInfoModal] = useState(false);

  const instructorsData = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const getBookingInfoData = async () => {
    const aircraftData = await fetchAircraftResourceData(
      selectedClub?.preferences?.calendar?.hideTailNumbers,
      locationAircraft,
      locationSqauwks
    );
    const pilotsData = fetchClubPilotsData(locationUsers, clubUsersPermissions);
    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);

    setResourceData([...instructorsData, ...aircraftData, ...meetingRoomData]);
    setPilotData(pilotsData);
  };

  useEffect(() => {
    getBookingInfoData();
  }, [
    selectedClubId,
    selectedLocationId,
    locationUsers,
    locationSqauwks,
    clubUsersPermissions,
    meetingRooms,
  ]);

  const handleBookingInfoOpen = (bookingId: string) => {
    setSelectedBookingId(bookingId);
    setOpenBookingInfoModal(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfoModal(false);
    setSelectedBookingId("");
    refreshNotes();
  };

  return (
    <SoftBox>
      <TabPanel value={selectedTab} index={0} sx={{ p: 0 }}>
        {userNotes.map((note) => (
          <SingleNote note={note} key={note.id} />
        ))}
      </TabPanel>
      <TabPanel value={selectedTab} index={1} sx={{ p: 0 }}>
        {bookingNotes.length ? (
          bookingNotes.map((note) => (
            <SingleNote
              onBookingNoteClick={handleBookingInfoOpen}
              note={note}
              withCalendarDate
              key={note.id}
            />
          ))
        ) : (
          <SoftTypography fontSize="small" fontWeight="small">
            <i>No notes yet.</i>
          </SoftTypography>
        )}
      </TabPanel>
      <Modal
        open={openBookingInfoModal}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BookingInfoModal
          bookingId={selectedBookingId}
          handleClose={handleBookingInfoClose}
          pilots={pilotData}
          resources={resourceData}
        />
      </Modal>
    </SoftBox>
  );
}
