// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Soft UI Dashboard PRO React components
// import DefaultInfoCard from 'src/containers/Cards/InfoCards/DefaultInfoCard';

// Billing page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import Invoices from "src/pages/club-billing/account-details/components/Invoices";
import PendingPayments from "src/pages/user/account/billing/components/PendingPayments";
import Memberships from "src/pages/user/account/billing/components/Memberships";
import Transactions from "src/pages/user/account/billing/components/Transactions";
import { useUser } from "src/features/user/UserProvider";
// import ThinBarChart from 'src/components/Charts/BarCharts/ThinBarChart';
// import thinBarChartData from 'template-app/layouts/dashboards/smart-home/data/thinBarChartData';

function Billing() {
  const { userId } = useUser();
  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <SoftBox mb={3}>
                <Transactions />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={6} xl={5}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="Upcoming"
                    description="Scheduled Income/Expenses"
                    value="+$2000"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={7}>
                  <ThinBarChart title="Monthly Income" height="9.8rem" chart={thinBarChartData} />
                </Grid> */}
                <Grid item xs={12}>
                  <Memberships />
                </Grid>
                <Grid item xs={12}>
                  <PendingPayments />
                </Grid>
                <Grid item xs={12}>
                  <Invoices userId={userId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}

export default Billing;
