// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftAvatar from "src/components/SoftAvatar";
import SoftTypography from "src/components/SoftTypography";

function MemberNameCell({ image, name, checked = false }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <Checkbox defaultChecked={checked} />
      <SoftBox mx={1}>
        <SoftAvatar
          src={image}
          alt="profile-image"
          variant="rounded"
          size="sm"
        />
        {/* <SoftBox component="img" src={image} alt={name} width="100%" height="100%" /> */}
      </SoftBox>
      <SoftTypography variant="p" color="primary" fontWeight="bold">
        {name}
      </SoftTypography>
    </SoftBox>
  );
}

// Typechecking props for the ProductCell
MemberNameCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default MemberNameCell;
