import { Stack } from "@mui/material";
import { useMemo } from "react";
import { SoftCollapsibleSelect } from "src/components/SoftCollapsibleSelect/SoftCollapsibleSelect";
import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";

interface ReservationTypesProps {
  selectedReservationTypes: string[];
  setReservationTypes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ReservationTypes = ({
  selectedReservationTypes,
  setReservationTypes,
}: ReservationTypesProps) => {
  const { locationReservationTypes } = useClubs() as IUseClub;
  const reservations = useMemo(
    () => Array.from(locationReservationTypes?.values()),
    [locationReservationTypes]
  );
  return (
    <>
      <Stack direction="column" gap="5px" mt="12px">
        <SoftCollapsibleSelect
          title="Reservation Types"
          selectedItemsByDefault={selectedReservationTypes ?? []}
          items={reservations.map((d) => ({
            id: d.id,
            label: d.label,
          }))}
          onChange={(e) => setReservationTypes(e)}
        />
      </Stack>
    </>
  );
};
