import { getDoc, getDocs, doc } from "firebase/firestore";
import {
  getUserPermissionsCollection,
  getUsersCollection,
} from "src/features/user/collections";
import { getBillingCollection } from "src/features/billing/collections";
import { getFunctions, httpsCallable } from "firebase/functions";

const getUserWithId = async (userId) =>
  getDoc(doc(getUsersCollection(), userId));

const getUserPermissionsWithId = async (userId) =>
  getDocs(getUserPermissionsCollection(userId));

const getUserBalanceWithId = async (userId) =>
  getDoc(doc(getBillingCollection(), userId));

const getClubAccountBalancesWithId = async (userId, clubId) =>
  getDoc(doc(getBillingCollection(), userId, "clubAccounts", clubId));

const createStripeCustomer = async (user) => {
  try {
    const functions = getFunctions();
    const createStripeCustomerFunction = httpsCallable(
      functions,
      "createStripeCustomer"
    );
    const res = await createStripeCustomerFunction({
      id: user.uid,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
    return res;
  } catch (error) {
    console.error("CUSTOMER ERROR", error);
  }
};

export {
  getUserWithId,
  getUserPermissionsWithId,
  getUserBalanceWithId,
  getClubAccountBalancesWithId,
  createStripeCustomer,
};
