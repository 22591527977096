import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "src/context";

function PageLayout({ background = "default", children }) {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <SoftBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{
        overflowX: "hidden",
      }}
    >
      {children}
    </SoftBox>
  );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
