import { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AirplanemodeActive } from "@mui/icons-material";
import SoftTypography from "src/components/SoftTypography";
import TabPanel from "src/components/TabPanel";
import { getAircraftDocumentsCollection } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import InformationBite from "src/components/InformationBite";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import AircraftCard from "src/pages/resources/aircrafts/AircraftCard";
import SquawksList from "src/components/SquawksList";
import DocumentsList from "src/components/DocumentsList";
import Modal from "@mui/material/Modal";
import { UploadDocumentModal } from "src/modals/UploadDocument";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import Swal from "sweetalert2";
import { doc, updateDoc } from "firebase/firestore";
import toastr from "toastr";

const iconAnim = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const StyledAirplanemodeActive = styled(AirplanemodeActive)`
  animation: ${iconAnim} 4s infinite;
`;

const documentTypes = [
  { label: "POH", value: "poh" },
  { label: "W&B", value: "weightAndBalance" },
  { label: "Other", value: "" },
];

function AircraftInfo({ aircraft }) {
  const [tabValue, setTabValue] = useState(0);
  const { selectedClubId } = useClubs();
  const { addData } = entityCrudUtils();

  const uploadPath = `clubs/${selectedClubId}/aircraft/${aircraft?.id}/documents`;

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [openAddDocument, setOpenAddDocument] = useState(false);
  const handleOpenAddDocument = () => setOpenAddDocument(true);
  const handleCloseAddDocument = async (documentsAdded) => {
    if (documentsAdded && documentsAdded.length > 0) {
      await Promise.all(
        documentsAdded.map(async (document) => {
          await addData(
            getAircraftDocumentsCollection(selectedClubId, aircraft?.id),
            document
          );
        })
      );
    }
    setOpenAddDocument(false);
  };

  const deleteDocument = async (document) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(
            doc(
              getAircraftDocumentsCollection(selectedClubId, aircraft?.id),
              document.id
            ),
            { deleted: true }
          );
          toastr.success("Document deleted successfully");
        }
      });
  };

  if (!aircraft)
    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexGrow="1"
        sx={{ height: "100%" }}
      >
        <StyledAirplanemodeActive
          color="dark"
          sx={{ width: 130, height: 130 }}
        />
        <SoftTypography>please select an aircraft</SoftTypography>
      </SoftBox>
    );

  return (
    <SoftBox sx={{ flexGrow: 1 }} variant="contained" padding={1}>
      <Grid container>
        <Grid
          lg={4}
          md={4}
          xs={12}
          item
          // sx={{ display: { lg: 'block', xxs: 'none', xs: 'block', sm: 'none', md: 'none' } }}
        >
          <AircraftCard aircraft={aircraft} />
        </Grid>

        <Grid xs={12} md={8} padding={1} width="100%" item>
          <Tabs
            value={tabValue}
            onChange={handleSetTabValue}
            variant="scrollable"
          >
            <Tab label="Aircraft Times" />
            <Tab label="Description" />
            <Tab label="Specs" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <SoftTypography variant="body2" padding={1}>
              Hobbs Time: {aircraft.hobbs}
            </SoftTypography>
            <SoftTypography variant="body2" padding={1}>
              Tach Time: {aircraft.tach1}
            </SoftTypography>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SoftTypography variant="caption">
              {aircraft?.description !== "" ? (
                aircraft.description
              ) : (
                <i>No description for this aircraft</i>
              )}
            </SoftTypography>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
              <Grid item xs={4}>
                <InformationBite
                  label="Manufacturer"
                  value={
                    aircraft.aircraft_description?.manufacturer ??
                    aircraft.details?.mfr
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Model"
                  value={
                    aircraft.aircraft_description?.model ??
                    aircraft.details?.model
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Tail Number"
                  value={
                    aircraft.aircraft_description?.tail_number ??
                    aircraft.tailNumber
                  }
                />
              </Grid>{" "}
              <Grid item xs={4}>
                <InformationBite
                  label="Year"
                  value={
                    aircraft.aircraft_description?.year ??
                    aircraft.details?.yearMfr
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Engine"
                  value={
                    aircraft.aircraft_description?.type_engine ??
                    aircraft.details?.typeEngine
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Type"
                  value={
                    aircraft.aircraft_description?.type ??
                    aircraft.details?.typeAircraft
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Serial"
                  value={
                    aircraft.aircraft_description?.serial_number ??
                    aircraft.details?.serialNumber
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InformationBite
                  label="Engine Manuf."
                  value={
                    aircraft.airworthiness?.engine_manufacturer ??
                    aircraft.details?.engine?.mfr
                  }
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
        <Grid item xs={12} marginTop={2} borderRadius="sm">
          <SquawksList clubId={selectedClubId} aircraft={aircraft} />
        </Grid>
        <Grid item xs={12} marginTop={2} borderRadius="sm">
          <DocumentsList
            documentsCollection={getAircraftDocumentsCollection(
              selectedClubId,
              aircraft?.id
            )}
            openUploadModal={handleOpenAddDocument}
            documentTypes={documentTypes}
            deleteDocument={deleteDocument}
          />
        </Grid>
      </Grid>
      <Modal
        open={openAddDocument}
        onClose={handleCloseAddDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <UploadDocumentModal
          handleClose={handleCloseAddDocument}
          documentTypes={documentTypes}
          storagePath={uploadPath}
        />
      </Modal>
    </SoftBox>
  );
}

AircraftInfo.propTypes = {
  aircraft: PropTypes.object,
};

export default AircraftInfo;
