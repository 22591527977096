import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { UserAvatar } from "src/components/UserAvatar";

function Header({ userSnapshot }) {
  const user = userSnapshot.data();
  const [visible, setVisible] = useState(true);
  const handleSetVisible = () => setVisible(!visible);

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <UserAvatar user={user} />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {user.displayName}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                UUID: {userSnapshot.id}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              ml: "auto",
            }}
          >
            <SoftBox
              display="flex"
              justifyContent={{
                md: "flex-end",
              }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

Header.propTypes = {
  userSnapshot: PropTypes.object.isRequired,
};

export default Header;
