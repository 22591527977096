import IconAvatar from "src/components/IconAvatar";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

interface ICourseFactoidProps {
  icon: React.ReactNode;
  content: React.ReactNode | string;
}

export default function CourseFactoid({ icon, content }: ICourseFactoidProps) {
  return (
    <SoftBox display="flex" alignItems="center">
      <IconAvatar sx={{ mr: 1 }}>{icon}</IconAvatar>
      <SoftTypography variant="h6" fontSize="small" fontWeight="medium">{content}</SoftTypography>
    </SoftBox>
  );
}
