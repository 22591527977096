import { Card, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import DataTable from "src/components/Tables/DataTable";
import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import Footer from "src/components/Footer";
import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import { format } from "date-fns";
import { useClubs } from "src/features/club/ClubProvider";
import { getDocs, query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import SoftBadge from "src/components/SoftBadge";

const columns = [
  {
    Header: "Student",
    accessor: "extendedProps.pilot.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Instructor",
    accessor: "extendedProps.instructor.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Date",
    accessor: "start",
    Cell: ({ value }) =>
      value ? <DefaultCell value={format(value?.toDate(), "PP")} /> : null,
  },
  {
    Header: "Type",
    accessor: "extendedProps.type.label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Outcome",
    accessor: "flightData.checkrideOutcome",
    Cell: ({ value }) => (
      <SoftBadge
        badgeContent={value || "N/A"}
        color={
          value === "pass"
            ? "success"
            : value === "fail"
            ? "error"
            : "secondary"
        }
        size="lg"
        sx={{ ml: 0 }}
      />
    ),
  },
];

export default function CheckRidesReport() {
  const { selectedLocationId, selectedClubId } = useClubs();
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [stats, setStats] = useState({ passed: 0, failed: 0, incomplete: 0 });

  const getBookings = async () => {
    const bookingDocs = await getDocs(
      query(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        where("flightData.checkrideOutcome", "!=", null)
      )
    );
    const counts = { passed: 0, failed: 0, incomplete: 0 };
    const bookingData = bookingDocs.docs.map((bookingDoc) => {
      if (bookingDoc.data()?.flightData?.checkrideOutcome === "pass") {
        counts.passed += 1;
      } else if (bookingDoc.data()?.flightData?.checkrideOutcome === "fail") {
        counts.failed += 1;
      } else {
        counts.incomplete += 1;
      }
      return {
        ...bookingDoc.data(),
        id: bookingDoc.id,
      };
    });
    setBookings(bookingData);
    setStats(counts);
    setIsLoading(false);
  };

  const percentageOfTotal = (num, total) => {
    return (Math.round((num / total) * 100) || 0) + " %";
  };

  useEffect(() => {
    getBookings();
  }, [selectedLocationId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <SoftBox display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </SoftBox>
      ) : (
        <SoftBox>
          <SoftBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Passed",
                    fontWeight: "medium",
                  }}
                  count={stats.passed + " Checkrides"}
                  icon={{
                    color: "success",
                    component: percentageOfTotal(stats.passed, bookings.length),
                    isCustom: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Failed",
                    fontWeight: "medium",
                  }}
                  count={stats.failed + " Checkrides"}
                  icon={{
                    color: "error",
                    component: percentageOfTotal(stats.failed, bookings.length),
                    isCustom: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MiniStatisticsCard
                  title={{
                    text: "Incomplete",
                    fontWeight: "medium",
                  }}
                  count={stats.incomplete + " Checkrides"}
                  icon={{
                    color: "info",
                    component: percentageOfTotal(
                      stats.incomplete,
                      bookings.length
                    ),
                    isCustom: true,
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Card>
              <DataTable
                table={{
                  columns,
                  rows: bookings.sort((a, b) => b.start - a.start),
                }}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [10, 20, 50, 100],
                }}
                canSearch
              />
            </Card>
          </SoftBox>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
