import { Stack } from "@mui/material";
import { SoftCollapsibleSelect } from "src/components/SoftCollapsibleSelect/SoftCollapsibleSelect";
import { requiredUserDetails } from "src/config/localDocuments";

interface RequiredUserDetailsProps {
  selectedUserDetails: string[];
  setRequiredUserDetails: React.Dispatch<React.SetStateAction<string[]>>;
}

export const RequiredUserDetails = ({
  selectedUserDetails,
  setRequiredUserDetails,
}: RequiredUserDetailsProps) => {
  return (
    <>
      <Stack direction="column" gap="5px" mt="12px">
        <SoftCollapsibleSelect
          title="Required User Details"
          selectedItemsByDefault={selectedUserDetails ?? []}
          items={requiredUserDetails}
          onChange={(e) => setRequiredUserDetails(e)}
        />
      </Stack>
    </>
  );
};
