import { Stack } from "@mui/material";
import { SoftCollapsibleSelect } from "src/components/SoftCollapsibleSelect/SoftCollapsibleSelect";

interface RoleTypesProps {
  selectedRoles: string[];
  setRoleTypes: React.Dispatch<React.SetStateAction<string[]>>;
}

const roleTypes = ["Staff", "Instructor", "Mechanic"];

export const RoleTypes = ({ selectedRoles, setRoleTypes }: RoleTypesProps) => {

  return (
    <>
      <Stack direction="column" gap="5px" mt="12px">
        <SoftCollapsibleSelect
          title="Role Types"
          selectedItemsByDefault={selectedRoles ?? []}
          items={roleTypes.map((d) => ({
            id: d,
            label: d,
          }))}
          onChange={(e) => setRoleTypes(e)}
        />
      </Stack>
    </>
  );
};
