import { useEffect, useState, useMemo } from "react";

// @mui material components
import { Grid, Modal, Icon, Card, Tab, Tabs } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import { collection, getDocs, query, where } from "firebase/firestore";

// Soft UI Dashboard PRO React example components
import Calendar from "./Calendar";

import { useCollection } from "react-firebase-hooks/firestore";
import { useUser } from "src/features/user/UserProvider";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import { useClubs } from "src/features/club/ClubProvider";

import {
  fetchInstructorResourceData,
  fetchAircraftResourceData,
  fetchSimulatorResourceData,
  fetchClubPilotsData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";
import {
  getBookingTitle,
  getClassName,
} from "src/pages/book-flight/Calendar/uiHelper";
import { getClubBookingsCollection } from "src/features/club/collections";

import { AircraftInfoModal } from "src/modals/AircraftInfoModal";
import BookingInfoModal from "src/modals/BookingInfoModal";
import { startOfDay } from "date-fns";
import { usePermissions } from "src/hooks/usePermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";
import MySchedule from "../schedule/my-schedule";

const iconStyle = {
  position: "absolute",
  left: "5px",
  color: "red",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <SoftBox sx={{ p: 3 }}>{children}</SoftBox>}
    </div>
  );
}

// const eventDidMount = (info) => {
//   // Using https://atomiks.github.io/tippyjs/v6/html-content/
//   if (info?.event?.end && info?.event?.start) {
//     tippy(info.el, {
//       content: `<div><strong>${
//         info.event.extendedProps.pilot?.label || info.event.extendedProps.aircraft?.label
//       }</strong></div><div><b>Start: </b>${format(
//         info.event.start,
//         'eee MMM do yyyy @ HH:mm'
//       )}</div><div><b>End: </b>${format(info.event.end, 'eee MMM do yyyy @ HH:mm')}</div>`,
//       allowHTML: true,
//       hideOnClick: true,
//     });
//   }
// };

function BookFlight() {
  const [bookingData, setBookingData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  const { userId } = useUser();
  const { canEditBooking } = useUserPermissions();
  const {
    selectedClubId,
    selectedClub,
    selectedLocationId,
    locationUsers,
    locationAircraft,
    locationSqauwks,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
    locationReservationTypes,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [calendarStartDate, setCalendarStartDate] = useState(new Date());

  const [linkedResources, setLinkedResources] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const [openAircraftInfo, setOpenAircraftInfo] = useState(false);
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const { hasAccess, isStaff } = usePermissions();

  const handleAircraftInfoOpen = (aircraft) => {
    setSelectedAircraft(aircraft);
    setOpenAircraftInfo(true);
  };
  const handleSimulatorInfoOpen = (aircraft) => {
    setSelectedAircraft(aircraft);
    setOpenAircraftInfo(true);
  };

  const handleAircraftInfoClose = () => {
    setSelectedAircraft(null);
    setOpenAircraftInfo(false);
  };

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id?.replace("dup_", ""));
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenBookingInfo(false);
      setSelectedBookingId(null);
    }
  };

  const renderResourceContent = (resourceInfo) => {
    let prefix;
    let { title } = resourceInfo.resource;

    if (resourceInfo?.resource?.extendedProps?.showTailNumber) {
      const [first, ...rest] = title.split(" - ");
      title = rest.join(" - ");
      prefix = first;
    }

    if (resourceInfo.resource.extendedProps.type === "Aircraft") {
      let isGrounded = false;

      if (
        resourceInfo.resource.extendedProps.payload.groundingSquawks?.length > 0
      )
        isGrounded = true;

      return (
        <span>
          <SoftBox
            sx={{
              display: "inline",
              cursor: hasAccess([systemPermissions.VIEW_AIRCRAFT_DETAILS])
                ? "pointer"
                : "unset",
              color: isGrounded ? "red" : "#3b3b3b",
            }}
            onClick={() => {
              if (hasAccess([systemPermissions.VIEW_AIRCRAFT_DETAILS])) {
                handleAircraftInfoOpen({
                  ...resourceInfo.resource.extendedProps.payload,
                  id: resourceInfo.resource.id,
                });
              }
            }}
          >
            {isGrounded && resourceInfo?.view?.type === "resourceTimeline" && (
              <Icon fontSize="default" sx={iconStyle}>
                info
              </Icon>
            )}
            {prefix && (
              <SoftBox
                sx={{ display: "inline" }}
                color={isGrounded ? "error" : null}
              >
                <b>{prefix}</b>
                {" - "}
              </SoftBox>
            )}
            <SoftBox
              sx={{ display: "inline" }}
              color={isGrounded ? "error" : null}
            >
              {title}
            </SoftBox>
          </SoftBox>
        </span>
      );
    }
    if (resourceInfo.resource.extendedProps.type === "Simulators") {
      let isGrounded = false;

      if (
        resourceInfo.resource.extendedProps.payload.groundingSquawks?.length > 0
      )
        isGrounded = true;

      return (
        <span>
          <SoftBox
            sx={{ display: "inline", cursor: "pointer" }}
            onClick={() =>
              handleSimulatorInfoOpen({
                ...resourceInfo.resource.extendedProps.payload,
                id: resourceInfo.resource.id,
              })
            }
          >
            {prefix && (
              <SoftBox
                sx={{ display: "inline" }}
                color={isGrounded ? "error" : null}
              >
                <b>{prefix}</b>
                {" - "}
              </SoftBox>
            )}
            <SoftBox sx={{ display: "inline" }}>{title}</SoftBox>
          </SoftBox>
        </span>
      );
    }
    if (resourceInfo.resource.extendedProps.type === "Instructors") {
      return (
        <span>
          <SoftBox
            sx={{ display: "inline", cursor: "pointer", width: "250px" }}
            // onClick={() =>
            //   handleSimulatorInfoOpen({
            //     ...resourceInfo.resource.extendedProps.payload,
            //     id: resourceInfo.resource.id,
            //   })
            // }
          >
            {prefix && (
              <SoftBox sx={{ display: "inline" }}>
                <b>{prefix}</b>
                {" - "}
              </SoftBox>
            )}
            <SoftBox sx={{ display: "inline" }}>{title}</SoftBox>
          </SoftBox>
        </span>
      );
    }
    if (resourceInfo.resource.extendedProps.type === "MeetingRooms") {
      return (
        <span>
          <SoftBox
            sx={{ display: "inline", cursor: "pointer", minWidth: "250px" }}
            // onClick={() =>
            //   handleSimulatorInfoOpen({
            //     ...resourceInfo.resource.extendedProps.payload,
            //     id: resourceInfo.resource.id,
            //   })
            // }
          >
            {prefix && (
              <SoftBox sx={{ display: "inline" }}>
                <b>{prefix}</b>
                {" - "}
              </SoftBox>
            )}
            <SoftBox sx={{ display: "inline" }}>{title}</SoftBox>
          </SoftBox>
        </span>
      );
    }
    return <span>{resourceInfo.fieldValue}</span>;
  };

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircrafts = await fetchAircraftResourceData(
      selectedClub?.preferences?.calendar?.hideTailNumbers,
      locationAircraft,
      locationSqauwks
    );
    const simulators = await fetchSimulatorResourceData(
      selectedClubId,
      selectedLocationId
    );
    const pilots = fetchClubPilotsData(locationUsers, clubUsersPermissions);
    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);

    const linkedResourcesToSet = [];
    instructors.forEach((instructor) => {
      if (instructor.payload?.linkedResources?.length > 0) {
        instructor.payload.linkedResources.forEach((linkedResource) => {
          linkedResourcesToSet.push({
            resourceId: instructor.id,
            ref: linkedResource,
          });
        });
      }
    });
    aircrafts.forEach((aircraft) => {
      if (aircraft.payload?.linkedResources?.length > 0) {
        aircraft.payload.linkedResources.forEach((linkedResource) => {
          linkedResourcesToSet.push({
            resourceId: aircraft.id,
            ref: linkedResource,
          });
        });
      }
    });
    simulators.forEach((simulator) => {
      if (simulator.payload?.linkedResources?.length > 0) {
        simulator.payload.linkedResources.forEach((linkedResource) => {
          linkedResourcesToSet.push({
            resourceId: simulator.id,
            ref: linkedResource,
          });
        });
      }
    });
    setLinkedResources(linkedResourcesToSet);

    setResourceData([
      ...aircrafts,
      ...simulators,
      ...instructors,
      ...meetingRoomData,
    ]);
    setPilotData(pilots);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedClubId,
    selectedLocationId,
    instructors,
    meetingRooms,
    locationUsers,
    locationSqauwks,
    clubUsersPermissions,
  ]);

  const fetchResourceData = async (fetchInfo, successCallback) => {
    successCallback(
      resourceData.filter((resource) => !resource.payload.hidden)
    );
  };

  const [bookingsSnapshot, loadingBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", false),
      where("end", ">=", startOfDay(calendarStartDate))
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const loadBookingData = async () => {
    // if (loadingBookings) return;
    const bookings = [];
    if (bookingsSnapshot) {
      bookingsSnapshot.docs.map((document) => {
        const booking = document.data();
        bookings.push({
          ...booking,
          title: getBookingTitle(booking, userId, isStaff(), selectedClub),
          id: document.id,
          start: booking.start.toDate(),
          end: booking.end.toDate(),
          editable: canEditBooking(booking),
          className: getClassName(booking, userId),
        });
      });
    }

    let linkedBookings = [];
    if (linkedResources?.length > 0) {
      linkedBookings = await Promise.all(
        linkedResources.map(async (linkedResource) => {
          const locations = await getDocs(
            query(collection(linkedResource.ref.parent.parent, "locations"))
          );
          const locationBookings = await Promise.all(
            locations.docs.map(async (location) => {
              const locBookings = await getDocs(
                query(
                  collection(
                    linkedResource.ref.parent.parent,
                    "locations",
                    location.id,
                    "bookings"
                  ),
                  where("cancelled", "==", false),
                  where("resourceIds", "array-contains", linkedResource.ref.id)
                )
              );
              return locBookings?.docs?.map((document) => {
                const booking = document.data();
                if (booking.extendedProps.instructor?.value) {
                  booking.extendedProps.instructor.value = null;
                }
                booking.extendedProps.aircraft.value =
                  linkedResource.resourceId;
                booking.resourceIds = [linkedResource.resourceId];
                return {
                  ...booking,
                  title: "External",
                  id: document.id,
                  start: booking.start.toDate(),
                  end: booking.end.toDate(),
                  editable: false,
                  className: "completed-other",
                };
              });
            })
          );
          return locationBookings;
        })
      );
    }
    setBookingData([...bookings, ...linkedBookings.flat(2)]);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    loadBookingData();
  }, [
    bookingsSnapshot,
    linkedResources,
    loadingBookings,
    locationReservationTypes,
  ]);

  return (
    <DashboardLayout removePadding>
      <DashboardNavbar addPadding />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox
            sx={{
              height: "100%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SoftBox mt={3}>
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                        className="bottomConnect"
                      >
                        <Tab label="Club Schedule" {...a11yProps(0)} />
                        <Tab label="My Schedule" {...a11yProps(1)} />
                      </Tabs>
                    </SoftBox>
                    <CustomTabPanel value={tabValue} index={0}>
                      {resourceData?.length > 0 && (
                        <Calendar
                          events={bookingData}
                          pilots={pilotData}
                          resourceLabelContent={renderResourceContent}
                          resources={fetchResourceData}
                          resourceData={resourceData}
                          datesSet={(dateInfo) => {
                            setCalendarStartDate(dateInfo.start);
                          }}
                          // eventDidMount={eventDidMount}
                          eventClick={(info) =>
                            handleBookingInfoOpen(info.event)
                          }
                        />
                      )}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      <MySchedule />
                    </CustomTabPanel>
                  </Card>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footer />
      <Modal
        open={openBookingInfo}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BookingInfoModal
          bookingId={selectedBookingId || ""}
          handleClose={handleBookingInfoClose}
          pilots={pilotData}
          resources={resourceData}
        />
      </Modal>
      <Modal
        open={openAircraftInfo}
        onClose={handleAircraftInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AircraftInfoModal
          handleClose={handleAircraftInfoClose}
          aircraft={selectedAircraft}
        />
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_BOOK_A_FLIGHT,
  BookFlight
);
