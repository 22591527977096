// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "src/containers/DefaultNavbar";
import PageLayout from "src/containers/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "src/assets/theme/base/typography";

// Authentication layout components
import Footer from "src/components/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "src/routes/page.routes";

// Images
// import crashedAircraft from "src/assets/images/CrashedAircraft.png";

function Error500() {
  const { d1, d3, d4, d5 } = typography;

  const handleGoToHome = () => {
    window.location.href = "/";
  };

  return (
    <PageLayout white>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        // action={{
        //   type: 'external',
        //   route: 'https://creative-tim.com/product/soft-ui-dashboard-pro-react',
        //   label: 'buy now',
        //   color: 'dark',
        // }}
      />
      <SoftBox my={18} height="calc(100vh - 18rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={5}>
              <SoftBox
                fontSize={{
                  xs: d5.fontSize,
                  sm: d4.fontSize,
                  md: d3.fontSize,
                  lg: d1.fontSize,
                }}
                lineHeight={1.2}
              >
                <SoftTypography
                  variant="inherit"
                  color="primary"
                  fontWeight="bold"
                >
                  Error 7700
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" color="text" fontWeight="bold">
                Mayday, Mayday, Mayday!
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text" opacity={0.6}>
                  A notification of the error has been sent to the PreFlight
                  Team, try going back to the homepage while we investigate this
                  issue.
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <SoftButton
                  variant="outlined"
                  color="primary"
                  onClick={handleGoToHome}
                >
                  go to homepage
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default Error500;
