import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import moment from "moment/moment";
import { Controller } from "react-hook-form";
import SoftDatePicker from "src/components/SoftDatePicker";
import Checkbox from "@mui/material/Checkbox";
import { RemindAtIntervalForm, RemindAtIntervalTypes } from "..";

export function FormEltBatInspection({
  control,
  errors,
  watch,
  register,
  unregister,
}) {
  const type = "timeBased";
  const [nextDue, setNextDue] = useState();

  const lastCompletedOn = watch(`${type}LastCompletedOn`);
  const remindEvery = watch(`${type}RemindEvery`);
  const remindEveryUnit = watch(`${type}RemindEveryUnit`);
  const endOfMonth = watch(`${type}EndOfMonth`);

  useEffect(() => {
    if (lastCompletedOn && remindEvery && remindEveryUnit) {
      const nextDueDate = moment(lastCompletedOn).add(
        parseInt(remindEvery, 10),
        remindEveryUnit.value
      );

      if (endOfMonth) {
        nextDueDate.endOf("month");
      }
      const formattedNextDue = nextDueDate.format("MM/DD/yyyy");
      setNextDue(formattedNextDue);
    }
  }, [lastCompletedOn, remindEvery, remindEveryUnit, endOfMonth]);

  return (
    <>
      <Grid item xs={12} sm={4}>
        <SoftBox
          mb={1}
          width="100%"
          ml={0.5}
          lineHeight={0}
          display="inline-block"
        >
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Last Completed Date
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={`${type}LastCompletedOn`}
          render={({ field: { onChange, onBlur, value } }) => (
            <SoftDatePicker
              dateFormat="Pp"
              style={{ width: "100%" }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
            />
          )}
        />
        {errors?.[`${type}LastCompletedOn`]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[`${type}LastCompletedOn`].message}
          </SoftTypography>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Next Due
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex">
          <SoftInput disabled placeholder="0" value={nextDue} />
        </SoftBox>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        mt={5}
        bgcolor="dark"
        display="flex"
        alignItems="center"
      >
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <Controller
            control={control}
            name={`${type}EndOfMonth`}
            render={({ field: { onChange, value, ref } }) => (
              <SoftBox display="flex" alignItems="center">
                <Checkbox checked={value} inputRef={ref} onChange={onChange} />
                <SoftTypography variant="button">
                  End of the month?
                </SoftTypography>
              </SoftBox>
            )}
          />
          {errors?.[`${type}EndOfMonth`]?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors[`${type}EndOfMonth`].message}
            </SoftTypography>
          )}
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        {RemindAtIntervalForm({
          control,
          errors,
          register,
          unregister,
          reminderTypes: [
            RemindAtIntervalTypes.days,
            RemindAtIntervalTypes.months,
          ],
          watch,
        })}
      </Grid>
    </>
  );
}
