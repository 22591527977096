import { Component } from "react";
import Error500 from "src/pages/error/500";
import { datadogLogs } from "@datadog/browser-logs";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    datadogLogs.logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error500 />;
    }

    return this.props.children;
  }
}
