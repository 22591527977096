import { useClubs } from "src/features/club/ClubProvider";
import { useStorage } from "src/hooks/useStorage";
import { IAircraft } from "src/interfaces";
import get from "lodash/get";
const AIRCRAFT_KEY = "AircraftSearchHistory";
const MAX_LENGTH = 20;

export const useAircraftSearch = () => {
  const { getStorageItem, saveStorageItem } = useStorage();
  const { selectedClubId, selectedLocationId } = useClubs();
  const getId = () => `${selectedClubId}-${selectedLocationId}`;
  const getAircrafts = (): IAircraft[] => {
    const aircrafts = getStorageItem(AIRCRAFT_KEY);
    return get(aircrafts, getId(), []);
  };

  const getAllAircrafts = (): { [key: string]: IAircraft[] } => {
    return getStorageItem(AIRCRAFT_KEY) ?? {};
  };

  const saveAircraft = (aircraft: IAircraft) => {
    let aircrafts = getAircrafts();
    const foundIndex = aircrafts.findIndex((ac) => ac.id === aircraft.id);

    if (foundIndex !== -1) {
      aircrafts = aircrafts
        .slice(0, foundIndex)
        .concat(aircrafts.slice(foundIndex + 1));
    }
    if (aircrafts.length >= MAX_LENGTH) {
      aircrafts.pop();
    }
    aircrafts = [aircraft].concat(aircrafts);
    saveStorageItem(AIRCRAFT_KEY, { ...getAllAircrafts(), [getId()]: aircrafts });
  };

  const getAircraftById = (aircraftId: string) => {
    return getAircrafts().find((ac) => ac.id === aircraftId);
  };

  return { saveAircraft, getAircrafts, getAircraftById };
};
