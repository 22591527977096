import * as yup from "yup";

export const schema = yup
  .object({
    displayName: yup.string().required("A display name is required."),
    engineCount: yup.object().required("Number of engines is required."),
    billFrom: yup.object().required("Billing from is required."),
    quickbooksItem: yup.object().optional(),
    rates: yup.lazy((obj) => {
      const shape: any = {};
      Object.keys(obj).forEach((key) => {
        if (key === "standard") {
          shape[key] = yup
            .number()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .required("Hourly rate is required.");
        } else {
          shape[key] = yup
            .number()
            .transform((value) => (Number.isNaN(value) ? undefined : value));
        }
      });
      return yup.object().shape(shape);
    }),
    hobbs: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Starting hobbs is required."),
    tt: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Airframe Total Time is required."),
    tach1: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount: any) => engineCount.value >= 1,
        then: (elementSchema) => elementSchema.required("Tach 1 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach2: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount: any) => engineCount.value >= 2,
        then: (elementSchema) => elementSchema.required("Tach 2 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach3: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount: any) => engineCount.value >= 3,
        then: (elementSchema) => elementSchema.required("Tach 3 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
    tach4: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .when("engineCount", {
        is: (engineCount: any) => engineCount.value >= 4,
        then: (elementSchema) => elementSchema.required("Tach 4 is required."),
        otherwise: (elementSchema) => elementSchema.notRequired(),
      }),
  })
  .required();
