import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { getClubCustomRatesCollection } from "src/features/club/collections";
import * as toastr from "toastr";
import { CircularProgress, IconButton, Grid, Stack } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftSelect from "src/components/SoftSelect";
import Checkbox from "@mui/material/Checkbox";
import SoftButton from "src/components/SoftButton";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IAircraft, IUseClub } from "src/interfaces";
import { schema } from "./aircraftEdit.schema";

type rates = { id: string; label: string };

interface IAircraftEdit {
  aircraft: IAircraft;
}

export default function AircraftEdit({ aircraft }: IAircraftEdit) {
  const functions = getFunctions();
  const [quickbooksItems, setQuickbooksItems] = useState<Array<any>>([]);
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);
  const [aircraftRates, setAircraftRates] = useState<rates[]>([]);
  const {
    selectedClubId,
    selectedClub,
    clubLocations,
    clubMemberships,
    clubAccounts,
  } = useClubs() as IUseClub;
  const { addData, updateData } = entityCrudUtils();

  useEffect(() => {
    const getItems = httpsCallable(functions, "getQuickbooksItems-default");
    getItems({ clubId: selectedClubId })
      .then((result: any) => {
        if (typeof result.data !== "string") {
          setQuickbooksItems(result.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [selectedClubId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...aircraft,
      billFrom: {
        label: aircraft.billFrom
          ? aircraft.billFrom.charAt(0).toUpperCase() +
            aircraft.billFrom.slice(1)
          : "Hobbs",
        value: aircraft.billFrom || "hobbs",
      },
      enabledRates: aircraft.enabledRates || [],
      tachFollowsHobbs: aircraft.tachFollowsHobbs || false,
      rates: aircraft.rates || {},
      engineCount: {
        value: aircraft.engineCount || 1,
        label: `${aircraft.engineCount || 1}`,
      },
    },
  });

  useEffect(() => {
    console.debug("Yup Errors", errors);
  }, [errors]);

  const engineCount = watch("engineCount");
  const enabledRates = watch("enabledRates");
  const locations = watch("locations");
  const selectedMemberships = watch("restrictions.memberships");

  const { data: clubRates } = useRealtimeCollectionData(
    getClubCustomRatesCollection(selectedClubId),
    false
  );

  useEffect(() => {
    const rates: rates[] = [];
    clubAccounts.forEach((account: any) => {
      if (account.hasFixedRate) {
        rates.push({
          id: account.id,
          label: `${account.name}`,
        });
      }
    });

    clubRates.forEach((rate: any) => {
      rates.push({
        id: rate.id,
        label: rate.label,
      });
    });

    setAircraftRates(rates);
  }, [clubAccounts, clubRates]);

  const [picture, setPicture] = useState<any>(null);

  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   setImgData(reader.result);
      // });
      // reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setFirebaseInProgress(true);
      const storage = getStorage();

      if (data.engineCount) {
        data.engineCount = data.engineCount.value;
      }
      if (data.billFrom) {
        data.billFrom = data.billFrom.value;
      }

      const docToUpload = {
        ...aircraft,
        ...data,
        index: aircraft?.index || 0,
        deleted: false,
        disabled: false,
      };
      if (data.locations && typeof data.locations === "string") {
        docToUpload.locations = [data.locations];
      }

      if (data.memberships && typeof data.memberships === "string") {
        docToUpload.memberships = [data.memberships];
      }

      if (picture) {
        const fileName = uuidv4() + picture?.name;
        const storageRef = ref(storage, fileName);

        const fi = await uploadBytes(storageRef, picture);
        docToUpload.photo = await getDownloadURL(ref(storage, fi.ref.name));
      }

      if (!aircraft.id) {
        await addData(getAircraftCollection(selectedClubId), docToUpload);
      } else {
        await updateData(
          {
            entity: getAircraftCollection(selectedClubId),
            pathSegmentsArr: [aircraft.id],
          },
          docToUpload
        );
      }

      toastr.success(`Aircraft editted!`, "Success");
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setFirebaseInProgress(false);
    }
  };

  const isLocationSelected = (location: string) => {
    if (typeof locations === "string") {
      return location === locations;
    }
    if (typeof locations === "object") {
      return (
        typeof locations?.find((element) => element === location) === "string"
      );
    }

    return false;
  };

  const isMembershipSelected = (membership: string) => {
    if (typeof selectedMemberships === "string") {
      return membership === selectedMemberships;
    }
    if (typeof selectedMemberships === "object") {
      return (
        typeof selectedMemberships?.find(
          (element) => element === membership
        ) === "string"
      );
    }

    return false;
  };

  const renderTachInputs = () => {
    const tachInputs = [];
    tachInputs.push(
      <Grid item xs={6} key="tachFollowsHobbs">
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Tach follows Hobbs?
            </SoftTypography>
          </SoftBox>
          <Controller
            control={control}
            name="tachFollowsHobbs"
            render={({ field: { onChange, value, ref: inputRef } }) => (
              <Checkbox
                inputRef={inputRef}
                value={value}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        </SoftBox>
      </Grid>
    );
    for (let i = 1; i <= engineCount?.value; i += 1) {
      tachInputs.push(
        <Grid item xs={6} key={i}>
          <SoftBox mb={2} lineHeight={1.25}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Tach
                {engineCount?.value > 1 && ` ${i}`} *
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="0"
              {...register(`tach${i}` as any, {
                required: true,
              })}
            />
            {(errors as any)[`tach${i}`]?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {(errors as any)[`tach${i}`].message}
              </SoftTypography>
            )}
          </SoftBox>
        </Grid>
      );
    }
    return tachInputs;
  };

  if (firebaseInProgress) {
    return (
      <Grid
        container
        columns={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <CircularProgress />
          <SoftTypography mt={2}>Saving</SoftTypography>
        </Grid>
      </Grid>
    );
  }

  return (
    <SoftBox>
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Stack direction="column" flexWrap="wrap" padding="0 20px">
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Upload photo
                </SoftTypography>
              </SoftBox>
              <SoftInput
                height="1.6rem"
                size="large"
                placeholder="0"
                type="file"
                onChange={onChangePicture}
              />
            </SoftBox>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Display Name *
                </SoftTypography>
              </SoftBox>
              <SoftInput
                placeholder="Plane Name"
                {...register("displayName", {
                  required: true,
                })}
              />
              {errors?.displayName?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.displayName.message}
                </SoftTypography>
              )}
            </SoftBox>
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Bill From *
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="billFrom"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftSelect
                        options={[
                          { label: "Tach", value: "tach" },
                          { label: "Hobbs", value: "hobbs" },
                        ]}
                        onChange={onChange}
                        ref={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.billFrom?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.billFrom.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Airframe Total *
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    placeholder="0"
                    {...register("tt", {
                      required: true,
                    })}
                  />
                  {errors?.tt?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.tt.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Hobbs *
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    placeholder="0"
                    {...register("hobbs", {
                      required: true,
                    })}
                  />
                  {errors?.hobbs?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.hobbs.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mb={2} lineHeight={1.25}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      # of Engines *
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="engineCount"
                    render={({ field: { onChange, value, ref: inputRef } }) => (
                      <SoftSelect
                        isDisabled={
                          aircraft.aircraft_description?.type ===
                            "Fixed Wing Single-Engine" ||
                          aircraft.details?.typeAcft ===
                            "Fixed Wing Single-Engine"
                        }
                        options={[
                          { label: "None", value: 0 },
                          { label: "1", value: 1 },
                          { label: "2", value: 2 },
                          { label: "3", value: 3 },
                          { label: "4", value: 4 },
                        ]}
                        onChange={onChange}
                        ref={inputRef}
                        value={value}
                      />
                    )}
                  />
                  {errors?.engineCount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.engineCount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2 }}>
              {renderTachInputs()}
            </Grid>
          </Stack>

          <Grid item xs={12}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Aircraft Rates
                </SoftTypography>
                <Grid container spacing={{ xs: 2 }}>
                  {aircraftRates
                    .sort((a, b) =>
                      a.id === "standard" ? -1 : b.id === "standard" ? 1 : 0
                    )
                    .map((rate) => (
                      <Grid key={rate.id} item xs={12} sm={4}>
                        <SoftBox mb={2} lineHeight={1.25}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              {rate.label}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            {rate.id !== "standard" && (
                              <Checkbox
                                value={rate.id}
                                checked={enabledRates?.includes(rate.id)}
                                {...register("enabledRates", {})}
                              />
                            )}

                            <SoftBox sx={{ width: "150px" }}>
                              <Controller
                                control={control}
                                name={`rates[${rate.id}]` as any}
                                render={({ field: { onChange, value } }) => (
                                  <SoftCurrencyInput
                                    currencySymbol="$"
                                    placeholder="0.00"
                                    outputFormat="number"
                                    onBlur={onChange}
                                    value={value}
                                  />
                                )}
                              />
                            </SoftBox>

                            {rate.id !== "standard" && (
                              <SoftBox>
                                <IconButton
                                  aria-label="delete"
                                  // onClick={}
                                >
                                  <PersonAddIcon />
                                </IconButton>
                              </SoftBox>
                            )}
                          </SoftBox>
                          {errors?.rates?.[rate.id]?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {(errors.rates as any)[rate.id].message}
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </Grid>
                    ))}
                </Grid>
              </SoftBox>
            </SoftBox>
          </Grid>

          {selectedClub?.quickbooks?.enabled && quickbooksItems.length > 0 && (
            <Grid item xs={12}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Quickbooks Override
                  </SoftTypography>
                  <Grid pt={2} container spacing={{ xs: 2 }}>
                    <Grid item xs={12}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Item for Aircraft Rental
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <Controller
                            control={control}
                            name="quickbooksItem"
                            render={({
                              field: { onChange, value, ref: inputRef },
                            }) => (
                              <SoftSelect
                                ref={inputRef}
                                options={quickbooksItems?.map((item) => ({
                                  label: item.Name,
                                  value: item.Id,
                                }))}
                                value={value}
                                onChange={onChange}
                                label="Aircraft Rental"
                              />
                            )}
                          />
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Grid>
          )}

          <Grid item xs={12}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Description
                </SoftTypography>
              </SoftBox>
              <SoftInput
                multiline
                rows={5}
                placeholder="optional description"
                {...register("description", {})}
              />
              {errors?.description?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.description.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>

          <Grid item xs={6}>
            <SoftBox mb={2} lineHeight={1.25}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Select Locations
                </SoftTypography>
              </SoftBox>
              {clubLocations.map((loc: any) => (
                <SoftBox display="flex" alignItems="center" key={loc.id}>
                  <Checkbox
                    value={loc.id}
                    checked={isLocationSelected(loc.id)}
                    {...register("locations", {})}
                  />

                  <SoftTypography component="a" href="#" variant="button">
                    {loc.name}
                  </SoftTypography>
                </SoftBox>
              ))}
              {errors?.locations?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.locations.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          {clubMemberships.length > 0 && (
            <Grid item xs={6}>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Required Membership Levels
                  </SoftTypography>
                </SoftBox>
                {clubMemberships.map((membership: any) => (
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    key={membership.id}
                  >
                    <Checkbox
                      value={membership.id}
                      checked={isMembershipSelected(membership.id)}
                      {...register("restrictions.memberships", {})}
                    />

                    <SoftTypography component="a" href="#" variant="button">
                      {membership.data().label}
                    </SoftTypography>
                  </SoftBox>
                ))}
                {(errors as any)?.memberships?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {(errors as any).memberships.message}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
          )}
        </Grid>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            Save
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}
