import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import ModalContainer from "src/components/ModalContainer";
import SoftButton from "src/components/SoftButton";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";

import { getFunctions, httpsCallable } from "firebase/functions";
import { ThreeDots } from "react-loader-spinner";

const schema = yup
  .object({
    date: yup.date().required("A transaction date is required."),
    member: yup.object().required("A member must be selected."),
    type: yup.object().required("A transaction type must be selected."),
    amount: yup.number().required("An amount is required."),
  })
  .required();

export function AddDepositModal({ handleClose, userId, accountId }) {
  const { selectedClubId, selectedLocationId, clubUsers, clubAccounts } =
    useClubs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState([]);

  const user = clubUsers.find((u) => u.uid === userId);
  const account = clubAccounts.find((a) => a.id === accountId);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    register,
  } = useForm({
    defaultValues: {
      date: new Date(),
      member: user ? { value: user.uid, label: user.displayName } : undefined,
      account: account
        ? { value: account.id, label: account.name }
        : { value: "", label: "Standard" },
    },
    resolver: yupResolver(schema),
  });

  const depositType = watch("type");

  useEffect(() => {
    const memberOptions = clubUsers.map((member) => ({
      value: member.uid,
      label: member.displayName,
    }));

    setMembers(memberOptions);
  }, [clubUsers]);

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const functions = getFunctions();
    const createDeposit = httpsCallable(functions, "createDeposit");

    const depositData = {
      type: data.type.label,
      amount: data.amount,
      account: data.account.value,
      date: data.date,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      userId: data.member.value,
    };
    if (data.type.label === "other") {
      depositData.typeOther = data.typeOther;
    }
    if (data.type.label === "finance") {
      depositData.financeCompany = data.company;
    }

    createDeposit(depositData)
      .then(() => {
        setIsSubmitting(false);
        handleClose();
      })
      .catch((error) => {
        console.error("error", error);
        setIsSubmitting(false);
      });
  };

  if (isSubmitting) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              {user && `${user.firstName}'s `}
              {account && `${account.name} Account - `}Deposit Funds
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        dateFormat="P"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.date?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.date.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              {!userId && (
                <Grid item xs={8}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Member
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox flexGrow={1} ml={2}>
                    <Controller
                      control={control}
                      name="member"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={members}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.member?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.member.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
            </Grid>
          </SoftBox>
          <SoftBox mt={2} display="flex" flexDirection="column">
            <SoftBox display="flex" justifyContent="start" mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Type:
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox ml={1}>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { onChange, ref, value } }) => (
                        <SoftSelect
                          options={[
                            { value: "cash", label: "Cash" },
                            { value: "check", label: "Check" },
                            { value: "finance", label: "Finance" },
                            { value: "other", label: "Other" },
                          ]}
                          inputRef={ref}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    {errors?.type?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.type.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            {depositType?.value === "finance" && (
              <SoftBox display="flex" justifyContent="start" mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <SoftBox>
                      <SoftTypography
                        component="label"
                        variant="body2"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Company:
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox ml={1}>
                      <SoftInput
                        {...register("company", {
                          required: true,
                        })}
                        type="text"
                      />
                      {errors?.company?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.company.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            )}
            {depositType?.value === "other" && (
              <SoftBox display="flex" justifyContent="start" mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <SoftBox />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox ml={1}>
                      <SoftInput
                        {...register("typeOther", {
                          required: true,
                        })}
                        placeholder="Please specify"
                        type="text"
                      />
                      {errors?.typeOther?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.typeOther.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            )}
            <SoftBox display="flex" justifyContent="start" mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <SoftBox>
                    <SoftTypography
                      component="label"
                      variant="body2"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Amount:
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SoftBox ml={1}>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, ref, value } }) => (
                        <SoftCurrencyInput
                          currencySymbol="$"
                          placeholder="0.00"
                          outputFormat="number"
                          onBlur={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.amount?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.amount.message}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
                {!accountId && clubAccounts?.length > 0 && (
                  <>
                    <Grid item xs={12} sm={2}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          To Account:
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <SoftBox ml={1}>
                        <Controller
                          control={control}
                          name="account"
                          render={({ field: { onChange, ref, value } }) => (
                            <SoftSelect
                              options={[].concat(
                                [{ value: "", label: "Standard" }],
                                clubAccounts?.map((account) => ({
                                  value: account.id,
                                  label: account.name,
                                }))
                              )}
                              inputRef={ref}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                        {errors?.account?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.account.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                  </>
                )}
              </Grid>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton disabled={isSubmitting} type="submit" color="primary">
              Save
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddDepositModal.propTypes = {
  handleClose: PropTypes.func,
  accountId: PropTypes.string,
  userId: PropTypes.string,
};
