import { useEffect, useMemo, useState } from "react";

import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserEnrollmentsCollection,
  getUserEnrolledCoursesCollection,
} from "src/features/user/collections";
import { fetchInstructorResourceData } from "src/pages/book-flight/utils";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubCoursesCollection } from "src/features/lms/collections";
import { useUser } from "src/features/user/UserProvider";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import SoftSelectLabel from "src/components/SoftSelectLabel";
import SingleCourseEnrollment from "./components/SingleCourseEnrollment";

const schema = yup
  .object({
    member: yup.object().nullable().required("Please select a member."),
    startDate: yup.date().required("Please provide the start date."),
    graduationDate: yup.date().required("Please provide the graduation date."),
  })
  .required();

export function EnrollNewStudentModal({ handleClose, enrollmentData }) {
  const {
    selectedClubId,
    selectedLocationId,
    clubUsers,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs();
  const { userId } = useUser();
  const { addData, updateData } = entityCrudUtils();
  const [coursesValues, setCoursesValues] = useState([]);

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(
      clubInstructors,
      clubInstructorPrefs
    ).map((instructor) => ({
      label: instructor.title,
      value: instructor.id,
    }));
  }, [clubInstructors, clubInstructorPrefs]);

  const { data: allCourses } = useRealtimeCollectionData(
    getClubCoursesCollection(selectedClubId),
    false
  );

  const selectedCourseIds = useMemo(() => {
    return coursesValues.map(({ course }) => course?.value);
  }, [coursesValues]);

  const isCoursesDataComplete = useMemo(() => {
    const areAnyMissingInfo = coursesValues.some(
      ({ instructor, course, date }) => {
        return !instructor?.value || !course?.value || !date;
      }
    );
    if (!areAnyMissingInfo) {
      return true;
    }
    return false;
  }, [coursesValues]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      member: enrollmentData?.member || null,
      startDate: enrollmentData?.startDate?.toDate() || new Date(),
      graduationDate: enrollmentData?.graduationDate?.toDate() || new Date(),
    },
    resolver: yupResolver(schema),
  });

  const handleChangeCourseValues = (value, index, field) => {
    const newCourseValues = {
      ...coursesValues[index],
      [field]: value,
    };
    setCoursesValues(coursesValues.toSpliced(index, 1, newCourseValues));
  };

  const handleAddCourse = () => {
    setCoursesValues([
      ...coursesValues,
      { course: null, instructor: null, date: null, preexisting: false },
    ]);
  };

  const handleRemoveCourse = (index) => {
    const courseValues = coursesValues[index];
    if (!courseValues.preexisting) {
      setCoursesValues(coursesValues.toSpliced(index, 1));
    } else {
      // *** INFO: To allow deletion of an enrolledCourse from this modal, uncomment the following,
      // ***       import Swal & deleteData, and remove the disabled flag in /SingleCourseEnrollment
      // const newSwal = Swal.mixin({
      //   customClass: {
      //     cancelButton: "button",
      //     confirmButton: "button button-error",
      //   },
      //   buttonsStyling: false,
      // });
      // newSwal
      //   .fire({
      //     title: "Are you sure?",
      //     text: "You will not be able to recover any progress or grades for this course.",
      //     showCancelButton: true,
      //     confirmButtonText: "Yes, delete it!",
      //     cancelButtonText: "No, cancel!",
      //     reverseButtons: true,
      //     icon: "warning",
      //   })
      //   .then((result) => {
      //     if (result.isConfirmed) {
      //       const enrolledCourse = enrollmentData.enrolledCourses.find(
      //         (enrolled) => enrolled.course.value === courseValues.course.value
      //       );
      //       if (!enrolledCourse) {
      //         console.error(
      //           "Error deleting enrolled course. Could not find course."
      //         );
      //         return;
      //       }
      //       deleteData({
      //         entity: getUserEnrolledCoursesCollection(
      //           enrolledCourse.member?.value,
      //           enrolledCourse.enrollmentId
      //         ),
      //         pathSegmentsArr: [enrolledCourse.id],
      //       });
      //       setCoursesValues(coursesValues.toSpliced(index, 1));
      //     }
      //   });
    }
  };

  const onSubmit = async (data) => {
    if (!isCoursesDataComplete) {
      setError("courses", {
        type: "custom",
        message: "Selected courses must have complete information.",
      });
      return;
    }

    let enrollmentId = enrollmentData?.id;

    if (enrollmentId) {
      await updateData(
        {
          entity: getUserEnrollmentsCollection(data.member.value),
          pathSegmentsArr: [enrollmentId],
        },
        {
          ...data,
        }
      );
    } else {
      const newEnrollment = await addData(
        getUserEnrollmentsCollection(data.member.value),
        {
          ...data,
          clubId: selectedClubId,
          locationId: selectedLocationId,
          createdAt: new Date(),
          createdBy: userId,
          status: "active",
        }
      );
      enrollmentId = newEnrollment.id;
    }
    await Promise.all(
      coursesValues.map(async ({ course, instructor, date, preexisting }) => {
        if (preexisting) {
          const enrolledCourse = enrollmentData.enrolledCourses.find(
            (ec) => ec.course.value === course.value
          );
          await updateData(
            {
              entity: getUserEnrolledCoursesCollection(
                data.member.value,
                enrollmentId
              ),
              pathSegmentsArr: [enrolledCourse.id],
            },
            {
              course,
              instructor,
              completionDate: date,
            }
          );
        } else {
          await addData(
            getUserEnrolledCoursesCollection(data.member.value, enrollmentId),
            {
              course,
              instructor,
              completionDate: date,
              enrollmentId: enrollmentId,
              clubId: selectedClubId,
              locationId: selectedLocationId,
              createdAt: new Date(),
              createdBy: userId,
              status: "NOT_STARTED",
            }
          );
        }
      })
    );
    handleClose(true);
  };

  useEffect(() => {
    if (isCoursesDataComplete) {
      clearErrors("courses");
    }
  }, [isCoursesDataComplete]);

  useEffect(() => {
    register("courses");
    if (enrollmentData?.enrolledCourses?.length) {
      setCoursesValues(
        enrollmentData.enrolledCourses.map((enrolledCourse) => ({
          course: enrolledCourse.course,
          instructor: enrolledCourse.instructor,
          date: enrolledCourse.completionDate?.toDate(),
          preexisting: true,
        }))
      );
    } else {
      setCoursesValues([
        {
          course: null,
          instructor: null,
          date: null,
          preexisting: false,
        },
      ]);
    }
  }, []);

  return (
    <ModalContainer handleClose={() => handleClose()}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {enrollmentData ? "Edit Enrollment" : "Enroll New Student"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftSelectLabel labelText="Member">
                  <Controller
                    control={control}
                    name="member"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={clubUsers.map((user) => ({
                          label: user.displayName,
                          value: user.uid,
                        }))}
                        onChange={onChange}
                        inputRef={ref}
                        disabled={enrollmentData?.id}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftSelectLabel>
              </Grid>

              <Grid item xs={12} display="flex" gap={2}>
                <SoftSelectLabel labelText="Start Date">
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        showTimeSelect
                        dateFormat="Pp"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                </SoftSelectLabel>
                <SoftSelectLabel labelText="Graduation Date">
                  <Controller
                    control={control}
                    name="graduationDate"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        showTimeSelect
                        dateFormat="Pp"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                </SoftSelectLabel>
              </Grid>
              <Grid
                item
                xs={12}
                mt={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <SoftTypography fontSize="medium" fontWeight="bold">
                  Courses
                </SoftTypography>
                <SoftButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    height: "2.5rem",
                    minHeight: 0,
                  }}
                  onClick={handleAddCourse}
                >
                  <SoftTypography
                    color="primary"
                    fontWeight="medium"
                    fontSize="small"
                  >
                    Add Course +
                  </SoftTypography>
                </SoftButton>
              </Grid>
              {coursesValues.map((value, index) => (
                <SingleCourseEnrollment
                  courseOptions={allCourses.map((course) => ({
                    label: course.title,
                    value: course.id,
                  }))}
                  selectedCourseIds={selectedCourseIds}
                  key={index}
                  index={index}
                  courseValues={value}
                  instructorOptions={instructors}
                  onChange={handleChangeCourseValues}
                  onAddRow={handleAddCourse}
                  onRemoveRow={handleRemoveCourse}
                  inErrorState={!!errors?.courses?.message}
                />
              ))}
              {errors?.courses?.message && (
                <SoftTypography
                  marginLeft={1}
                  marginTop={1}
                  fontSize={12}
                  color="error"
                >
                  {errors.courses.message}
                </SoftTypography>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {enrollmentData ? "Apply Changes" : "Enroll"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

EnrollNewStudentModal.propTypes = {
  handleClose: PropTypes.func,
  enrollmentData: PropTypes.any,
};
