import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";
// import SoftButton from "src/components/SoftButton";

// Billing page components
import Transaction from "src/pages/user/account/billing/components/Transaction";

import { useCollection } from "react-firebase-hooks/firestore";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";

import { sub, isBefore, format, subDays } from "date-fns";
import { formatCurrency } from "src/features/utils";
import { getTransactionsCollection } from "src/features/transaction/collection";
import { query, where } from "firebase/firestore";

function Transactions() {
  const [latestTransactions, setLatestTransactionsData] = useState([]);
  const [previousTransactions, setPreviousTransactionsData] = useState([]);
  const [dateRange, setDateRange] = useState([
    subDays(new Date(), 30),
    new Date(),
  ]);
  const { userId } = useUser();
  const { selectedClubId } = useClubs();

  const [transactionsSnapshot, loadingTransactions] = useCollection(
    query(
      getTransactionsCollection(userId),
      where("clubId", "==", selectedClubId),
      where("date", ">=", dateRange[0])
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingTransactions) return;

    const latest = [];
    const previous = [];
    if (transactionsSnapshot) {
      transactionsSnapshot.docs.map((document) => {
        const transaction = { id: document.id, ...document.data() };
        if (isBefore(transaction.date.toDate(), dateRange[1])) {
          if (isBefore(transaction.date.toDate(), sub(new Date(), { days: 7 })))
            previous.push(transaction);
          else latest.push(transaction);
        }
      });
    }
    setLatestTransactionsData(
      latest.sort((a, b) =>
        isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
      )
    );
    setPreviousTransactionsData(
      previous.sort((a, b) =>
        isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
      )
    );
  }, [transactionsSnapshot]);

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={2}
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Transactions
        </SoftTypography>
        <SoftBox display="flex" alignItems="flex-start">
          <SoftDateRangePicker
            value={dateRange}
            onChange={(newDates) => setDateRange(newDates)}
          />
        </SoftBox>
      </SoftBox>
      <SoftBox pt={3} pb={2} px={2}>
        {latestTransactions.length === 0 && previousTransactions.length === 0 && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>You don&apos;t have any transactions yet..</i>
            </SoftTypography>
          </SoftBox>
        )}
        {latestTransactions.length > 0 && (
          <SoftBox>
            <SoftBox mb={2}>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                latest
              </SoftTypography>
            </SoftBox>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{
                listStyle: "none",
              }}
            >
              {latestTransactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  color={transaction.type === "debit" ? "error" : "success"}
                  icon={
                    transaction.type === "debit"
                      ? "arrow_downward"
                      : "arrow_upward"
                  }
                  name={transaction.description}
                  description={format(
                    transaction.date.toDate(),
                    "eee MMM dd yyyy"
                  )}
                  value={formatCurrency(transaction.amount)}
                />
              ))}
            </SoftBox>
          </SoftBox>
        )}
        {previousTransactions.length > 0 && (
          <SoftBox>
            <SoftBox mt={1} mb={2}>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                previous
              </SoftTypography>
            </SoftBox>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{
                listStyle: "none",
              }}
            >
              {previousTransactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  color={transaction.type === "debit" ? "error" : "success"}
                  icon={
                    transaction.type === "debit"
                      ? "arrow_downward"
                      : "arrow_upward"
                  }
                  name={transaction.description}
                  description={format(
                    transaction.date.toDate(),
                    "eee MMM dd yyyy"
                  )}
                  value={`${
                    transaction.type === "debit" ? "-" : "+"
                  } ${formatCurrency(transaction.amount)}`}
                />
              ))}
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

export default Transactions;
