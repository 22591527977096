// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "./styles";
import IconButton from "@mui/material/IconButton";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

function TimelineItem({
  color = "info",
  icon,
  title,
  dateTime,
  description = "",
  lastItem = false,
  isSelected = false,
  onEdit,
  onDelete,
}) {
  return (
    <SoftBox
      position="relative"
      sx={(theme) =>
        timelineItem(theme, {
          lastItem,
        })
      }
    >
      <SoftBox
        bgColor={isSelected ? "primary" : "white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon
          sx={(theme) =>
            timelineItemIcon(theme, {
              color: isSelected ? "white" : color,
            })
          }
        >
          {icon}
        </Icon>
      </SoftBox>
      <SoftBox
        ml={5.75}
        pt={description ? 0.7 : 0.5}
        lineHeight={0}
        maxWidth="30rem"
      >
        <SoftBox sx={{ display: "flex" }}>
          <SoftTypography variant="button" fontWeight="medium" color="dark">
            {title}
          </SoftTypography>
          <SoftBox sx={{ display: "flex" }}>
            <WithPermissions permissions={[systemPermissions.MODIFY_EVENT]}>
              <IconButton
                aria-label="edit"
                sx={iconButtonStyles}
                onClick={onEdit}
              >
                <Icon>edit</Icon>
              </IconButton>
            </WithPermissions>
            <WithPermissions permissions={[systemPermissions.CANCEL_EVENT]}>
              <IconButton
                aria-label="delete"
                sx={iconButtonStyles}
                onClick={onDelete}
              >
                <Icon>delete</Icon>
              </IconButton>
            </WithPermissions>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={0.5}>
          <SoftTypography
            variant="caption"
            fontWeight="medium"
            color="secondary"
          >
            {dateTime}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={2} mb={1.5}>
          {description ? (
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
            >
              {description}
            </SoftTypography>
          ) : null}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
