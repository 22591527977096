import { collection } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getBillingCollection = () => collection(db, "billing");

const getUsersClubAccountCollection = (userId) =>
  collection(db, "billing", userId, "clubAccounts");

const getPaymentRequestsCollection = () => collection(db, "paymentRequests");

export {
  getBillingCollection,
  getPaymentRequestsCollection,
  getUsersClubAccountCollection,
};
