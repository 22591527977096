import { PropTypes } from "prop-types";
import ModalContainer from "src/components/ModalContainer";
import SoftTypography from "src/components/SoftTypography";
import AircraftDetailsForm from "src/pages/resources/aircrafts/AircraftDetailsForm";

function EditAircraftDetails({ onCompleted, aircraft }) {
  return (
    <ModalContainer handleClose={onCompleted}>
      <SoftTypography textAlign="center" textTransform="capitalize">
        Edit Aircraft
      </SoftTypography>
      <AircraftDetailsForm aircraft={aircraft} onCompleted={onCompleted} />
    </ModalContainer>
  );
}

EditAircraftDetails.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  aircraft: PropTypes.object,
};

export default EditAircraftDetails;
