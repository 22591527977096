import { useEffect, useRef, RefObject } from 'react';

/**
 * Hook that creates a ref and triggers a handler function when a click outside of the referenced element occurs.
 * @param handler - Function to call when an outside click is detected
 * @returns A ref object to attach to the element you want to monitor
 */
export function useClickOutside<T extends HTMLElement>(handler: (event: MouseEvent | TouchEvent) => void): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    // Function to call on outside click
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [handler]);

  return ref;
}

