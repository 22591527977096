import { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import DataTable from "src/components/Tables/DataTable";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import { query, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import useFileUpload from "react-use-file-upload";

import { FileIcon, defaultStyles } from "react-file-icon";
import SoftAvatar from "src/components/SoftAvatar";
import ActionsCell from "./components/ActionsCell";
import DefaultCell from "./components/DefaultCell";
import FileTypeCell from "./components/FileTypeCell";

const schema = yup
  .object({
    reservationType: yup.object(),
    startDate: yup.date(),
    endDate: yup.date(),
  })
  .required();

const dropzoneStyle = {
  border: "2px dashed #bbb",
  borderRadius: "5px",
  padding: "50px",
  textAlign: "center",
};

const columns = [
  {
    Header: "",
    accessor: "file",
  },
  {
    Header: "File Name",
    accessor: "name",
    width: "50%",
  },
  {
    Header: "size",
    accessor: "size",
    width: "10%",
  },
  {
    Header: "type",
    accessor: "type",
    align: "center",
    width: "40%",
  },
  {
    Header: "actions",
    accessor: "actions",
    align: "center",
  },
];

const standardFileTypes = [
  "photoId",
  "certificate",
  "medical",
  "tsaCitizenshipVerification",
];

export function UploadDocumentModal({
  handleClose,
  documentTypes,
  storagePath,
}) {
  const { selectedClubId } = useClubs();
  const [firebaseInProgress, setFirebaseInProgress] = useState(false);

  const clubDocumentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: clubDocumentTypes, isDataLoaded: clubDocumentTypesLoaded } =
    useRealtimeCollectionData(
      query(clubDocumentTypesRef, where("deleted", "==", false))
    );

  const [allDocumentTypes, setAllDocumentTypes] = useState([]);

  useEffect(() => {
    const types = [...documentTypes];
    if (clubDocumentTypesLoaded) {
      clubDocumentTypes.forEach((element) => {
        types.push({
          label: element.name,
          value: element.id,
        });
      });
    }
    setAllDocumentTypes(types);
  }, [clubDocumentTypes, clubDocumentTypesLoaded]);

  const { handleSubmit } = useForm({
    defaultValues: {
      reservationType: {
        label: "Reservation",
        value: "reservation",
      },
    },
    resolver: yupResolver(schema),
  });

  const [documentRows, setDocumentRows] = useState([]);

  const onSubmit = async () => {
    handleClose();
  };

  const { files, handleDragDropEvent, setFiles, removeFile } = useFileUpload();

  useEffect(() => {
    const rows = [];
    files.forEach((element) => {
      const fileType = element.type.split("/")[1];
      const size = `${Math.round(element.size / 1000).toString()}kb`;

      rows.push({
        file: ["png", "jpeg"].includes(fileType) ? (
          <SoftAvatar
            src={URL.createObjectURL(element)}
            alt="image"
            variant="rounded"
          />
        ) : (
          <SoftBox sx={{ width: "48px" }}>
            <FileIcon extension={fileType} {...defaultStyles[fileType]} />
          </SoftBox>
        ),
        name: <DefaultCell>{element.name}</DefaultCell>,
        size: <DefaultCell>{size}</DefaultCell>,
        type: (
          <FileTypeCell
            onChange={(value) => {
              element.fileType = value.value;
            }}
            rowData={element}
            documentTypes={allDocumentTypes}
          />
        ),
        actions: <ActionsCell onDelete={() => removeFile(element.name)} />,
      });
    });
    setDocumentRows(rows);
  }, [files]);

  const inputRef = useRef();

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    setFirebaseInProgress(true);
    const storage = getStorage();
    const docsUploaded = [];
    try {
      await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `${storagePath}/${file.name}`);

          const fi = await uploadBytes(storageRef, file);
          const url = await getDownloadURL(ref(storage, fi.ref.fullPath));

          const docData = {
            createdAt: new Date(),
            documentType: file.fileType,
            url,
            storagePath: fi.ref.fullPath,
            fileType: file.type.split("/")[1],
            fileName: fi.ref.name,
          };

          if (!standardFileTypes.includes(file.fileType)) {
            docData.clubId = selectedClubId;
          }

          docsUploaded.push(docData);
        })
      );
      handleClose(docsUploaded);
    } catch (error) {
      console.error("Failed to submit files.");
      setFirebaseInProgress(false);
    }
  };

  if (firebaseInProgress) {
    return (
      <ModalContainer handleClose={handleClose}>
        <Grid
          container
          columns={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <CircularProgress />
            <SoftTypography mt={2}>Saving</SoftTypography>
          </Grid>
        </Grid>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        id="MODAL"
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Upload Documents
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SoftBox sx={dropzoneStyle}>
                  <SoftBox
                    className="form-container"
                    onClick={() => inputRef.current.click()}
                  >
                    {/* Provide a drop zone and an alternative button inside it to upload files. */}
                    <SoftBox
                      onDragEnter={handleDragDropEvent}
                      onDragOver={handleDragDropEvent}
                      onDrop={(e) => {
                        handleDragDropEvent(e);
                        setFiles(e, "a");
                      }}
                    >
                      <SoftTypography variant="h6" fontWeight="bold">
                        Drag and drop files here
                      </SoftTypography>

                      <SoftButton variant="outlined" color="info">
                        Or select files to upload
                      </SoftButton>

                      {/* Hide the crappy looking default HTML input */}
                      <input
                        ref={inputRef}
                        type="file"
                        multiple
                        hidden
                        style={{ display: "none!important" }}
                        onChange={(e) => {
                          setFiles(e, "a");
                          inputRef.current.value = null;
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox py={1}>
                  <DataTable
                    table={{ columns, rows: documentRows }}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25],
                    }}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                  />
                </SoftBox>
                {/* <SoftBox>
                  <ul>
                    {fileNames.map((name) => (
                      <li key={name}>
                        <span>{name}</span>

                        <Icon onClick={() => removeFile(name)}>delete</Icon>
                        <SoftSelect options={documentTypes} />
                      </li>
                    ))}
                  </ul>

                  {files.length > 0 && (
                    <ul>
                      <li>File types found: {fileTypes.join(', ')}</li>
                      <li>Total Size: {totalSize}</li>
                      <li>Total Bytes: {totalSizeInBytes}</li>
                    </ul>
                  )}
                </SoftBox> */}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={5} xl={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={0} xl={3} />
              <Grid item xs={6} xl={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleUploadSubmit}
                  >
                    Save Documents
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

UploadDocumentModal.propTypes = {
  handleClose: PropTypes.func,
  documentTypes: PropTypes.array,
  storagePath: PropTypes.string,
};
