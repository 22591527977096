import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { ThreeDots } from "react-loader-spinner";

// Custom styles for SoftButton
import SoftButtonRoot, {
  SoftButtonOwnerStateProps,
} from "src/components/SoftButton/SoftButtonRoot";
import { ButtonTypeMap } from "@mui/material";

type SoftButtonProps = Omit<
  ButtonTypeMap["props"],
  "color" | "variant" | "ref"
> &
  Partial<SoftButtonOwnerStateProps> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    spinnerColor?: string;
  };

const SoftButton = forwardRef<HTMLButtonElement, SoftButtonProps>(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      textPadding = 20,
      circular = false,
      iconOnly = false,
      loading = false,
      spinnerColor = "white",
      children,
      ...rest
    },
    ref
  ) => (
    <SoftButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      disabled={rest.disabled || loading}
      ownerState={{
        color,
        variant,
        size,
        textPadding,
        circular,
        iconOnly,
      }}
    >
      {loading && (
        <ThreeDots
          height="20"
          width="20"
          radius="9"
          color={spinnerColor}
          ariaLabel="loading"
          wrapperStyle={{ marginRight: "8px" }}
        />
      )}
      {children}
    </SoftButtonRoot>
  )
);

// Typechecking props for the SoftButton
SoftButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  textPadding: PropTypes.number,
  iconOnly: PropTypes.bool,
  loading: PropTypes.bool,
  spinnerColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SoftButton;
