import { Editor } from "@tinymce/tinymce-react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useRef } from "react";
import { useClubs } from "src/features/club/ClubProvider";

export default function SoftEditor({ fileStoagePath, ...props }) {
  const { selectedClubId, selectedLocationId } = useClubs();

  const uploadFile = (fileData, _value, _meta) =>
    new Promise((resolve, reject) => {
      const storage = getStorage();
      const defaultPath = `clubs/${selectedClubId}/locations/${selectedLocationId}`;
      const path = `${fileStoagePath || defaultPath}/${fileData.filename()}`;
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytes(storageRef, fileData.blob());

      uploadTask
        .then(() => {
          return getDownloadURL(storageRef);
        })
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          console.error("Upload failed:", error);
          reject({ message: "Upload Error: " + error.message, remove: true });
        });
    });

  const editorRef = useRef(null);

  return (
    <Editor
      {...props}
      onInit={(_evt, editor) => {
        editorRef.current = editor;
        props.onInit?.(_evt, editor);
      }}
      ref={props.forwardedRef}
      licenseKey={import.meta.env.VITE_TINYMCE_KEY}
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      init={{
        menubar: true,
        promotion: false,
        branding: false,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "image media | removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        ...props.init,
        images_upload_handler: uploadFile,
        image_title: true,
        automatic_uploads: true,
        image_dimensions: true,
        file_picker_callback: (cb, _value, _meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e) => {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener("load", () => {
              const id = "blobid" + new Date().getTime();
              const blobCache = editorRef.current.editorUpload.blobCache;
              const base64 = reader.result.split(",")[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
      }}
    />
  );
}
