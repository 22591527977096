import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
};

export const getTableColumns = (onAction, quickbooksItems) => {
  const columns = [
    {
      Header: "Custom Rates",
      accessor: "label",
    },
    {
      Header: "Actions",
      width: "10%",
      align: "center",
      Cell: ({ row: { original } }) => (
        <SoftBox display="flex" flexDirection="column" alignItems="center">
          <SoftBox sx={{ display: "flex" }}>
            <IconButton
              aria-label="delete"
              sx={iconButtonStyles}
              onClick={() => onAction(TableActions.DELETE, original)}
            >
              <Icon>delete</Icon>
            </IconButton>
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={() => onAction(TableActions.EDIT, original)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </SoftBox>
        </SoftBox>
      ),
    },
  ];

  if (typeof quickbooksItems !== "string" && quickbooksItems?.length > 0) {
    columns.splice(1, 0, {
      Header: "Quickbooks Item",
      accessor: "qboItem",
      Cell: ({ row: { original } }) => (
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <SoftBox sx={{ display: "block", width: "100%" }}>
            <SoftSelect
              options={
                typeof quickbooksItems !== "string" &&
                quickbooksItems?.length > 0
                  ? quickbooksItems?.map((item) => ({
                      label: item.Name,
                      value: item.Id,
                    }))
                  : []
              }
              onChange={(value) =>
                onAction(TableActions.UPDATE, original, { qboItem: value })
              }
              value={original.qboItem}
            />
          </SoftBox>
        </SoftBox>
      ),
    });
  }

  return columns;
};

export const getTableColumnsStandard = (onAction, quickbooksItems) => {
  const columns = [
    {
      Header: "Default Rates",
      accessor: "label",
    },
  ];

  if (typeof quickbooksItems !== "string" && quickbooksItems?.length > 0) {
    columns.splice(1, 0, {
      Header: "Quickbooks Item",
      accessor: "qboId",
      Cell: ({ row: { original } }) => (
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <SoftBox sx={{ display: "block", width: "100%" }}>
            <SoftSelect
              options={
                typeof quickbooksItems !== "string" &&
                quickbooksItems?.length > 0
                  ? quickbooksItems?.map((item) => ({
                      label: item.Name,
                      value: item.Id,
                    }))
                  : []
              }
              onChange={(value) =>
                onAction(TableActions.UPDATE, original, { qboItem: value })
              }
              value={original.qboItem}
            />
          </SoftBox>
        </SoftBox>
      ),
    });
  }

  return columns;
};
