import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import SoftInput from "src/components/SoftInput";
import { Link } from "@mui/material";
import { UserAvatar } from "src/components/UserAvatar";

function InstructorDataForm({
  instructor,
  instructorGroundHours,
  onInstructorGroundTimeChanged,
}) {
  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <UserAvatar user={instructor} size="sm" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Instructor
            </SoftTypography>
            <SoftTypography
              variant="caption"
              color="text"
              fontSize="1.3rem"
              fontWeight="bold"
            >
              {instructor.displayName}
            </SoftTypography>
            <SoftTypography variant="caption" fontSize="0.8rem" color="text">
              <Link href={`tel:${instructor.phoneNumber}`}>
                {instructor.phoneNumber}
              </Link>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8}>
        <SoftBox
          display="flex"
          alignItems="center"
          py={2}
          borderRadius="lg"
          bgColor="light"
        >
          <Grid
            container
            spacing={1}
            display="flex"
            justifyContent="center"
            direction="column"
          >
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={1}
                display="flex"
                justifyContent="center"
              >
                <Grid item xs={12} sm={5}>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    height="100%"
                    pr={3}
                  >
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                      >
                        Ground:
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox display="flex" flexDirection="column">
                      <SoftInput
                        type="number"
                        bold
                        error={instructorGroundHours < 0}
                        size="small"
                        onWheel={(event) => event.target.blur()}
                        sx={{ maxWidth: "7rem" }}
                        onChange={(event) =>
                          onInstructorGroundTimeChanged(event.target.value)
                        }
                      />
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
InstructorDataForm.propTypes = {
  instructor: PropTypes.object.isRequired,
  instructorGroundHours: PropTypes.number,
  onInstructorGroundTimeChanged: PropTypes.func.isRequired,
};

export default InstructorDataForm;
