import { query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { getAircraftSquawksCollection } from "src/features/aircraft/collections";
import { ISquawk } from "src/interfaces";
import { isBefore } from "date-fns";

interface IUseSquawks {
  clubId: string;
  aircraftId: string;
  queryConfig?: {
    resolved?: boolean;
    deleted?: boolean;
  };
}
function useSquawks({ clubId, aircraftId, queryConfig }: IUseSquawks) {
  const [squawks, setSquawks] = useState<Array<ISquawk>>([]);
  const [squawksSnapshot, loadingSquawks] = useCollection(
    !!clubId && !!aircraftId
      ? query(
          getAircraftSquawksCollection(clubId, aircraftId),
          where("resolved", "==", !!queryConfig?.resolved),
          where("deleted", "==", !!queryConfig?.deleted)
        )
      : undefined,
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingSquawks) return;

    const squawksList: Array<ISquawk> = [];
    if (squawksSnapshot) {
      squawksSnapshot.docs.map((document) => {
        const squawk: any = document.data();
        squawksList.push({ id: document.id, ...squawk });
      });
    }
    setSquawks(
      squawksList.sort((a, b) =>
        isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
      )
    );
  }, [squawksSnapshot, loadingSquawks]);
  return { squawks, loadingSquawks };
}
export default useSquawks;
