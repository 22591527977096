import { useEffect, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Sidenav from "src/containers/Sidenav";

import { Chart, registerables } from "chart.js";

// Soft UI Dashboard PRO React routes
import authRoutes from "src/routes/auth.routes";
import menuRoutes from "src/routes/menu.routes";
import backofficeRoutes from "src/routes/user-routes/backoffice.routes";

// Soft UI Dashboard PRO React contexts
import { setMiniSidenav, useSoftUIController } from "src/context";

import mainRoutes from "src/routes/main.routes";
import { useClubs } from "src/features/club/ClubProvider";
import { usePermissions } from "./hooks/usePermissions";
import { getBrandIcons } from "./features/utils";

let navigate;
const { ipcRenderer } = window;
if (ipcRenderer) {
  ipcRenderer.receive("fromMain", (url) => {
    const route = url.slice(url.indexOf("/") + 1);
    console.log(route);
    if (navigate) navigate(route);
  });
}

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={route.component}
          key={route.key}
        />
      );
    }

    return null;
  });

function App() {
  const [controller] = useSoftUIController();
  const { direction, router } = controller;
  const { pathname } = useLocation();
  navigate = useNavigate();
  Chart.register(...registerables);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (router === "auth") {
    const originalUrl = encodeURI(window.location.pathname);
    return (
      <Routes>
        {getRoutes(authRoutes)}
        {getRoutes(mainRoutes)}
        <Route
          path="*"
          element={<Navigate to={`/auth/sign-in?original=${originalUrl}`} />}
        />
      </Routes>
    );
  }

  return <MainRoutes />;
}

function MainRoutes() {
  const { isPreFlightAdmin } = usePermissions();
  const { memberHasNoClub, selectedClubId } = useClubs();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [searchParams] = useSearchParams();

  const [routerData, setRouterData] = useState({
    userRoute: menuRoutes,
    initialRoute: "/pages/dashboard",
  });

  const getUserRoutes = () => {
    if (isPreFlightAdmin) {
      if (selectedClubId === "backoffice") {
        return backofficeRoutes;
      }
    }
    return menuRoutes;
  };

  const getInitialRoute = () => {
    const originalUrl = searchParams.get("original");

    if (
      originalUrl &&
      originalUrl !== window.location.pathname &&
      originalUrl !== "/"
    ) {
      return originalUrl;
    }

    return "/dashboard";
  };

  useEffect(() => {
    const userRoute = getUserRoutes();
    const initialRoute = getInitialRoute();
    setRouterData({
      userRoute,
      initialRoute,
    });
  }, [isPreFlightAdmin, memberHasNoClub, selectedClubId]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      {!window.location.pathname.includes("/pay/") && (
        <Sidenav
          color={sidenavColor}
          brand={getBrandIcons().brand}
          brandIcon={getBrandIcons().brandIcon}
          routes={getUserRoutes()}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}

      <Routes>
        {getRoutes(routerData.userRoute)}
        {getRoutes(mainRoutes)}
        <Route path="*" element={<Navigate to={routerData.initialRoute} />} />
      </Routes>
    </>
  );
}

export default App;
