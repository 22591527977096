import { Divider, Stack } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import { IAircraft, IUseClub } from "src/interfaces";
import get from "lodash/get";
import { SectionText, SectionTitle } from "./AircraftTypography";
import { useClubs } from "src/features/club/ClubProvider";

const DescriptionItem = ({ title, text }: { title: string; text: string }) => {
  return (
    <Stack direction="column">
      <SoftTypography
        fontSize="10px"
        sx={{ color: "#54628899", fontWeight: 600 }}
        textTransform="uppercase"
      >
        {title}
      </SoftTypography>
      <SoftTypography
        fontSize="13px"
        sx={{ color: "#414041", fontWeight: 500 }}
      >
        {text}
      </SoftTypography>
    </Stack>
  );
};

interface IAircraftDescription {
  aircraft: IAircraft;
}
function AircraftDescription({ aircraft }: IAircraftDescription) {
  const { clubRates } = useClubs() as IUseClub;
  return (
    <Stack direction="column" gap="4px">
      <SectionTitle>Description</SectionTitle>
      {aircraft.description}
      <Divider variant="fullWidth" sx={{ backgroundColor: "#C9CCD3" }} />
      <SectionTitle>Details</SectionTitle>
      <Stack direction="row" flexWrap="wrap" gap="30px">
        <DescriptionItem
          title="Manufacturer"
          text={get(aircraft, "details.mfr", "")}
        />
        <DescriptionItem
          title="Model"
          text={get(aircraft, "details.engMfrMdl")}
        />
        <DescriptionItem
          title="Tail #"
          text={get(aircraft, "tailNumber", "")}
        />
        <DescriptionItem
          title="Year"
          text={get(aircraft, "details.yearMfr", "")}
        />
        <DescriptionItem
          title="Serial"
          text={get(aircraft, "details.serialNumber", "")}
        />
        <DescriptionItem
          title="Engine"
          text={get(aircraft, "details.engine.type", "")}
        />
        <DescriptionItem
          title="Type"
          text={get(aircraft, "details.typeAircraft", "")}
        />
        <DescriptionItem
          title="Engine Manuf."
          text={get(aircraft, "details.engine.mfr", "")}
        />
      </Stack>
      <Divider variant="fullWidth" sx={{ backgroundColor: "#C9CCD3" }} />
      <SectionTitle>Rates</SectionTitle>
      <Stack direction="column" gap="16px">
        {Object.entries(aircraft.rates).map(([rateKey, value]: any) => {
          const rateLabel = clubRates.get(rateKey)?.label ?? "";
          const rateValue = value;
          return (
            !!rateLabel && (
              <Stack
                direction="row"
                justifyContent="space-between"
                key={rateKey}
              >
                <SectionText>{rateLabel}</SectionText>
                <SectionText fontWeight="700">${rateValue}</SectionText>
              </Stack>
            )
          );
        })}
      </Stack>
    </Stack>
  );
}
export default AircraftDescription;
