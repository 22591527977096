import { collection, doc } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getAnnouncementsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/announcements`);

const getClubAnnouncement = (clubId, id) =>
  doc(db, `clubs/${clubId}/announcements/${id}`);

export { getAnnouncementsCollection, getClubAnnouncement };
