import { orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { useClubs } from "src/features/club/ClubProvider";
import { IAircraft } from "src/interfaces";

export const useAircraft = () => {
  const [aircrafts, setAircrafts] = useState<Array<IAircraft>>([]);
  const { selectedClubId } = useClubs();

  const [aircraftSnap, loadingAircraft] = useCollection(
    query(
      getAircraftCollection(selectedClubId),
      where("deleted", "==", false),
      orderBy("index", "asc")
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (!aircraftSnap || loadingAircraft) return;
    const aircraftArray: Array<IAircraft> = [];
    const aircraftDocs = aircraftSnap.docs;
    if (aircraftDocs?.length > 0) {
      aircraftDocs.forEach((element) => {
        aircraftArray.push({ ...element.data(), id: element.id } as IAircraft);
      });
      setAircrafts(aircraftArray);
    }
  }, [aircraftSnap, loadingAircraft]);

  return {
    aircrafts,
    loadingAircraft,
  };
};
