import { useState } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import { CheckOutModal } from "src/modals/CheckOutModal";
import { CheckInModal } from "src/modals/CheckInModal";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { useSoftUIController } from "src/context";

function SidenavCheckInOut() {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [openCheckIn, setOpenCheckIn] = useState(false);

  const handleCheckOutOpen = () => setOpenCheckOut(true);
  const handleCheckOutClose = () => {
    setOpenCheckOut(false);
  };
  const handleCheckInOpen = () => setOpenCheckIn(true);
  const handleCheckInClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenCheckIn(false);
    }
  };

  const handleCheckOut = () => {
    handleCheckOutOpen();
  };

  const handleCheckIn = () => {
    handleCheckInOpen();
  };

  if (miniSidenav) return null;

  return (
    <WithPermissions
      permissions={[
        systemPermissions.CHECK_OUT_OWN_BOOKING,
        systemPermissions.CHECK_IN_OWN_BOOKING,
      ]}
    >
      <Grid
        container
        alignItems="center"
        mb={4}
        spacing={1}
        sx={{ marginLeft: 0, width: "100%", justifyContent: "center" }}
      >
        <WithPermissions permissions={systemPermissions.CHECK_OUT_OWN_BOOKING}>
          <Grid item xs={6}>
            <SoftButton
              size="small"
              variant="outlined"
              color="dark"
              onClick={handleCheckOut}
            >
              <SoftTypography variant="button" fontWeight="medium">
                Dispatch
              </SoftTypography>
            </SoftButton>
          </Grid>
        </WithPermissions>
        <WithPermissions permissions={systemPermissions.CHECK_IN_OWN_BOOKING}>
          <Grid item xs={6}>
            <SoftButton
              size="small"
              color="dark"
              textPadding={25}
              onClick={handleCheckIn}
            >
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="white"
              >
                Check In
              </SoftTypography>
            </SoftButton>
          </Grid>
        </WithPermissions>
        <Modal
          open={openCheckOut}
          onClose={handleCheckOutClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <CheckOutModal handleClose={handleCheckOutClose} />
        </Modal>
        <Modal
          open={openCheckIn}
          onClose={handleCheckInClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: "blur(2px)",
          }}
        >
          <CheckInModal handleClose={handleCheckInClose} />
        </Modal>
      </Grid>
    </WithPermissions>
  );
}

export default SidenavCheckInOut;
