import TailNumberCell from "./TailNumberCell";
import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import PlaneIcon from "src/assets/images/plane-icon.png";
import Switch from "@mui/material/Switch";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
  TRACK: "TRACK",
};

export const getTableColumnsAdmin = (onAction, hasAccess) => [
  // {
  //   id: 'photo',
  //   width: '20%',
  //   Cell: ({ row: { original } }) => {
  //     const { photo } = original;
  //     return <ProductCell image={photo ?? PlaneIcon} checked={false} />;
  //   },
  // },
  {
    Header: "Sim ID",
    accessor: "tailNumber",
    width: "31%",
    Cell: ({ row: { original } }) => {
      const { photo } = original;
      return (
        <TailNumberCell
          image={photo ?? PlaneIcon}
          name={original.tailNumber}
          checked={false}
        />
      );
    },
  },
  {
    Header: "Simulator Name",
    accessor: "displayName",
  },
  {
    Header: "Enabled",
    accessor: "status",
    width: "15%",
    align: "center",
    Cell: ({ row: { original } }) => {
      const { disabled } = original;
      return (
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
        >
          <Switch
            onChange={() => onAction(TableActions.TOGGLE, original)}
            checked={!disabled}
            disabled={!hasAccess([systemPermissions.DISABLE_SIMULATOR])}
          />
        </SoftBox>
      );
    },
  },
  {
    Header: "action",
    accessor: "action",
    width: "14%",
    align: "center",
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftBox display="flex" flexDirection="row">
          <WithPermissions permissions={systemPermissions.DELETE_SIMULATOR}>
            <IconButton
              aria-label="delete"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onAction(TableActions.DELETE, original);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </WithPermissions>
          <WithPermissions permissions={systemPermissions.EDIT_SIMULATOR}>
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onAction(TableActions.EDIT, original);
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
          </WithPermissions>
        </SoftBox>
      </SoftBox>
    ),
  },
];

export const getTableColumns = () => [
  // {
  //   id: 'photo',
  //   width: '20%',
  //   Cell: ({ row: { original } }) => {
  //     const { photo } = original;
  //     return <ProductCell image={photo ?? PlaneIcon} checked={false} />;
  //   },
  // },
  {
    Header: "Sim ID",
    accessor: "tailNumber",
    width: "31%",
    Cell: ({ row: { original } }) => {
      const { photo } = original;
      return (
        <TailNumberCell
          image={photo ?? PlaneIcon}
          name={original.tailNumber}
          checked={false}
        />
      );
    },
  },
  {
    Header: "Simulator Name",
    accessor: "displayName",
  },
];
