import ModalWrapper from "../ModalWrapper/ModalWrapper";
import AddBadgeModal from "./AddBadgeModal";
import { IModalBase } from "../modalConfiguration";

interface IWrappedAddBadgeModal extends IModalBase {
  badgeNo: string;
  userId: string;
}
function WrappedAddBadgeModal({
  userId,
  isOpen,
  onClose,
}: IWrappedAddBadgeModal) {
  return (
    <ModalWrapper isOpen={isOpen} onClose={() => onClose()}>
      <AddBadgeModal handleClose={onClose} userId={userId} />
    </ModalWrapper>
  );
}
export default WrappedAddBadgeModal;
