import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";
import SoftButton from "src/components/SoftButton";

// Billing page components
import Transaction from "src/pages/user/account/billing/components/Transaction";

import { useCollection } from "react-firebase-hooks/firestore";
import { useClubs } from "src/features/club/ClubProvider";

import { sub, isBefore, format, subDays, endOfDay, startOfDay } from "date-fns";
import { formatCurrency } from "src/features/utils";
import { getTransactionsCollection } from "src/features/transaction/collection";
import { getDoc, doc, query, where } from "firebase/firestore";
import { AddDepositModal } from "src/modals/AddDepositModal";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { useNavigate } from "react-router-dom";
import { getClubBookingsCollection } from "src/features/club/collections";
import { CSVLink } from "react-csv";
import { Icon } from "@mui/material";

function Transactions({ usersName, userId, accountId }) {
  const navigate = useNavigate();
  const [latestTransactions, setLatestTransactionsData] = useState([]);
  const [previousTransactions, setPreviousTransactionsData] = useState([]);
  const [openAddNewDeposit, setOpenAddNewDeposit] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [dateRange, setDateRange] = useState([
    startOfDay(subDays(new Date(), 30)),
    endOfDay(new Date()),
  ]);

  const { selectedClub, selectedClubId, selectedLocation, selectedLocationId } =
    useClubs();

  const addDeposit = () => {
    setOpenAddNewDeposit(true);
  };

  const handleNewDepositClose = () => {
    setOpenAddNewDeposit(false);
  };

  const [transactionsSnapshot, loadingTransactions] = useCollection(
    query(
      getTransactionsCollection(userId),
      where("clubId", "==", selectedClubId),
      where("date", ">=", dateRange[0])
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const viewTransaction = async (transaction) => {
    if (transaction.transactionId) {
      navigate(`/billing/invoices/${transaction.transactionId}`);
    }
    if (transaction.bookingId) {
      const bookingSnap = await getDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          transaction.bookingId
        )
      );
      const booking = bookingSnap.data();
      if (booking?.transactionId) {
        navigate(`/billing/invoices/${booking.transactionId}`);
      }
    }
  };

  const loadTransactions = () => {
    const latest = [];
    const previous = [];
    if (transactionsSnapshot) {
      transactionsSnapshot.docs.map((document) => {
        const transaction = { id: document.id, ...document.data() };
        if (accountId) {
          if (
            transaction.accountId === accountId &&
            isBefore(transaction.date.toDate(), dateRange[1])
          ) {
            if (
              isBefore(transaction.date.toDate(), sub(new Date(), { days: 7 }))
            )
              previous.push(transaction);
            else latest.push(transaction);
          }
        } else {
          if (
            !transaction.accountId &&
            isBefore(transaction.date.toDate(), dateRange[1])
          ) {
            if (
              isBefore(transaction.date.toDate(), sub(new Date(), { days: 7 }))
            )
              previous.push(transaction);
            else latest.push(transaction);
          }
        }
      });
    }
    setLatestTransactionsData(
      latest.sort((a, b) =>
        isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
      )
    );
    setPreviousTransactionsData(
      previous.sort((a, b) =>
        isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
      )
    );
  };

  useEffect(() => {
    const tableRows = [...latestTransactions, ...previousTransactions];
    updateCsvData(tableRows);
  }, [latestTransactions, previousTransactions]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      Date: row.date ? format(row.date.toDate(), "yyyy/MM/dd hh:mm:ss") : "---",
      Amount:
        row.type === "credit"
          ? Math.round(row.amount * 100) / 100
          : Math.round(row.amount * -100) / 100,
      Description: row.description || "---",
    }));

    setCsvData(csvDataFromRows);
  };

  useEffect(() => {
    if (loadingTransactions) return;
    loadTransactions();
  }, [transactionsSnapshot, loadingTransactions]);

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={2}
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Transactions {latestTransactions.length}
        </SoftTypography>
        <SoftBox display="flex" alignItems="flex-start">
          <WithPermissions permissions={systemPermissions.DEPOSIT_FUNDS}>
            <SoftButton
              variant="outlined"
              color="primary"
              // sx={{ marginLeft: 3 }}
              onClick={addDeposit}
            >
              Deposit Funds
            </SoftButton>
          </WithPermissions>
          <SoftDateRangePicker
            value={dateRange}
            onChange={(newDates) => {
              setDateRange(newDates);
            }}
          />
          {csvData && (
            <SoftBox ml={3}>
              <SoftBox>
                <CSVLink
                  data={csvData}
                  filename={`${selectedClub.name}-${
                    selectedLocation?.icao
                  }-${usersName}_transactions-${format(
                    new Date(),
                    "MM/dd/yyyy"
                  )}.csv`}
                  target="_blank"
                >
                  <SoftButton variant="outlined" color="dark">
                    <Icon>description</Icon>
                    &nbsp;export csv
                  </SoftButton>
                </CSVLink>
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <SoftBox pt={3} pb={2} px={2}>
        {latestTransactions.length === 0 && previousTransactions.length === 0 && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>There are no transactions yet..</i>
            </SoftTypography>
          </SoftBox>
        )}
        {latestTransactions.length > 0 && (
          <SoftBox>
            <SoftBox mb={2}>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                latest ({latestTransactions.length})
              </SoftTypography>
            </SoftBox>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{
                listStyle: "none",
              }}
            >
              {latestTransactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  color={transaction.type === "debit" ? "error" : "success"}
                  icon={
                    transaction.type === "debit"
                      ? "arrow_downward"
                      : "arrow_upward"
                  }
                  name={transaction.description}
                  description={format(
                    transaction.date.toDate(),
                    "eee MMM dd yyyy"
                  )}
                  value={`${
                    transaction.type === "debit" ? "-" : "+"
                  } ${formatCurrency(transaction.amount)}`}
                  onClick={() => viewTransaction(transaction)}
                />
              ))}
            </SoftBox>
          </SoftBox>
        )}
        {previousTransactions.length > 0 && (
          <SoftBox>
            <SoftBox mt={1} mb={2}>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="bold"
                textTransform="uppercase"
              >
                previous
              </SoftTypography>
            </SoftBox>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
              sx={{
                listStyle: "none",
              }}
            >
              {previousTransactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  color={transaction.type === "debit" ? "error" : "success"}
                  icon={
                    transaction.type === "debit"
                      ? "arrow_downward"
                      : "arrow_upward"
                  }
                  name={transaction.description}
                  description={format(
                    transaction.date.toDate(),
                    "eee MMM dd yyyy"
                  )}
                  value={`${
                    transaction.type === "debit" ? "-" : "+"
                  } ${formatCurrency(transaction.amount)}`}
                  onClick={() => viewTransaction(transaction)}
                />
              ))}
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
      <Modal
        open={openAddNewDeposit}
        onClose={handleNewDepositClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddDepositModal
          handleClose={handleNewDepositClose}
          userId={userId}
          accountId={accountId}
        />
      </Modal>
    </Card>
  );
}

Transactions.propTypes = {
  usersName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  accountId: PropTypes.string,
};

export default Transactions;
