import { useEffect, useState } from "react";
import { useUser } from "src/features/user/UserProvider";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import CurrencyFormat from "react-currency-format";
import { VerifyAccountModal } from "src/modals/VerifyAccountModal";
import { getFunctions, httpsCallable } from "firebase/functions";

function PaymentItem({
  date,
  id,
  totalAmount,
  data,
  noGutter = false,
  status,
  refreshPayments,
}) {
  const [statusString, setStatusString] = useState("Loading...");
  const [open, setOpen] = useState(false);
  const { userId } = useUser();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onPaymentDelete = () => {
    const functions = getFunctions();
    const cancelPaymentIntent = httpsCallable(functions, "cancelPaymentIntent");
    cancelPaymentIntent({ paymentIntentId: id });
    refreshPayments();
  };

  useEffect(() => {
    switch (status) {
      case "requires_payment_method":
        setStatusString("Payment Method Required");
        break;

      case "requires_confirmation":
        setStatusString("Payment Pending");
        break;

      case "requires_action":
        setStatusString("Payment Pending");
        break;

      case "requires_capture":
        setStatusString("Payment Pending");
        break;

      case "canceled":
        setStatusString("Payment Canceled");
        break;

      case "succeeded":
        setStatusString("Payment Succeeded");
        break;

      case "processing":
        setStatusString("Processing Payment");
        break;

      case "failed":
        setStatusString("Payment Failed");
        break;

      case "payment_intent":
        setStatusString("Payment Succeeded");
        break;

      case "payment_intent_payment_failed":
        setStatusString("Payment Failed");
        break;

      case "payment_intent_canceled":
        setStatusString("Payment Canceled");
        break;

      case "payment_intent_processing":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_requires_action":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_requires_confirmation":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_requires_capture":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_unknown":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_not_sent_for_capture":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_requires_payment_method":
        setStatusString("Payment Pending");
        break;

      case "payment_intent_requires_action_from_customer":
        setStatusString("Payment Pending");
        break;

      default:
        setStatusString("Unknown");
    }
  }, [status]);

  return (
    <SoftBox
      component="li"
      key={id}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SoftBox lineHeight={1.125}>
        <SoftTypography display="block" variant="button" fontWeight="medium">
          {date}
        </SoftTypography>
        <SoftTypography variant="caption" fontWeight="regular" color="text">
          <b>Status:</b> {statusString}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="button" fontWeight="regular" color="text">
          <CurrencyFormat
            value={totalAmount}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            prefix="$"
          />
        </SoftTypography>
        {status === "requires_action" && (
          <SoftBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            ml={3}
            sx={{
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            <Icon fontSize="small">info</Icon>
            <SoftTypography variant="button" fontWeight="bold">
              Verify
            </SoftTypography>
          </SoftBox>
        )}
        {(status === "requires_payment_method" ||
          status === "requires_capture" ||
          status === "requires_confirmation" ||
          status === "requires_action") && (
          <SoftBox
            component="span"
            onClick={() => onPaymentDelete()}
            sx={{
              color: "error.main",
              cursor: "pointer",
            }}
          >
            <Icon className="material-icons-round">delete</Icon>
          </SoftBox>
        )}
      </SoftBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <VerifyAccountModal
          paymentIntent={data}
          userId={userId}
          handleClose={handleClose}
        />
      </Modal>
    </SoftBox>
  );
}

// Typechecking props for the Invoice
PaymentItem.propTypes = {
  date: PropTypes.string.isRequired,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  totalAmount: PropTypes.number.isRequired,
  noGutter: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

export default PaymentItem;
