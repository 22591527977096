import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useCollection } from "react-firebase-hooks/firestore";
import { useUser } from "src/features/user/UserProvider";

// Billing page components
import MembershipItem from "./components/MembershipItem";
import { getMembershipsCollection } from "src/features/user/collections";
import {
  getClubMembershipsCollection,
  getClubsCollection,
} from "src/features/club/collections";
import { doc, getDoc } from "firebase/firestore";

import { AddNewMembershipModal } from "./components/AddNewMembershipModal";

function Memberships() {
  const [memberships, setMemberships] = useState([]);
  const [loadingMemberships, setLoadingMemberships] = useState(true);
  const [openAddNewMembershipModal, setOpenAddNewMembershipModal] =
    useState(false);
  const { userId } = useUser();

  const handleAddNewMembershipModalClose = () => {
    setOpenAddNewMembershipModal(false);
  };

  const [membershipsListSnapshot, loadingMembershipsList] = useCollection(
    getMembershipsCollection(userId),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const addMembershipDetails = async (allMemberships) => {
    const membershipsWithDetails = await Promise.all(
      allMemberships.map(async (membership) => {
        const { clubId } = membership;
        const club = await getDoc(doc(getClubsCollection(), clubId));
        const membershipPlan = await getDoc(
          doc(getClubMembershipsCollection(clubId), membership.membershipPlan)
        );
        let nextMembershipPlan;
        if (membership.nextMembershipPlan) {
          const nextPlanDoc = await getDoc(
            doc(
              getClubMembershipsCollection(clubId),
              membership.nextMembershipPlan
            )
          );
          nextMembershipPlan = { ...nextPlanDoc.data(), id: nextPlanDoc.id };
        }
        // const { name } = club.data();
        return {
          ...membership,
          club: club.data(),
          membershipPlan: { ...membershipPlan.data(), id: membershipPlan.id },
          nextMembershipPlan,
        };
      })
    );
    setMemberships(membershipsWithDetails);
    setLoadingMemberships(false);
  };

  useEffect(() => {
    if (loadingMembershipsList) return;

    const allMemberships = [];
    if (membershipsListSnapshot && membershipsListSnapshot.docs.length > 0) {
      membershipsListSnapshot.docs.map((document) => {
        const membershipListItem = document.data();
        allMemberships.push({
          id: document.id,
          clubId: document.id,
          ...membershipListItem,
        });
      });
      addMembershipDetails(allMemberships);
    } else {
      setLoadingMemberships(false);
    }
  }, [membershipsListSnapshot]);

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Memberships
        </SoftTypography>
        <SoftButton
          variant="outlined"
          color="info"
          size="small"
          onClick={() => setOpenAddNewMembershipModal(true)}
        >
          Add New
        </SoftButton>
      </SoftBox>
      <SoftBox p={2}>
        {memberships.length === 0 && !loadingMemberships && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>You don&apos;t have any memberships yet!</i>
            </SoftTypography>
          </SoftBox>
        )}
        {memberships.length === 0 && loadingMemberships && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>Loading your memberships..</i>
            </SoftTypography>
          </SoftBox>
        )}
        {memberships.length > 0 && (
          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {memberships.map((membership) => (
              <MembershipItem key={membership.id} membership={membership} />
            ))}
          </SoftBox>
        )}
      </SoftBox>
      <Modal
        open={openAddNewMembershipModal}
        onClose={handleAddNewMembershipModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddNewMembershipModal
          handleClose={handleAddNewMembershipModalClose}
          existingMemberships={memberships}
        />
      </Modal>
    </Card>
  );
}

export default Memberships;
