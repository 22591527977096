import { useEffect } from "react";
import SoftBox from "src/components/SoftBox";
import { Card, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { set } from "date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import { ThreeDots } from "react-loader-spinner";

const style = {
  position: "relative",
  p: 2,

  ".workDayInput > .MuiGrid-item": {
    position: "relative",
    marginTop: "15px",

    "> :first-of-type": {
      position: "absolute",
      top: "-10px",
    },

    ".MuiButton-root": {
      minWidth: "120px",
    },
  },

  ".MuiInputAdornment-positionEnd": {
    position: "absolute",
    right: "10px",
  },
};

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

const schema = yup
  .object({
    type: yup.object(),
    amount: yup.number().when("type.value", {
      is: "custom",
      then: (elementSchema) =>
        elementSchema
          .positive("Refunds must be a positive amount")
          .required("An amount is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    note: yup.string(),
  })
  .required();

export function EditWorkHours({
  workingHours,
  handleSave,
  showRequestOnlyOption = false,
  title = "Edit Work Hours",
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      monday: {
        isOff: false,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      tuesday: {
        isOff: false,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      wednesday: {
        isOff: false,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      thursday: {
        isOff: false,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      friday: {
        isOff: false,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      saturday: {
        isOff: true,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
      sunday: {
        isOff: true,
        isRequestOnly: false,
        start: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
        end: set(new Date(), { hours: 17, minutes: 0, seconds: 0 }),
      },
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (workingHours) {
      Object.entries(workingHours).map(([key, value]) => {
        if (value.start) {
          if (!(value.start instanceof Date))
            value.start = value.start.toDate();
        }
        if (value.end) {
          if (!(value.end instanceof Date)) value.end = value.end.toDate();
        }
        setValue(key, value, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      });
    }
  }, [workingHours]);

  const saveWorkHours = async (data) => {
    handleSave(data);
  };

  const shouldDisableTime = (timeValue, clockType) =>
    clockType === "minutes" && timeValue % 15;

  const copyToAll = (day) => {
    const data = getValues(day);
    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    days.forEach((d) => {
      if (d !== day) {
        setValue(d, data, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    });
  };

  if (!workingHours) {
    return (
      <Card sx={loadingStyle}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </Card>
    );
  }

  return (
    <Card id="edit-work-hours" sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(saveWorkHours)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="left"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5">{title}</SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={0} className="workDayInput">
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Monday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="monday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("monday.isRequestOnly") ||
                            watch("monday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="monday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("monday.isRequestOnly") ||
                            watch("monday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("monday.isRequestOnly")}
                        checked={watch("monday.isOff")}
                        {...register("monday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("monday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("monday.isOff")}
                          checked={watch("monday.isRequestOnly")}
                          {...register("monday.isRequestOnly")}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("monday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("monday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.monday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.monday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Tuesday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="tuesday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("tuesday.isRequestOnly") ||
                            watch("tuesday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="tuesday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("tuesday.isRequestOnly") ||
                            watch("tuesday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("tuesday.isRequestOnly")}
                        checked={watch("tuesday.isOff")}
                        {...register("tuesday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("tuesday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        {showRequestOnlyOption && (
                          <Checkbox
                            disabled={watch("tuesday.isOff")}
                            checked={watch("tuesday.isRequestOnly")}
                            {...register("tuesday.isRequestOnly")}
                          />
                        )}
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("tuesday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("tuesday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.tuesday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.tuesday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Wednesday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="wednesday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("wednesday.isRequestOnly") ||
                            watch("wednesday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="wednesday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("wednesday.isRequestOnly") ||
                            watch("wednesday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                          s
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("wednesday.isRequestOnly")}
                        checked={watch("wednesday.isOff")}
                        {...register("wednesday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("wednesday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("wednesday.isOff")}
                          checked={watch("wednesday.isRequestOnly")}
                          {...register("wednesday.isRequestOnly")}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("wednesday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("wednesday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.wednesday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.wednesday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Thursday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="thursday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("thursday.isRequestOnly") ||
                            watch("thursday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="thursday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("thursday.isRequestOnly") ||
                            watch("thursday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("thursday.isRequestOnly")}
                        checked={watch("thursday.isOff")}
                        {...register("thursday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("thursday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("thursday.isOff")}
                          checked={watch("thursday.isRequestOnly")}
                          {...register("thursday.isRequestOnly")}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("thursday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("thursday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.thursday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.thursday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Friday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="friday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("friday.isRequestOnly") ||
                            watch("friday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="friday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("friday.isRequestOnly") ||
                            watch("friday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("friday.isRequestOnly")}
                        checked={watch("friday.isOff")}
                        {...register("friday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("friday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("friday.isOff")}
                          checked={watch("friday.isRequestOnly")}
                          {...register("friday.isRequestOnly")}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("friday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("friday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.friday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.friday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Saturday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="saturday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("saturday.isRequestOnly") ||
                            watch("saturday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="saturday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("saturday.isRequestOnly") ||
                            watch("saturday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("saturday.isRequestOnly")}
                        checked={watch("saturday.isOff")}
                        {...register("saturday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("saturday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("saturday.isOff")}
                          checked={watch("saturday.isRequestOnly")}
                          {...register("saturday.isRequestOnly")}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("saturday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("saturday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.saturday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.saturday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>

              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Sunday
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  flexGrow={1}
                  ml={1}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  mt={!showRequestOnlyOption ? 2 : 0}
                >
                  <Controller
                    control={control}
                    name="sunday.start"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("sunday.isRequestOnly") ||
                            watch("sunday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftTypography
                    display="inline-flex"
                    marginLeft={1}
                    marginRight={1}
                    fontSize={12}
                  >
                    to
                  </SoftTypography>
                  <Controller
                    control={control}
                    name="sunday.end"
                    render={({ field: { onChange, value, ref } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          inputRef={ref}
                          disabled={
                            watch("sunday.isRequestOnly") ||
                            watch("sunday.isOff")
                          }
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                          shouldDisableTime={(timeValue, clockType) =>
                            shouldDisableTime(timeValue, clockType)
                          }
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <SoftBox
                    display="inline-flex"
                    flexDirection="column"
                    flexGrow={1}
                    ml={2}
                  >
                    <SoftBox width="max-content">
                      <Checkbox
                        disabled={watch("sunday.isRequestOnly")}
                        checked={watch("sunday.isOff")}
                        {...register("sunday.isOff")}
                      />
                      <SoftTypography
                        display="inline-flex"
                        marginLeft={1}
                        marginRight={1}
                        fontSize={12}
                        color={
                          watch("sunday.isRequestOnly") ? "light" : "inherit"
                        }
                      >
                        Unavailable
                      </SoftTypography>
                    </SoftBox>
                    {showRequestOnlyOption && (
                      <SoftBox width="max-content">
                        <Checkbox
                          disabled={watch("sunday.isOff")}
                          checked={watch("sunday.isRequestOnly")}
                          {...register("sunday.isRequestOnly", {})}
                        />
                        <SoftTypography
                          display="inline-flex"
                          marginLeft={1}
                          marginRight={1}
                          fontSize={12}
                          color={watch("sunday.isOff") ? "light" : "inherit"}
                        >
                          Request only
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                  <SoftButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => copyToAll("sunday")}
                  >
                    Copy to all
                  </SoftButton>
                  {errors?.sunday?.end?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.sunday.end.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={4}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton type="submit" color="primary">
              Save
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EditWorkHours.propTypes = {
  workingHours: PropTypes.shape({
    monday: PropTypes.object,
    tuesday: PropTypes.object,
    wednesday: PropTypes.object,
    thursday: PropTypes.object,
    friday: PropTypes.object,
    saturday: PropTypes.object,
    sunday: PropTypes.object,
  }),
  showRequestOnlyOption: PropTypes.bool,
  handleSave: PropTypes.func,
  title: PropTypes.string,
};
