import { DeleteOutlined } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftDatePicker from "src/components/SoftDatePicker";
import SoftSelect from "src/components/SoftSelect";
import SoftSelectLabel from "src/components/SoftSelectLabel";
import SoftTypography from "src/components/SoftTypography";

interface ISelectOption {
  label: string;
  value: string;
}

interface ICourseValues {
  course: ISelectOption | null;
  instructor: ISelectOption | null;
  date: Date | null;
  preexisting: boolean;
}

interface ICourseEnrollmentProps {
  courseOptions: ISelectOption[];
  selectedCourseIds?: string[];
  instructorOptions: ISelectOption[];
  courseValues: ICourseValues;
  onChange: (value: any, index: number, field: string) => {};
  onRemoveRow: (index: number) => {};
  index: number;
  inErrorState?: boolean;
}

export default function SingleCourseEnrollment({
  courseOptions,
  selectedCourseIds,
  courseValues,
  instructorOptions,
  onChange,
  onRemoveRow,
  index,
  inErrorState,
}: ICourseEnrollmentProps) {
  const indexInLabel = index !== 0 ? index + 1 : "";
  return (
    <Grid
      item
      xs={12}
      display="flex"
      alignItems={index === 0 ? "flex-end" : "center"}
      gap={1}
    >
      <SoftBox display="flex" gap={1} flexGrow={1}>
        <SoftSelectLabel
          labelText={`Course ${indexInLabel}`}
          hidden={index !== 0}
        >
          {!courseValues.preexisting && selectedCourseIds ? (
            <SoftSelect
              options={courseOptions.filter(
                (option) => !selectedCourseIds.includes(option.value)
              )}
              value={courseValues?.course}
              onChange={(val) => onChange(val, index, "course")}
              error={inErrorState && !courseValues?.course?.value}
            />
          ) : (
            <SoftBox display="flex" alignItems="center" height="40px">
              <SoftTypography fontWeight="regular" sx={{ fontSize: "0.75rem" }}>
                {courseValues?.course?.label}
              </SoftTypography>
            </SoftBox>
          )}
        </SoftSelectLabel>
        <SoftSelectLabel
          labelText={`Instructor ${indexInLabel}`}
          hidden={index !== 0}
        >
          <SoftSelect
            options={instructorOptions}
            value={courseValues?.instructor}
            onChange={(val) => onChange(val, index, "instructor")}
            error={inErrorState && !courseValues?.instructor?.value}
          />
        </SoftSelectLabel>
        <SoftSelectLabel
          labelText={`Completion Date ${indexInLabel}`}
          hidden={index !== 0}
        >
          <SoftDatePicker
            showTimeSelect
            dateFormat="Pp"
            selected={courseValues?.date}
            onChange={(val: any) => onChange(val, index, "date")}
            error={inErrorState && !courseValues?.date}
          />
        </SoftSelectLabel>
      </SoftBox>
      <IconButton
        sx={{ width: "2.5rem", px: 0, minWidth: 0 }}
        color="error"
        onClick={() => onRemoveRow(index)}
        disabled={courseValues.preexisting}
      >
        <DeleteOutlined fontSize="small" />
      </IconButton>
    </Grid>
  );
}
