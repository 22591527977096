import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import Divider from "@mui/material/Divider";
import { useClubs } from "src/features/club/ClubProvider";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup
  .object({
    email: yup.string().required("An email is required."),
    firstName: yup.string().required("A first name is required."),
    lastName: yup.string().required("A last name is required."),
  })
  .required();

export function UserLookup({ onNext, onClose }) {
  const { globalUsers } = useClubs();
  const [emailAddress, setEmailAddress] = useState();
  const [userData, setUserData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const findUserWithEmail = async (emailEntered) => {
    const gloablUsersArray = Array.from(globalUsers.entries());
    const match = gloablUsersArray.find(
      ([, value]) => value.email === emailEntered
    );

    if (match.length > 0) {
      setUserData(match[1]);
    }
  };

  useEffect(() => {
    setUserData(null);
    clearErrors();
    const delayDebounceFn = setTimeout(() => {
      if (emailRegex.test(emailAddress)) {
        findUserWithEmail(emailAddress);
      }
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [emailAddress]);

  useEffect(() => {
    setValue("firstName", userData?.firstName);
    setValue("lastName", userData?.lastName);
  }, [userData]);

  const onSubmit = async (data) => {
    onNext(userData || data);
  };

  return (
    <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item xs={10}>
          <SoftBox>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Enter the users email address
            </SoftTypography>
          </SoftBox>
          <SoftInput
            {...register("email", {
              required: true,
            })}
            placeholder="example@mail.com"
            onChange={(evt) => setEmailAddress(evt.target.value)}
          />
        </Grid>
        <Grid item xs={10} sm={5}>
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              First Name
            </SoftTypography>
          </SoftBox>
          <SoftInput
            {...register("firstName", {
              required: true,
            })}
            disabled={userData}
          />
          {errors?.firstName?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.firstName.message}
            </SoftTypography>
          )}
        </Grid>
        <Grid item xs={10} sm={5}>
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Last Name
            </SoftTypography>
          </SoftBox>
          <SoftInput
            {...register("lastName", {
              required: true,
            })}
            disabled={userData}
          />
          {errors?.lastName?.message && (
            <SoftTypography marginTop={1} fontSize={12} color="error">
              {errors.lastName.message}
            </SoftTypography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        columns={{ xs: 12 }}
        display="flex"
        justifyContent="flex-end"
      >
        <Grid item xs={12} marginY={2}>
          <Divider />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <SoftButton color="secondary" onClick={onClose}>
            Cancel
          </SoftButton>
          <SoftButton
            color="primary"
            sx={{
              marginLeft: 2,
            }}
            type="submit"
          >
            Next
          </SoftButton>
        </Grid>
      </Grid>
    </SoftBox>
  );
}
