/**
 For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import BackOfficeDashboard from "src/pages/dashboards/BackOffice";
import ManageClubs from "src/pages/backoffice/ManageClubs";
import ManageUsers from "src/pages/backoffice/ManageUsers";
import Settings from "src/pages/backoffice/Settings";

// Soft UI Dashboard PRO React icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";

const backOfficeRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <BackOfficeDashboard />,
    icon: <DashboardIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Manage Clubs",
    key: "manage-clubs",
    route: "/manage-clubs",
    component: <ManageClubs />,
    icon: <BusinessIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Manage Users",
    key: "manage-users",
    component: <ManageUsers />,
    icon: <GroupIcon size="12px" />,
    route: "/manage-users",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    component: <Settings />,
    icon: <SettingsIcon size="12px" />,
    route: "/settings",
    noCollapse: true,
  },
];

export default backOfficeRoutes;
