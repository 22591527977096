import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { getClubHobbsMultiplierCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddHobbsMultiplierModal } from "src/modals/AddHobbsMultiplierModal/AddHobbsMultiplierModal";
import { deleteDoc, doc } from "firebase/firestore";

function HobbsMultiplier() {
  const {
    selectedClubId,
    selectedLocationId,
    hobbsMultipliers,
  }: {
    selectedClubId: string;
    selectedLocationId: any;
    hobbsMultipliers: any[];
  } = useClubs();
  const [selectedMultiplier, setSelectedMultiplier] = useState<any>(null);
  const [openAddHobbsMultiplierModal, setOpenAddHobbsMultiplierModal] =
    useState(false);

  const deleteMultiplier = (multiplier: any) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteDoc(
            doc(
              getClubHobbsMultiplierCollection(
                selectedClubId,
                selectedLocationId
              ),
              multiplier.id
            )
          );
        }
      });
  };

  const handleTableActions = (action: any, multiplier: any) => {
    switch (action) {
      case TableActions.DELETE:
        deleteMultiplier(multiplier);
        break;
      case TableActions.EDIT:
        setSelectedMultiplier(multiplier);
        setOpenAddHobbsMultiplierModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddHobbsMultiplierClose = () => {
    setOpenAddHobbsMultiplierModal(false);
    setSelectedMultiplier(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="hobbs-multiplier"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Hobbs Multiplier</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          When an aircraft doesn't have a hobbs, you can choose to apply one of
          these multipliers to the tach in order to calculate the hobbs for
          billing. If enabled in an aircraft, the pilots will be able to select
          one if these multipliers on check-in.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <DataTable
            selectedRowId={false}
            canDrag={false}
            showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            entriesPerPage={false}
            table={{
              columns: tableColumns,
              rows: hobbsMultipliers,
            }}
          />
        </SoftBox>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddHobbsMultiplierModal(true)}
            >
              Add New Multiplier
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddHobbsMultiplierModal}
        onClose={handleAddHobbsMultiplierClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddHobbsMultiplierModal
          handleClose={handleAddHobbsMultiplierClose}
          existingMultiplier={selectedMultiplier}
        />
      </Modal>
    </Card>
  );
}

export default HobbsMultiplier;
